import { Directive, ElementRef, HostListener, Inject, Input, OnInit } from "@angular/core";

@Directive({
    selector: '[draggable]'
})
export class Draggable implements OnInit {
    private element: HTMLElement;
    private handlerNode: HTMLElement;
    private data: { x: number, y: number };

    @Input('draggable')
    private handler: string;

    @HostListener('mousedown', ['$event'])
    mousedown(e) {
        if (e.target === this.handlerNode) {
            var rect = this.element.getBoundingClientRect();
            this.data = {
                x: e.clientX - rect.left,
                y: e.clientY - rect.top
            };
        } else {
            delete this.data;
        }
    }

    @HostListener('document:mouseup', ['$event'])
    mouseup(e) {
        delete this.data;
    }
    constructor(@Inject(ElementRef) element: ElementRef) {
        this.element = element.nativeElement;
    }
    ngOnInit() {
        this.element.classList.add('dragabble');
        this.handlerNode = this.element.querySelector(this.handler);
    }

    @HostListener('document:mousemove', ['$event'])
    onPointerMove(e: PointerEvent): void {
        if (this.data) {
            var x = e.clientX - this.data.x;
            var y = e.clientY - this.data.y;
            this.element.style.left = x + 'px';
            this.element.style.top = y + 'px';
        }
    }
}