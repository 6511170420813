import { Component, OnInit } from '@angular/core';
import { EnvService } from '../../core/services/environment/env.service';
import { ErrorCustomService } from '../../core/services/error/error-custom.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {


  public UrlClientRedirect: string;

  constructor(private _errorCustom: ErrorCustomService, private _env: EnvService) {
    this.UrlClientRedirect = this._env.URL_CLIENT_BASE
  }

  ngOnInit(): void {
    this._errorCustom.SetErrorPage(true);
  }

  public ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this._errorCustom.SetErrorPage(false);
  }

  public RedirectHomePage() {
    window.onbeforeunload = null;
    window.location.href = "/#/dashboard";

  }

}
