import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../core/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorComponent } from './error/error.component';
import { SessionExpireComponent } from './session-expire/session-expire.component';
import { ContentLoaderComponent } from './util/loader/content-loader/content-loader.component';
import { ChartsModule } from 'ng2-charts';
import { ConfirmDialogComponent } from './util/confirm-dialog/confirm-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SpinnerLoaderComponent } from './util/loader/spinner-loader/spinner-loader.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LegendComponent } from './legend/legend.component';
import { PrintJsonPipe } from '../core/extension/print-json.pipe';
import { FileUploadComponent } from './util/file-upload/file-upload.component';
import { Resizable } from '../core/extension/directive/resize.directive';
import { Draggable } from '../core/extension/directive/draggable.directive';
import { EditCommandesComponent } from '../member/commandes/edit-commandes/edit-commandes.component';
import { EditLineCommandeComponent } from '../member/commandes/edit-line-commande/edit-line-commande.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { ScrollIntoViewDirective } from '../core/extension/directive/scroll-into-view.directive';
import { MatSelectAutocompleteComponent } from './util/mat-select-autocomplete/mat-select-autocomplete.component';
import { NumberInputPipe } from '../core/extension/pipe/number-input.pipe';
import { CurrencyPipeRound } from '../core/extension/pipe/currency-round.pipe';
import { SendMailDialogComponent } from './dialog/send-mail-dialog/send-mail-dialog.component';
import { PdfPreviewDialogComponent } from './dialog/pdf-preview-dialog/pdf-preview-dialog.component';
import { SnackBarConfirmComponent } from './notification/snack-bar/snack-bar-confirm/snack-bar-confirm.component';
import { AlertSnackBarComponent } from './notification/snack-bar/alert-snack-bar/alert-snack-bar.component';



@NgModule({
  declarations: [
    ErrorComponent,
    SessionExpireComponent,
    ContentLoaderComponent,
    ConfirmDialogComponent,
    SpinnerLoaderComponent,
    EditCommandesComponent,
    EditLineCommandeComponent,
    LegendComponent,
    PrintJsonPipe,
    NumberInputPipe,
    CurrencyPipeRound,
    FileUploadComponent,
    Resizable,
    Draggable,
    BreadcrumbComponent,
    ScrollIntoViewDirective,
    MatSelectAutocompleteComponent,
    SendMailDialogComponent,
    PdfPreviewDialogComponent,
    SnackBarConfirmComponent,
    AlertSnackBarComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    TranslateModule,
    CKEditorModule,
    NgxExtendedPdfViewerModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ContentLoaderComponent,
    SpinnerLoaderComponent,
    ChartsModule,
    TranslateModule,
    CKEditorModule,
    NgxExtendedPdfViewerModule,
    LegendComponent,
    PrintJsonPipe,
    NumberInputPipe,
    CurrencyPipeRound,
    FileUploadComponent,
    Resizable,
    Draggable,
    EditCommandesComponent,
    EditLineCommandeComponent,
    BreadcrumbComponent,
    RouterModule,
    ScrollIntoViewDirective,
    MatSelectAutocompleteComponent
  ],
})
export class SharedModule { }
