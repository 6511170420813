import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchArticleModel } from '../../../core/model/member/purchases/denree/search-article-result.model';

export class ViewArticleDetailData {
  constructor(public CodeDenree: string, public IuDenree: string) { }
}

@Component({
  selector: 'DialogViewArticleDetailComponent',
  templateUrl: './dialog-view-article-detail.component.html',
  styleUrls: ['./dialog-view-article-detail.component.css'],
})
export class DialogViewArticleDetailComponent implements OnInit {
  public Height: string = '100px';

  public HeightPx: Object = { height: '100px' };

  public SelectedArticleSearch: SearchArticleModel;

  constructor(
    public dialogRef: MatDialogRef<DialogViewArticleDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public viewArtDetail: ViewArticleDetailData
  ) { }

  ngOnInit(): void {

    this.SelectedArticleSearch = {
      CODE_DENREE: this.viewArtDetail.CodeDenree,
      FAMI_DENREE: '',
      IU_FOURNISSEUR: '',
      NOM_FOURNISSEUR: '',
      IU_DENREE: this.viewArtDetail.IuDenree,
      IU_FAMDEN: '',
      IU_SFAMDEN: '',
      LIBFAM_DENREE: '',
      LIBSFAM_DENREE: '',
      LIB_DENREE: '',
      SFAMI_DENREE: '',
      PRESENT: '',
      UNI_CALC: '',
      DENREE_TVA: '',
      NOM_DENREE: '',
      CATALOG_PRIX_EUROS: ''
    };
  }

  public onClose() {
    this.dialogRef.close(false);
  }
}
