import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { isPlatformBrowser } from "@angular/common";
import { Directive, ElementRef, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges } from "@angular/core";
import { MatListOption } from "@angular/material/list";

@Directive({
    selector: '[ScrollIntoView]'
})
export class ScrollIntoViewDirective implements OnChanges {

    @Input() ScrollIntoView: boolean | undefined;

    @Input() heigtParent: number;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private elementRef: ElementRef,
        // private matListOption?: MatListOption // angular matérial list option
    ) { }

    ngOnInit(): void {

    }

    ngOnChanges(simpleChange: SimpleChanges) {
        // if (isPlatformBrowser(this.platformId)) {
        //     if (coerceBooleanProperty(this.ScrollIntoView)) {
        //         let target = this.elementRef.nativeElement as HTMLInputElement;
        //         console.log( target);
        //         console.log(target.offsetTop);
        //         var topPos =  target.scrollTop + 700;
        //         console.log(topPos);
        //         target.parentElement.scrollTop =  target.offsetTop + 400;

        //     }
        //     // angular matérial list option
        //     // if (coerceBooleanProperty(this.ScrollIntoView)) {
        //     //     if (this.matListOption) {
        //     //         this.matListOption.focus();
        //     //     }
        //     // }
        // }
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platformId)) {
            if (coerceBooleanProperty(this.ScrollIntoView)) {
                let target = this.elementRef.nativeElement as HTMLInputElement;
                target.parentElement.scrollTop = target.offsetTop;
            }
        }
    }
}