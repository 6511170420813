<div class="container error-page">
    <div class="row">
        <div class="col-md-12">
            <img class="error-page-logo" src="../../../assets/images/logo-wenzi.svg" alt="W Wenzi circle" />
            <h1 class="error-page-text">OOOPS <br /> YOUR SESSION HAS EXPIRED</h1>
            <button type="button" class="btn btn-lg error-page-button" (click)="redirect()">
                LOGIN
            </button>
        </div>
    </div>

</div>

<!-- <div class="timer" style="text-align: center;">
    <br />
    {{message}}
    <br>
    <br>
    <a href="{{UrlClientRedirect}}"> Cliquez ici </a> si vous ne voulez pas attendre !
</div>
<br /><br /><br /><br /><br /><br /><br /> -->