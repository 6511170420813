import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StockService } from '../../../../core/services/member/stock/stock.service';


export class ViewArtEtatStockDetailData {

  constructor(public pCodePr: string) {
  }
}

@Component({
  selector: 'app-dialog-view-art-etat-stock',
  templateUrl: './dialog-view-art-etat-stock.component.html',
  styleUrls: ['./dialog-view-art-etat-stock.component.css']
})
export class DialogViewArtEtatStockComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogViewArtEtatStockComponent>,
    @Inject(MAT_DIALOG_DATA) public artInfo: ViewArtEtatStockDetailData,
    private _stockService: StockService,
  ) { }

  ngOnInit(): void {
  }

  public onClose() {
    this.dialogRef.close(false);
  }
}
