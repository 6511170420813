import { Component, Inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(
        private readonly snackBar: MatSnackBar,
        public dialog: MatDialog
    ) { }

    /**
     * Presents a toast displaying the message with a green background
     * @param message Message to display
     * @example
     * this.notificationService.success("confirm oked");
     */
    success(message: string) {
        // this.openSnackBar(message, '', 'success-snackbar');
        this.openSnackBar(message, '', 'mat-notification-success');
    }

    /**
     * Presents a toast displaying the message with a red background
     * @param message Message to display
     * @example
     * this.notificationService.error("confirm canceled");
     */
    error(message: string) {
        // this.openSnackBar(message, '', 'error-snackbar');
        this.openSnackBar(message, '', 'mat-notification-error');
    }

    /**
     * Displays a toast with provided message
     * @param message Message to display
     * @param action Action text, e.g. Close, Done, etc
     * @param className Optional extra css class to apply
     * @param duration Optional number of SECONDS to display the notification for
     */
    openSnackBar(
        message: string,
        action: string,
        className = '',
        duration: number = 5000,
        _horizontalPosition: MatSnackBarHorizontalPosition = 'center',
        _verticalPosition: MatSnackBarVerticalPosition = 'top'
    ) {
        this.snackBar.open(message, action, {
            duration: duration,
            horizontalPosition: _horizontalPosition,
            verticalPosition: _verticalPosition,
            panelClass: [className]
        });
    }
}

export interface DialogData {
    message: string;
    title: string;
}


