import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subscription } from 'rxjs';
import { CommandesService } from '../../../core/services/member/commandes/commandes.service';
import { CMD_GES_PRECO_HEADER } from '../../../core/model/member/commandes/cmd-ges-preco-header.model';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MailAdreseModel, SendMailCmdModel } from '../../../core/model/member/commandes/cmd-send-mail-content.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParametreService } from '../../../core/services/member/parametre/parametre.service';
import { GRAAL_CENTRE } from '../../../core/model/common/graal_centre.model';

export class SendMailCmdData {

  constructor(public Msg: string, public Cmd: CMD_GES_PRECO_HEADER, public Email_Fou: string) {
  }
}

@Component({
  selector: 'SendMailCommandesComponent',
  templateUrl: './send-mail-commandes.component.html',
  styleUrls: ['./send-mail-commandes.component.css']
})
export class SendMailCommandesComponent implements OnInit {


  private _subscriptions: Subscription[] = [];


  public RegisterFormSendCmdMail: FormGroup;
  public submittedRegisterFormSendCmdMail: boolean = false;


  private durationNotification: number = 5000;
  private _horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private _verticalPosition: MatSnackBarVerticalPosition = 'top';

  /** loader bloc pdf */

  public colorLoader: string = 'warn';
  public modeLoader: string = 'indeterminate';
  public valueLoader: string = '50';
  public displayProgressSpinner: boolean = false;

  public modelTextContent = {
    editorData: 'Bonjour'
  };

  public editor = ClassicEditor;


  constructor(public dialogRef: MatDialogRef<SendMailCommandesComponent>,
    @Inject(MAT_DIALOG_DATA) public dataSendMailCmd: SendMailCmdData,
    private _commandesService: CommandesService,
    private _snackBar: MatSnackBar,
    private _formBuilderSendMailCmd: FormBuilder,
    private _paramService: ParametreService,

  ) {

    this.RegisterFormSendCmdMail = this._formBuilderSendMailCmd.group({
      EmailDestinataire: [this.dataSendMailCmd.Email_Fou, [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
      ]],
      EmailCC: ['', [
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
      ]],
      Objet: [''],
      Message: ['']
    });
  }

  ngOnInit(): void {
    this._paramService.GetPreMailCmdSync().subscribe((res: GRAAL_CENTRE[]) => {
      if (res.length != 0) {
        this.RegisterFormSendCmdMail.controls['Objet'].setValue(res.find(p => p.CLEPAR == 'MAI01')?.HTMLTEXT);
        this.RegisterFormSendCmdMail.controls['Message'].setValue(res.find(p => p.CLEPAR == 'MAI02')?.HTMLTEXT);
      }
    });
  }

  /**
 * get form send mail
 */
  public get f() { return this.RegisterFormSendCmdMail.controls; }

  public onConfirmEnvoyer() {

    this.submittedRegisterFormSendCmdMail = true;

    if (!this.RegisterFormSendCmdMail.valid) {
      return;
    }

    let isSendOk: boolean = false;

    this.displayProgressSpinner = true;



    let dest: MailAdreseModel[] = [{
      Name: this.f.EmailDestinataire.value,
      AdressMail: this.f.EmailDestinataire.value
    }
    ];

    let destCC: MailAdreseModel[] = [{
      Name: this.f.EmailCC.value,
      AdressMail: this.f.EmailCC.value
    }
    ];

    let CmdContentMail: SendMailCmdModel = {
      IsAttach: true,
      Destinataire: dest,
      DestinataireCC: destCC,
      Objet: this.f.Objet.value,
      Message: this.f.Message.value,
      Preco_entete: this.dataSendMailCmd.Cmd
    };

    this._commandesService.GenererPDFSendMailCommande(CmdContentMail).subscribe(
      (responseSendMail: any) => {
        if (responseSendMail == "200") {

          this.NotificationMsg("La commande a été envoyée avec succès !", true);
          isSendOk = true;
        }
        else {
          this.NotificationMsg("Erreur de génération du PDF !", false);
          isSendOk = false;
        }

        if (isSendOk) {
          this.dialogRef.close(isSendOk);
        }

        this.displayProgressSpinner = false;
      }
    );

  }

  public NotificationMsg(msg: string, isOk: boolean) {
    if (isOk) {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-success']
      });
    }
    else {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-error']
      });
    }

  }

  public onAnnulerEnvoyer() {
    console.log('annuler');
    this.dialogRef.close(false);
  }

  public ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this._subscriptions.forEach((s) => s.unsubscribe());
    this.displayProgressSpinner = false;
  }

}
