import { Injectable } from '@angular/core';

@Injectable()
export class EnvService {
  public URL_CLIENT_BASE = 'https://wenzi';

  public TITLE = 'Graal quest Web';

  // Client Name
  public CLIENT_NAME = 'Ma Boîte';

  // API url
  public API_URL_BASE = 'https://localhost:44346';

  public API_AUTH_URL = 'https://localhost:44346/api/login';

  // Whether or not to enable debug mode
  public enableDebug = true;

  constructor() {
    console.log('debug : ' + this.enableDebug);
  }
}
