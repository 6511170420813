<div class="container-fluid background-whitesmoke height-100-percent">
  <div class="row">
    <!-- TODO translation -->
    <div class="col-md-8 mt-4 justify-content-center">
      <mat-card class="bottom-5 d-flex justify-content-between p-3">
        <span *ngIf="bs.NOM_FOURNISSEUR" class=" pe-2"> {{bs.NOM_FOURNISSEUR}}</span>
        <span class=" pe-2">le {{bs.DATE_MVT}}</span>
        <span *ngIf="bs.NUM_LOT" class=" pe-2">Num de Lot : {{bs.NUM_LOT}}</span>
        <span *ngIf="bs.DATE_LIMITE" class="">DLC : {{bs.DATE_LIMITE}}</span>
      </mat-card>
    </div>
    <div class="col-md-4">
      <div mat-dialog-actions class="d-flex justify-content-end">
        <button (click)="onClose()" matTooltip="{{ 'Cancel' | translate }}" mat-stroked-button
          class="button-icon-generic-cancel" type="button">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </div>



  <div class="row py-4 ">
    <!-- TODO get Infos Pictures from article -->
    <div class="col-md">
      <div class="container-art-ouput-view">
        <div class="row d-flex position-relative">
          <div *ngFor="let item of picsListWithUpdate;let i= index" class="col-md-4 ">
            <img class="icon-tab" (click)="zoomOnImg(i.toString())" id="{{i}}" src="{{item}}">
          </div>
        </div>
        <!-- <div class="row d-flex  position-relative">
          <div class="col-md-4">
            <img (click)="zoomOnImg('0')" id="0" src="https://picsum.photos/id/42/200/150">
          </div>
          <div class="col-md-4">
            <img (click)="zoomOnImg('1')" id="1" src="https://picsum.photos/id/36/200/150">
          </div>
          <div class="col-md-4">
            <img (click)="zoomOnImg('2')" id="2" src="https://picsum.photos/id/30/200/150">
          </div>
        </div> -->