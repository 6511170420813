import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../core/services/common/common.service';
import { SendMailCommonModel } from '../../../core/model/common/send-mail-common.model';
import { MailAdreseModel } from '../../../core/model/member/commandes/cmd-send-mail-content.model';
import { ParametreService } from '../../../core/services/member/parametre/parametre.service';
import { FilesAttachement } from '../../../core/model/common/files-attachement.model';
import { GRAAL_CENTRE } from '../../../core/model/common/graal_centre.model';

export class SendMailData {

  constructor(public isGetContentCmd: boolean, public Msg: string, public Email_Dest: string, public FileJoin: FilesAttachement[]) {
  }
}


@Component({
  selector: 'app-send-mail-dialog',
  templateUrl: './send-mail-dialog.component.html',
  styleUrls: ['./send-mail-dialog.component.css']
})
export class SendMailDialogComponent implements OnInit {


  private _subscriptions: Subscription[] = [];


  public RegisterFormSendCmdMail: FormGroup;
  public submittedRegisterFormSendCmdMail: boolean = false;


  private durationNotification: number = 5000;
  private _horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private _verticalPosition: MatSnackBarVerticalPosition = 'top';

  /** loader bloc pdf */

  public colorLoader: string = 'warn';
  public modeLoader: string = 'indeterminate';
  public valueLoader: string = '50';
  public displayProgressSpinner: boolean = false;

  public modelTextContent = {
    editorData: 'Bonjour'
  };

  public editor = ClassicEditor;


  constructor(public dialogRef: MatDialogRef<SendMailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataSendMail: SendMailData,
    private _snackBar: MatSnackBar,
    private _formBuilderSendMailCmd: FormBuilder,
    private _paramService: ParametreService,
    private _commonService: CommonService
  ) {

    this.RegisterFormSendCmdMail = this._formBuilderSendMailCmd.group({
      EmailDestinataire: [this.dataSendMail.Email_Dest, [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
      ]],
      EmailCC: ['', [
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
      ]],
      Objet: [''],
      Message: ['']
    });
  }

  ngOnInit(): void {
    if (this.dataSendMail.isGetContentCmd) {
      this._paramService.GetPreMailCmdSync().subscribe((res: GRAAL_CENTRE[]) => {
        if (res.length != 0) {
          this.RegisterFormSendCmdMail.controls['Objet'].setValue(res.find(p => p.CLEPAR == 'MAI01')?.HTMLTEXT);
          this.RegisterFormSendCmdMail.controls['Message'].setValue(res.find(p => p.CLEPAR == 'MAI02')?.HTMLTEXT);
        }
      });
    }
  }

  public download(pfile: FilesAttachement) {

    const newBlob = new Blob([pfile.FileArray64], { type: 'application/pdf' });
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = pfile.Name;
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 0);
  }

  /**
 * get form send mail
 */
  public get f() { return this.RegisterFormSendCmdMail.controls; }

  public onConfirmEnvoyer() {

    this.submittedRegisterFormSendCmdMail = true;

    if (!this.RegisterFormSendCmdMail.valid) {
      return;
    }

    let isSendOk: boolean = false;

    this.displayProgressSpinner = true;

    let dest: MailAdreseModel[] = [{
      Name: this.f.EmailDestinataire.value,
      AdressMail: this.f.EmailDestinataire.value
    }];

    let destCC: MailAdreseModel[] = [{
      Name: this.f.EmailCC.value,
      AdressMail: this.f.EmailCC.value
    }];

    let fileattach: FilesAttachement[] = [];
    let CmdContentMail: SendMailCommonModel = {
      Destinataire: dest,
      DestinataireCC: destCC,
      Objet: this.f.Objet.value,
      Message: this.f.Message.value,
      FilesJoin: fileattach
    };
    let promises = [];
    for (let file of this.dataSendMail.FileJoin) {
      let filePromise = new Promise(resolve => {
        let reader = new FileReader();
        var blob = new Blob([file.FileArray64])
        reader.readAsDataURL(blob);
        reader.onload = () => resolve({ result: reader.result, name: file.Name });
      });
      promises.push(filePromise);
    }

    Promise.all(promises).then(fileContents => {
      fileContents.forEach(x => {
        CmdContentMail.FilesJoin = [...CmdContentMail.FilesJoin, {
          Name: x.name,
          FileArray64: x.result
        }];
      })
      this._commonService.SendMailCommon(CmdContentMail).subscribe(
        (responseSendMail: any) => {
          if (responseSendMail == "200") {

            this.NotificationMsg("le mail a été envoyé avec succès !", true);
            isSendOk = true;
          }
          else {
            this.NotificationMsg("Erreur interne", false);
            isSendOk = false;
          }

          if (isSendOk) {
            this.dialogRef.close(isSendOk);
          }

          this.displayProgressSpinner = false;
        }
      );
    });
  }

  public NotificationMsg(msg: string, isOk: boolean) {
    if (isOk) {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-success']
      });
    }
    else {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-error']
      });
    }

  }

  public onAnnulerEnvoyer() {
    //console.log('annuler');
    this.dialogRef.close(false);
  }

  public ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this._subscriptions.forEach((s) => s.unsubscribe());
    this.displayProgressSpinner = false;
  }

}
