import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { StringNullableChain } from 'lodash';
import { Subscription } from 'rxjs';
import { CmdGesPrecoModel } from '../../../../core/model/member/commandes/cmd-ges-preco.model';
import { TrameCmdValide } from '../../../../core/model/member/commandes/trame-commande-valide.model';
import { CommandesService } from '../../../../core/services/member/commandes/commandes.service';


export class DialogCreateTrameCmdData {

  constructor(public Msg: string, public NumCmd: string, public LetterCmd: string, public TypeTrame: string, public CmdTrame?: CmdGesPrecoModel) {
  }
}

@Component({
  selector: 'app-dialog-create-trame-commande',
  templateUrl: './dialog-create-trame-commande.component.html',
  styleUrls: ['./dialog-create-trame-commande.component.css']
})
export class DialogCreateTrameCommandeComponent implements OnInit {

  private _subscriptions: Subscription[] = [];

  public RegisterFormCreateTrameCmd: FormGroup;
  public submittedRegisterFormCreateTrameCmd: boolean = false;

  public colorLoader: string = 'warn';
  public modeLoader: string = 'indeterminate';
  public valueLoader: string = '50';
  public displayProgressSpinner: boolean = false;

  private durationNotification: number = 5000;
  private _horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private _verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialogRef: MatDialogRef<DialogCreateTrameCommandeComponent>,
    @Inject(MAT_DIALOG_DATA) public dataCreateTrameCmd: DialogCreateTrameCmdData,
    private _commandesService: CommandesService,
    private _snackBar: MatSnackBar,
    private _formBuilderCreateTrameCmd: FormBuilder) {


    this.RegisterFormCreateTrameCmd = this._formBuilderCreateTrameCmd.group({
      TrameNom: ['', [Validators.required, Validators.maxLength(128)]]
    });


  }

  ngOnInit(): void {
  }


  public onConfirmCreateTrameCmd() {
    this.submittedRegisterFormCreateTrameCmd = true;

    if (!this.RegisterFormCreateTrameCmd.valid) {
      return;
    }

    if (this.dataCreateTrameCmd.TypeTrame != 'G') {
      let trameCrate: TrameCmdValide = {
        NomTrameCmd: this.f.TrameNom.value,
        LetterCmd: this.dataCreateTrameCmd.LetterCmd,
        NumCmd: this.dataCreateTrameCmd.NumCmd,
        TypeTrame: ''
      };

      this._subscriptions.push(this._commandesService.CreateTrameCommande(trameCrate).subscribe(
        (result: number) => {
          if (result == 200) {
            this.NotificationMsg("La trame a été créé avec succès !", true);
            this.onAnnulerCreate();
          }
          else {
            this.NotificationMsg("Une trame avec ce nom existe déjà", false);
          }
          this.displayProgressSpinner = false;
        }));
    }
    else {
      // trame global
      console.log(this.dataCreateTrameCmd.CmdTrame);
      let trameCrate: TrameCmdValide = {
        NomTrameCmd: this.f.TrameNom.value,
        LetterCmd: this.dataCreateTrameCmd.LetterCmd,
        NumCmd: this.dataCreateTrameCmd.NumCmd,
        CmdValide: this.dataCreateTrameCmd.CmdTrame,
        TypeTrame: 'G'
      };
      this._subscriptions.push(this._commandesService.CreateTrameCommande(trameCrate).subscribe(
        (result: number) => {
          if (result == 200) {
            this.NotificationMsg("La trame a été créé avec succès !", true);
            this.onAnnulerCreate();
          }
          else {
            this.NotificationMsg("Une trame avec ce nom existe déjà", false);
          }
          this.displayProgressSpinner = false;
        }));
    }

    let isSendOk: boolean = false;

    this.displayProgressSpinner = true;
  }

  public get f() { return this.RegisterFormCreateTrameCmd.controls; }

  public onAnnulerCreate() {
    this.dialogRef.close(false);
  }

  public NotificationMsg(msg: string, isOk: boolean) {
    if (isOk) {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-success']
      });
    }
    else {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-error']
      });
    }

  }

  public ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this._subscriptions.forEach((s) => s.unsubscribe());
    this.displayProgressSpinner = false;
  }

}
