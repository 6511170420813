<div class="container-fluid pdf-view-cmd">
    <div class="row">
        <div class="col-md" *ngIf="pdfCmd && pdfCmd.PdfSrcByte">
            <ngx-extended-pdf-viewer [src]="pdfCmd.PdfSrcByte" [showToolbar]="true" [showSidebarButton]="false"
                [showFindButton]="false" [showPagingButtons]="false" [showZoomButtons]="true" [textLayer]="false"
                [showPresentationModeButton]="false" [showOpenFileButton]="false" [showPrintButton]="true"
                [showDownloadButton]="true" [showBookmarkButton]="false" [showSecondaryToolbarButton]="false"
                [showRotateButton]="false" [showHandToolButton]="false" [showScrollingButton]="true"
                [showSpreadButton]="false" [showPropertiesButton]="false" [useBrowserLocale]="true" zoom="80%"
                [height]="'100vh'" [delayFirstView]="10">
            </ngx-extended-pdf-viewer>
        </div>
    </div>
    <br />
</div>

<!-- <div mat-dialog-actions class="d-flex justify-content-center">
    <button mat-stroked-button class="button-generic-action-6cb9c6 btn-space" type="button" (click)="DownloadPDF()">
        <i class="fa fa-download"></i>
    </button>
    <button mat-stroked-button class="button-generic-action-6cb9c6 btn-space" type="button" (click)="print()">

        <i class="fas fa-print"></i>
    </button>
</div> -->