import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { SupplierModel } from '../../../../core/model/member/purchases/supplier/supplier-name.model';
import { DayCheck } from '../../../../core/model/member/purchases/supplier/day-check.model';
import { SupplierDetailModel } from '../../../../core/model/member/purchases/supplier/supplier-detail.model';
import { SupplierService } from '../../../../core/services/member/purchages/supplier/supplier.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../../../shared/util/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/util/notification.service';
import { ParametreService } from 'src/app/core/services/member/parametre/parametre.service';

@Component({
  selector: 'DetailSupplierComponent',
  templateUrl: './detail-supplier.component.html',
  styleUrls: ['./detail-supplier.component.css'],
})
export class DetailSupplierComponent implements OnInit {
  @Input() Detailfourni: string;

  //prop that comes from list-supplier(parent) to specify profile editing rights
  @Input() isUpdatedInfRight: boolean;

  public RegisterFormDetailSupplier: FormGroup;
  public submittedDetailSupplier: boolean = false;

  public IsLoadedFournisseur: Boolean;

  public DetailSupplier: SupplierDetailModel;
  public IsFirstLoad: boolean;

  private durationNotification: number = 5000;
  private _horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private _verticalPosition: MatSnackBarVerticalPosition = 'top';

  private _subscriptions: Subscription[] = [];

  public selectedDay: string = '';

  public DayCheckBuilder: DayCheck[] = [
    {
      id: 0,
      Name: 'Lundi',
      isSelected: false,
    },
    {
      id: 1,
      Name: 'Mardi',
      isSelected: false,
    },
    {
      id: 2,
      Name: 'Mercredi',
      isSelected: false,
    },
    {
      id: 3,
      Name: 'Jeudi',
      isSelected: false,
    },
    {
      id: 4,
      Name: 'Vendredi',
      isSelected: false,
    },
    {
      id: 5,
      Name: 'Samedi',
      isSelected: false,
    },
    {
      id: 6,
      Name: 'Dimanche',
      isSelected: false,
    },
  ];

  public IdSupplier: string;

  public FournLogoImport: File[] = [];
  public FournLogoSrc: any;
  public IsEditFournLogoSrc: boolean;
  public IsEdit: boolean;
  public IsEditPicture: boolean;

  @Output() ChangeDetailSupplier = new EventEmitter<SupplierModel>();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private formBuilderProfil: FormBuilder,
    private _snackBar: MatSnackBar,
    private _supplierService: SupplierService,
    private _router: Router,
    private _dialog: MatDialog,
    private _notificationService: NotificationService,
  ) {
    /**********  start contructor form  ******/
    this.RegisterFormDetailSupplier = this.formBuilderProfil.group({
      fournom: ['', Validators.required],
      fourcode: ['', Validators.required],
      fournoclient: [''],
      MtnMinimalCmd: ['', Validators.pattern('^[0-9]+(?:[.,][0-9]{1,2})?$')],
      fourinactif: [false],
      fourconctnom: [''],
      fourconcttel: [''],
      fouradr1: [''],
      fouradr2: [''],
      fourcp: [''],
      fourcity: [''],
      fourtel: [''],
      fourfax: [''],
      fourmail: [''],
      foururl: [''],
      delaiShip: [''],
      hourcmddeb: [''],
      hourcmdfin: [''],
      CommentSupplier: [''],
    });

    this.DayCheckBuilder.forEach((x) => {
      this.RegisterFormDetailSupplier.addControl(
        x.Name + 'ship',
        new FormControl(false)
      );
    });

    this.DayCheckBuilder.forEach((x) => {
      this.RegisterFormDetailSupplier.addControl(
        x.Name + 'cmd',
        new FormControl(false)
      );
    });

    /**********  end contructor form  ******/

    this._subscriptions.push(
      // TODO pourquoi retourne une valeur différente du _supplierService.GetDetailSupplier() ????
      this._supplierService.SupplierDetailSubject.subscribe(
        (ResultDetailSupplier: SupplierDetailModel) => {
          if (ResultDetailSupplier) {
            this.DetailSupplier = ResultDetailSupplier;
            console.log('fournisseur selectionné  ', this.DetailSupplier);
            this.RegisterFormDetailSupplier.controls['fournom'].setValue(
              ResultDetailSupplier.fournom
            );
            this.RegisterFormDetailSupplier.controls['fourcode'].setValue(
              ResultDetailSupplier.fourcode
            );
            this.RegisterFormDetailSupplier.controls['fournoclient'].setValue(
              ResultDetailSupplier.fournoclient
            );
            this.RegisterFormDetailSupplier.controls['fourinactif'].setValue(
              ResultDetailSupplier.fourinactif == '1' ? true : false
            );
            this.RegisterFormDetailSupplier.controls['fourconctnom'].setValue(
              ResultDetailSupplier.fourconctnom
            );
            this.RegisterFormDetailSupplier.controls['fourconcttel'].setValue(
              ResultDetailSupplier.fourconcttel
            );
            this.RegisterFormDetailSupplier.controls['fouradr1'].setValue(
              ResultDetailSupplier.fouradr1
            );
            this.RegisterFormDetailSupplier.controls['fouradr2'].setValue(
              ResultDetailSupplier.fouradr2
            );
            this.RegisterFormDetailSupplier.controls['fourcp'].setValue(
              ResultDetailSupplier.fourcp
            );
            this.RegisterFormDetailSupplier.controls['fourcity'].setValue(
              ResultDetailSupplier.fourcity
            );
            this.RegisterFormDetailSupplier.controls['fourtel'].setValue(
              ResultDetailSupplier.fourtel
            );
            this.RegisterFormDetailSupplier.controls['fourfax'].setValue(
              ResultDetailSupplier.fourfax
            );
            this.RegisterFormDetailSupplier.controls['fourmail'].setValue(
              ResultDetailSupplier.fourmail
            );
            this.RegisterFormDetailSupplier.controls['foururl'].setValue(
              ResultDetailSupplier.foururl
            );
            // TODO verifier que ca fonctionne
            if (this.DetailSupplier.fourlogo) {
              console.log('je suis ici ', this.DetailSupplier)
              this.DetailSupplier.fourlogo = this.FournLogoSrc;
            }
            this.RegisterFormDetailSupplier.controls['delaiShip'].setValue(
              ResultDetailSupplier.delaiShip.replace(/\s/g, '') // clear whitespace
            );
            this.RegisterFormDetailSupplier.controls['CommentSupplier'].setValue(ResultDetailSupplier.comment);
            this.RegisterFormDetailSupplier.controls['hourcmddeb'].setValue(
              ResultDetailSupplier.fourheucmddeb
            );
            this.RegisterFormDetailSupplier.controls['hourcmdfin'].setValue(
              ResultDetailSupplier.fourheucmdfin
            );
            this.RegisterFormDetailSupplier.controls['MtnMinimalCmd'].setValue(
              ResultDetailSupplier.limcmd && ResultDetailSupplier.limcmd.toFixed(2)
            );
            this.IsLoadedFournisseur = true;
            this.IsFirstLoad = true;
          }
        }
      )
    );

    // this._activatedRoute.params.subscribe(params => {
    //   if (params['id']) {
    //     this.IdSupplier = params['id'];
    //     console.log('Url Id: ', this.IdSupplier);
    //   }
    // });
  }

  ngOnInit(): void {
    // if (this.IdSupplier) {
    //   if (!isNaN(Number(this.IdSupplier))) {
    //     this._supplierService.GetDetailSupplier(this.IdSupplier);
    //   }
    // }
    // else {
    //   this._supplierService.InitDetailSupplier();
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.IsLoadedFournisseur = false;
    // select fournisseur
    if (changes.Detailfourni) {
      if (changes.Detailfourni.currentValue) {
        if (!isNaN(Number(changes.Detailfourni.currentValue))) {
          this.IdSupplier = changes.Detailfourni.currentValue;
          this._supplierService.GetDetailSupplier(this.IdSupplier);
        }
      }
    } else {
      this.IsFirstLoad = false;
    }
  }

  public clickCmdDay(selectedDiv: string) {
    let elementidx = this.DetailSupplier.ShipCmd.findIndex(
      (element) => element.Name == selectedDiv
    );
    if (elementidx != -1) {
      this.DetailSupplier.ShipCmd[elementidx].isSelected =
        !this.DetailSupplier.ShipCmd[elementidx].isSelected;
    }
  }

  public clickShipDay(selectedDiv: string) {
    let elementidx = this.DetailSupplier.ShipDay.findIndex(
      (element) => element.Name == selectedDiv
    );
    if (elementidx != -1) {
      this.DetailSupplier.ShipDay[elementidx].isSelected =
        !this.DetailSupplier.ShipDay[elementidx].isSelected;
    }
  }

  public CancelEditSupplier() {
    this._router.navigateByUrl('purchases/list-suppliers');
  }

  public DeleteSupplier() {
    const message = 'Voulez-vous vraiment supprimer ce fournisseur ? ';

    const dialogData = new ConfirmDialogModel(
      'Confirmation de suppression',
      message
    );

    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      maxWidth: '500px',
      data: dialogData,
      height: '220px',
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this._subscriptions.push(
          this._supplierService
            .DeleteSupplier(this.DetailSupplier.fouriu)
            .subscribe((result: number) => {
              if (result == 200) {
                this._snackBar.open('Suppression succès !', 'Fermer', {
                  duration: this.durationNotification,
                  horizontalPosition: this._horizontalPosition,
                  verticalPosition: this._verticalPosition,
                  panelClass: ['mat-notification-success'],
                });
                this.DetailSupplier = undefined;
                this.IsLoadedFournisseur = true;
                this._supplierService.GetListSupplier();
              } else {
                this._snackBar.open('Erreur de modification !', 'Fermer', {
                  duration: this.durationNotification,
                  horizontalPosition: this._horizontalPosition,
                  verticalPosition: this._verticalPosition,
                  panelClass: ['mat-notification-error'],
                });
              }
            })
        );
      }
    });
  }

  //picture funtions
  public EditPicture() {
    this.IsEditPicture = true;
  }
  public AnnulerPic() {
    this.IsEditPicture = false;
  }
  public SelectedFileChange(files: File[]) {
    this.FournLogoImport = files;

    if (files[0]) {
      const file = files[0];

      const reader = new FileReader();
      reader.onload = (e) => (this.FournLogoSrc = reader.result);

      reader.readAsDataURL(file);
    } else {
      this.FournLogoSrc = undefined;
    }

    this.IsEditFournLogoSrc = true;
  }

  public LoadingChange(isLoaded: boolean) {
    //this.displayProgressSpinner = isLoaded;
  }

  public RegisterLogoFournPic() {
    if (this.FournLogoSrc) {
      let logo = this.FournLogoSrc;
      this.DetailSupplier.fourlogo = this.FournLogoSrc;
      this._supplierService
        .SaveLogoSupplier(this.DetailSupplier)
        .subscribe((result: number) => {
          console.log('result ', result)
          if (result == 200) {
            this._notificationService.success('Modification enregistrée !')
            this.FournLogoImport = [];
            this.IsEditFournLogoSrc = false;
            this.FournLogoSrc = logo;
          } else {
            this._notificationService.error('Error survenue');
          }
        });
    }
  }


  public onSubmitRegisterFormDetailSupplier() {
    this.submittedDetailSupplier = true;

    // stop here if form is invalid
    if (this.RegisterFormDetailSupplier.invalid) {
      this.getFormValidationErrors();
      this._snackBar.open('Champs obligatoires', 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-error'],
      });
      return;
    } else {
      let fouValide: SupplierDetailModel = _.cloneDeep(this.DetailSupplier);

      fouValide.fournom = this.f.fournom.value;
      fouValide.fourcode = this.f.fourcode.value;
      fouValide.fournoclient = this.f.fournoclient.value;
      fouValide.fourconctnom = this.f.fourconctnom.value;
      fouValide.fourconcttel = this.f.fourconcttel.value;
      fouValide.fouradr1 = this.f.fouradr1.value;
      fouValide.fouradr2 = this.f.fouradr2.value;
      fouValide.fourcp = this.f.fourcp.value;
      fouValide.fourcity = this.f.fourcity.value;
      fouValide.fourtel = this.f.fourtel.value;
      fouValide.fourfax = this.f.fourfax.value;
      fouValide.fourmail = this.f.fourmail.value;
      fouValide.foururl = this.f.foururl.value;
      fouValide.delaiShip = this.f.delaiShip.value;
      fouValide.ShipCmd = this.DetailSupplier.ShipCmd;
      fouValide.ShipDay = this.DetailSupplier.ShipDay;
      fouValide.fourheucmddeb = this.f.hourcmddeb.value;
      fouValide.fourheucmdfin = this.f.hourcmdfin.value;
      fouValide.comment = this.f.CommentSupplier.value;
      fouValide.fourinactif = this.f.fourinactif.value === true ? '1' : '';
      fouValide.limcmd = this.f.MtnMinimalCmd.value ? Number(this.f.MtnMinimalCmd.value) : undefined

      this._subscriptions.push(
        this._supplierService
          .SaveEditSupplier(fouValide)
          .subscribe((result: SupplierModel) => {
            if (result) {
              if (result.IU_FOURNISSEUR != '-409') {
                this.DetailSupplier.fouriu = result.IU_FOURNISSEUR;
                this.ChangeDetailSupplier.emit(result);
                this._snackBar.open('Modification enregistrée !', 'Fermer', {
                  duration: this.durationNotification,
                  horizontalPosition: this._horizontalPosition,
                  verticalPosition: this._verticalPosition,
                  panelClass: ['mat-notification-success'],
                });
              } else {
                this._snackBar.open(
                  'Un fournisseur avec ce code existe déjà !',
                  'Fermer',
                  {
                    duration: this.durationNotification,
                    horizontalPosition: this._horizontalPosition,
                    verticalPosition: this._verticalPosition,
                    panelClass: ['mat-notification-error'],
                  }
                );
              }
            } else {
              this._snackBar.open('Erreur de modification !', 'Fermer', {
                duration: this.durationNotification,
                horizontalPosition: this._horizontalPosition,
                verticalPosition: this._verticalPosition,
                panelClass: ['mat-notification-error'],
              });
            }
          })
      );
    }
  }

  getFormValidationErrors() {
    Object.keys(this.RegisterFormDetailSupplier.controls).forEach((key) => {
      const controlErrors: ValidationErrors =
        this.RegisterFormDetailSupplier.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
            controlErrors[keyError]
          );
        });
      }
    });
  }

  public get f() {
    return this.RegisterFormDetailSupplier.controls;
  }

  public ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this._subscriptions.forEach((s) => s.unsubscribe());
  }
}
