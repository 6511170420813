import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  tap,
} from 'rxjs/operators';
import { NotificationService } from '../../../../core/services/util/notification.service';
import { STOCK_ZONE_STOCK } from '../../../../core/model/common/zone-stock.model';
import { VUE_STOCK_SUIVI_ARTICLE } from '../../../../core/model/member/stock/stock-output-art.model';
import { CommonService } from '../../../../core/services/common/common.service';
import { StockService } from '../../../../core/services/member/stock/stock.service';
import {
  DialogViewArtMouvementComponent,
  ViewArtMouvDetailData,
} from '../../inventaire/dialog-view-art-mouvement/dialog-view-art-mouvement.component';
import { ExportEtatStock } from '../../../../core/model/member/stock/export-etat-stock.model';
import { PdfData, PdfPreviewDialogComponent } from '../../../../shared/dialog/pdf-preview-dialog/pdf-preview-dialog.component';
import { SelectValueModel } from 'src/app/core/model/common/select_value.model';
import { ParamAppService } from 'src/app/core/services/member/param-app/param-app.service';
import { InterfaceAccesModel } from 'src/app/core/model/member/param-app/interface-acces.model';

@Component({
  selector: 'EtatStockComponent',
  templateUrl: './etat-stock.component.html',
  styleUrls: ['./etat-stock.component.css'],
})
export class EtatStockComponent implements OnInit {
  private selectedDate: string;

  private selectedDateExport: string;

  public IsDisplayResult: boolean = false;

  public IsDisplayPannelOption: boolean = true;

  public ZoneStockSelected: STOCK_ZONE_STOCK;

  public Name: string;

  public ZoneStock: STOCK_ZONE_STOCK[] = [];

  public ListFilterStock: SelectValueModel[];

  public FilterSelected: SelectValueModel;

  public IsLoaded: boolean = false;

  private _subscriptions: Subscription[] = [];

  public InventoryDate: any;

  public pdfSrc: any;

  /** loader bloc pdf */

  public colorLoader: string = 'warn';
  public modeLoader: string = 'indeterminate';
  public valueLoader: string = '50';
  public displayProgressSpinner: boolean = false;

  public ListStockArticle: VUE_STOCK_SUIVI_ARTICLE[] = [];

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() CodePr: string; // call state stock from other componet

  public dataSourceListEtatStockArticles: any =
    new TableVirtualScrollDataSource([]);

  public ArticleSearchForm: FormGroup;

  public IsLoadingSearchArt: boolean = false;

  public displayedColumns: string[] = [
    'Mouvement',
    //'CODE_PR',
    'NOM_PR',
    'STOCK_REEL',
    'US',
    'PRIX_VALO',
    'PMP_VALO',
    'NUM_LOT',
    'DATE_LIMITE',
    'TotalHT',
    'TotalTTC',
  ];

  //name of this interface
  private _monInf: string = 'WZ_STOC_ETAT';
  //modification auth(bool)
  public isUpdatedInf: boolean;

  // profil user
  public profilId: string;

  constructor(
    private _stockService: StockService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _commonService: CommonService,
    private _formSearchArticle: FormBuilder,
    private _notificationService: NotificationService,
    private _paramAppService: ParamAppService
  ) {
    /**** search cmd  form */


    /******* end search  */

    // get all filter stock
    this.ListFilterStock = this._stockService.GetTypeFilterStock();
    this.FilterSelected = this.ListFilterStock[1]; // set filter par défault positif

    this._subscriptions.push(
      this._stockService.ListEtatStockArtSubject.subscribe(
        (ListEtatStockArticles: VUE_STOCK_SUIVI_ARTICLE[]) => {
          if (ListEtatStockArticles) {
            // console.log('liste etat ', ListEtatStockArticles)

            ListEtatStockArticles = this.FilterStock(ListEtatStockArticles.slice());

            this.dataSourceListEtatStockArticles.data = ListEtatStockArticles;

            this.ListStockArticle = ListEtatStockArticles;

            this.dataSourceListEtatStockArticles.sort = this.sort;
            // this. dataSourceListEtatStockArticles.paginator = this.paginator;
          }
          this.selectedDateExport = this.selectedDate;
          this.IsLoaded = true;
          this.displayProgressSpinner = false;

        }
      )
    );

    /**** search cmd  form */
    this.ArticleSearchForm = this._formSearchArticle.group({
      ArticleSearchInput: null,
    });

    this._subscriptions.push(
      this.ArticleSearchForm.get('ArticleSearchInput')
        .valueChanges.pipe(
          debounceTime(300),
          distinctUntilChanged(), // If previous search is different from current
          filter((value) => typeof value === 'string'), // trigger if value is string to prevent twice fire
          tap(() => {
            this.IsLoadingSearchArt = true;
            this.IsLoaded = false;
          }),
          switchMap((value) =>
            this._stockService
              .ChercherArticleFromListEtatStock(value)
              .pipe(
                finalize(() => {
                  this.IsLoadingSearchArt = false;
                  this.IsLoaded = true;
                })
              )
          )
        )
        .subscribe((resultArticleStock) => {
          this.dataSourceListEtatStockArticles.data = [];
          this.ListStockArticle = [];
          resultArticleStock = this.FilterStock(resultArticleStock.slice());
          this.dataSourceListEtatStockArticles.data = resultArticleStock;
          this.ListStockArticle = resultArticleStock;
        })
    );

    /******* end search  */
  }

  ngOnInit(): void {
    // scroll to top when load page
    window.scrollTo(0, 0);

    this.InventoryDate = new Date();

    this.selectedDate = moment(this.InventoryDate)
      .local(false)
      .utc(true)
      .format('YYYYMMDD');

    if (this.CodePr) {
      this.IsDisplayPannelOption = false;
      // call state stock from other component
      this._subscriptions.push(
        this._stockService
          .SearchCommandSearchArticleStockServer(
            'etat',
            { name: this.CodePr },
            1,
            this.selectedDate
          )
          .subscribe((resultArticleStock) => {
            this.dataSourceListEtatStockArticles.data = [];
            this.dataSourceListEtatStockArticles.data = resultArticleStock;
            this.IsLoaded = true;
          })
      );
    } else {

      this._stockService.GetEtatStockArticles(this.selectedDate, '0199');
      // load zone stock
      this._subscriptions.push(
        this._commonService
          .GetListZoneStockSync()
          .subscribe((result: STOCK_ZONE_STOCK[]) => {
            result.sort((a, b) => {
              return a.LIB_ZONE_STOCK.localeCompare(b.LIB_ZONE_STOCK)
            })
            this.ZoneStock = result;
            if (result.length != 0) {
              this.ZoneStockSelected = this.ZoneStock.find(
                (p) => p.IU_ZONE_STOCK == '0199'
              );
            }
          })
      );
    }

    this.GesDroit();

  }

  //get the modification auth through _paramAppService
  public GesDroit() {
    this._subscriptions.push(
      this._paramAppService.ListLeftMenuSubject.subscribe(
        (result: InterfaceAccesModel[]) => {
          if (result.length != 0) {
            // this.isUpdatedInf = result.find(
            //   (p) => p.INF_Id == this._monInf
            // )?.IsUpdated;
            // if (!this.isUpdatedInf) {
            //   this.displayedColumns.splice(this.displayedColumns.indexOf('QteInventaire'), 1);
            // }
            this.profilId = this._commonService.GetUserInfoJwt('UserProfilId');
            if (this.profilId != 'ROOT') {
              this.ListFilterStock.splice(this.ListFilterStock.findIndex(p => p.IdSelect == '3'), 1);
            }
          }
        }
      )
    );
    this._paramAppService.EmitterListLeftMenu();
  }

  public FilterStock(pListstock: VUE_STOCK_SUIVI_ARTICLE[]) {

    switch (this.FilterSelected.IdSelect) {
      case "2": {
        // only stock positif
        pListstock = pListstock.filter(p => p.STOCK_REEL != 0).slice();
        // pListstock = pListstock.sort(function (a, b) {
        //   return a.NOM_PR.localeCompare(b.NOM_PR) || Number(b.STOCK_REEL) - Number(a.STOCK_REEL);
        // });
        break;
      }
      case "3": {
        // only stock 0
        pListstock = pListstock.filter(p => p.STOCK_REEL == 0).slice();
        // pListstock = pListstock.sort(function (a, b) {
        //   return a.NOM_PR.localeCompare(b.NOM_PR) || Number(b.STOCK_REEL) - Number(a.STOCK_REEL);
        // });
        break;
      }
      default: {
        //statements; 
        break;
      }
    }

    return pListstock.slice();

  }

  public ChangeDateSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.IsLoaded = false;

    this.displayProgressSpinner = true;

    this.dataSourceListEtatStockArticles.data = [];

    this.ListStockArticle = [];

    this.selectedDate = moment(event.value)
      .local(false)
      .utc(true)
      .format('YYYYMMDD');

    this._stockService.GetEtatStockArticles(this.selectedDate, this.ZoneStockSelected.IU_ZONE_STOCK);
  }

  public onZoneStockChange(event: any) {
    console.log(this.ZoneStockSelected.IU_ZONE_STOCK)
    this.dataSourceListEtatStockArticles.data = [];
    this.ListStockArticle = [];
    this.IsLoaded = false;
    this.displayProgressSpinner = true;
    if (this.ZoneStockSelected.IU_ZONE_STOCK != '0199') {
      // this._subscriptions.push(
      //   this._stockService
      //     .GetArticleByZoneStockSync(this.ZoneStockSelected.IU_ZONE_STOCK)
      //     .subscribe((result: VUE_STOCK_SUIVI_ARTICLE[]) => {
      //       // change date now when filter zone stock
      //       this.InventoryDate = new Date();
      //       this.selectedDate = moment(this.InventoryDate)
      //         .local(false)
      //         .utc(true)
      //         .format('YYYYMMDD');

      //       this.dataSourceListEtatStockArticles.data = result;
      //       this.ListStockArticle = result;
      //       this.IsLoaded = true;
      //     })
      // );
      this._stockService.GetEtatStockArticles(this.selectedDate, this.ZoneStockSelected.IU_ZONE_STOCK);
    } else {
      this._stockService.GetEtatStockArticles(this.selectedDate, this.ZoneStockSelected.IU_ZONE_STOCK);
    }
  }

  public onFiltrePar(event: any) {

    if (this.FilterSelected) {
      this.ArticleSearchForm.get('ArticleSearchInput').setValue(undefined);
      this.dataSourceListEtatStockArticles.data = [];
      this.ListStockArticle = [];
      this._stockService.EmitterEtatStockArticle()
    }
  }

  /**
   * Chercher un article
   *
   */
  public onSubmitRechercherArticle() {
    // let numcmd = this.CommandeSearchForm.get('ArticleSearchInput').value;
    // if (numcmd && typeof numcmd === 'string') {
    // }
    // console.log(this.CommandeSearchForm.get('ArticleSearchInput').value);
  }

  public ConsultMouvementDetail(pCodePr: string, pNumLot, pDateDlc) {
    const ArtDialogData = new ViewArtMouvDetailData(
      pCodePr,
      pNumLot,
      pDateDlc,
      undefined
    );

    const dialogRef = this._dialog.open(DialogViewArtMouvementComponent, {
      width: '100%',
      data: ArtDialogData,
      disableClose: false,
    });

    return dialogRef;
    // this._router.navigate(['stock/mouvement/article'], {
    //   queryParams: { a: pCodePr, lot: pNumLot, dlc: pDateDlc },
    // });
  }

  public displayFnCmd() { }

  // cliquer sur article trouvé
  public ClickOptionSearchArticle(event: any, value: any) {

    if (value) {
    }
  }

  onSave() {
    this.IsDisplayResult = true;
  }

  public MTTC: string;
  //TTC column added
  public CalculTotalMontalTTC() {
    let total: number = 0;
    total = this.dataSourceListEtatStockArticles.data
      .reduce(
        (sum, y) => {
          if (Number(y.TotalTTC) > 0)
            return Number(sum) + Number(y.TotalTTC)
          return sum;
        }, total);

    this.MTTC = (Math.round(total * 100 + Number.EPSILON) / 100).toString()

    return this.MTTC;

    //   .reduce((sum, y) => Number(sum) + Number(y.TotalTTC), total);

    // this.MTTC = total.toFixed(2);

    // return this.MTTC;
  }

  //total 
  public MTHT: string;

  public CalculTotalMontalHT() {
    let total: number = 0;
    total = this.dataSourceListEtatStockArticles.data
      .reduce(
        (sum, y) => {
          if (Number(y.TotalHT) > 0)
            return Number(sum) + Number(y.TotalHT)
          return sum;
        }, total);

    this.MTHT = (Math.round(total * 100 + Number.EPSILON) / 100).toString()
    //this.MTHT = total.toString()
    return this.MTHT;

    // if (this.dataSourceListEtatStockArticles.data) {
    //   if (this.dataSourceListEtatStockArticles.data > 0) {
    //     let total: number = 0;
    //     total = this.dataSourceListEtatStockArticles.data.reduce(
    //       (sum, y) =>
    //         sum +
    //         Number(
    //           (Number(y.PRECO_PRIX ? y.PRECO_PRIX : "0") *
    //             Number(y.PRECO_QTE_ALIVRER ? y.PRECO_QTE_ALIVRER : "0")
    //           ).toFixed(2)
    //         )
    //       ,
    //       total
    //     );
    //     this.MTHT = total.toString();
    //   }
    //   else {
    //     this.MTHT = "0";
    //   }
    // }
    // else {
    //   this.MTHT = "0";
    // }
    // return this.MTHT;
  }

  public ExportEtatExcelStock(event: any) {
    if (this.ListStockArticle) {
      this.displayProgressSpinner = true;

      let etatExportExcel: ExportEtatStock = {
        SelectedDate: this.selectedDateExport,
        ZoneName: this.ZoneStockSelected?.LIB_ZONE_STOCK,
        Total: this.MTHT,
        ListArt: this.ListStockArticle
      }

      this._subscriptions.push(
        this._stockService
          .ExportExcelEtatStock(etatExportExcel)
          .subscribe((responseExport: any) => {
            if (responseExport) {
              const newBlob = new Blob([responseExport], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              const data = window.URL.createObjectURL(newBlob);
              const link = document.createElement('a');
              link.href = data;
              link.download =
                this.ZoneStockSelected?.LIB_ZONE_STOCK + '_Etat_stock_au_' +
                moment(this.selectedDateExport).local(false).utc(true).format('DD_MM_YYYY') + '.xlsx';
              link.dispatchEvent(
                new MouseEvent('click', {
                  bubbles: true,
                  cancelable: true,
                  view: window,
                })
              );
              setTimeout(function () {
                window.URL.revokeObjectURL(data);
                link.remove();
              }, 0);
            } else {
              this._notificationService.error('Error internal');
            }
            this.displayProgressSpinner = false;
          })
      );
    }
  }

  public ExportEtatPdfStock(event: any) {
    if (this.ListStockArticle) {
      this.displayProgressSpinner = true;

      let etatExportExcel: ExportEtatStock = {
        SelectedDate: this.selectedDateExport,
        ZoneName: this.ZoneStockSelected?.LIB_ZONE_STOCK,
        Total: this.MTHT,
        ListArt: this.ListStockArticle
      }

      this._subscriptions.push(
        this._stockService.ExportPdfEtatStock(etatExportExcel).subscribe((response: any) => {
          if (response) {
            this.pdfSrc = response;
            const IsPdfdialog = this.DialogPdfViewMessage('Génrération PDF', response);
            IsPdfdialog.afterClosed().subscribe((dialogResult) => {
              if (dialogResult) {
              }
            });
          }
          this.displayProgressSpinner = false;
        })
      );
    }
  }

  public DialogPdfViewMessage(NumCmd, pdfSrcByte: any) {

    const PdfDialogData = new PdfData(pdfSrcByte);

    const dialogRef = this._dialog.open(PdfPreviewDialogComponent, {
      width: '60%',
      data: PdfDialogData,
      disableClose: false,
      panelClass: 'pdf-view-dialog-container',
    });

    return dialogRef;
  }
  public ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this._subscriptions.forEach((s) => s.unsubscribe());
    this.displayProgressSpinner = false;
  }
}
