export class SearchAvoirDemAvoirModel {
    constructor(
        public AVOIR_IU: string,
        public AVOIR_LETTRE: string,
        public AVOIR_DATE_LIVRE: Date,
        public AVOIR_BON: string,
        public AVOIR_FOU: string,
        public PRECO_IUCOM: string,
        public TOTAL_HT: number,
        public totalTTC: number,

    ) { }

}

export interface ISearchAvoirDemAvoirModel {
    total: number,
    results: SearchAvoirDemAvoirModel[],
}