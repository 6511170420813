import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from '../../../../config';
import { EnvService } from "../../environment/env.service";
import { catchError, map } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { VUE_DASHBOARD } from "../../../../core/model/member/dashboard/VUE_DASHBOARD.model";
import { StatsEgalimModel } from "../../../../core/model/member/statistiques/egalim/stats-egalim.model";

@Injectable()
export class DashboardService {
    // url api
    private _urlBaseAPI: string;

    private _urlInfoBasic: string;

    private _urlGetStatEgalimDashboard: string;

    public InfoDashboardSubject$ = new Subject<VUE_DASHBOARD>();
    private _dashboardInfo: VUE_DASHBOARD;
    private _urlPostEditNote: string;

    constructor(private _httpClient: HttpClient, private env: EnvService) {
        this._urlBaseAPI = env.API_URL_BASE;
        this._urlInfoBasic = Config.API_ROUTES.DASHBOARD_API.API_GET_INFO_BASIC;
        this._urlGetStatEgalimDashboard = Config.API_ROUTES.DASHBOARD_API.API_GET_STAT_EGALIM_CURRENT_MONTH;
        this._urlPostEditNote = Config.API_ROUTES.DASHBOARD_API.API_POST_SAVE_NOTE;
    }

    public EmitterDashboardInfo() {
        if (this._dashboardInfo) {
            this.InfoDashboardSubject$.next(this._dashboardInfo);
        }
    }

    public GetInfoBasic() {

        let urlAPI = this._urlBaseAPI + this._urlInfoBasic;

        this._httpClient.get<VUE_DASHBOARD>(urlAPI).subscribe(
            (response: VUE_DASHBOARD) => {
                this._dashboardInfo = response;
                this.EmitterDashboardInfo();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public SaveEditNote(EditNote: string): Observable<VUE_DASHBOARD> {
        let NoteEditModel: VUE_DASHBOARD = {
            CmdWait: 0,
            CmdARecept: 0,
            NbArt: 0,
            NbArtMercurial: 0,
            TotalDepense: 0,
            TotalDepenseN: 0,
            Note: EditNote,
        };

        let URI = this._urlBaseAPI + this._urlPostEditNote;
        try {
            return this._httpClient.post(URI, NoteEditModel,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let DashReturn: VUE_DASHBOARD = ((response.body) as any);
                                return DashReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public GetStatEgalimCurrentMonthSync() {

        let urlAPI = this._urlBaseAPI + this._urlGetStatEgalimDashboard;

        return this._httpClient.get<StatsEgalimModel[]>(urlAPI);

    }
}