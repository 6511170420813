import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CmdGesPrecoModel } from '../../../core/model/member/commandes/cmd-ges-preco.model';
import { CommandesService } from '../../../core/services/member/commandes/commandes.service';
import {
  SendMailCmdData,
  SendMailCommandesComponent,
} from '../send-mail-commandes/send-mail-commandes.component';
import { SupplierModel } from '../../../core/model/member/purchases/supplier/supplier-name.model';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ExtensionErrorStateMatcher } from '../../../core/extension/ExtensionErrorStateMatcher.matcher';
import { GES_CATALOG_FOUR } from '../../../core/model/member/purchases/denree/ges_catalog_four.model';
import { CMD_GES_PRECO_Line } from '../../../core/model/member/commandes/cmd-ges-preco-line.model';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import * as moment from 'moment';
import { CommonService } from '../../../core/services/common/common.service';
import { SelectValueModel } from '../../../core/model/common/select_value.model';
import * as _ from 'lodash';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../../shared/util/confirm-dialog/confirm-dialog.component';
import {
  PdfCmdData,
  PdfViewCommandeComponent,
} from '../pdf-view-commande/pdf-view-commande.component';
import { SupplierService } from '../../../core/services/member/purchages/supplier/supplier.service';
import { CatalogSupplierModel } from '../../../core/model/member/commandes/CatalogSupplierModel.model';
import { DenreeService } from '../../../core/services/member/purchages/supplier/denree.service';
import { VUE_GES_CATALOG_FOUR } from '../../../core/model/member/purchases/denree/VUE_GES_CATALOG_FOUR.model';
import { DOCUMENT } from '@angular/common';
import { VueTrameCommandeModel } from '../../../core/model/member/commandes/vue-trame-commande.model';
import { LayoutService } from '../../../core/services/member/layout/layout.service';
import { ResultDockModal } from '../../../core/model/member/layout/ResultDockModal.model';
import { GRAAL_CENTRE } from '../../../core/model/common/graal_centre.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SendTelCommandesComponent } from '../send-tel-commandes/send-tel-commandes.component';
import { DialogViewFournDetailComponent, ViewFournDetailData } from '../dialog-view-fourn-detail/dialog-view-fourn-detail.component';
import { FilterCommandeModel } from '../../../core/model/member/commandes/filter-commande.model';
import { NotificationService } from '../../../core/services/util/notification.service';
import { DialogCreateTrameCmdData, DialogCreateTrameCommandeComponent } from '../trame-commandes/dialog-create-trame-commande/dialog-create-trame-commande.component';

@Component({
  selector: 'EditCommandesComponent',
  templateUrl: './edit-commandes.component.html',
  styleUrls: ['./edit-commandes.component.css'],
})
export class EditCommandesComponent implements OnInit {
  @Input() public isLayout: boolean;

  public isLoadedDetailCmd: boolean = false;
  private _etatFilterHistory: FilterCommandeModel;

  private durationNotification: number = 5000;
  private _horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private _verticalPosition: MatSnackBarVerticalPosition = 'top';

  public CommandInfo: CmdGesPrecoModel;

  matcher = new ExtensionErrorStateMatcher();

  /** loader bloc pdf */

  public colorLoader: string = 'warn';
  public modeLoader: string = 'indeterminate';
  public valueLoader: string = '50';
  public displayProgressSpinner: boolean = false;

  public pdfSrc: any;

  public NumCmd: string;
  public LetterCmd: string;

  public NomTrame: string;
  public IuFourTrame: string;

  public RegisterFormEditCommande: FormGroup;
  public submittedRegisterFormEditCommande: boolean = false;

  public isLoadingSearchArt = false;
  public ArticleSearchForm: FormGroup;
  public SelectFormFourControl: FormControl;

  private _subscriptions: Subscription[] = [];

  public ListLettersCmd: SelectValueModel[] = [];

  public ListSupplierCatalog: CatalogSupplierModel[] = [];

  // public DetailCmd: CmdGesPrecoModel;

  public SelectedLine: string;

  public ArticleCatalogFour: GES_CATALOG_FOUR;

  public OptSaisie = [
    { id: '0', label: 'Par fournisseur' },
    { id: '1', label: 'Globale' },
  ];

  public SelectedOptSaisie: string = '0';
  public previousSelectedOptSaisie = '0';

  public IsEdit: boolean = false; // pour gérer les bouton pdf and mail

  private _isCreateLineCmd: boolean = false; // savoir si c'est la création d'une line de

  public isImportCatalog: boolean = false;
  public isNotYesNo: boolean = false;
  public message: string = ''; //to get a different confirmation dialog(ConfirmSuppressionQteOrPrixVide) in case 'isImportCatalog'

  private currentTotalLine: number = 1;

  public isConsult: boolean = false; // déterminer si c'est un edit commande ou une création d'une nouvelle commande

  public ListAdrLivraison: GRAAL_CENTRE[] = [];

  public ListAdrFac: GRAAL_CENTRE[] = [];

  @ViewChild('linecmdform') FromLinesCmd: { form: FormGroup };

  public MtnTotalHT: string = "0";
  public MtnTotalTva: string = "0";
  public MtnTotalTTC: string = "0";

  public DatelivreEdit: Date = undefined;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cdRef: ChangeDetectorRef,
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _commandesService: CommandesService,
    private _commonService: CommonService,
    private _snackBar: MatSnackBar,
    private _formBuilderEditCmd: FormBuilder,
    private _router: Router,
    private _supplierService: SupplierService,
    private _denreeService: DenreeService,
    private _layoutService: LayoutService,
    private _notificationService: NotificationService
  ) {
    this.constructorFormEditCmd();

    this.GesSubscription();

    this.SelectFormFourControl = new FormControl('', Validators.required);
  }

  public GesSubscription() {
    if (!this.isLayout) {
      // detail commande
      this._subscriptions.push(
        this._commandesService.CmdDetailSubject.subscribe(
          (DetailCmdReponse: CmdGesPrecoModel) => {
            if (DetailCmdReponse) {
              this.CommandInfo = DetailCmdReponse;

              this.RegisterFormEditCommande.controls['DateCmd'].setValue(
                this.CommandInfo.CmdHeader.PRECO_DATE_COM &&
                this.CommandInfo.CmdHeader.PRECO_DATE_COM.replace(
                  /\s/g,
                  ''
                ) != '' &&
                moment(
                  this.CommandInfo.CmdHeader.PRECO_DATE_COM.replace(/\s/g, '')
                )
                  .local(false)
                  .utc(true)
                  .toDate()
              );

              this.RegisterFormEditCommande.controls['DateCmd'].disable();
              // set date de livraison
              if (this.CommandInfo.CmdHeader.PRECO_DATE_LIVRE_EDIT) {
                let datelivreformatEdited = this.CommandInfo.CmdHeader.PRECO_DATE_LIVRE_EDIT.replace(/\s/g, '') != '' &&
                  moment(this.CommandInfo.CmdHeader.PRECO_DATE_LIVRE_EDIT.replace(/\s/g, ''))
                    .local(false)
                    .utc(true)
                    .toDate();
                this.RegisterFormEditCommande.controls['DateLivr'].setValue(datelivreformatEdited);
                this.DatelivreEdit = datelivreformatEdited;
                this.CommandInfo.CmdHeader.PRECO_DATE_LIVRE_EDIT = moment(datelivreformatEdited)
                  .local(false)
                  .utc(true)
                  .format('DD/MM/YYYY');

              }
              else {
                this.RegisterFormEditCommande.controls['DateLivr'].setValue(
                  this.CommandInfo.CmdHeader.PRECO_DATE_LIVRE &&
                  this.CommandInfo.CmdHeader.PRECO_DATE_LIVRE.replace(/\s/g, '') != '' &&
                  moment(this.CommandInfo.CmdHeader.PRECO_DATE_LIVRE.replace(/\s/g, ''))
                    .local(false)
                    .utc(true)
                    .toDate()
                );
              }


              // disable changement date livraison si cmd envoyé ou partiel recu
              if (this.CommandInfo.CmdHeader.PRECO_ETAT != '0') {
                this.RegisterFormEditCommande.controls['DateLivr'].disable();
              }

              this.SelectFormFourControl.setValue(
                this.CommandInfo.CmdHeader.PRECO_IUFOU
              );

              // set letter cmd
              this.ListLettersCmd.splice(this.ListLettersCmd.length, 0, {
                IdSelect: this.CommandInfo.CmdHeader.PRECO_LETTRE,
                ValueSelect: this.CommandInfo.CmdHeader.PRECO_LETTRE,
              });
              this.RegisterFormEditCommande.controls['LetterCmdCtrl'].setValue(
                this.CommandInfo.CmdHeader.PRECO_LETTRE
              );
              if (this.CommandInfo.CmdHeader.PRECO_ADR_LIV_ID) {
                this.RegisterFormEditCommande.controls['AdrLivIdCtrl'].setValue(
                  this.CommandInfo.CmdHeader.PRECO_ADR_LIV_ID
                );
              }
              else {
                this.RegisterFormEditCommande.controls['AdrLivIdCtrl'].setValue(
                  "CMDLIV"
                );
              }
              if (this.CommandInfo.CmdHeader.PRECO_ADR_FAC_ID) {
                this.RegisterFormEditCommande.controls['AdrFacIdCtrl'].setValue(
                  this.CommandInfo.CmdHeader.PRECO_ADR_FAC_ID
                );
              }
              this.RegisterFormEditCommande.controls['NoteCmd'].setValue(
                this.CommandInfo.CmdHeader.PRECO_COMMENT
              );

              // calcul montant total
              this.CalculTotalMontalHT();
              this.CalculTotalMontalTVA();
              this.CalculTotalMontalTTC();

              this.isLoadedDetailCmd = true;
            }
          }
        )
      );
    }

    // delete line cmd
    this._subscriptions.push(
      this._commandesService.LineCmdDeleteSubject.subscribe(
        (LineCmdDeletedReponse: CMD_GES_PRECO_Line) => {
          if (LineCmdDeletedReponse) {
            if (this.CommandInfo.CmdLine.length == 1) {
              this.NotificationMsg(
                'La commande doit comporter au moins une ligne',
                false
              );
            } else {
              let idxLineCmdDeleted = this.CommandInfo.CmdLine.findIndex(
                (p) =>
                  p.PRECO_IUCOM == LineCmdDeletedReponse.PRECO_IUCOM &&
                  p.PRECO_NOLIG == LineCmdDeletedReponse.PRECO_NOLIG
              );
              if (idxLineCmdDeleted != -1) {
                this.CommandInfo.CmdLine.splice(idxLineCmdDeleted, 1);
                // set edit cmd to true
                this.IsEdit = true;
                this.CalculTotalMontalHT();
                this.CalculTotalMontalTVA();
                this.CalculTotalMontalTTC();
              }
            }
          }
        }
      )
    );

    // add line cmd meme fournisseur
    this._subscriptions.push(
      this._commandesService.LineCmdSameAddedSubject.subscribe(
        (LineCmdAddedReponse: CMD_GES_PRECO_Line) => {
          if (LineCmdAddedReponse) {
            let NewNoLig =
              this.CommandInfo.CmdLine.length < 10
                ? '00' + this.CommandInfo.CmdLine.length.toString()
                : '0' + this.CommandInfo.CmdLine.length.toString();
            LineCmdAddedReponse.PRECO_NOLIG = NewNoLig;
            this.CommandInfo.CmdLine.splice(
              this.CommandInfo.CmdLine.length + 1,
              0,
              LineCmdAddedReponse
            );
          }
        }
      )
    );

    // load letters cmd
    this._subscriptions.push(
      this._commonService.ListLetterCmdSubject.subscribe(
        (LettersCmdResponse: SelectValueModel[]) => {
          if (LettersCmdResponse) {
            this.ListLettersCmd = LettersCmdResponse;
          }
        }
      )
    );
    if (!this.isLayout) {
      // load detail trame commande et créer une nouvelle commande avec cette trame
      this._subscriptions.push(
        this._commandesService.TrameCmdDetailSubject.subscribe(
          (DetailTrameCmdReponse: VueTrameCommandeModel[]) => {
            if (DetailTrameCmdReponse) {
              //créer une nouvelle commande avec cette trame
              this.IsEdit = true;
              this.CommandInfo = {
                IsSavedStock: false,
                CmdHeader: this._commandesService.InitNewCmdHeader(),
                CmdLine: [],
                LineDeleted: []
              };

              if (DetailTrameCmdReponse.length != 0) {

                // check if trame global       
                let IuFouCurrent = DetailTrameCmdReponse[0].BONECO_IU_FOU;
                let TrameParFou: boolean = true;

                DetailTrameCmdReponse.forEach((x) => {
                  if (IuFouCurrent != x.BONECO_IU_FOU) {
                    TrameParFou = false;
                  }
                  let newLineCmdByTrame = this._commandesService.InitNewLineCmd();
                  newLineCmdByTrame.PRECO_NOLIG = x.BONECO_FITECH;
                  newLineCmdByTrame.PRECO_IUFOU = x.BONECO_IU_FOU;
                  newLineCmdByTrame.Preco_fou_libelle = x.NOM_FOURNISSEUR;
                  // newLineCmdByTrame.preco_denree_lib = x.NOM_DENREE;
                  newLineCmdByTrame.PRECO_PRIX = x.CATALOG_PRIX_EUROS;
                  newLineCmdByTrame.PRECO_QTE_ALIVRER = x.BONECO_QTE;
                  newLineCmdByTrame.PRECO_DENREE = x.BONECO_DENREE;
                  newLineCmdByTrame.PRECO_UF = x.BONECO_UF;
                  newLineCmdByTrame.PRECO_TVA = x.DENREE_TVA;
                  newLineCmdByTrame.PRECO_REF = x.CATALOG_REF_EXTERNE;
                  this.CommandInfo.CmdLine = [...this.CommandInfo.CmdLine, newLineCmdByTrame];
                });
                if (TrameParFou) {
                  this.SelectFormFourControl.setValue(DetailTrameCmdReponse[0].BONECO_IU_FOU);
                  this.SelectedOptSaisie = "0";
                }
                else {
                  this.SelectedOptSaisie = "1";
                }
                this.CalculTotalMontalHT();
                this.CalculTotalMontalTVA();
                this.CalculTotalMontalTTC();
              
              }
              this.isLoadedDetailCmd = true;
              this.GetCommonDataFromDB();
            }
          }
        )
      );
    }
  }

  ngOnInit(): void {
    // scroll to top when load page
    window.scrollTo(0, 0);

    if (window.history.state.filterRecepHistory) {
      this._etatFilterHistory = window.history.state.filterRecepHistory;
    }


    if (this.isLayout) {
      this.CommandInfo = {
        IsSavedStock: false,
        CmdHeader: this._commandesService.InitNewCmdHeader(),
        CmdLine: [],
        LineDeleted: []
      };
      // créer une nouvelle commande
      this.CommandInfo.CmdLine = [
        ...this.CommandInfo.CmdLine,
        this._commandesService.InitNewLineCmd(),
      ];
      this.CalculTotalMontalHT();
      this.CalculTotalMontalTVA();
      this.CalculTotalMontalTTC();
      this.isLoadedDetailCmd = true;
    } else {
      this.InitCommande();
    }

    // commande par fournisseur, on charge tous les fournisseurs
    if (this.SelectedOptSaisie == '0') {
      this.GetAllFournisseur();
    }
  }

  public InitCommande() {
    this._activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.hasOwnProperty('iu')) {
        this.NumCmd = params['iu'];
      }
      if (params.hasOwnProperty('cmd')) {
        this.LetterCmd = params['cmd'];
      }

      if (params.hasOwnProperty('trame')) {
        this.NomTrame = decodeURIComponent(params['trame']);
      }

      if (!this.NomTrame) {
        // edit commande ou create commande
        if (this.LetterCmd && this.NumCmd) {
          // cas edit commande
          this.ListSupplierCatalog = [];
          this.isConsult = true;
          // set disable fournissseur
          this.SelectFormFourControl.disable();
          this._commandesService.GetDetailCmd(this.NumCmd, this.LetterCmd);
          // set type de commande par fournisseur dans le cas edit d'une commande
          this.OptSaisie.splice(1, 1);
        } else {
          this.IsEdit = true;
          // cas nouvelle commande
          this.CommandInfo = {
            IsSavedStock: false,
            CmdHeader: this._commandesService.InitNewCmdHeader(),
            CmdLine: [],
            LineDeleted: []
          };
          // créer une nouvelle commande
          this.CommandInfo.CmdLine = [
            ...this.CommandInfo.CmdLine,
            this._commandesService.InitNewLineCmd(),
          ];
          this.CalculTotalMontalHT();
          this.CalculTotalMontalTVA();
          this.CalculTotalMontalTTC();
          this.isLoadedDetailCmd = true;
        }


        this.GetCommonDataFromDB();
      }

      if (!this.LetterCmd && !this.NumCmd) {
        // create commande par trame de commande
        if (this.NomTrame) {
          this.SelectFormFourControl.disable();
          // load all article par trame de commande pour créer une nouvelle commande avec cette trame de comande
          this._commandesService.GetTrameDetailCmd(this.NomTrame);
        }
      }
   
    });
  }

  public GetAllFournisseur() {
    this._subscriptions.push(
      this._supplierService
        .GetListSupplierActifSync()
        .subscribe((ListSupplierCatalogResult: SupplierModel[]) => {
          if (ListSupplierCatalogResult) {
            this.ListSupplierCatalog = [];
            ListSupplierCatalogResult.forEach((x) => {
              let newCatalogSupplier: CatalogSupplierModel = {
                CODE_FOURNISSEUR: x.CODE_FOURNISSEUR,
                Code_Denree: '',
                IU_FOURNISSEUR: x.IU_FOURNISSEUR,
                NOM_FOURNISSEUR: x.NOM_FOURNISSEUR,
                E_MAIL: x.E_MAIL,
                FOU_EGALIM: '',
                Prix_Denree: '',
              };
              this.ListSupplierCatalog = [
                ...this.ListSupplierCatalog,
                newCatalogSupplier,
              ];
            });
          }
        })
    );
  }

  public RefreshOnFou(event: any) {
    event.stopPropagation();

    const message =
      'Voulez-vous réinitaliser la saisie commande ? Les données saisies actuelles seront effacées.';

    const dialogData = new ConfirmDialogModel('Confirmation', message);

    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      maxWidth: '500px',
      data: dialogData,
      height: '220px',
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        // init saisie cmd
        this.CommandInfo.CmdLine = [];
        this.CommandInfo.CmdLine = [
          ...this.CommandInfo.CmdLine,
          this._commandesService.InitNewLineCmd(),
        ];
        this.ListSupplierCatalog = [];
        this.SelectFormFourControl.enable();
        this.GetAllFournisseur();
        this.SelectFormFourControl.setValue('');
      }
    });
  }

  ///pop-up fournisseur detail
  public ConsultFournDetail() {
    if (this.ListSupplierCatalog.length == 1) {
      const ArtDialogData = new ViewFournDetailData(
        this.ListSupplierCatalog[0].IU_FOURNISSEUR
      );
      const dialogRef = this._dialog.open(DialogViewFournDetailComponent, {
        width: '100%',
        data: ArtDialogData,
        disableClose: false,
      });
      return dialogRef;
    }
  }

  public onFournisseurChange(event: any) {
    this.IsEdit = true;

    // si c'est mode de saisie par fournisseur, on bloque fournisseur selectionné dans la liste
    // set un seul fournisseur dans la liste dropdown
    // ajouter un seul fournisseur dans la liste dropdown
    let selectedSupplier: CatalogSupplierModel = {
      CODE_FOURNISSEUR: '',
      Code_Denree: '',
      IU_FOURNISSEUR: event.value,
      NOM_FOURNISSEUR: this.ListSupplierCatalog.find(
        (x) => x.IU_FOURNISSEUR == event.value
      ).NOM_FOURNISSEUR,
      E_MAIL: this.ListSupplierCatalog.find(
        (x) => x.IU_FOURNISSEUR == event.value
      ).E_MAIL,
      FOU_EGALIM: this.ListSupplierCatalog.find(
        (x) => x.IU_FOURNISSEUR == event.value
      ).FOU_EGALIM,
      Prix_Denree: '',
    };
    this.ListSupplierCatalog = []; // delete all catalog four
    this.ListSupplierCatalog.splice(
      this.ListSupplierCatalog.length,
      0,
      selectedSupplier
    ); // add 1 founr
    this._commandesService.EmitterChangeFournisseurCmd(selectedSupplier);
  }

  // ajouter une ligne de commande
  public AddLineCmd(currentValTotalLinecmd: number): void {
    this.currentTotalLine = currentValTotalLinecmd;
    this.IsEdit = true;
    this._isCreateLineCmd = true;

    if (this.SelectedOptSaisie == '0') {
      // add line commande par fournisseur
      if (this.CommandInfo.CmdLine.length != 0) {
        let LineCmdAddedSameFour = this._commandesService.InitNewLineCmd();
        let LineCmdAddedSameFourCopy = _.cloneDeep(this.CommandInfo.CmdLine[0]);
        if (LineCmdAddedSameFourCopy.PRECO_IUFOU != '') {
          LineCmdAddedSameFour.PRECO_IUCOM = LineCmdAddedSameFourCopy.PRECO_IUCOM;
          LineCmdAddedSameFour.PRECO_LETTRE = LineCmdAddedSameFourCopy.PRECO_LETTRE;
          LineCmdAddedSameFour.PRECO_DATE_COM = LineCmdAddedSameFourCopy.PRECO_DATE_COM;
          LineCmdAddedSameFour.PRECO_DATE_LIVRE = LineCmdAddedSameFourCopy.PRECO_DATE_LIVRE;
          LineCmdAddedSameFour.PRECO_IUFOU = LineCmdAddedSameFourCopy.PRECO_IUFOU;
          LineCmdAddedSameFour.Preco_fou_libelle = LineCmdAddedSameFourCopy.Preco_fou_libelle;
          LineCmdAddedSameFour.PRECO_DENREE = '';
          LineCmdAddedSameFour.PRECO_REF = '';
          LineCmdAddedSameFour.PRECO_PRIX = '';
          LineCmdAddedSameFour.PRECO_VALIDE_COM = '1';
          const maxLig = this.CommandInfo.CmdLine.reduce(function (prev, current) {
            return (Number(prev.PRECO_NOLIG) > Number(current.PRECO_NOLIG) ? prev : current)
          });//returns object
          LineCmdAddedSameFour.PRECO_NOLIG = (Number(maxLig.PRECO_NOLIG) + 1).toString().padStart(3, '0');
          this.CommandInfo.CmdLine.splice(this.CommandInfo.CmdLine.length + 1, 0, LineCmdAddedSameFour);
        } else {
          this.NotificationMsg(
            'Vous êtes en mode saisie par fournisseur, selectionnez un fournisseur ! ',
            false
          );
        }
      } else {
        this.NotificationMsg(
          'Vous êtes en mode saisie par fournisseur, selectionnez un fournisseur ! ',
          false
        );
      }
    } else {
      // add line commande glabal
      let NewLineCmd = this._commandesService.InitNewLineCmd();
      const maxLig = this.CommandInfo.CmdLine.reduce(function (prev, current) {
        return (Number(prev.PRECO_NOLIG) > Number(current.PRECO_NOLIG) ? prev : current)
      });//returns object
      NewLineCmd.PRECO_NOLIG = (Number(maxLig.PRECO_NOLIG) + 1).toString().padStart(3, '0');
      this.CommandInfo.CmdLine = [...this.CommandInfo.CmdLine, NewLineCmd];
    }
  }

  /***
   * MAJ info line cmd
   */
  public ChangeFromLineCmd(linecmd: CMD_GES_PRECO_Line) {
    this.IsEdit = true;

    let idxLineCmdMAJ = this.CommandInfo.CmdLine.findIndex(
      (p) =>
        p.PRECO_IUCOM == linecmd.PRECO_IUCOM &&
        p.PRECO_NOLIG == linecmd.PRECO_NOLIG
    );
    if (idxLineCmdMAJ != -1) {
      this.CommandInfo.CmdLine[idxLineCmdMAJ].PRECO_PRIX = linecmd.PRECO_PRIX;
      this.CommandInfo.CmdLine[idxLineCmdMAJ].PRECO_QTE_ALIVRER =
        linecmd.PRECO_QTE_ALIVRER;
      this.CommandInfo.CmdLine[idxLineCmdMAJ].PRECO_IUFOU = linecmd.PRECO_IUFOU;
      this.CommandInfo.CmdLine[idxLineCmdMAJ].Preco_fou_libelle =
        linecmd.Preco_fou_libelle;
      this.CommandInfo.CmdLine[idxLineCmdMAJ].PRECO_DENREE =
        linecmd.PRECO_DENREE;
    }

    this.CalculTotalMontalHT();
    this.CalculTotalMontalTVA();
    this.CalculTotalMontalTTC();

  }

  /***
   * Calculer le montant total des lignes de commande
   */
  public CalculTotalMontalHT(): string {

    if (this.CommandInfo) {
      if (this.CommandInfo.CmdLine.length > 0) {
        let total: number = 0;
        total = this.CommandInfo.CmdLine.reduce(
          (sum, y) =>
            sum +
            Number(
              (Number(y.PRECO_PRIX ? y.PRECO_PRIX : "0") *
                Number(y.PRECO_QTE_ALIVRER ? y.PRECO_QTE_ALIVRER : "0")
              ).toFixed(2)
            )
          ,
          total
        );
        this.MtnTotalHT = total.toString();
      }
      else {
        this.MtnTotalHT = "0";
      }
    }
    else {
      this.MtnTotalHT = "0";
    }
    return this.MtnTotalHT;
  }

  public CalculTotalMontalTVA(): string {

    if (this.CommandInfo) {
      if (this.CommandInfo.CmdLine.length > 0) {
        let total: number = 0;
        total = this.CommandInfo.CmdLine.reduce(
          (sum, y) =>
            sum +
            Number(
              (Number(y.PRECO_PRIX ? y.PRECO_PRIX : "0") *
                Number(y.PRECO_QTE_ALIVRER ? y.PRECO_QTE_ALIVRER : "0") *
                ((Number(y.PRECO_TVA) / 100) / 100)).toFixed(2)
            ),
          total
        );
        this.MtnTotalTva = total.toString();
      }
      else {
        this.MtnTotalTva = "0";
      }
    }
    else {
      this.MtnTotalTva = "0";
    }

    return this.MtnTotalTva;
  }

  public CalculTotalMontalTTC(): string {

    this.MtnTotalTTC = "0";

    this.MtnTotalTTC = (Number(this.MtnTotalHT) + Number(this.MtnTotalTva)).toString();

    return this.MtnTotalTTC;
  }

  /**
   * from edit entete commande
   */
  public constructorFormEditCmd(): void {
    this.RegisterFormEditCommande = this._formBuilderEditCmd.group({
      DateLivr: [
        moment(new Date()).local(false).utc(true).toDate(),
        [Validators.required],
      ],
      DateCmd: [
        moment(new Date()).local(false).utc(true).toDate(),
        [Validators.required],
      ],
      LetterCmdCtrl: ['A'],
      NoteCmd: [''],
      AdrLivIdCtrl: ['CMDLIV'],
      AdrFacIdCtrl: ['']
    });
  }

  public onSelectLetterCmdChange(event: any) {
    this.IsEdit = true;

    this.CommandInfo.CmdHeader.PRECO_LETTRE = event.value;
  }

  public onSelectAdrLivraison(event: any) {
    this.IsEdit = true;

    this.CommandInfo.CmdHeader.PRECO_ADR_LIV_ID = event.value;
  }

  public onSelectAdrFac(event: any) {
    this.IsEdit = true;
    this.CommandInfo.CmdHeader.PRECO_ADR_FAC_ID = event.value;
    //this.CommandInfo.CmdHeader.PRECO_ADR_LIV_ID = event.value;
  }

  /**
   *
   * @param event
   * MOde de saisie
   */
  public onSelectSaisieModeChange(event: any) {
    // renit action
    this.IsEdit = true;
    this.isImportCatalog = false;

    if (this.CommandInfo.CmdLine.length != 0) {
      if (
        this.CommandInfo.CmdLine[0].PRECO_IUFOU != '' ||
        this.CommandInfo.CmdLine[0].PRECO_DENREE != '' ||
        this.CommandInfo.CmdLine[0].PRECO_PRIX != '' ||
        this.CommandInfo.CmdLine[0].PRECO_QTE_ALIVRER != '' ||
        this.CommandInfo.CmdLine.length > 1
      ) {
        const message =
          'Voulez-vous changer le mode de saisie ? Les données saisies actuelle seront effacées.';

        const dialogData = new ConfirmDialogModel(
          'Confirmation de changement',
          message
        );

        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
          maxWidth: '500px',
          data: dialogData,
          height: '220px',
        });

        this._subscriptions.push(
          dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult) {
              this.SelectedOptSaisie = event.value;
              this.previousSelectedOptSaisie = event.value;
              // init saisie cmd
              this.CommandInfo.CmdLine = [];
              this.CommandInfo.CmdLine = [
                ...this.CommandInfo.CmdLine,
                this._commandesService.InitNewLineCmd(),
              ];
              this.GesDataLineCmdModeSaisiChange(event.value);
            } else {
              this.SelectedOptSaisie = this.previousSelectedOptSaisie;
            }
          })
        );
      } else {
        this.GesDataLineCmdModeSaisiChange(event.value);
      }
    }
  }

  /**
   * Gestion d'evenenement en fonction de mode de saisie
   * @param val mode de saisie
   *
   */
  public GesDataLineCmdModeSaisiChange(val: string) {
    if (val == '0') {
      // commande par fournisseur
      this.GetAllFournisseur();
      this.SelectFormFourControl.enable();
      // send emit to line cmd that the mode is changed
      this._commandesService.EmitterChangeModeSaisieCmd(val);
    } else {
      // commande global
      // réinit liste fournisseur
      // réinit cmd header fournisseur
      this.CommandInfo.CmdHeader.PRECO_IUFOU = '';
      this.ListSupplierCatalog = [];
      // set disable fournissseur
      this.SelectFormFourControl.disable();
      this.SelectFormFourControl.setValue('');
      // send emit to line cmd that the mode is changed
      this._commandesService.EmitterChangeModeSaisieCmd(val);
    }
  }

  public ConfirmSuppressionQteOrPrixVide() {
    if (!this.isImportCatalog) {
      this.isNotYesNo = true;
      this.message =
        'Des quantités ou des prix ne sont pas renseignés, impossible d\'enregistrer la commande.';
    } else {
      this.isNotYesNo = false;
      this.message = 'Des quantités ou des prix ne sont pas renseignés, voulez-vous enregistrer la commande ?';
    }

    const dialogData = new ConfirmDialogModel('Saisie Incomplète', this.message, this.isNotYesNo);

    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      maxWidth: '500px',
      data: dialogData,
      height: '220px',
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        // garder que les lignes de qté et prix saisis
        let LignesSaisies = this.CommandInfo.CmdLine.filter(
          (p) =>
            p.PRECO_PRIX != undefined &&
            p.PRECO_PRIX != null &&
            p.PRECO_PRIX.replace(' ', '') != '' &&
            p.PRECO_QTE_ALIVRER != null &&
            p.PRECO_QTE_ALIVRER != undefined &&
            p.PRECO_QTE_ALIVRER.replace(' ', '') != ''
        ).slice();

        if (LignesSaisies.length != 0) {
          this.CommandInfo.CmdLine = LignesSaisies;
        }
      }
    });
  }

  /**
   *
   * @returns save commande in backend
   */
  public onSubmitRegisterFormEditCommande(): void {
    // console.log(this.FromLinesCmd.form);

    // if (this.FromLinesCmd.form.valid) {
    //   console.log('Err child');
    //   this.getFormValidationErrors(this.FromLinesCmd.form);
    //   return;
    // }

    if (!this.RegisterFormEditCommande.valid) {
      //this.getFormValidationErrors(this.RegisterFormEditCommande);
      console.log('ERR');
      return;
    } else {
      // controle prix unitaire ou qté pour supprimer les lignes vides
      if (
        this.CommandInfo.CmdLine.findIndex(p => p.PRECO_PRIX == null) != -1 || this.CommandInfo.CmdLine.findIndex(p => p.PRECO_PRIX.replace(' ', '') == '') != -1 ||
        this.CommandInfo.CmdLine.findIndex(p => p.PRECO_QTE_ALIVRER == null) != -1 || this.CommandInfo.CmdLine.findIndex(p => p.PRECO_QTE_ALIVRER.replace(' ', '') == '') != -1) {
        this.ConfirmSuppressionQteOrPrixVide();
        return;
      }

      // controle article et fournisseur
      if (
        this.CommandInfo.CmdLine.findIndex(
          (p) => p.PRECO_IUFOU.replace(' ', '') == ''
        ) != -1 ||
        this.CommandInfo.CmdLine.findIndex(
          (p) => p.PRECO_DENREE.replace(' ', '') == ''
        ) != -1 ||
        this.CommandInfo.CmdLine.findIndex(
          (p) => p.preco_iserror_contitio === true
        ) != -1
      ) {
        console.log('ERR_LINE');
        this.NotificationMsg('Erreur, champs obligatoires ! ', false);
        return;
      }

      this.CommandInfo.CmdHeader.PRECO_DATE_COM =
        this.f.DateCmd.value &&
        moment(this.f.DateCmd.value)
          .local(false)
          .utc(true)
          .format('DD/MM/YYYY');
      this.CommandInfo.CmdHeader.PRECO_DATE_LIVRE =
        this.f.DateLivr.value &&
        moment(this.f.DateLivr.value)
          .local(false)
          .utc(true)
          .format('DD/MM/YYYY');



      this.CommandInfo.CmdHeader.PRECO_COMMENT = this.f.NoteCmd.value;
      const message = 'Voulez-vous vraiment enregistrer la commande ? ';

      const dialogData = new ConfirmDialogModel('Confirmation', message);

      const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        maxWidth: '500px',
        data: dialogData,
        height: '220px',
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.displayProgressSpinner = true;
          this._commandesService
            .EnregistreCommande(this.CommandInfo)
            .subscribe((responseCmdValide: CmdGesPrecoModel[]) => {
              this.displayProgressSpinner = false;
              if (responseCmdValide) {
                if (responseCmdValide.length == 1) {
                  if (responseCmdValide[0].CmdHeader.PRECO_IUCOM != '-409') {
                    this.CommandInfo.CmdHeader.PRECO_IUCOM =
                      responseCmdValide[0].CmdHeader.PRECO_IUCOM;

                    // delete dock modal if existe
                    let dockmodal: ResultDockModal = {
                      IsCreate: false,
                      Title: '',
                      Type: '',
                    };
                    this._layoutService.EmitterCreateDockModal(dockmodal);

                    this.NotificationMsg('Modification enregistrée !', true);
                    setTimeout(() => {
                      this._router.navigateByUrl('commandes/list-success', {
                        state: { listCmdSuccessCreated: responseCmdValide },
                      });
                    }, 100);

                  }
                } else {
                  // redirect list cmd créé

                  // delete dock modal if existe
                  let dockmodal: ResultDockModal = {
                    IsCreate: false,
                    Title: '',
                    Type: '',
                  };
                  this._layoutService.EmitterCreateDockModal(dockmodal);

                  this.NotificationMsg('Modification enregistrée !', true);
                  setTimeout(() => {
                    this._router.navigateByUrl('commandes/list-success', {
                      state: { listCmdSuccessCreated: responseCmdValide },
                    });
                  }, 100);

                }
              }
            });
        }
      });
    }
  }

  /**
   *
   * @returns
   * format date de commande
   */
  public GetDateCmd(): string {
    if (this.f.DateCmd) {
      return moment(this.f.DateCmd.value)
        .local(false)
        .utc(true)
        .format('DD/MM/YYYY');
    }
  }

  public ChangeDateLivreSelected(type: string, event: MatDatepickerInputEvent<Date>) {

    let ChangedDate = moment(event.value)
      .local(false)
      .utc(true)
      .format('DD/MM/YYYY');

    this.CommandInfo.CmdHeader.PRECO_DATE_LIVRE_EDIT = ChangedDate;

  }

  /**
   * load data common sectioni
   */
  public GetCommonDataFromDB() {
    this._commonService.GetALlLettersCmd();
    this._commonService.GetAllAdrLivraisonSync().subscribe((result: GRAAL_CENTRE[]) => {
      let ArdCalculPricipal = result.filter(p => p.LIB_Id == "CMDLIV")[0];
      this.ListAdrLivraison = [...this.ListAdrLivraison, ArdCalculPricipal];
      for (let i = 1; i <= 9; i++) {
        let ArdCalculbis = result.filter(p => p.LIB_Id == "CMDLIV" + i.toString())[0];
        if (ArdCalculbis) {
          this.ListAdrLivraison = [...this.ListAdrLivraison, ArdCalculbis];
        }
      }
      this.ListAdrFac = this.ListAdrLivraison.slice();
      let adrfac = result.filter(x => x.LIB_Id == 'CMDFAC')[0];
      if (adrfac) {
        this.ListAdrFac = [...this.ListAdrFac, adrfac];
      }


    });
  }

  public NotificationMsg(msg: string, isOk: boolean) {
    if (isOk) {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-success'],
      });
    } else {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-error'],
      });
    }
  }

  /**
   * obsolete not used
   */
  public print() {
    const fl = URL.createObjectURL(
      new Blob([this.pdfSrc], { type: 'application/pdf' })
    );
    window.open(fl).print();
  }

  public DialogPdfViewMessage(NumCmd, pdfSrcByte: any) {
    const PdfDialogData = new PdfCmdData(NumCmd, pdfSrcByte);

    const dialogRef = this._dialog.open(PdfViewCommandeComponent, {
      width: '60%',
      data: PdfDialogData,
      disableClose: false,
      panelClass: 'pdf-view-dialog-container',
    });

    return dialogRef;
  }

  public CreateTrameCmdGlobal() {

    let LineControl = this.CommandInfo.CmdLine.findIndex(p => p.PRECO_IUFOU == "" || p.PRECO_DENREE == "" || p.PRECO_QTE_ALIVRER == "" || p.PRECO_QTE_ALIVRER == null);

    if (LineControl != -1) {
      this._notificationService.error('Erreur : Sélectionnez un founiseur ou un denrée ');
      return;
    }
    else {
      const TrameCmdDialogData = new DialogCreateTrameCmdData(
        'Créer une trame de commande',
        '',
        '',
        'G',
        _.cloneDeep(this.CommandInfo)
      );

      const dialogRef = this._dialog.open(DialogCreateTrameCommandeComponent, {
        width: '50%',
        data: TrameCmdDialogData,
        height: '30%',
        disableClose: true,
      });

      return dialogRef;
    }

  }

  /**
   * preview pdf commande
   */
  public OpenGenererPDF() {
    this.displayProgressSpinner = true;

    this._subscriptions.push(
      this._commandesService
        .GenererPDFCommande(this.CommandInfo.CmdHeader)
        .subscribe((responseCmdValide: any) => {
          if (responseCmdValide) {
            this.pdfSrc = responseCmdValide;
            const IsPdfdialog = this.DialogPdfViewMessage(
              'Génrération PDF',
              responseCmdValide
            );
            IsPdfdialog.afterClosed().subscribe((dialogResult) => {
              if (dialogResult) {
              }
            });
          } else {
            this.NotificationMsg('Error internal', false);
          }

          this.displayProgressSpinner = false;
        })
    );
  }

  /**
   * open dialog send mail commande
   */
  public OpenSendMailCmd(): void {
    const IsSendMaildialog = this.DialogSendMailMessage(
      'Envoyer commande par mail'
    );

    IsSendMaildialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        // si send mail OK , passer à l'état 9
        this.CommandInfo.CmdHeader.PRECO_ETAT = '9';
      }
    });
  }
  //tel
  public OpenTelCmd(): void {
    const IsSendTeldialog = this.DialogTelMessage(
      'Envoyer commande par tel'
    );
    IsSendTeldialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        // si send mail OK , passer à l'état 7
        this.CommandInfo.CmdHeader.PRECO_ETAT = '7';
        this._router.navigateByUrl('commandes/list', {
          //state: { listCmdSuccessCreated: [...[], this.CommandInfo] },
          state: {
            etat: this.CommandInfo.CmdHeader.PRECO_ETAT,
            filterRecepHistory: this._etatFilterHistory
          }
        });
      }

    });
  }

  public DialogSendMailMessage(message: string) {
    const SendMailDialogData = new SendMailCmdData(
      message,
      this.CommandInfo.CmdHeader,
      this.ListSupplierCatalog.find(
        (x) => x.IU_FOURNISSEUR == this.SelectFormFourControl.value
      )?.E_MAIL
    );

    const dialogRef = this._dialog.open(SendMailCommandesComponent, {
      width: '100%',
      data: SendMailDialogData,
      height: '80%',
      disableClose: true,
    });

    return dialogRef;
  }
  //tel
  public DialogTelMessage(message: string) {
    const SendMailDialogData = new SendMailCmdData(
      message,
      this.CommandInfo.CmdHeader,
      this.ListSupplierCatalog.find(
        (x) => x.IU_FOURNISSEUR == this.SelectFormFourControl.value
      )?.E_MAIL
    );


    const dialogRef = this._dialog.open(SendTelCommandesComponent, {
      maxWidth: '500px',
      data: SendMailDialogData,
      height: '220px',
      disableClose: true,
    });

    return dialogRef;
  }



  public DownloadPDF() {
    this._commandesService
      .GenererPDFCommande(this.CommandInfo.CmdHeader)
      .subscribe((responseCmdValide: any) => {
        if (responseCmdValide) {
          const newBlob = new Blob([responseCmdValide], {
            type: 'application/pdf',
          });
          const data = window.URL.createObjectURL(newBlob);

          const link = document.createElement('a');
          link.href = data;
          link.download = 'cmd.pdf';
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );

          setTimeout(function () {
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 0);
        } else {
          this.NotificationMsg('Erreur interne', false);
        }
      });
  }

  /**** start région bouton action ****/

  public ImportCatalogFourn() {
    this.displayProgressSpinner = true;
    if (this.ListSupplierCatalog.length == 1 && this.SelectedOptSaisie == '0') {
      this._subscriptions.push(
        this._denreeService
          .GetImportCatalogFournisseurCmdSyc(
            this.ListSupplierCatalog[0].IU_FOURNISSEUR
          )
          .subscribe((CatalogFourResult: VUE_GES_CATALOG_FOUR[]) => {
            if (CatalogFourResult.length != 0) {
              // add all line cmd
              this.CommandInfo.CmdLine = [];
              CatalogFourResult.forEach((x) => {
                let NewLineCmd = this._commandesService.InitNewLineCmd();
                let NewNoLig =
                  this.CommandInfo.CmdLine.length < 10
                    ? '00' + this.CommandInfo.CmdLine.length.toString()
                    : '0' + this.CommandInfo.CmdLine.length.toString();
                NewLineCmd.PRECO_NOLIG = NewNoLig;
                NewLineCmd.PRECO_DENREE = x.CATALOG_DENREE;
                NewLineCmd.PRECO_IUFOU = x.CATALOG_IUFOU;
                NewLineCmd.Preco_fou_libelle = x.FOU_NOM;
                NewLineCmd.PRECO_PRIX = x.CATALOG_PRIX_EUROS;
                NewLineCmd.PRECO_UF = x.CATALOG_UF_PRESENT;
                NewLineCmd.PRECO_CONDITIO = x.CATALOG_CONDITIO;
                NewLineCmd.PRECO_REF = x.CATALOG_REF_EXTERNE;
                NewLineCmd.preco_denree_lib = x.NOM_DENREE;
                NewLineCmd.PRECO_TVA = x.DENREE_TVA;
                NewLineCmd.Iu_Denree = x.IU_DENREE;
                this.CommandInfo.CmdLine = [
                  ...this.CommandInfo.CmdLine,
                  NewLineCmd,
                ];
              });
              // this.CalculTotalMontalHT();
              // this.CalculTotalMontalTVA();
              // this.CalculTotalMontalTTC();
              this.isImportCatalog = true;
            } else {
              this.NotificationMsg(
                "Le catalogue de ce fournisseur ne contient pas d'article",
                false
              );
            }
            this.displayProgressSpinner = false;
          })
      );
    }
  }

  /***** end  région bouton action  *****/

  public getFormValidationErrors(formcheck: FormGroup) {
    Object.keys(formcheck.controls).forEach((key) => {
      const controlErrors: ValidationErrors = formcheck.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
            controlErrors[keyError]
          );
        });
      }
    });
  }

  public Annuler(type: string) {
    if (type == 'back') {
      if (this.NomTrame) {
        this._router.navigate(['/commandes/trames']);
      } else {
        //this._router.navigate(['/commandes/list']);
        this._router.navigateByUrl('/commandes/list', {
          state: {
            etat: this.CommandInfo.CmdHeader.PRECO_ETAT,
            filterRecepHistory: this._etatFilterHistory
          }
        });
      }
    } else {
      const message = 'Voulez-vous vraiment annuler la saisie ? ';

      const dialogData = new ConfirmDialogModel('Confirmation', message);

      const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        maxWidth: '500px',
        data: dialogData,
        height: '220px',
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          if (this.NomTrame) {
            this._router.navigate(['/commandes/trames']);
          } else {
            //this._router.navigate(['/commandes/list']);
            this._router.navigateByUrl('/commandes/list', {
              state: {
                filterRecepHistory: this._etatFilterHistory
              }
            });
          }
        }
      });
    }
  }

  public get f() {
    return this.RegisterFormEditCommande.controls;
  }

  public ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this._subscriptions.forEach((s) => s.unsubscribe());
    this.displayProgressSpinner = false;
  }

  ngAfterViewChecked() {
    // console.log('avant ' + this.currentTotalLine);
    if (this.CommandInfo && this.CommandInfo.CmdLine) {
      // set focus in new line cmd added
      if (
        this.CommandInfo.CmdLine.length >= 3 &&
        !this.isImportCatalog &&
        this._isCreateLineCmd
      ) {
        var elmnt = document.getElementById(
          'search-article-cmd-' +
          (this.CommandInfo.CmdLine.length - 1).toString()
        );
        if (elmnt) {
          if (this.currentTotalLine != this.CommandInfo.CmdLine.length) {
            elmnt.scrollIntoView();
            elmnt.focus();
            this.currentTotalLine = this.CommandInfo.CmdLine.length;
            this.cdRef.detectChanges(); //force change detection
          }
        }
      }
    }
    this._isCreateLineCmd = false;
  }

  positionOptions: string[] = [
    'after',
    'before',
    'above',
    'below',
    'left',
    'right',
  ];
  position = new FormControl(this.positionOptions[0]);
}
