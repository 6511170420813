<div class="container-fluid">
  <form [formGroup]="RegisterFormCreateTrameCmd">
    <div class="header-mail">Créer une trame de commande</div>
    <div class="row mt-2">
      <div class="col-md">
        <label class="font-weight-700">Nom de trame de commande</label>
        <mat-form-field class="input-size-height" appearance="fill">
          <input
            type="text"
            matInput
            placeholder=""
            formControlName="TrameNom"
          />
          <mat-error *ngIf="f.TrameNom.hasError('required')" class="error-input"
            >Nom de trame obligatoire
          </mat-error>
          <mat-error
            *ngIf="f.TrameNom.hasError('maxlength')"
            class="error-input"
            >Nom de trame comporte de 1 à 128 charactères
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-center">
      <button
        mat-stroked-button
        class="button-generic-validate"
        (click)="onConfirmCreateTrameCmd()"
        type="submit"
      >
        {{ "Create" | translate }}
      </button>
      <button
        mat-button
        class="button-generic-delete"
        (click)="onAnnulerCreate()"
        type="button"
      >
        {{ "Cancel" | translate }}
      </button>
    </div>
  </form>
  <Spinner-Loader
    class="spinner-loader-custom"
    [color]="colorLoader"
    [mode]="modeLoader"
    [value]="valueLoader"
    [backdropEnabled]="true"
    [positionGloballyCenter]="true"
    [displayProgressSpinner]="displayProgressSpinner"
  >
  </Spinner-Loader>
</div>
