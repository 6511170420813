export class Config {
    public static get API_ROUTES(): MICROSERVICES {
        return {
            APP_BASE_URL_API: '/',
            APP_BASE_URL_API_AUTH: '/',
            USER_API: {
                API_POST_PUBLIC_LOGIN_AUTH: '/auth/login',
                API_POST_MEMBER_LOGOUT: '/auth/logout'
            },
            PURCHASE_API: {
                API_GET_ALL_SUPPLIERS: '/purchase/suppliers',
                API_GET_DETAIL_SUPPLIER: '/purchase/supplier/detail?id=',
                API_GET_ALL_ACTIF_SUPPLIERS: '/purchase/suppliers-actif',
                API_GET_FAM_DEN: '/purchase/famden',
                API_GET_SFAM_DEN: '/purchase/sfamden?sf=',
                API_GET_DENREE: '/purchase/denree?d=',
                API_GET_DETAIL_DENREE: '/purchase/detail-denree?d=',
                API_GET_SEARCH_ARTICLE_DENREE: '/purchase/denree/search?name=',
                API_GET_SEARCH_ARTICLE_WITH_FOU_PRICE: '/purchase/denree/search-fou-price?name=',
                API_POST_SAVE_EDIT_DENREE: '/purchase/denree/save-edit',
                API_GET_CATALOG_FOURNISSEUR_PRIX: '/purchase/denree/get-catalog-price?',
                API_GET_ART_CATALOG_FOURNISSEUR: '/purchase/denree/get-art-catalog-supplier?',
                API_POST_SAVE_FAMILLE: '/purchase/denree/fam/save-edit',
                API_POST_SAVE_SFAMILLE: '/purchase/denree/sfam/save-edit',
                API_POST_DELETE_DENREE_ARTICLE: '/purchase/denree/delete-article',
                API_GET_FAM_DETAIL: '/purchase/famden-detail?f=',
                API_GET_CATALOG_FOUR_CMD: '/purchase/denree/get-catalog-supplier-cmd?iu=',
                API_POST_SAVE_EDIT_SUPPLIER: '/purchase/supplier/save-edit',
                API_POST_SAVE_LOGO_SUPPLIER: '/purchase/supplier/save-logo',
                API_POST_DELETE_SUPPLIER: '/purchase/supplier/delete',
                API_GET_SEARCH_SUPPLIER: '/purchase/supplier/search?name=',
                API_GET_TARIF_Fournisseur: '/purchase/supplier/tarif-catalog-fou?iufou=',
                API_POST_SAVE_EDIT_ALL_TARIF_Fournisseur: '/purchase/supplier/save-edit-tarif-catalog',
                API_GET_ALL_SUPPLIERS_WITH_PRICE: '/purchase/supplier/all-with-price-art?a=',
                API_GET_ALIMENTS_BY_GROUP: '/purchase/denree/all-alim-group?',
                API_GET_VAL_NUTRI_BY_ALIM: '/purchase/denree/val-nutri-alim?',
                API_POST_CREATE_ALIMENT: '/purchase/denree/nutri-createorupdate-aliment',
                API_POST_CREATE_GROUPE_ALIMENT: '/purchase/denree/nutri-createorupdate-group',
                API_POST_DELETE_SOUS_FAM: '/purchase/denree/delete-sfam',
                API_GET_LIST_DENREE_BY_FAM: '/purchase/denree-by-fam?f=',
                API_POST_COPY_DENREE: '/purchase/denree/copy',
                API_POST_EXPORT_ALL_ART_EXCEL: '/purchase/art/export-excel',
                API_POST_IMPORT_ALL_ART_EXCEL: '/purchase/art/import-excel',
                API_POST_DELETE_FAM: '/purchase/denree/delete-fam'
            },
            COMMON_API: {
                API_GET_UNIT_POIDS: '/common/unipoid',
                API_GET_GRAAL_CENTRE_PREPA: '/common/allcentreprep',
                API_GET_ALL_STOCK: '/common/allstock',
                API_GET_ALL_ZONE_STOCK: '/common/allzonestock',
                API_GET_ALL_TVA: '/common/alltva',
                API_GET_ALL_TYPE_DENREE_INFO: '/common/infotype',
                API_GET_ALL_ETABLISSEMENTS: '/common/getetablissements',
                API_GET_ALL_SERVICE_ETAB: '/common/getservice-etab?etab=',
                API_GET_DETAIL_SERVICE_INFO: '/common/getdetail-service?',
                API_GET_LIB_SERV_CONDITION: '/common/getdetail-service-condition',
                API_GET_ALL_REGIME: '/common/getall-regime',
                API_GET_ALL_GES_GAMME: '/common/getall-gesgame',
                API_GET_ALL_EGALIM: '/common/getall-egalim',
                API_GET_DENREE_EGALIM: '/common/denree-egalim?',
                API_GET_ALL_ALLERGENES: '/common/getall-allergenes',
                API_GET_DENREE_ALLERGENES: '/common/denree-allergenes?',
                API_GET_UNIT_CALCUL: '/common/unicalcul',
                API_GET_ALL_LETTERS_CMD: '/common/letterscmd',
                API_GET_CONTROLE_COHERENCE_SORTIE_ART: '/controle/stock-output-art',
                API_GET_ALL_NUTRI_GROUP: '/common/all-nutri-group',
                API_POST_RIGHTS: '/common/rights',
                API_GET_ALL_ADR_LIVRAISON: '/common/alladrlivraison',
                API_POST_SEND_MAIL_COMMON: '/common/send-mail',
                API_GET_RECIPE_SALAISON: '/common/recipe/salaison'
            },
            PARAM_API: {
                API_POST_EDIT_ETABLISSEMENT: '/param/etab/save',
                API_POST_EDIT_SERVICE_ETAB: '/param/etab-service/save',
                API_GET_ARTICLES_ZONE_STOCK: '/param/zone-stock-article?iuzone=',
                API_POST_EDIT_ZONE_STOCK: '/param/zone-stock/save-edit',
                API_POST_DELETE_ZONE_STOCK: '/param/zone-stock/delete',
                API_POST_DELETE_SERVICE_ETAB: '/param/service-etab/delete',
                API_POST_DELETE_ETABLISSEMENT: '/param/etab/delete',
                API_POST_EDIT_TARIF_DENREE: '/param/tarif-denree/save-edit',
                API_POST_DELETE_TARIF_DENREE: '/param/tarif-denree/delete',
                API_POST_EDIT_COND_ART_UNI_POID: '/param/cond-art-uni-poid/save-edit',
                API_POST_DELETE_COND_ART_UNI_POID: '/param/cond-art-uni-poid/delete',
                API_GET_PARAM_HEADER_CMD: '/param/cmd-header',
                API_POST_EDIT_PARAM_HEADER: '/param/cmd-header/save-edit',
                API_POST_EDIT_PARAM_ETAB: '/param/etab/save-edit-info',
                API_GET_PARAM_ETAB: '/param/etab/info',
                API_GET_PARAM_ETAB_INFO_SUP: '/param/etab/info-sup',
                API_POST_DELETE_ADR_LIVRAISON: '/param/etab/delete-adr-liv',
                API_POST_SAVE_LOGO_ETAB: '/param/etab/save-logo',
                API_GET_PARAM_PREMAIL_CMD: '/param/etab/pre-mail-cmd',
                API_POST_ATTACH_ART_ZONE_STOCK: '/param/attach-zone-stock-article'
            },
            PARAM_APP_API: {
                API_GET_ALL_USERS_ACTIF: '/app/users-actif',
                API_GET_ALL_PROFIL_USER: '/app/profils',
                API_POST_GENERATE_USER_ID: '/app/generate-user-id',
                API_POST_SAVE_USER_INFO: '/app/save-user',
                API_POST_DELETE_USER: '/app/delete-user',
                API_POST_INTERFACE_MENU_BY_PROFIL_APP: '/app/all-access',
                API_GET_INTERFACE_MENU_ACCES: '/app/user-access',
                API_POST_SAVE_PROFIL_APP: '/app/save-profil-app',
                API_POST_DELETE_PROFIL_APP: '/app/delete-profil-app',
                API_POST_SAVE_ACCESS_USER_APP: '/app/save-access',
                API_POST_SAVE_MENU_APP: '/app/save-menu'
            },
            COMMANDES_API: {
                API_GET_ALL_CMD_HEADER: '/commandes/all-header',
                API_GET_SEARCH_COMMANDE: '/commandes/search?iucmd=',
                API_GET_DETAIL_COMMANDE_IU_LETTER: '/commandes/detail-iu-cmd?',
                API_POST_ENREGISTRER_COMMANDE: '/commandes/enregistre',
                API_POST_GENERER_PDF_COMMANDE: '/commandes/generepdfcmd',
                API_POST_CMD_AFTER_EDIT: '/commandes/list-cmd-success',
                API_POST_FILTER_CMD: '/commandes/filter',
                API_POST_FILTER_RECEP: '/commandes/reception/filter',
                API_POST_SEND_MAIL_CMD: '/commandes/send-mail-pdfcmd',
                API_POST_SEND_TEL_CMD: '/commandes/send-tel-cmd',
                API_POST_CREATE_TRAME_CMD: '/commandes/trame/create',
                API_GET_ALL_TRAME_CMD: '/commandes/trame/all',
                API_GET_DETAIL_TRAME_CMD: '/commandes/trame/detail?',
                API_POST_SAVE_EDIT_TRAME_CMD: '/commandes/trame/enregistre',
                API_POST_DELETE_TRAME_CMD: '/commandes/trame/delete',
                API_GET_LIST_CRITERE_FIQ: '/commandes/allfiq',
                API_POST_SAVE_EDIT_RECEPTION: '/commandes/save-reception-cmd',
                API_GET_ALL_ORIGIN_ART_RECEPTION: '/commandes/orgin-reception',
                API_POST_SAVE_EDIT_AVOIR: '/commandes/save-avoir',
                API_GET_DETAIL_AVOIR: '/commandes/detail-iu-avoir?',
                API_GET_LIST_AVOIRS: '/commandes/list-avoirs',
                API_POST_LIST_AVOIRS_AFTER_EDIT: '/commandes/list-avoirs-success',
                API_POST_SAVE_EDIT_DEM_AVOIR: '/commandes/save-dem-avoir',
                API_POST_LIST_DEM_AVOIRS_AFTER_EDIT: '/commandes/list-dem-avoirs-success',
                API_GET_LIST_DEM_AVOIRS: '/commandes/list-dem-avoirs',
                API_GET_DETAIL_DEM_AVOIR: '/commandes/detail-iu-dem-avoir?',
                API_POST_SOLDER_COMMANDE_RECEPTION_CALCUL: '/commandes/solder-cmd-recep-calcul',
                API_POST_CREATE_DEM_AVOIR_FROM_CMD: '/commandes/cmd-dem-avoir-calcul',
                API_POST_CREATE_AVOIR_FROM_CMD: '/commandes/cmd-avoir-calcul',
                API_GET_SEARCH_AVOIR_BY_NOBON: '/commandes/avoir/search?nobon=',
                API_GET_SEARCH_DEM_AVOIR_BY_NOBON: '/commandes/dem-avoir/search?nobon=',
                API_POST_FILTER_AVOIR: '/commandes/avoir/filter',
                API_POST_FILTER_DEM_AVOIR: '/commandes/dem-avoir/filter',
                API_POST_DELETE_CMD: '/commandes/delete',
                API_POST_GENERER_PDF_DEMAVOIR: '/commandes/genererpdfdemavoir',
                API_POST_GENERER_PDF_AVOIR: '/commandes/genererpdfavoir',
                API_POST_APURER_CMD: '/commandes/apurer',
                API_POST_ANNULER_RECEPTION: '/commandes/annuler-reception',
                API_POST_MODIF_RECEPTION: '/commandes/edit-reception',
                API_GET_DETAIL_CMD_BY_RECEPTION: '/commandes/detail-reception',

            },
            STOCK_API: {
                API_GET_ALL_STOCK_SORTIE: '/stock/output-art',
                API_GET_CLIENT_OUTPUT_ART: '/stock/client-output-art',
                API_POST_SAVE_OUTPUT_ART: '/stock/save-output-art',
                API_POST_GENERER_PDF_BS: '/stock/generepdfbs',
                API_GET_SEARCH_ARTICLE_STOCK: '/stock/search-article?d=',
                API_GET_ART_STOCK_BY_ZONE_STOCK: '/stock/get-article-by-zonestock?',
                API_POST_SAVE_INVENTORY_ARTICLE: '/stock/save-inventory-art',
                API_GET_ETAT_STOCK_ARTICLE: '/stock/etat-stock?',
                API_GET_MVT_STOCK_ARTICLE: '/stock/mvt/art?',
                API_GET_LIST_SORTIE_HEADER: '/stock/list-output-art-header',
                API_GET_LIST_SORTIE_ART_DETAIL: '/stock/list-output-art-detail?nobs=',
                API_POST_FILTER_ART_HEADER: '/stock/filter-art-header',
                API_POST_EXPORT_EXCEL_INVENTAIRE_TEMPLATE: '/stock/export-inventaire-template',
                API_GET_INVENTORY_HISTORY: '/stock/inventory-history?d=',
                API_GET_INVENTORY_HISTORY_DETAIL: '/stock/inventory-history-detail?id=',
                API_POST_GENERER_PDF_INVENTORY_DETAIL: '/stock/generepdfinventorydetail',
                API_POST_GENERER_PDF_RECEPTION: '/stock/generepdfreception',
                API_POST_IMPORT_INVENTORY_EXCEL: '/stock/import-inventory',
                API_POST_SAVE_IMPORT_INVENTORY_EXCEL: '/stock/save-import-inventory',
                API_GET_LIST_SORTIE_ART_DETAIL_EDIT: '/stock/list-output-art-detail-edit?nobs=',
                API_POST_SAVE_OUTPUT_ART_EDIT: '/stock/save-output-art-edit',
                API_POST_EXPORT_EXCEL_ETAT_STOCK: '/stock/export-excel-etat-stock',
                API_POST_EXPORT_PDF_OUTPUT_HEADER_ART: '/stock/generepdfoutputartheader',
                API_POST_EXPORT_PDF_ETAT_STOCK_ART: '/stock/generepdfetatstockart',
                API_POST_EXPORT_PDF_MVT_ART: '/stock/generepdfmvtstockart',
                API_GET_LIST_RECEPTION: '/stock/list-nb-recep',
                API_POST_FILTER_LIST_RECEPTION: '/stock/reception/filter',
                API_GET_SEARCH_RECEPTION_BL_FAC: '/stock/search-reception?t='
            },
            MERCURIALES: {
                API_POST_EXPORT_STD_MERCURIALE: '/mercuriale/export-standard',
                API_POST_IMPORT_STD_MERCURIALE: '/mercuriale/import-standard',
                API_POST_SCA_CHARGER_MERCURIALE: '/mercuriale/sca-load',
                API_POST_SCA_SAVE_MERCURIALE: '/mercuriale/sca-save',
                API_POST_SAVE_FOU_INACTIF: '/mercuriale/save-fou-inactif',
                API_GET_NEW_Fournisseur_MERCURIALE: '/mercuriale/new-fou',
                API_GET_NOTIFY_MERCURIALE: '/mercuriale/notify',
                API_POST_CLOSE_NOTIFY_MERCURIALE: '/mercuriale/close-notify',
                API_POST_AGA_CHARGER_MERCURIALE: '/mercuriale/aga-load',
                API_POST_AGA_SAVE_MERCURIALE: '/mercuriale/aga-save'
            },
            STATISTIQUES_API: {
                API_POST_STAT_EGALIM_ART_RECEP: '/stats/egalim-recep-pie',
                API_POST_STAT_EXPORT_EGALIM_ART_RECEP: '/stats/egalim-recep-export-excel',
                API_POST_PDF_HEADER_RECEP: '/stats/recep-header-pdf'
            },
            DASHBOARD_API: {
                API_GET_INFO_BASIC: '/dashboard/basic',
                API_GET_STAT_EGALIM_CURRENT_MONTH: '/stats/egalim-recep-line-current-month',
                API_POST_SAVE_NOTE: '/dashboard/basic/save'
            },
            RECETTE_API: {
                API_GET_FAM_RECETTE: '/recipe/fam',
                API_GET_SFAM_RECETTE: '/recipe/sfam?f=',
                API_GET_CATEG_RECETTE: '/recipe/categ?sf=',
                API_GET_PLAT_RECETTE: '/recipe/plat?cat=',
                API_GET_FICH_TECH_RECETTE: '/recipe/fichtech?p=',
                API_POST_EDIT_FAM_RECETTE: '/recipe/save-fam',
                API_POST_EDIT_SFAM_RECETTE: '/recipe/save-sfam',
                API_POST_EDIT_CAT_RECETTE: '/recipe/save-cat',
                API_GET_ALL_PARAM_PROD_RECIPE: '/recipe/param-prod-recipe',
                API_GET_FITECH_BY_PLAT_GAMME: '/recipe/fitech?'
            }
        };
    }
}

export class MICROSERVICES {
    APP_BASE_URL_API: string;
    APP_BASE_URL_API_AUTH: string;
    USER_API: USER_API;
    PURCHASE_API: PURCHASE_API;
    MERCURIALES: MERCURIALES_API;
    COMMON_API: COMMON_API;
    PARAM_API: PARAM_API;
    PARAM_APP_API: PARAM_APP_API;
    COMMANDES_API: COMMANDES_API;
    STOCK_API: STOCK_API;
    STATISTIQUES_API: STATISTIQUES_API;
    DASHBOARD_API: DASHBOARD_API;
    RECETTE_API: RECETTE_API;
}

export class STATISTIQUES_API {
    API_POST_STAT_EGALIM_ART_RECEP: string;
    API_POST_STAT_EXPORT_EGALIM_ART_RECEP: string;
    API_POST_PDF_HEADER_RECEP: string;
}

export class PARAM_API {
    API_POST_EDIT_ETABLISSEMENT: string;
    API_POST_EDIT_SERVICE_ETAB: string;
    API_GET_ARTICLES_ZONE_STOCK: string;
    API_POST_EDIT_ZONE_STOCK: string;
    API_POST_DELETE_ZONE_STOCK: string;
    API_POST_DELETE_SERVICE_ETAB: string;
    API_POST_DELETE_ETABLISSEMENT: string;
    API_POST_EDIT_TARIF_DENREE: string;
    API_POST_DELETE_TARIF_DENREE: string;
    API_POST_EDIT_COND_ART_UNI_POID: string;
    API_POST_DELETE_COND_ART_UNI_POID: string;
    API_GET_PARAM_HEADER_CMD: string;
    API_POST_EDIT_PARAM_HEADER: string;
    API_POST_EDIT_PARAM_ETAB: string;
    API_GET_PARAM_ETAB: string;
    API_GET_PARAM_ETAB_INFO_SUP: string;
    API_POST_DELETE_ADR_LIVRAISON: string;
    API_POST_SAVE_LOGO_ETAB: string;
    API_GET_PARAM_PREMAIL_CMD: string;
    API_POST_ATTACH_ART_ZONE_STOCK: string;
}

export class PARAM_APP_API {
    API_GET_ALL_USERS_ACTIF: string;
    API_GET_ALL_PROFIL_USER: string;
    API_POST_GENERATE_USER_ID: string;
    API_POST_SAVE_USER_INFO: string;
    API_POST_DELETE_USER: string;
    API_POST_INTERFACE_MENU_BY_PROFIL_APP: string;
    API_GET_INTERFACE_MENU_ACCES: string;
    API_POST_SAVE_PROFIL_APP: string;
    API_POST_DELETE_PROFIL_APP: string;
    API_POST_SAVE_ACCESS_USER_APP: string;
    API_POST_SAVE_MENU_APP: string;
}

export class USER_API {
    API_POST_PUBLIC_LOGIN_AUTH: string;
    API_POST_MEMBER_LOGOUT: string;
}

export class COMMON_API {
    API_GET_UNIT_POIDS: string;
    API_GET_GRAAL_CENTRE_PREPA: string;
    API_GET_ALL_STOCK: string;
    API_GET_ALL_ZONE_STOCK: string;
    API_GET_ALL_TVA: string;
    API_GET_ALL_TYPE_DENREE_INFO: string;
    API_GET_ALL_ETABLISSEMENTS: string;
    API_GET_ALL_SERVICE_ETAB: string;
    API_GET_DETAIL_SERVICE_INFO: string;
    API_GET_LIB_SERV_CONDITION: string;
    API_GET_ALL_REGIME: string;
    API_GET_ALL_GES_GAMME: string;
    API_GET_ALL_EGALIM: string;
    API_GET_DENREE_EGALIM: string;
    API_GET_DENREE_ALLERGENES: string;
    API_GET_ALL_ALLERGENES: string;
    API_GET_UNIT_CALCUL: string;
    API_GET_ALL_LETTERS_CMD: string;
    API_GET_CONTROLE_COHERENCE_SORTIE_ART: string;
    API_GET_ALL_NUTRI_GROUP: string;
    API_POST_RIGHTS: string;
    API_GET_ALL_ADR_LIVRAISON: string;
    API_POST_SEND_MAIL_COMMON: string;
    API_GET_RECIPE_SALAISON: string;
}

export class PURCHASE_API {
    API_GET_ALL_SUPPLIERS: string;
    API_GET_DETAIL_SUPPLIER: string;
    API_GET_ALL_ACTIF_SUPPLIERS: string;
    API_GET_FAM_DEN: string;
    API_GET_SFAM_DEN: string;
    API_GET_DENREE: string;
    API_GET_DETAIL_DENREE: string;
    API_GET_SEARCH_ARTICLE_DENREE: string;
    API_POST_SAVE_EDIT_DENREE: string;
    API_GET_CATALOG_FOURNISSEUR_PRIX: string;
    API_GET_ART_CATALOG_FOURNISSEUR: string;
    API_POST_SAVE_FAMILLE: string;
    API_POST_SAVE_SFAMILLE: string;
    API_POST_DELETE_DENREE_ARTICLE: string;
    API_GET_FAM_DETAIL: string;
    API_GET_CATALOG_FOUR_CMD: string;
    API_POST_SAVE_EDIT_SUPPLIER: string;
    API_POST_SAVE_LOGO_SUPPLIER: string;
    API_POST_DELETE_SUPPLIER: string;
    API_GET_SEARCH_SUPPLIER: string;
    API_GET_TARIF_Fournisseur: string;
    API_POST_SAVE_EDIT_ALL_TARIF_Fournisseur: string;
    API_GET_ALL_SUPPLIERS_WITH_PRICE: string;
    API_GET_ALIMENTS_BY_GROUP: string;
    API_GET_VAL_NUTRI_BY_ALIM: string;
    API_POST_CREATE_ALIMENT: string;
    API_POST_CREATE_GROUPE_ALIMENT: string;
    API_POST_DELETE_SOUS_FAM: string;
    API_GET_SEARCH_ARTICLE_WITH_FOU_PRICE: string;
    API_GET_LIST_DENREE_BY_FAM: string;
    API_POST_COPY_DENREE: string;
    API_POST_EXPORT_ALL_ART_EXCEL: string;
    API_POST_IMPORT_ALL_ART_EXCEL: string;
    API_POST_DELETE_FAM: string;
}

export class COMMANDES_API {
    API_GET_ALL_CMD_HEADER: string;
    API_GET_SEARCH_COMMANDE: string;
    API_GET_DETAIL_COMMANDE_IU_LETTER: string;
    API_POST_ENREGISTRER_COMMANDE: string;
    API_POST_GENERER_PDF_COMMANDE: string;
    API_POST_CMD_AFTER_EDIT: string;
    API_POST_FILTER_CMD: string;
    API_POST_FILTER_RECEP: string;
    API_POST_SEND_MAIL_CMD: string;
    API_POST_SEND_TEL_CMD: string;
    API_POST_CREATE_TRAME_CMD: string;
    API_GET_ALL_TRAME_CMD: string;
    API_GET_DETAIL_TRAME_CMD: string;
    API_POST_SAVE_EDIT_TRAME_CMD: string;
    API_POST_DELETE_TRAME_CMD: string;
    API_GET_LIST_CRITERE_FIQ: string;
    API_POST_SAVE_EDIT_RECEPTION: string;
    API_GET_ALL_ORIGIN_ART_RECEPTION: string;
    API_POST_SAVE_EDIT_AVOIR: string;
    API_GET_DETAIL_AVOIR: string;
    API_GET_LIST_AVOIRS: string;
    API_POST_LIST_AVOIRS_AFTER_EDIT: string;
    API_POST_SAVE_EDIT_DEM_AVOIR: string;
    API_POST_LIST_DEM_AVOIRS_AFTER_EDIT: string;
    API_GET_LIST_DEM_AVOIRS: string;
    API_GET_DETAIL_DEM_AVOIR: string;
    API_POST_SOLDER_COMMANDE_RECEPTION_CALCUL: string;
    API_POST_CREATE_DEM_AVOIR_FROM_CMD: string;
    API_POST_CREATE_AVOIR_FROM_CMD: string;
    API_GET_SEARCH_AVOIR_BY_NOBON: string;
    API_GET_SEARCH_DEM_AVOIR_BY_NOBON: string;
    API_POST_FILTER_AVOIR: string;
    API_POST_FILTER_DEM_AVOIR: string;
    API_POST_DELETE_CMD: string;
    API_POST_GENERER_PDF_DEMAVOIR: string;
    API_POST_GENERER_PDF_AVOIR: string;
    API_POST_APURER_CMD: string;
    API_POST_ANNULER_RECEPTION: string;
    API_POST_MODIF_RECEPTION: string;
    API_GET_DETAIL_CMD_BY_RECEPTION: string;

}

export class STOCK_API {
    API_GET_ALL_STOCK_SORTIE: string;
    API_GET_CLIENT_OUTPUT_ART: string;
    API_POST_SAVE_OUTPUT_ART: string;
    API_POST_GENERER_PDF_BS: string;
    API_GET_SEARCH_ARTICLE_STOCK: string;
    API_GET_ART_STOCK_BY_ZONE_STOCK: string;
    API_POST_SAVE_INVENTORY_ARTICLE: string;
    API_GET_ETAT_STOCK_ARTICLE: string;
    API_GET_MVT_STOCK_ARTICLE: string;
    API_GET_LIST_SORTIE_HEADER: string;
    API_GET_LIST_SORTIE_ART_DETAIL: string;
    API_POST_FILTER_ART_HEADER: string;
    API_POST_EXPORT_EXCEL_INVENTAIRE_TEMPLATE: string;
    API_GET_INVENTORY_HISTORY: string;
    API_GET_INVENTORY_HISTORY_DETAIL: string;
    API_POST_GENERER_PDF_INVENTORY_DETAIL: string;
    API_POST_GENERER_PDF_RECEPTION: string;
    API_POST_IMPORT_INVENTORY_EXCEL: string;
    API_POST_SAVE_IMPORT_INVENTORY_EXCEL: string;
    API_GET_LIST_SORTIE_ART_DETAIL_EDIT: string;
    API_POST_SAVE_OUTPUT_ART_EDIT: string;
    API_POST_EXPORT_EXCEL_ETAT_STOCK: string;
    API_POST_EXPORT_PDF_OUTPUT_HEADER_ART: string;
    API_POST_EXPORT_PDF_ETAT_STOCK_ART: string;
    API_POST_EXPORT_PDF_MVT_ART: string;
    API_GET_LIST_RECEPTION: string;
    API_POST_FILTER_LIST_RECEPTION: string;
    API_GET_SEARCH_RECEPTION_BL_FAC: string;
}

export class MERCURIALES_API {
    API_POST_EXPORT_STD_MERCURIALE: string;
    API_POST_IMPORT_STD_MERCURIALE: string;
    API_POST_SCA_CHARGER_MERCURIALE: string;
    API_POST_AGA_CHARGER_MERCURIALE: string;
    API_POST_SCA_SAVE_MERCURIALE: string;
    API_POST_AGA_SAVE_MERCURIALE: string;
    API_POST_SAVE_FOU_INACTIF: string;
    API_GET_NEW_Fournisseur_MERCURIALE: string;
    API_GET_NOTIFY_MERCURIALE: string;
    API_POST_CLOSE_NOTIFY_MERCURIALE: string;
}

export class DASHBOARD_API {
    API_GET_INFO_BASIC: string;
    API_GET_STAT_EGALIM_CURRENT_MONTH: string;
    API_POST_SAVE_NOTE: string;
}

export class RECETTE_API {
    API_GET_FAM_RECETTE: string;
    API_GET_SFAM_RECETTE: string;
    API_GET_CATEG_RECETTE: string;
    API_GET_PLAT_RECETTE: string;
    API_GET_FICH_TECH_RECETTE: string;
    API_POST_EDIT_FAM_RECETTE: string;
    API_POST_EDIT_SFAM_RECETTE: string;
    API_POST_EDIT_CAT_RECETTE: string;
    API_GET_ALL_PARAM_PROD_RECIPE: string;
    API_GET_FITECH_BY_PLAT_GAMME: string;
}


