<div class="col-xl-12">
  <div class="d-flex flex-direction-edit-line line-border-bottom">
    <div class="container-fluid p-0 pt-2" [ngClass]="IndexLine % 2 === 0 ? 'even' : ''">
      <div class="row overflow-hidden">
        <div class="width-5-percent d-flex justify-content-end">
          <div style="width: 5px" class="d-flex justify-content-center">
            <p class="index-line mt-4">{{ IndexLine }}</p>
          </div>
          <!-- --------------------------begin removed part1(Vanessa 22/09/22)------------------------- -->

          <!-- <div style="width: 5px" class="d-flex justify-content-center">
            <div>
              <button
                (click)="div1Toggle()"
                class="chevron-button mt-2"
                mat-mini-fab
                color="lightgrey"
                type="button"
              >
                <i
                  *ngIf="!show"
                  class="fas fa-chevron-down fa-lg margin-top-icon-global-command"
                  type="button"
                ></i>
                <div *ngIf="show">
                  <i class="fas fa-chevron-up fa-lg"></i>
                </div>
              </button>
            </div>
          </div> -->
          <!-- --------------------------end removed part1--------------------------------- -->
        </div>
        <!--ARTICLE-->
        <div class="width-65-percent d-flex justify-content-between">
          <div class="width-50-percent d-flex justify-content-start ps-3 px-2">
            <!--- saisie manuelle -->
            <div style="width: 90%" *ngIf="!LineCmdInfo.preco_denree_lib" class="search-art-line-cmd">
              <form class="search-form-article" [formGroup]="ArticleSearchForm"
                (ngSubmit)="onSubmitRechercherArticle()">
                <label class="font-weight-700">{{
                  "Article" | translate
                  }}</label>
                <mat-form-field class="input-size-height" appearance="fill">
                  <input id="search-article-cmd-{{ IdxLineCmd }}" matInput
                    placeholder="{{ 'SearchAnArticle' | translate }}" value="" class="search-article-cmd"
                    formControlName="ArticleSearchInput" [matAutocomplete]="auto"
                    matTooltip="{{SelectedArtSearch &&  SelectedArtSearch.NOM_DENREE  }}" />
                  <mat-icon matPrefix>
                    <img class="mb-2" style="width: 20px" src="../../../../../assets/images/zoom.svg" alt="icon" />
                  </mat-icon>
                  <!-- <mat-error
                    *ngIf="
                      ArticleSearchForm.controls.ArticleSearchInput.errors
                        ?.matchRequired
                    "
                  >
                    <span class="error-input"
                      >Sélectionnez un article dans la liste.</span
                    >
                    <br />
                  </mat-error> -->
                </mat-form-field>
                <div>
                  <mat-error class="error-input" *ngIf="
                      ArticleSearchForm.controls.ArticleSearchInput.errors
                        ?.matchRequired
                    ">
                    <span>{{ "SelectAnArticle" | translate }}.</span>
                    <br />
                  </mat-error>
                </div>
                <div [ngClass]="
                    !LineCmdInfo.PRECO_DENREE
                      ? 'justify-content-end'
                      : 'justify-content-between'
                  " class="d-flex">
                  <div class="d-flex art-cmd-eye" *ngIf="LineCmdInfo.PRECO_DENREE" (click)="
                      OpenDetailArticle(
                        LineCmdInfo.PRECO_DENREE,
                        LineCmdInfo.Iu_Denree
                      )
                    ">
                    <i class="far fa-eye mt-1"></i>
                    <p class="px-2">
                      {{ LineCmdInfo.PRECO_DENREE }}
                    </p>
                  </div>
                  <div class="d-flex margin-right-60">
                    <label class="font-weight-700">Réf : </label>
                    <p *ngIf="
                        LineCmdInfo && LineCmdInfo.PRECO_REF;
                        else notprecoref
                      ">
                      &nbsp;{{ LineCmdInfo.PRECO_REF }}
                    </p>
                    <ng-template #notprecoref>
                      <p>&nbsp;</p>
                    </ng-template>
                  </div>
                </div>

                <!--   [panelWidth]="420" seen with Vanessa for Reception also 09/11/22-->
                <mat-autocomplete panelWidth="auto" #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="
                    ClickOptionSearchArticle($event, $event.option.value)
                  ">
                  <mat-option *ngIf="isLoadingSearchArt" class="is-loading">
                    <mat-spinner class="mat-loader" color="warn" diameter="20" mode="indeterminate"
                      style="margin: auto">
                    </mat-spinner>
                  </mat-option>
                  <ng-container *ngIf="!isLoadingSearchArt">
                    <mat-option *ngFor="let art of ListResultSearchArticle" [value]="art">
                      <span class="pe-3">
                        ({{ art.CODE_DENREE }}) {{ art.NOM_DENREE }}
                      </span>

                      <span style="float: right">
                        {{
                        art.CATALOG_PRIX_EUROS
                        | currency: "EUR":"symbol":"1.3-3"
                        }}
                      </span>
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </form>
            </div>
            <!--- import catalog fournisseur -->
            <div *ngIf="LineCmdInfo.preco_denree_lib">
              <br />
              <div>
                {{ LineCmdInfo.preco_denree_lib }}
              </div>
              <div class="d-flex art-cmd-eye" *ngIf="LineCmdInfo.PRECO_DENREE" (click)="
                  OpenDetailArticle(
                    LineCmdInfo.PRECO_DENREE,
                    LineCmdInfo.Iu_Denree
                  )
                ">
                <i class="far fa-eye mt-1"></i>
                <p class="px-2">
                  {{ LineCmdInfo.PRECO_DENREE }}
                </p>
              </div>
            </div>
          </div>
          <!--SUPPLIER-->
          <div class="width-50-percent d-flex" [ngClass]="
              ModeSaisieChange != '0'
                ? 'justify-content-end'
                : 'justify-content-start'
            ">
            <div *ngIf="ModeSaisieChange != '0'">
              <label class="font-weight-700">{{
                "Supplier" | translate
                }}</label>
              &nbsp;
              <span *ngIf="IsDisplayEgalim">
                <i class="fas fa-balance-scale"></i>
              </span>
              <mat-form-field class="input-size-select" appearance="fill">
                <mat-select [formControl]="SelectFormFourControl" required
                  (selectionChange)="onFournisseurChange($event)">
                  <mat-option *ngFor="let four of ListSupplierCatalog" [value]="four.IU_FOURNISSEUR"
                    class="font-size-select">
                    <span>{{ four.NOM_FOURNISSEUR }} </span>&nbsp;&nbsp;
                    <span style="float: right">
                      <span *ngIf="four.FOU_EGALIM == '1'">
                        <i class="fas fa-balance-scale"></i>
                      </span>
                      {{ four.Prix_Denree | currency: "EUR":"symbol":"1.3-3" }}
                    </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div>
                <mat-error *ngIf="SelectFormFourControl.hasError('required')"
                  class="error-input">{{ "SelectASupplier" | translate }}</mat-error>
                <mat-hint>{{
                  SelectFormFourControl.value?.IU_FOURNISSEUR
                  }}</mat-hint>
              </div>
            </div>
            <!--QUANTITY-->
            <div style="width: 80px" class="px-2">
              <label class="font-weight-700">{{
                "Quantity" | translate
                }}</label>
              <mat-form-field class="input-size-height" appearance="fill">
                <input matInput placeholder="" [formControl]="FormQteArtControl"
                  oninput="this.value= this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                <!-- <mat-error
              *ngIf="FormQteArtControl.hasError('required')"
              class="error-input"
              >Quantité vide</mat-error
            > -->
              </mat-form-field>
              <div>
                <mat-error class="error-input" *ngIf="FormQteArtControl.hasError('required')">
                  <span>{{ "Quantity" | translate }}
                    {{ "Required" | translate }}</span>
                  <br />
                </mat-error>
              </div>
            </div>

            <!--UF-->
            <div style="width: 80px" class="px-2">
              <label class="font-weight-700">UF</label>
              <p *ngIf="LineCmdInfo && LineCmdInfo.PRECO_UF; else notprecouf">
                {{ LineCmdInfo.PRECO_UF }}
              </p>
              <ng-template #notprecouf>
                <p>&nbsp;</p>
              </ng-template>
            </div>

            <!--CDT-->
            <div style="width: 80px" class="px-2">
              <label class="font-weight-700">Cdt</label>
              <p [ngClass]="
                  !LineCmdInfo.preco_iserror_contitio
                    ? 'p-background-color-content-global-command'
                    : 'error-cdt'
                " *ngIf="
                  LineCmdInfo && LineCmdInfo.PRECO_CONDITIO;
                  else notprecocdt
                ">
                {{ LineCmdInfo.PRECO_CONDITIO }}
              </p>
              <ng-template #notprecocdt>
                <p>&nbsp;</p>
              </ng-template>
            </div>
            <!--TODO envoi de données OK(service & response), mais pas prise en compte côté API!
              ------------DATES CONSO-->
            <div style="width: 100px" class="px-2 d-none">
              <label class="font-weight-700 white-space-nowrap">{{
              "DateOfUse" | translate
              }}</label>
              <mat-form-field class="input-size-height" appearance="fill">
                <input type="text" matInput placeholder="" [formControl]="FormDateConsoControl" />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="width-25-percent d-flex" [ngClass]="
            ModeSaisieChange != '0'
              ? 'justify-content-center'
              : 'justify-content-start'
          ">
          <!--PRIX UNIT-->
          <div style="width: 80px" class="px-2">
            <label class="font-weight-700 white-space-nowrap">{{
              "UnitPrice" | translate
              }}</label>
            <mat-form-field class="input-size-height" appearance="fill">
              <input type="text" matInput placeholder="" [formControl]="FormPrixArtControl" class="input-price"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
              <!-- <mat-error
                *ngIf="FormPrixArtControl.hasError('required')"
                class="error-input"
                >Prix vide
              </mat-error> -->
              <!-- <mat-error
                *ngIf="FormPrixArtControl.hasError('pattern')"
                class="error-input"
                >Prix incorrect
              </mat-error> -->
            </mat-form-field>
            <div>
              <mat-error *ngIf="
                  FormPrixArtControl.hasError('required') ||
                  FormPrixArtControl.hasError('pattern')
                " class="error-input">{{ "Price" | translate }} {{ "Required" | translate }}
              </mat-error>
            </div>
          </div>
          <!--MONTANT-->
          <div style="width: 100px" class="px-4">
            <label class="font-weight-700 white-space-nowrap">{{ "Amount" | translate }}
              {{ "ExclTax" | translate }}</label>
            <p class="p-background-color-content-global-command text-align-width-background-global-command"
              style="display: inline-block">
              {{ MontantTotalLine | currency: "EUR":"symbol":"1.2-2" }}
            </p>
          </div>
        </div>
        <div class="width-5-percent d-flex justify-content-center">
          <div>
            <button *ngIf="
                LineCmdInfo.PRECO_VALIDE_COM != '4' &&
                EtatCmd != '3' &&
                EtatCmd != '9'
              " matTooltip="Supprimer" mat-stroked-button
              class="button-icon2-generic-delete button-edit-line margin-left-right-0 mt-3 mx-5" type="button"
              (click)="DeleteLineCmd()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- --------------------------begin removed part2 (Vanessa 22/09)--------------------------------- -->

      <!-- <div *ngIf="show" class="row d-flex">
        <div class="d-flex justify-content-end width-5-percent"></div>
        <div class="d-flex justify-content-end width-65-percent">
          <div class="width-50-percent d-flex"></div>
          <div class="width-50-percent d-flex justify-content-start">
            <div style="width: 80px">
              <label class="font-weight-700 white-space-nowrap">{{
                "MinStock" | translate
              }}</label>
              <p>xxxx</p>
            </div>
            <div style="width: 80px">
              <label class="font-weight-700">{{
                "StockQuantity" | translate
              }}</label>
              <p>xxxx</p>
            </div>
            <div style="width: 80px">
              <label class="font-weight-700">{{ "Outings" | translate }}</label>
              <p>xxxx</p>
            </div>
            <div style="width: 80px">
              <label class="font-weight-700">{{ "Entries" | translate }}</label>
              <p>xxxx</p>
            </div>
            <div style="width: 80px">
              <label class="font-weight-700">{{
                "OpeningStock" | translate
              }}</label>
              <p>xxxx</p>
            </div>
          </div>
        </div>
        <div class="width-25-percent"></div>
        <div class="width-5-percent"></div>
      </div> -->
      <!-- --------------------------end removed part2------------------------------------------- -->

      <!-- <div class="row column-gap-global-command d-flex justify-content-between"> -->
      <!-- <div class="div-index-arrow-global-command">
          <div class="d-flex justify-content-between">
            <p class="index-line">{{ IndexLine }}</p>
            <button
              (click)="div1Toggle()"
              class="chevron-button mt-2"
              mat-mini-fab
              color="lightgrey"
              type="button"
            >
              <i
                *ngIf="!show"
                class="fas fa-chevron-down fa-lg margin-top-icon-global-command"
                type="button"
              ></i>
              <div *ngIf="show">
                <i class="fas fa-chevron-up fa-lg"></i>
              </div>
            </button>
          </div>
        </div> -->
      <!--- saisie manuelle -->
      <!-- <div class="col-xl-3"> -->
      <!-- <div
            *ngIf="!LineCmdInfo.preco_denree_lib"
            class="search-art-line-cmd"
          >
            <form
              class="search-form-article"
              [formGroup]="ArticleSearchForm"
              (ngSubmit)="onSubmitRechercherArticle()"
            > -->
      <!-- <label class="font-weight-700">{{ "Article" | translate }}</label>
              <mat-form-field class="input-size-height" appearance="fill">
                <input
                  id="search-article-cmd-{{ IdxLineCmd }}"
                  matInput
                  placeholder="{{ 'SearchAnArticle' | translate }}"
                  value=""
                  class="search-article-cmd"
                  formControlName="ArticleSearchInput"
                  [matAutocomplete]="auto"
                />
                <mat-icon matPrefix>
                  <img
                    class="mb-2"
                    style="width: 20px"
                    src="../../../../../assets/images/zoom.svg"
                    alt="icon"
                  />
                </mat-icon> -->
      <!-- <mat-error
                  *ngIf="
                    ArticleSearchForm.controls.ArticleSearchInput.errors
                      ?.matchRequired
                  "
                >
                  <span class="error-input"
                    >Sélectionnez un article dans la liste.</span
                  >
                  <br />
                </mat-error> -->
      <!-- </mat-form-field>
              <div>
                <mat-error
                  class="error-input"
                  *ngIf="
                    ArticleSearchForm.controls.ArticleSearchInput.errors
                      ?.matchRequired
                  "
                >
                  <span>{{ "SelectAnArticle" | translate }}.</span>
                  <br />
                </mat-error>
              </div> -->

      <!-- <div
            class="d-flex art-cmd-eye"
            *ngIf="SelectedArtSearch && SelectedArtSearch.CODE_DENREE"
            (click)="OpenDetailArticle(SelectedArtSearch.CODE_DENREE)"
          >
            <i class="far fa-eye mt-1"></i>
            <p class="px-2">
              {{ SelectedArtSearch && SelectedArtSearch.CODE_DENREE }}
            </p>
          </div> -->

      <!-- <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="
              ClickOptionSearchArticle($event, $event.option.value)
            "
          >
            <mat-option *ngIf="isLoadingSearchArt" class="is-loading">
              <mat-spinner
                class="mat-loader"
                color="warn"
                diameter="20"
                mode="indeterminate"
                style="margin: auto"
              >
              </mat-spinner>
            </mat-option>
            <ng-container *ngIf="!isLoadingSearchArt">
              <mat-option
                *ngFor="let art of ListResultSearchArticle"
                [value]="art"
              >
                <span>{{ art.NOM_DENREE }} </span>
                <div style="float: right; width: 50%">
                  <span style="float: right"> -->
      <!-- <small> | ID: </small> -->
      <!-- </span>
                </div>
              </mat-option>
            </ng-container>
          </mat-autocomplete> -->
      <!-- </form>
          </div> -->
      <!--- import catalog fournisseur -->
      <!-- <div *ngIf="LineCmdInfo.preco_denree_lib">
            <br />
            <p >
              {{ LineCmdInfo.preco_denree_lib }}
            </p>
          </div>
        </div> -->
      <!-- <div class="col-xl-3">
          <label class="font-weight-700">{{ "Supplier" | translate }}</label>
          &nbsp;
          <span *ngIf="IsDisplayEgalim">
            <i class="fas fa-balance-scale"></i>
          </span>
          <mat-form-field class="input-size-select" appearance="fill">
            <mat-select
              [formControl]="SelectFormFourControl"
              required
              (selectionChange)="onFournisseurChange($event)"
            >
              <mat-option>None</mat-option>
              <mat-option
                *ngFor="let four of ListSupplierCatalog"
                [value]="four.IU_FOURNISSEUR"
                class="font-size-select"
              >
                <span>{{ four.NOM_FOURNISSEUR }} </span>&nbsp;&nbsp;
                <span style="float: right">
                  <span *ngIf="four.FOU_EGALIM == '1'">
                    <i class="fas fa-balance-scale"></i>
                  </span>
                  {{ four.Prix_Denree | currency: "EUR":"symbol":"1.3-3" }}
                </span>
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="SelectFormFourControl.hasError('required')"
              class="error-input"
              >Sélectionnez un fournisseur</mat-error
            >
            <mat-hint>{{
              SelectFormFourControl.value?.IU_FOURNISSEUR
            }}</mat-hint>
          </mat-form-field>

          <div>
            <mat-error
              class="error-input"
              *ngIf="SelectFormFourControl.hasError('required')"
            >
              <span>{{ "SelectASupplier" | translate }}</span>
              <br />
            </mat-error>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label class="font-weight-700">Réf</label>
              <p
                
                *ngIf="LineCmdInfo && LineCmdInfo.PRECO_REF; else notprecoref"
              >
                {{ LineCmdInfo.PRECO_REF }}
              </p>
              <ng-template #notprecoref>
                <p >&nbsp;</p>
              </ng-template>
            </div>
            <div class="col-md-4">
              <label class="font-weight-700">Cdt</label>
              <p
                [ngClass]="
                  !LineCmdInfo.preco_iserror_contitio
                    ? 'p-background-color-content-global-command'
                    : 'error-cdt'
                "
                *ngIf="
                  LineCmdInfo && LineCmdInfo.PRECO_CONDITIO;
                  else notprecocdt
                "
              >
                {{ LineCmdInfo.PRECO_CONDITIO }}
              </p>
              <ng-template #notprecocdt>
                <p >&nbsp;</p>
              </ng-template>
            </div>
            <div class="col-md-4">
              <label class="font-weight-700">UF</label>
              <p
                
                *ngIf="LineCmdInfo && LineCmdInfo.PRECO_UF; else notprecouf"
              >
                {{ LineCmdInfo.PRECO_UF }}
              </p>
              <ng-template #notprecouf>
                <p >&nbsp;</p>
              </ng-template>
            </div>
          </div>
        </div> -->
      <!-- <div class="col-xl-1"> -->
      <!-- <label class="font-weight-700">{{ "Quantity" | translate }}</label>
            <mat-form-field class="input-size-height" appearance="fill">
              <input
                matInput
                placeholder=""
                [formControl]="FormQteArtControl"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              /> -->
      <!-- <mat-error
              *ngIf="FormQteArtControl.hasError('required')"
              class="error-input"
              >Quantité vide</mat-error
            > -->
      <!-- </mat-form-field>
            <div>
              <mat-error
                class="error-input"
                *ngIf="FormQteArtControl.hasError('required')"
              >
                <span
                  >{{ "Quantity" | translate }}
                  {{ "Required" | translate }}</span
                >
                <br />
              </mat-error>
            </div> -->

      <!-- <div class="row">
            <div class="col-md-6">
              <label class="font-weight-700">Préco</label>
              <p >&nbsp;</p>
            </div>
            <div class="col-md-6">
              <label class="font-weight-700">{{ "Needs" | translate }}</label>
              <p >&nbsp;</p>
            </div>
          </div> -->
      <!-- </div> -->
      <!-- <div class="col-xl-1">
            <label class="font-weight-700">{{ "UnitPrice" | translate }}</label>
            <mat-form-field class="input-size-height" appearance="fill">
              <input
                type="text"
                matInput
                placeholder=""
                [formControl]="FormPrixArtControl"
                class="input-price"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              /> -->
      <!-- <mat-error
              *ngIf="FormPrixArtControl.hasError('required')"
              class="error-input"
              >Prix vide
            </mat-error> -->
      <!-- <mat-error
              *ngIf="FormPrixArtControl.hasError('pattern')"
              class="error-input"
              >Prix incorrect
            </mat-error> -->
      <!-- </mat-form-field> -->
      <!-- <div>
              <mat-error
                *ngIf="
                  FormPrixArtControl.hasError('required') ||
                  FormPrixArtControl.hasError('pattern')
                "
                class="error-input"
                >{{ "Price" | translate }} {{ "Required" | translate }}
              </mat-error>
            </div>
          </div> -->

      <!-- <div class="col-xl-1">
            <label class="font-weight-700"
              >{{ "Amount" | translate }} {{ "ExclTax" | translate }}</label
            >
            <p
              class="p-background-color-content-global-command text-align-width-background-global-command"
            >
              {{
                CalculMontantLigne(LineCmdInfo.PRECO_PRIX)
                  | currency:"EUR":"symbol":"1.2-2"
              }}
            </p>
          </div> -->
      <!-- </div> -->
      <!-- <div class="row">
          <div class="col-md-12">
            <div class="container-fluid p-0">
              <div class="row justify-content-between">
                <div class="col-md-1 div-width-second-row-global-command"></div>
                <div class="col-md-3"></div>
                <div class="col-xl-3 col-md-12">
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="font-weight-700">Réf</label>
                        <p
                          
                          *ngIf="
                            LineCmdInfo && LineCmdInfo.PRECO_REF;
                            else notprecoref
                          "
                        >
                          {{ LineCmdInfo.PRECO_REF }}
                        </p>
                        <ng-template #notprecoref>
                          <p >
                            &nbsp;
                          </p>
                        </ng-template>
                      </div>
                      <div class="col-md-4">
                        <label class="font-weight-700">Cdt</label>
                        <p
                          [ngClass]="
                            !LineCmdInfo.preco_iserror_contitio
                              ? 'p-background-color-content-global-command'
                              : 'error-cdt'
                          "
                          *ngIf="
                            LineCmdInfo && LineCmdInfo.PRECO_CONDITIO;
                            else notprecocdt
                          "
                        >
                          {{ LineCmdInfo.PRECO_CONDITIO }}
                        </p>
                        <ng-template #notprecocdt>
                          <p >
                            &nbsp;
                          </p>
                        </ng-template>
                      </div>
                      <div class="col-md-4">
                        <label class="font-weight-700">UF</label>
                        <p
                          
                          *ngIf="
                            LineCmdInfo && LineCmdInfo.PRECO_UF;
                            else notprecouf
                          "
                        >
                          {{ LineCmdInfo.PRECO_UF }}
                        </p>
                        <ng-template #notprecouf>
                          <p >
                            &nbsp;
                          </p>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div> -->
      <!-- <div class="col-xl-1 col-md-12">
                <div class="container-fluid p-0">
                  <div class="row">
                    <div class="col-md-6">
                      <label class="font-weight-700">Préco</label>
                      <p >
                        &nbsp;
                      </p>
                    </div>
                    <div class="col-md-6">
                      <label class="font-weight-700">{{
                        "Needs" | translate
                      }}</label>
                      <p >
                        &nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </div> -->
      <!-- <div class="col-md-1"></div>
                <div class="col-md-1"></div>
              </div>
            </div>
          </div>
        </div> -->
      <!-- <div class="row">
          <div class="col-md-12">
            <div class="container-fluid p-0">
              <div
                class="row column-gap-global-command d-flex justify-content-between"
                *ngIf="show"
              >
                <div class="col-md-1 div-width-second-row-global-command"></div>
                <div class="col-md-3"></div>
                <div class="col-xl-3">
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-xl-4" style="flex-wrap: wrap">
                        <label class="font-weight-700">{{
                          "MinimumStock" | translate
                        }}</label>
                        <p >
                          xxxx
                        </p>
                      </div>
                      <div class="col-xl-4">
                        <label class="font-weight-700">{{
                          "StockQuantity" | translate
                        }}</label>
                        <p >
                          xxxx
                        </p>
                      </div>
                      <div class="col-xl-4">
                        <label class="font-weight-700">{{
                          "Outings" | translate
                        }}</label>
                        <p >
                          xxxx
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-1">
                  <label class="font-weight-700">{{
                    "Entries" | translate
                  }}</label>
                  <p >xxxx</p>
                </div>
                <div class="col-xl-1">
                  <label class="font-weight-700">{{
                    "OpeningStock" | translate
                  }}</label>
                  <p >xxxx</p>
                </div>
                <div class="col-xl-1">
                  <label class="font-weight-700">{{
                    "ConsumptionDate" | translate
                  }}</label>
                  <p >xxxx</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      <!-- </div> -->
      <!-- <div [ngClass]="IndexLine % 2 === 0 ? 'even' : ''">
        <div class="d-flex justify-content-end button-footer-form p-0"> -->
      <!-- not used clone line-->
      <!-- <button mat-stroked-button class="button-icon2-generic-duplicate" type="button"
                    (click)="AddLineFourCmd()">
                    <i class="far fa-clone"></i>
                </button>
                <span class="btn-space"></span> -->

      <!-- <button
            *ngIf="LineCmdInfo.PRECO_VALIDE_COM != '4'"
            matTooltip="Supprimer"
            mat-stroked-button
            class="button-icon2-generic-delete button-edit-line margin-left-right-0 mt-3 mx-5"
            type="button"
            (click)="DeleteLineCmd()"
          >
            <i class="fas fa-times"></i>
          </button> -->
      <!-- <p
        *ngIf="LineCmdInfo.PRECO_VALIDE_COM == '4'"
        mat-stroked-button
        class="button-edit-line margin-left-right-0 mt-3 mx-5"
      > -->
      <!-- <i class="fas fa-check"></i> -->
      <!-- </p> -->
      <!-- TODO il faudrai faire dispartaire ce bouton vert -->
      <!-- <p *ngIf="LineCmdInfo.PRECO_VALIDE_COM == '4'" mat-stroked-button
        class="button-icon2-generic-success button-edit-line margin-left-right-0 mt-3 mx-5">
        <i class="fas fa-check"></i>
      </p> -->

      <!-- <div
            class="ms-2 mt-2"
            style="
              position: relative;
              right: 0;
              display: flex !important;
              vertical-align: middle;
              float: right;
            "
          >
            <p *ngIf="LineCmdInfo.PRECO_VALIDE_COM == '4'">
              <img
                matTooltip="Réceptionné"
                style="width: 30px"
                class="mt-2"
                src="../../../../../assets/images/icon-state-reception/soldee.svg"
              />
            </p> -->
      <!-- <p *ngIf="LineCmdInfo.PRECO_VALIDE_COM == '1'">
            <img matTooltip="À Traiter" style="width: 30px" class="mt-2"
              src="../../../../../assets/images/icon-state-reception/non-livree.svg" />
          </p>
          <p *ngIf="LineCmdInfo.PRECO_VALIDE_COM == '3'">
            <img matTooltip="Envoyé" style="width: 30px" class="mt-2"
              src="../../../../../assets/images/icon-state-reception/partielle.svg" />
          </p> -->
    </div>
  </div>
</div>
<!-- </div>
  </div>
</div> -->