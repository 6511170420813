import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { CommandesService } from '../../../core/services/member/commandes/commandes.service';
import { CmdSendTelModel } from '../../../core/model/member/commandes/cmd-send-tel-model';
import { CMD_GES_PRECO_HEADER } from '../../../core/model/member/commandes/cmd-ges-preco-header.model';

export class SendMailCmdData {

  constructor(public Msg: string, public Cmd: CMD_GES_PRECO_HEADER, public Email_Fou: string) {
  }
}

@Component({
  selector: 'SendTelCommandesComponent',
  templateUrl: './send-tel-commandes.component.html',
  styleUrls: ['./send-tel-commandes.component.css']
})
export class SendTelCommandesComponent implements OnInit {


  private _subscriptions: Subscription[] = [];

  private durationNotification: number = 5000;
  private _horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private _verticalPosition: MatSnackBarVerticalPosition = 'top';

  /** loader bloc pdf */

  public colorLoader: string = 'warn';
  public modeLoader: string = 'indeterminate';
  public valueLoader: string = '50';
  public displayProgressSpinner: boolean = false;

  public modelTextContent = {
    editorData: 'Bonjour'
  };

  public editor = ClassicEditor;

  constructor(public dialogRef: MatDialogRef<SendTelCommandesComponent>,
    @Inject(MAT_DIALOG_DATA) public dataSendMailCmd: SendMailCmdData,
    private _commandesService: CommandesService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {

  }

  /**
 * get form send mail
 */
  public onConfirmEnvoyer() {
    let isSendOk: boolean = false;

    this.displayProgressSpinner = true;


    let CmdContentTel: CmdSendTelModel = {
      Preco_entete: this.dataSendMailCmd.Cmd,
      Id: 0,
      Message: 'OK'
    };

    this._commandesService.GenererPDFSendTelCommande(CmdContentTel).subscribe(
      (responseSendTel: any) => {
        if (responseSendTel == "200") {

          this.NotificationMsg("La commande a été envoyée avec succès !", true);
          isSendOk = true;
        }
        else {
          this.NotificationMsg("Erreur de génération du PDF !", false);
          isSendOk = false;
        }

        if (isSendOk) {
          this.dialogRef.close(isSendOk);
        }

        this.displayProgressSpinner = false;
      }
    );


  }

  public NotificationMsg(msg: string, isOk: boolean) {
    if (isOk) {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-success']
      });
    }
    else {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-error']
      });
    }

  }

  public onAnnulerEnvoyer() {
    console.log('annuler');
    this.dialogRef.close(false);
  }

  public ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this._subscriptions.forEach((s) => s.unsubscribe());
    this.displayProgressSpinner = false;
  }

}
