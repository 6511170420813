import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Subscription } from 'rxjs';
import { VUE_STOCK_MOUVEMENT_ART } from '../../../core/model/member/stock/VUE_STOCK_MOUVEMENT_ART.model';
import { StockService } from '../../../core/services/member/stock/stock.service';
import { PdfData, PdfPreviewDialogComponent } from '../../../shared/dialog/pdf-preview-dialog/pdf-preview-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ArticleMouvementComponent',
  templateUrl: './article-mouvement.component.html',
  styleUrls: ['./article-mouvement.component.css'],
})
export class ArticleMouvementComponent implements OnInit {

  public colorLoader: string = 'warn';
  public modeLoader: string = 'indeterminate';
  public valueLoader: string = '50';
  public displayProgressSpinner: boolean = false;

  displayedColumns: string[] = [
    'DATE_MVT',
    'HEURE_MVT',
    'TYPE_MVT',
    'QUANTITE',
    'US',
    'DATE_LIMITE',
    'NUM_LOT',
    'TEMPERATURE',
    'PRIX_UNITAIRE',
    'PMP_TIME',
    'QTE_TIME',
    'NOM_FOURNISSEUR',
    'CreatedDate'
  ];

  public IsLoaded: boolean = false;

  public pdfSrc: any;

  private _subscriptions: Subscription[] = [];

  public ListMvtArt: VUE_STOCK_MOUVEMENT_ART[] = [];

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSourceListStockArtMvt: any = new TableVirtualScrollDataSource([]);

  @Input() CodePr: string;
  @Input() NumLot: string;
  @Input() DateDlc: string;
  @Input() Origine: string;

  constructor(private _stockService: StockService, private _dialog: MatDialog,) {
    this._subscriptions.push(
      this._stockService.ListStockArtMvtSubject.subscribe(
        (ListMvt: VUE_STOCK_MOUVEMENT_ART[]) => {
          if (ListMvt) {
            this.dataSourceListStockArtMvt.data = ListMvt;
            this.ListMvtArt = ListMvt;
            this.dataSourceListStockArtMvt.sort = this.sort;
          }

          this.IsLoaded = true;

        }
      )
    );

  }

  ngOnInit(): void {

    window.scrollTo(0, 0);

    this._stockService.GetMouvementStockArticles(this.CodePr, this.NumLot, this.DateDlc, this.Origine);

  }
  ////Seen with Vanessa 17/10/22 no need to display the total
  // public MTHT: string;

  // public CalculTotalMontalHT() {
  //   let total: number = 0;
  //   total = this.dataSourceListStockArtMvt.data
  //     .reduce((sum, y) => Number(sum) + (Number(y.PRIX_UNITAIRE) * Number(y.QUANTITE)), total);
  //   this.MTHT = total.toFixed(2);

  //   return this.MTHT;
  // }

  public GenererPdfMvtArt() {
    if (this.ListMvtArt) {
      this.displayProgressSpinner = true;


      this._subscriptions.push(
        this._stockService.ExportPdfMouvementStockArt(this.ListMvtArt).subscribe((response: any) => {
          if (response) {
            this.pdfSrc = response;
            const IsPdfdialog = this.DialogPdfViewMessage('Génrération PDF', response);
            IsPdfdialog.afterClosed().subscribe((dialogResult) => {
              if (dialogResult) {
              }
            });
          }
          this.displayProgressSpinner = false;
        })
      );
    }
  }

  public DialogPdfViewMessage(NumCmd, pdfSrcByte: any) {

    const PdfDialogData = new PdfData(pdfSrcByte);

    const dialogRef = this._dialog.open(PdfPreviewDialogComponent, {
      width: '60%',
      data: PdfDialogData,
      disableClose: false,
      panelClass: 'pdf-view-dialog-container',
    });

    return dialogRef;
  }

  public ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this._subscriptions.forEach((s) => s.unsubscribe());

  }

}


