import { Directive, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit } from "@angular/core";

@Directive({
    selector: '[resize]'
})
export class Resizable implements OnInit, OnDestroy {
    private nodes: HTMLElement[] = [];
    private limit = 50;
    private data: { x: number, y: number, rect: ClientRect, direction: string };
    
    constructor(@Inject(ElementRef) private element: ElementRef) {
        this.mousemove = this.mousemove.bind(this);
        this.mouseup = this.mouseup.bind(this);
    }

    @Input() public resize: boolean = true;

    mousemove(e) {
        if (this.data) {
            var { height, width, top, left } = this.data.rect;
            var style = this.element.nativeElement.style;
            var offset_y = this.data.y - e.clientY;
            var offset_x = this.data.x - e.clientX;
            var set: { [key: string]: number } = {};
            switch (this.data.direction) {
                case 'top':
                    set.height = height + offset_y;
                    set.top = top - offset_y;
                    break;
                case 'bottom':
                    set.height = height - offset_y;
                    break;
                case 'left':
                    set.width = width + offset_x;
                    set.left = left - offset_x;
                    break;
                case 'right':
                    set.width = width - offset_x;

            }
            if (set.width < this.limit) {
                delete set.width;
                delete set.left;
            }
            if (set.height < this.limit) {
                delete set.height;
                delete set.top;
            }
            Object.entries(set).forEach(([name, value]) => {
                style[name] = value + 'px';
            });
        }
    }

    createNode(side) {
        var node = document.createElement('div');
        node.classList.add('border-' + side, 'border');
        this.element.nativeElement.appendChild(node);
        this.nodes.push(node);
    }

    ngOnInit() {
        ['top', 'left', 'right', 'bottom'].forEach(this.createNode.bind(this));
        window.addEventListener('mousemove', this.mousemove);
        this.element.nativeElement.classList.add('resize');
        window.addEventListener('mouseup', this.mouseup);
    }

    @HostListener('mousedown', ['$event'])
    mousedown(e) {
        if (e.target.classList.contains('border') && this.resize) {
            var rect = this.element.nativeElement.getBoundingClientRect();
            this.data = {
                x: e.clientX,
                y: e.clientY,
                rect,
                direction: e.target.className.match(/border-([^ ]+)/)[1]
            };
            e.preventDefault();
        } else {
            delete this.data;
        }
    }

    mouseup(e) {
        delete this.data;
    }

    ngOnDestroy() {
        this.nodes.forEach(n => n.remove());
        window.removeEventListener('mousemove', this.mousemove);
        window.removeEventListener('mouseup', this.mouseup);
    }
}