<nav aria-label='breadcrumb'>
  <ol class='breadcrumb'>
    <li *ngFor='let breadcrumb of breadcrumbs'>
      <a [routerLink]="[breadcrumb.url, breadcrumb.params]" routerLinkActive='active'>
        {{breadcrumb.label }}</a>
    </li>
  </ol>
</nav>
<!-- <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <div class="d-flex">
        <li class="breadcrumb-item">
          <a class="color-768d74" href="#">Commandes</a>
        </li>
        <li class="breadcrumb-item color-768d74" aria-current="page">
          Saisie de commande
        </li>
      </div>
    </ol>
  </nav> -->
