import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { ErrorComponent } from './shared/error/error.component';
import { SessionExpireComponent } from './shared/session-expire/session-expire.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/Login',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./shared/_layout/layout.module')
      .then(mod => mod.LayoutModule)
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'sesstimeout',
    component: SessionExpireComponent
    //canActivate: [SessionGuard]
  },
  { path: '**', redirectTo: 'error' }
];

// const routerOptions: ExtraOptions = {
//   useHash: true,
//   anchorScrolling: 'enabled',
//   onSameUrlNavigation: 'reload',
//   scrollPositionRestoration: 'enabled'
//   // ...any other options you'd like to use
// };

@NgModule({
  // imports: [RouterModule.forRoot(routes, routerOptions)],
  imports: [RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
