import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Subscription } from 'rxjs';
import { DenreeModel } from 'src/app/core/model/member/purchases/denree/denree.model';
import { VUE_STOCK_SUIVI_ARTICLE } from 'src/app/core/model/member/stock/stock-output-art.model';
import { VUE_STOCK_MOUVEMENT_ART } from 'src/app/core/model/member/stock/VUE_STOCK_MOUVEMENT_ART.model';
import { StockService } from 'src/app/core/services/member/stock/stock.service';
import { DialogViewArtPictureDetailComponent, ViewArtPictureDetailData } from '../dialog-view-art-picture-detail/dialog-view-art-picture-detail.component';


@Component({
  selector: 'TraceabilityComponent',
  templateUrl: './traceability.component.html',
  styleUrls: ['./traceability.component.css']
})
export class TraceabilityComponent implements OnInit {

  @Input() article!: DenreeModel;

  public IsLoaded: boolean = false;

  public DataSourceListArtTraceability: any = new TableVirtualScrollDataSource([]);

  private _subscriptions: Subscription[] = [];
  public InventoryDate: any;
  private selectedDate: string;

  public displayedColumns = [
    'IMAGES',
    'MOUVEMENT',
    'DATE',
    'FOURNISSEUR',
    'NUM_LOT',
    'DATE_LIMITE',
    'TEMPERATURE'
  ];

  //all articles stock list TODO needed???
  public ListStockArticle: VUE_STOCK_SUIVI_ARTICLE[] = [];
  //one article (with traceability) stock list 
  public ListStockOneArticle: VUE_STOCK_SUIVI_ARTICLE[] = [];
  //mouvements list with trace for each article
  public ListMvtArt: VUE_STOCK_MOUVEMENT_ART[] = [];
  //final list displayed in table
  public ListMvtArtTrace: VUE_STOCK_MOUVEMENT_ART[] = [];


  //TODO Error: [tvsItemSize] requires TableVirtualScrollDataSource be set as [dataSource] of [mat-table] ????????????????????
  constructor(private _stockService: StockService, private _dialog: MatDialog,) {
    //get all articles stock list
    this._subscriptions.push(
      this._stockService.ListEtatStockArtSubject.subscribe(
        (ListEtatStockArticles: VUE_STOCK_SUIVI_ARTICLE[]) => {
          if (ListEtatStockArticles) {
            this.ListStockArticle = ListEtatStockArticles;
          } else {
            console.log('Get all articles stock list not working');
          }
        }
      )
    );
    //Get art mouvement for traceability & push it into displayed list
    this._subscriptions.push(
      this._stockService.ListStockArtMvtTraceSubject.subscribe(
        (ListMvt: VUE_STOCK_MOUVEMENT_ART[]) => {
          if (ListMvt) {
            this.ListMvtArt = ListMvt;
            //TODO for now only one(one entry???) el, until exits needed
            this.ListMvtArt.forEach(e => {
              let idx = this.ListMvtArt.indexOf(e);
              this.ListMvtArtTrace = [...this.ListMvtArtTrace, this.ListMvtArt[idx]];
            });

            this.DataSourceListArtTraceability = this.ListMvtArtTrace;
          }
          else {
            console.log('Get art mouvement for traceability not working');
          }
        }
      )
    );
  }

  ngOnInit(): void {
    //call the GetEtatStockArticles method
    this.InventoryDate = new Date();
    this.selectedDate = moment(this.InventoryDate)
      .local(false)
      .utc(true)
      .format('YYYYMMDD');
    this._stockService.GetEtatStockArticles(this.selectedDate, '0199');

    //TODO delayed for async data ????
    setTimeout(() => {
      this._subscriptions.push(
        // traceability => get only articles with lot num or useByDate from stock
        this._stockService
          .GetArtFromStockListWithTraceability(this.article.CODE_DENREE).subscribe((resultArticleStock) => {
            this.ListStockOneArticle = resultArticleStock;
            //get mouvements with traceability foreach article
            this.ListStockOneArticle.forEach(e => {
              this._stockService.GetMouvEntryTraceArticle(e.CODE_PR, e.NUM_LOT, e.DATE_LIMITE);
            })
          })
      );
      this.IsLoaded = true;

    }, 5000);


  }


  public OpenDetailPicArticle(pLotNumArt: string, pDlcArt: string, pDateMvt: string, pSuppName: string) {
    // console.log('num lot : ', pLotNumArt, 'dlc : ', pDlcArt, pDateMvt);
    const IsPdfdialog = this.DialogViewArticlePicDetail(pLotNumArt, pDlcArt, pDateMvt, pSuppName);
    IsPdfdialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
      }
    });
  }

  public DialogViewArticlePicDetail(pLotNumArt: string, pDlcArt: string, pDateMvt: string, pSuppName: string) {
    const ArtDialogData = new ViewArtPictureDetailData(pLotNumArt, pDlcArt, pDateMvt, pSuppName);

    const dialogRef = this._dialog.open(DialogViewArtPictureDetailComponent, {
      width: '80%',
      height: '80%',
      data: ArtDialogData,
      disableClose: false,
      panelClass: 'panel-view-dialog-art-detail',
    });

    return dialogRef;
  }






  public ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this._subscriptions.forEach(s => s.unsubscribe());
  }

}