import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import 'rxjs/add/observable/of'; // TODO import warning commonjs angular 10, à redéfinir allowedCommonJsDependencies in angular.json

@Injectable({
    providedIn: 'root'
})
export class ErrorCustomService {


    public IsErrorPageSubject = new Subject<boolean>();
    private _isErrorPage: boolean = false;

    constructor() {

    }

    public EmitterIsErrorPageSubject() {
        this.IsErrorPageSubject.next(this._isErrorPage);
    }

    public SetErrorPage(isError: boolean) {
        this._isErrorPage = isError;
        this.EmitterIsErrorPageSubject();
    }


}