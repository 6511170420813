<div *ngIf="IsFirstLoad; else firstload">
  <div class="col-lg-12" *ngIf="IsLoadedFournisseur; else loadingDetailFournisseur">
    <form [formGroup]="RegisterFormDetailSupplier" *ngIf="DetailSupplier"
      (ngSubmit)="onSubmitRegisterFormDetailSupplier()">
      <div class="row form-group">
        <div class="col-md-12">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-5">
                <fieldset style="height: 80%" class="form-legend-border">
                  <legend class="form-legend-border">
                    {{ "Supplier" | translate }}
                  </legend>
                  <div class="form-group row pb-2">
                    <div class="form-group">
                      <div class="form-group col-md-12">
                        <div class="d-flex justify-content-between">
                          <div>
                            <mat-label class="font-weight-700">{{
                            "NameOfSupplier" | translate
                            }}</mat-label>
                          </div>
                          <div>
                            <mat-label class="font-weight-700">IU Four</mat-label>
                            <span> {{
                              DetailSupplier.fouriu != "-1"
                              ? DetailSupplier.fouriu
                              : "&nbsp;"
                              }}</span>
                          </div>
                        </div>
                        <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                          <input type="text" matInput placeholder="{{ 'SupplierName' | translate }}"
                            formControlName="fournom" maxlength="35" />
                        </mat-form-field>
                        <div *ngIf="f.fournom.hasError('required')">
                          <mat-error>
                            {{ "RequiredField" | translate }}
                          </mat-error>
                        </div>

                      </div>

                      <div class="form-group row">
                        <div class="form-group col-md-6" style="align-items: center;
                        display: flex;">
                          <mat-slide-toggle formControlName="fourinactif">
                            <mat-label class="font-weight-700">{{
                              "Inactive" | translate
                              }}</mat-label>
                          </mat-slide-toggle>
                        </div>
                        <div class="col-md-6">
                          <mat-label class="font-weight-700 text-nowrap">{{ "MinOrderAmount" | translate }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input style="text-align: right;" type="text" matInput formControlName="MtnMinimalCmd"
                              maxlength="15"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                            <span class="font-size-2" matSuffix>€</span>
                            <mat-error class="error-input" *ngIf="f.MtnMinimalCmd.hasError('pattern')">
                              error
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="form-group col-md-12">
                          <mat-label class="font-weight-700 ">{{
                            "SupplierCode" | translate
                            }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput placeholder="{{ 'CodeSupplier' | translate }}"
                              formControlName="fourcode" maxlength="15" />
                            <mat-error class="error-input" *ngIf="f.fourcode.hasError('required')">
                              {{ "RequiredField" | translate }}
                            </mat-error>
                          </mat-form-field>
                        </div>

                      </div>
                      <div class="form-group row">
                        <div class="col-md-12">
                          <mat-label class="font-weight-700">{{
                            "ClientNumber" | translate
                            }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput placeholder="{{ 'ClientNumber' | translate }}"
                              formControlName="fournoclient" maxlength="15" />
                            <mat-error class="error-input" *ngIf="f.fournoclient.hasError('required')">
                              {{ "RequiredField" | translate }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="col-md-4">
                <fieldset style="height: 80%" class="form-legend-border">
                  <legend class="form-legend-border">
                    {{ "Contact" | translate }}
                  </legend>

                  <div class="form-group row">
                    <div class="form-group">
                      <div class="form-group row">
                        <div class="form-group col-md-12">
                          <mat-label class="font-weight-700">{{
                                    "Name" | translate
                                    }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput placeholder="{{
                                        'ContactName' | translate
                                      }}" formControlName="fourconctnom" maxlength="30" />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="form-group col-md-12">
                          <mat-label class="font-weight-700">{{
                                    "Phone" | translate
                                    }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput placeholder="{{ 'Phone' | translate }}"
                              formControlName="fourconcttel" maxlength="10" />
                            <!-- <mat-error *ngIf="f.fourconctnom.hasError('required')">
                                                Ce champ est obligatoire.
                                            </mat-error> -->
                          </mat-form-field>
                        </div>

                      </div>
                      <div class="form-group row">
                        <div class="form-group col-md-12">
                          <mat-label class="font-weight-700">{{
                                    "Email" | translate
                                    }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput placeholder="{{ 'Email' | translate }}"
                              formControlName="fourmail" maxlength="60" />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="form-group col-md-12">
                          <mat-label class="font-weight-700">{{
                                    "Website" | translate
                                    }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput placeholder="{{ 'Website' | translate }}" maxlength="60"
                              formControlName="foururl" />
                            <mat-icon class="refresh-fou" class="icon-display" matSuffix>
                              <a class="text-decoration-none" href="https://{{DetailSupplier.foururl}}" target="_blank">
                                <div class="refresh-size">language</div>
                              </a>

                            </mat-icon>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                </fieldset>
              </div>
              <div class="col-md-3">
                <fieldset style="height: 80%" class="form-legend-border">
                  <legend class="form-legend-border">
                    {{ "Logo" | translate }}
                  </legend>
                  <!-- test -->
                  <div class="d-flex justify-content-center align-items-center card-param-recette-sm"
                    style="position: relative">
                    <div style="position: absolute; top: 0; right: 0; margin: 1em"
                      *ngIf="FournLogoSrc && IsEditFournLogoSrc">
                      <button matTooltip="{{ 'Validate' | translate }}" mat-stroked-button
                        class="button-icon-generic-validate" type="button" (click)="RegisterLogoFournPic()">
                        <i class="fas fa-check"></i>
                      </button>
                    </div>
                    <div [ngClass]="{
                    'image-upload-param': !FournLogoSrc,
                    'image-upload-param-no-border': FournLogoSrc
                  }" class="d-flex justify-content-center align-items-center image-upload-param">
                      <div [ngClass]="{
                      'logo-cercle': !FournLogoSrc,
                      'logo-cercle-with-width': FournLogoSrc
                    }">
                        <!-- test function     (error)="hideImg($event)" -->
                        <img id="logo" [src]="FournLogoSrc" alt="" class="LogoSrc-img-circle" />
                      </div>
                      <div [ngClass]="{ 'param-fileup-logo': FournLogoSrc }">
                        <span *ngIf="!FournLogoSrc && isUpdatedInfRight"
                          class="d-flex justify-content-center font-size-1 text-center sub-title">{{ "UploadPhoto" | translate }}
                        </span>

                        <div class="d-flex justify-content-center">
                          <div *ngIf="isUpdatedInfRight">
                            <!-- TODO décommenter file-upload & remove span-->
                            <span class="">tt</span>
                            <!-- <file-upload [mode]="'custom'" [toolTip]="'Edit'"
                              [class]="'button-round round-button-icon p-0'" [srcImg]="
                            '../../../../assets/images/icon-picture/telecharger-photo.svg'
                          " (SelectedFileChange)="SelectedFileChange($event)" [files]="FournLogoImport"
                              (LoadingChange)="LoadingChange($event)">
                            </file-upload> -->
                          </div>
                        </div>
                        <span *ngIf="!FournLogoSrc && isUpdatedInfRight"
                          class="d-flex justify-content-center font-size-075 text-center">{{ "RecommendedSize" | translate }}
                        </span>
                        <span *ngIf="!FournLogoSrc && isUpdatedInfRight"
                          class="d-flex justify-content-center font-size-075 text-center">512 x 512 pixels
                        </span>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-6">
                <div class="container-fluid p-0">
                  <div class="form-group row">
                    <div class="col-md-12">
                      <fieldset class="form-legend-border">
                        <legend class="form-legend-border">
                          {{ "Address" | translate }}
                        </legend>
                        <div class="form-group row">
                          <div class="form-group col">
                            <mat-label class="font-weight-700">{{ "Address" | translate }} 1</mat-label>
                            <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                              <input type="text" matInput placeholder="{{ 'Address' | translate }} 1"
                                formControlName="fouradr1" maxlength="40" />
                            </mat-form-field>
                          </div>
                          <div class="form-group col">
                            <mat-label class="font-weight-700">{{ "Address" | translate }} 2</mat-label>
                            <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                              <input type="text" matInput placeholder="{{ 'Address' | translate }} 2"
                                formControlName="fouradr2" maxlength="40" />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-xl-6">
                            <mat-label class="font-weight-700">{{
                              "ZipCode" | translate
                              }}</mat-label>
                            <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                              <input type="text" matInput placeholder="{{ 'ZipCode' | translate }}"
                                formControlName="fourcp" maxlength="5" />
                            </mat-form-field>
                          </div>
                          <div class="col-xl-6">
                            <mat-label class="font-weight-700">{{
                              "City" | translate
                              }}</mat-label>
                            <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                              <input type="text" matInput placeholder="{{ 'City' | translate }}"
                                formControlName="fourcity" maxlength="40" />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-xl-6">
                            <mat-label class="font-weight-700">{{
                              "Phone" | translate
                              }}</mat-label>
                            <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                              <input type="text" matInput placeholder="{{ 'Phone' | translate }}"
                                formControlName="fourtel" maxlength="10" />
                            </mat-form-field>
                          </div>
                          <div class="col-xl-6">
                            <mat-label class="font-weight-700">{{
                              "Fax" | translate
                              }}</mat-label>
                            <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                              <input type="text" matInput placeholder="{{ 'Fax' | translate }}"
                                formControlName="fourfax" maxlength="10" />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="form-group col-xl">
                            <mat-label class="font-weight-700">{{
                              "Email" | translate
                              }}</mat-label>
                            <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                              <input type="text" matInput placeholder="{{ 'Email' | translate }}"
                                formControlName="fourmail" maxlength="60" />
                            </mat-form-field>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-12">
                      <fieldset class="form-legend-border">
                        <legend class="form-legend-border">
                          {{ "Notebook" | translate }}
                        </legend>
                        <div class="col-xl-12">
                          <mat-form-field appearance="fill" class="form-control-input-denree">
                            <textarea class="commentary-text-area-suppliers" matInput formControlName="CommentSupplier">
                            </textarea>
                          </mat-form-field>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <fieldset class="form-legend-border">
                  <legend class="form-legend-border">
                    {{ "Orders" | translate }}
                  </legend>

                  <div class="form-group row">
                    <div class="form-group col-xl-6">
                      <mat-label class="font-weight-700">{{
                        "From" | translate
                        }}</mat-label>
                      <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                        <input type="text" matInput type="time" id="de" formControlName="hourcmddeb" />
                      </mat-form-field>
                    </div>

                    <div class="form-group col-xl-6">
                      <mat-label class="font-weight-700">{{
                        "To" | translate
                        }}</mat-label>
                      <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                        <input matInput type="time" id="a" formControlName="hourcmdfin" />
                      </mat-form-field>
                    </div>

                    <div class="container-fluid panel-heading-order">
                      <h5 class="color-black">
                        {{ "OrdersDays" | translate }}
                      </h5>
                      <div *ngIf="DetailSupplier" class="row d-flex justify-content-center">
                        <div *ngFor="let cmdDay of DetailSupplier.ShipCmd" class="col-xl-8">
                          <div class="border border-2 mt-2 mb-2 p-2 checkbox-day hover-days" [ngClass]="
                              cmdDay.isSelected == true ? 'checked' : ''
                            " (click)="clickCmdDay(cmdDay.Name)">
                            {{ cmdDay.Name | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <mat-checkbox
                          formControlName="{{ cmdDay.Name + 'cmd' }}"
                        >
                        </mat-checkbox> -->
                </fieldset>
              </div>
              <div class="col-md-3">
                <fieldset class="form-legend-border">
                  <legend class="form-legend-border">
                    {{ "Delivery" | translate }}
                  </legend>
                  <div class="form-group row">
                    <div class="form-group col-xl-12">
                      <mat-label class="font-weight-700">{{ "Delay" | translate }} (
                        {{ "Day" | translate }} )</mat-label>
                      <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                        <input type="text" matInput placeholder="{{ 'Delay' | translate }}" formControlName="delaiShip"
                          maxlength="2" />
                      </mat-form-field>
                    </div>
                    <div class="container-fluid panel-heading-order">
                      <h5 class="color-black">
                        {{ "DeliveryDays" | translate }}
                      </h5>
                      <div *ngIf="DetailSupplier" class="row d-flex justify-content-center">
                        <div *ngFor="let shipDay of DetailSupplier.ShipDay" class="col-xl-8">
                          <div class="border border-2 mt-2 mb-2 checkbox-day hover-days" [ngClass]="
                              shipDay.isSelected == true ? 'checked' : ''
                            " (click)="clickShipDay(shipDay.Name)">
                            {{ shipDay.Name | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isUpdatedInfRight" class="form-group row">
        <div class="container-fluid">
          <div class="flex-direction-footer-form wrap-button-footer-form">
            <div>
              <!-- <button
                mat-stroked-button
                class="button-size button-footer-form button-generic-cancel mt-2"
                (click)="CancelEditSupplier()"
                type="button"
              >
                {{ "Cancel" | translate }}
              </button> -->
            </div>
            <div class="float-end">
              <button mat-stroked-button class="button-size button-footer-form button-generic-validate mt-2"
                type="submit">
                {{ "Save" | translate }}
              </button>
              <span class="btn-space"></span>
              <button *ngIf="DetailSupplier && DetailSupplier.fouriu != '-1'" mat-stroked-button
                class="button-size button-footer-form button-generic-delete mt-2 ms-4" (click)="DeleteSupplier()"
                type="button">
                {{ "Delete" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #firstload>
  <ng-container *ngFor="let number of [].constructor(1)">
    <div class="row">
      <div class="col-6">
        <br />
        <span class="font-weight-700">
          {{ "PleaseSelectASupplier" | translate }}
        </span>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #loadingDetailFournisseur>
  <ng-container *ngFor="let number of [].constructor(5)">
    <div class="row">
      <div class="col-6">
        <br />
        <content-loader [Width]="230" [Height]="50"></content-loader>
      </div>
      <div class="col-6">
        <br />
        <content-loader [Width]="230" [Height]="50"></content-loader>
      </div>
    </div>
  </ng-container>
</ng-template>