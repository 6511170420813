import { Component, OnInit } from '@angular/core';
import { ErrorCustomService } from '../../core/services/error/error-custom.service';
import { EnvService } from '../../core/services/environment/env.service';

@Component({
  selector: 'app-session-expire',
  templateUrl: './session-expire.component.html',
  styleUrls: ['./session-expire.component.css']
})
export class SessionExpireComponent implements OnInit {

  private intervalId = 0;
  public message = '';
  private seconds = 10;

  public UrlClientRedirect: string;

  constructor(private _errorCustom: ErrorCustomService, private _env: EnvService) {
    this.UrlClientRedirect = this._env.URL_CLIENT_BASE
  }

  ngOnInit(): void {
    this._errorCustom.SetErrorPage(true); // don't load header from server
    this.start();
  }

  public start() {
    this.countDown();
  }

  public stop() {
    this.clearTimer();
    this.message = `Holding at T-${this.seconds} seconds`;
  }

  private clearTimer() {
    clearInterval(this.intervalId);
  }

  private countDown() {

    this.clearTimer();

    this.intervalId = window.setInterval(() => {

      this.seconds -= 1;

      if (this.seconds === 0) {

        this.stop(); // stop count
        this.message = 'redirection en cours ...';
        window.onbeforeunload = null;
        window.location.href = this.UrlClientRedirect;

      } else {
        if (this.seconds < 0) {

          this.seconds = 10;

        } // reset

        this.message = `dans - ${this.seconds} seconds`;
      }
    }, 1000);
  }

  public redirect() {
    window.onbeforeunload = null;
    window.location.href = "/";
  }

  ngOnDestroy() {
    this._errorCustom.SetErrorPage(false);
    this.clearTimer();
  }
}

