import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';


/**
 * 
 * à injecter dans le config de chaque route souhaitée
 */
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router) { }

    /**
     * vérifie si l'utilisateur est authentifié (s'il a un token)
     *
     * @param route
     * @param state
     * @returns {boolean} est l'état authentifié par l'utilisateur, redirige vers la page de connexion si son pas
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {


        if (localStorage.getItem('currentUserToken')) {
            //console.log(localStorage.getItem('currentUserToken'));
            return true;
        }


        //this.router.navigate(['/Login'], { queryParams: { returnUrl: state.url } });

        this.router.navigate(['/']);


        return false;
    }
}
