<div class="container-fluid pdf-view-cmd">
  <div class="row">
    <div class="col-md" *ngIf="pdfData && pdfData.PdfSrcByte">
      <ngx-extended-pdf-viewer
        [src]="pdfData.PdfSrcByte"
        [showToolbar]="true"
        [showSidebarButton]="false"
        [showFindButton]="false"
        [showPagingButtons]="false"
        [showZoomButtons]="true"
        [textLayer]="false"
        [showPresentationModeButton]="false"
        [showOpenFileButton]="false"
        [showPrintButton]="true"
        [showDownloadButton]="true"
        [showBookmarkButton]="false"
        [showSecondaryToolbarButton]="false"
        [showRotateButton]="false"
        [showHandToolButton]="false"
        [showScrollingButton]="true"
        [showSpreadButton]="false"
        [showPropertiesButton]="false"
        [useBrowserLocale]="true"
        zoom="80%"
        [height]="'100vh'"
        [delayFirstView]="10"
      >
      </ngx-extended-pdf-viewer>
    </div>
  </div>
  <br />
</div>
