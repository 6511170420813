import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { GRAAL_CENTRE } from "../../../model/common/graal_centre.model";
import { GRAAL_SOUFAM } from "../../../model/member/recettes/GRAAL_SOUFAM.model";
import { Config } from '../../../../config';
import { EnvService } from "../../environment/env.service";
import { GRAAL_CATEG } from "../../../model/member/recettes/GRAAL_CATEG.model";
import { GRAAL_PLAT } from "../../../model/member/recettes/GRAAL_PLAT.model";
import { GES_FITECH } from "../../../model/member/recettes/GES_FITECH.model";
import { DynamicFlatNode } from "../../../../member/recettes/list-plats/list-plats.component";
import { TreePlatModel } from "../../../model/member/recettes/TreePlatModel.model";
import { Observable, Subject } from "rxjs";

@Injectable()
export class RecetteService {
    // url api
    private _urlBaseAPI: string;

    private _urlGetFamRecette: string;

    private _urlGetSFamRecette: string;

    private _urlGetCategRecette: string;

    private _urlGetPlatRecette: string;

    private _urlGetFichTechRecette: string;

    private _urlPostEditFamRecette: string;

    private _urlPostEditSFamRecette: string;

    public FamRecetteSubject = new Subject<GRAAL_CENTRE[]>();
    private _listFamRecette: GRAAL_CENTRE[] = [];

    public ParamProRecetteSubject = new Subject<GRAAL_CENTRE[]>();
    private _listParamProRecette: GRAAL_CENTRE[] = [];

    private _urlPostEditCatRecette: string;

    private _urlGetAllParamProductionRecette: string;

    private _urlGetFitechByPlatGame: string;

    constructor(private _httpClient: HttpClient, private env: EnvService) {
        this._urlBaseAPI = env.API_URL_BASE;
        this._urlGetFamRecette = Config.API_ROUTES.RECETTE_API.API_GET_FAM_RECETTE;
        this._urlGetSFamRecette = Config.API_ROUTES.RECETTE_API.API_GET_SFAM_RECETTE;
        this._urlGetCategRecette = Config.API_ROUTES.RECETTE_API.API_GET_CATEG_RECETTE;
        this._urlGetPlatRecette = Config.API_ROUTES.RECETTE_API.API_GET_PLAT_RECETTE;
        this._urlGetFichTechRecette = Config.API_ROUTES.RECETTE_API.API_GET_FICH_TECH_RECETTE;
        this._urlPostEditFamRecette = Config.API_ROUTES.RECETTE_API.API_POST_EDIT_FAM_RECETTE;
        this._urlPostEditSFamRecette = Config.API_ROUTES.RECETTE_API.API_POST_EDIT_SFAM_RECETTE;
        this._urlPostEditCatRecette = Config.API_ROUTES.RECETTE_API.API_POST_EDIT_CAT_RECETTE;
        this._urlGetAllParamProductionRecette = Config.API_ROUTES.RECETTE_API.API_GET_ALL_PARAM_PROD_RECIPE;
        this._urlGetFitechByPlatGame = Config.API_ROUTES.RECETTE_API.API_GET_FITECH_BY_PLAT_GAMME;
    }

    public GetListFamRecetteOrigine() {
        return this._listFamRecette.slice();
    }

    public SetListFamRecetteOrigine(item: GRAAL_CENTRE, index: number) {
        this._listFamRecette[index] = item;
    }

    public EmitterListFamDenSubject() {
        if (this._listFamRecette != undefined) {
            this.FamRecetteSubject.next(this._listFamRecette.slice());
        }
    }

    public EmitterListParamProdRecetteSubject() {
        if (this._listParamProRecette != undefined) {
            this.ParamProRecetteSubject.next(this._listParamProRecette.slice());
        }
    }

    public InitFitech() {
        let fitech: GES_FITECH = {
            CLEPLAT: '',
            CODE_GAMME: '',
            NO_ETIQUE_AUTO: undefined,
            RANG_FAMILLE: undefined,
            NOM_FICHE: undefined,
            ABREG_FICHE: undefined,
            ATEL_PRINC: undefined,
            ATEL_PROC1: undefined,
            ATEL_PROC2: undefined,
            ATEL_PROC3: undefined,
            ATEL_PROC4: undefined,
            ATEL_PROC5: undefined,
            ATEL_PROC6: undefined,
            IU_POSTE: undefined,
            FITECH_SEL: undefined,
            NBRE_PORTION: undefined,
            PROTO_HACCP: undefined,
            CREDIT_PORTION: undefined,
            DLC: undefined,
            NO_PRINT_AUTO: undefined,
            RDT: undefined,
            NO_FICHE_AUTO: undefined,
            FLAG_GRAMMAGE: undefined,
            POIDS_GRAMMAGE: undefined,
            CONDT_SPECIF: undefined,
            ATEL_SPECIF: undefined,
            NUM_ROLLS: undefined,
            FLAG_DECHETS: undefined,
            THERMO_CODE: undefined,
            QTE_BUDGET: undefined,
            PROD_SVIDE: undefined,
            LIEN_DENREE: undefined,
            FICHE_VALIDEE: undefined,
            GEMRCN: undefined,
            GEMRCN2: undefined,
            FICHES_ROLLS_AUTO: undefined,
            TEMOIN: undefined,
            POIDS_BON_LIVRE: undefined,
        }

        return fitech;
    }


    public GetNodeChildTreePlat(node: DynamicFlatNode) {

        var TreeData: Observable<TreePlatModel[]>;

        switch (node.level) {
            case 0:
                TreeData = this.GetAllSouFamRecetteByFam(node.code_2).pipe(
                    map((res: GRAAL_SOUFAM[]) => {
                        let data: TreePlatModel[] = [];
                        res.map(x => {
                            data = [...data,
                            {
                                CODE: x.CLESOUF,
                                CODE_2: '',
                                LIB: x.LIBSOUF,
                                isExpandable: true
                            }];
                        });
                        return data;
                    }));
                break;
            case 1:
                TreeData = this.GetAllCategorieRecetteBySFam(node.code).pipe(
                    map((res: GRAAL_CATEG[]) => {
                        let data: TreePlatModel[] = [];
                        res.map(x => {
                            data = [...data,
                            {
                                CODE: x.CLECAT,
                                CODE_2: '',
                                LIB: x.LIBCAT,
                                isExpandable: true
                            }];
                        });
                        return data;
                    }));
                break;
            case 2:
                // voir node catégorie
                // vérifier param production pour voir si c'est non gestion des plats
                // si oui on ne récupére pas les plats, sinon on récupère fittech
                if (this._listParamProRecette.findIndex(p => p.CLEPAR == "POR68") == -1) {
                    TreeData = this.GetAllPlatRecetteByCateg(node.code).pipe(
                        map((res: GRAAL_PLAT[]) => {
                            let data: TreePlatModel[] = [];
                            res.map(x => {
                                data = [...data,
                                {
                                    CODE: x.CLEPLAT,
                                    CODE_2: '',
                                    LIB: x.LIBPLAT,
                                    isExpandable: true
                                }];
                            });
                            return data;
                        }));
                }
                else {
                    TreeData = this.GetBuildTreeDataFichTechByPlat(node.code);
                }
                break;
            case 3:
                TreeData = this.GetBuildTreeDataFichTechByPlat(node.code);
                break;
            default:
                break;
        }

        return TreeData;
    }

    public GetBuildTreeDataFichTechByPlat(codePlat: string) {

        return this.GetAllFichTechByPlat(codePlat).pipe(
            map((res: GES_FITECH[]) => {
                let data: TreePlatModel[] = [];
                res.map(x => {
                    data = [...data,
                    {
                        CODE: x.CLEPLAT,
                        CODE_2: x.CODE_GAMME,
                        LIB: x.NOM_FICHE,
                        isExpandable: false
                    }];
                });
                return data;
            }));
    }

    public GetListFamRecette$() {
        return this.GetListFamRecette().subscribe((response: GRAAL_CENTRE[]) => {
            this._listFamRecette = response;
            this.EmitterListFamDenSubject();
        },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }


    public GetListParamProdRecette$() {
        return this.GetListParamProdRecette().subscribe((response: GRAAL_CENTRE[]) => {
            this._listParamProRecette = response;
            this.EmitterListParamProdRecetteSubject();
        },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetListFamRecette() {

        let urlAPI = this._urlBaseAPI + this._urlGetFamRecette;

        return this._httpClient.get(urlAPI)
            .pipe(
                map((res: GRAAL_CENTRE[]) => {
                    res.sort(function (a, b) {
                        return a.CLEPAR.localeCompare(b.CLEPAR)
                    });
                    return res;
                }
                )
            );
    }

    public GetFitechByPlatGame(plat: string, gamme: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetFitechByPlatGame + "p=" + plat + "&g=" + gamme;

        return this._httpClient.get(urlAPI)
            .pipe(
                map((res: GES_FITECH) => {
                    return res;
                }
                )
            );
    }

    public GetListParamProdRecette() {

        let urlAPI = this._urlBaseAPI + this._urlGetAllParamProductionRecette;

        return this._httpClient.get(urlAPI)
            .pipe(
                map((res: GRAAL_CENTRE[]) => {
                    res.sort(function (a, b) {
                        return a.CLEPAR.localeCompare(b.CLEPAR)
                    });
                    return res;
                }
                )
            );
    }

    public GetAllSouFamRecetteByFam(codeFam: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetSFamRecette + codeFam;

        return this._httpClient.get(urlAPI)
            .pipe(
                map((res: GRAAL_SOUFAM[]) => {
                    res.sort(function (a, b) {
                        return a.LIBSOUF.localeCompare(b.LIBSOUF)
                    });
                    return res;
                }
                )
            );
    }

    public GetAllCategorieRecetteBySFam(codeSFam: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetCategRecette + codeSFam;

        return this._httpClient.get(urlAPI)
            .pipe(
                map((res: GRAAL_CATEG[]) => {
                    res.sort(function (a, b) {
                        return a.LIBCAT.localeCompare(b.LIBCAT)
                    });
                    return res;
                }
                )
            );
    }

    public GetAllPlatRecetteByCateg(codeCateg: string) {
        let urlAPI = this._urlBaseAPI + this._urlGetPlatRecette + codeCateg;

        return this._httpClient.get(urlAPI)
            .pipe(
                map((res: GRAAL_PLAT[]) => {
                    res.sort(function (a, b) {
                        return a.CLEPLAT.localeCompare(b.CLEPLAT)
                    });
                    return res;
                }
                )
            );
    }

    public GetAllFichTechByPlat(codePlat: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetFichTechRecette + codePlat;

        return this._httpClient.get(urlAPI)
            .pipe(
                map((res: GES_FITECH[]) => {
                    res.sort(function (a, b) {
                        return a.CLEPLAT.localeCompare(b.CLEPLAT)
                    });
                    return res;
                }
                )
            );
    }

    public SaveEditFam(FamEdit: GRAAL_CENTRE) {

        let URI = this._urlBaseAPI + this._urlPostEditFamRecette;

        try {
            return this._httpClient.post(URI, FamEdit,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let FamReturn: GRAAL_CENTRE = ((response.body) as GRAAL_CENTRE);
                                return FamReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public SaveEditSFam(SFamEdit: GRAAL_SOUFAM) {

        let URI = this._urlBaseAPI + this._urlPostEditSFamRecette;

        try {
            return this._httpClient.post(URI, SFamEdit,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let SFamReturn: GRAAL_SOUFAM = ((response.body) as GRAAL_SOUFAM);
                                return SFamReturn;
                            }
                            return response.status;
                        },
                        (error) => {
                            return error;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }

    public SaveEditCat(CatEdit: GRAAL_CATEG) {

        let URI = this._urlBaseAPI + this._urlPostEditCatRecette;

        try {
            return this._httpClient.post(URI, CatEdit,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let CatReturn: GRAAL_CATEG = ((response.body) as GRAAL_CATEG);
                                return CatReturn;
                            }
                            return response.status;
                        },
                        (error) => {
                            return error;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }
}