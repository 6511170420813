<div class="container-fluid">
  <form [formGroup]="RegisterFormSendCmdMail">
    <div class="header-mail">
      <img
        src="../../../../assets/images/icons-commande/mail-orange.svg"
        style="width: 4%"
      />
      Envoyer par mail
    </div>
    <div class="row mt-2">
      <div class="col-md">
        <div class="input-group mb-3">
          <span class="input-group-text span-email-input">Destinataire</span>
          <input
            type="text"
            class="form-control"
            placeholder="Email"
            id="debut-email"
            formControlName="EmailDestinataire"
          />
        </div>
        <div class="text-danger" *ngIf="submittedRegisterFormSendCmdMail">
          <span
            *ngIf="
              f.EmailDestinataire.errors?.required ||
              f.EmailDestinataire.errors?.required
            "
            >Email invalide</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <div class="input-group mb-3">
          <span class="input-group-text span-email-input">Cc</span>
          <input
            type="text"
            class="form-control"
            placeholder="Cc"
            id="debut-email"
            formControlName="EmailCC"
          />
        </div>
      </div>
      <div class="text-danger" *ngIf="submittedRegisterFormSendCmdMail">
        <span *ngIf="f.EmailCC.errors?.pattern">Email invalide</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <div class="input-group mb-3">
          <span class="input-group-text span-email-input">Objet</span>
          <input
            type="text"
            class="form-control"
            placeholder="Objet"
            id="debut-email"
            formControlName="Objet"
          />
          <div></div>
        </div>
      </div>
    </div>
    <div class="row" *ngFor="let file of dataSendMail.FileJoin">
      <div class="col-md-1">
        <div
          class="white-space-nowrap px-2 mt-2 mb-4"
          (click)="download(file)"
          style="cursor: pointer"
        >
          <i class="fas fa-file-pdf" style="color: red"></i> {{ file.Name }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <ckeditor [editor]="editor" formControlName="Message"></ckeditor>
      </div>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-center">
      <button
        mat-stroked-button
        class="button-generic-validate"
        (click)="onConfirmEnvoyer()"
        type="submit"
      >
        {{ "Send" | translate }}
      </button>
      <button
        mat-button
        class="button-generic-delete"
        (click)="onAnnulerEnvoyer()"
        type="button"
      >
        {{ "Cancel" | translate }}
      </button>
    </div>
  </form>
  <Spinner-Loader
    class="spinner-loader-custom"
    [color]="colorLoader"
    [mode]="modeLoader"
    [value]="valueLoader"
    [backdropEnabled]="true"
    [positionGloballyCenter]="true"
    [displayProgressSpinner]="displayProgressSpinner"
  >
  </Spinner-Loader>
</div>
