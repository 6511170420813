import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { NotificationService } from '../services/util/notification.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {


    constructor(private router: Router, private _notificationService: NotificationService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes('Login')) {
            let isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
            if (isIEOrEdge)
                req = req.clone({
                    withCredentials: true,
                    responseType: "text" as "json",
                    setHeaders: {
                        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                        'Pragma': 'no-cache',
                        'Expires': '0'
                    }
                });
            else
                req = req.clone({
                    withCredentials: true,
                    responseType: "text" as "json",
                });
        }
        else if (localStorage.getItem('impersonate_access_token') != null) {
            const impersonateToken = sessionStorage.getItem('impersonate_access_token');
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${impersonateToken}`,
                    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                }
            });
        }
        // add authorization header with jwt token if available
        else if (localStorage.getItem('currentUserToken') != null) {
            const token = localStorage.getItem('currentUserToken');
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                }
            });
        }

        return next.handle(req).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
                let errorMessage = '';
                if (error.error instanceof ErrorEvent && !error.url.includes("/error")) {
                    // client-side error
                    errorMessage = `Error: ${error.error.message}`;
                    //console.log(this._isEnvProd);
                    if (localStorage.getItem('currentUserToken')) {
                        this.router.navigate(["/error"]);
                    }
                    else {
                        this.router.navigate(['/sesstimeout']);
                    }
                } else {
                    // server-side error
                    switch (error.status) {
                        case 409: {
                            // conflict
                            //console.log(error);
                            //return throwError(error.status);
                            this._notificationService.error(error.error);
                            break;
                        }
                        case 429: {
                            // too many requests
                            console.log(error);
                            return throwError(error.status);
                            //break;
                        }
                        case 403: {
                            // Acces denied
                            //console.log(error);
                            return throwError(error.status);
                            //break;
                        }
                        case 401: {
                            // unauthorized
                            //console.log(error);
                            if (req.url.includes('public-valide-confirm-change-pwd')) {
                                return throwError(error.status);
                            }
                            else {
                                this.router.navigate(['/sesstimeout']);
                            }
                            //break;
                        }
                        case 400: {
                            if (error.error) {
                                if (typeof error.error === 'string') {
                                    this._notificationService.error(error.error);
                                }
                                else {
                                    this._notificationService.error("Internal error");
                                }
                            }
                            else {
                                this._notificationService.error("Internal error");
                            }

                            //this.router.navigate(["/error"]);
                            break;
                        }
                        default: {
                            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                            //console.log(this._isEnvProd);
                            //console.log(errorMessage);
                            this._notificationService.error(error.statusText);

                            if (localStorage.getItem('currentUserToken')) {
                                this.router.navigate(["/error"]);
                            }
                            else {
                                this.router.navigate(['/sesstimeout']);
                            }
                            break;
                        }
                    }
                }

                // window.alert(errorMessage);
                return throwError(errorMessage);
            })
        );
    }
}
