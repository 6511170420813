import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StockService } from '../../../../core/services/member/stock/stock.service';


export class ViewArtMouvDetailData {

  constructor(public pCodePr: string, public pNumLot: string, public pDateDlc: string, public origine: string) {
  }
}

@Component({
  selector: 'app-dialog-view-art-mouvement',
  templateUrl: './dialog-view-art-mouvement.component.html',
  styleUrls: ['./dialog-view-art-mouvement.component.css']
})
export class DialogViewArtMouvementComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogViewArtMouvementComponent>,
    @Inject(MAT_DIALOG_DATA) public artInfo: ViewArtMouvDetailData,
    private _stockService: StockService,
  ) { }

  ngOnInit(): void {
  }

  public onClose() {
    this.dialogRef.close(false);
  }

}
