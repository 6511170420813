import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/of';
import * as _ from 'lodash-es';
import { SupplierModel } from '../../../../model/member/purchases/supplier/supplier-name.model';
import { EnvService } from '../../../environment/env.service';
import { SupplierDetailModel } from '../../../../model/member/purchases/supplier/supplier-detail.model';
import { Config } from '../../../../../config';
import { catchError, map, tap } from 'rxjs/operators';
import { IFourSearchResponse, SearchFournisseurModel } from '../../../../model/member/purchases/supplier/search-fournisseur.model';
import { VUE_TARIF_CATALOG_FOUR } from '../../../../model/member/purchases/denree/VUE_TARIF_CATALOG_FOUR.model';
import { VUE_ART_FOUR_TARIF } from '../../../../model/member/purchases/supplier/VUE_ART_FOUR_TARIF.model';
import { CatalogSupplierModel } from '../../../../model/member/commandes/CatalogSupplierModel.model';
import * as moment from 'moment';

@Injectable()
export class SupplierService {
  // url api
  private _urlBaseAPI: string;

  private _urlGetSuppliersAPI: string;
  private _urlGetDetailSupplierAPI: string;

  private _urlGetSuppliersActifAPI: string;

  private _urlPostSaveEditFournisseur: string;

  private _urlPostSaveLogoFournisseur: string;

  private _urlSearchFournisseur: string;

  private _urlGetAllCatalogTarifFou: string;

  private _urlPostSaveEditAllCatalogTarifFou: string;

  private _urlGetAllSupplierWithPrice: string;

  private _urlPostDeleteSupplier: string;

  // subscription and model

  public SupplierSubject = new Subject<SupplierModel[]>();
  private _listSupplier: SupplierModel[] = [];

  public SupplierDetailSubject = new Subject<SupplierDetailModel>();
  private _supplierDetail: SupplierDetailModel;

  public SupplierDirSubject = new Subject<SupplierDetailModel[]>();
  private _listSupplierDir: SupplierDetailModel[];

  public ListTarifCatalogFouSubject = new Subject<VUE_TARIF_CATALOG_FOUR[]>();
  private _listTarifCatalogFou: VUE_TARIF_CATALOG_FOUR[] = [];


  public ListSupplierWithPriceSubject = new Subject<VUE_ART_FOUR_TARIF[]>();
  private _listSupplierWithPrice: VUE_ART_FOUR_TARIF[] = [];



  constructor(private _httpClient: HttpClient, private env: EnvService) {
    this._urlBaseAPI = env.API_URL_BASE;
    this._urlGetSuppliersAPI = Config.API_ROUTES.PURCHASE_API.API_GET_ALL_SUPPLIERS;
    this._urlGetSuppliersActifAPI = Config.API_ROUTES.PURCHASE_API.API_GET_ALL_ACTIF_SUPPLIERS;
    this._urlGetDetailSupplierAPI = Config.API_ROUTES.PURCHASE_API.API_GET_DETAIL_SUPPLIER;
    this._urlPostSaveEditFournisseur = Config.API_ROUTES.PURCHASE_API.API_POST_SAVE_EDIT_SUPPLIER;
    this._urlPostSaveLogoFournisseur = Config.API_ROUTES.PURCHASE_API.API_POST_SAVE_LOGO_SUPPLIER;
    this._urlSearchFournisseur = Config.API_ROUTES.PURCHASE_API.API_GET_SEARCH_SUPPLIER;
    this._urlGetAllCatalogTarifFou = Config.API_ROUTES.PURCHASE_API.API_GET_TARIF_Fournisseur;
    this._urlPostSaveEditAllCatalogTarifFou = Config.API_ROUTES.PURCHASE_API.API_POST_SAVE_EDIT_ALL_TARIF_Fournisseur;
    this._urlGetAllSupplierWithPrice = Config.API_ROUTES.PURCHASE_API.API_GET_ALL_SUPPLIERS_WITH_PRICE;
    this._urlPostDeleteSupplier = Config.API_ROUTES.PURCHASE_API.API_POST_DELETE_SUPPLIER
  }

  public EmitterListSupplierWithPriceSubject() {
    if (this._listSupplierWithPrice != undefined) {
      this.ListSupplierWithPriceSubject.next(this._listSupplierWithPrice.slice());
    }
  }

  public EmitterListTarifSupplierSubject() {
    if (this._listTarifCatalogFou != undefined) {
      this.ListTarifCatalogFouSubject.next(this._listTarifCatalogFou.slice());
    }
  }

  public EmitterSupplierSubject() {
    if (this._listSupplier != undefined) {
      this.SupplierSubject.next(this._listSupplier.slice());
    }
  }

  public EmitterSupplierDirSubject() {
    if (this._listSupplierDir != undefined) {
      this.SupplierDirSubject.next(this._listSupplierDir.slice());
    }
  }

  public EmitterSupplierDetailSubject() {
    if (this._supplierDetail != undefined) {
      console.log('SERVICE EMITTER ', this._supplierDetail);
      this.SupplierDetailSubject.next(_.cloneDeep(this._supplierDetail));
    }
  }

  public GetListSupplierActifSync(): Observable<SupplierModel[]> {
    let urlAPI = this._urlBaseAPI + this._urlGetSuppliersActifAPI;

    return this._httpClient.get<SupplierModel[]>(urlAPI).pipe(
      map((res: SupplierModel[]) => {
        res.map(x => {
          res.sort(function (a, b) {
            return a.NOM_FOURNISSEUR.localeCompare(b.NOM_FOURNISSEUR)
          });
        });
        return res;
      })
    );
  }

  public GetCopyDirSupplier(): SupplierDetailModel[] {
    return this._listSupplierDir.slice();
  }

  public GetListSupplier() {

    let urlAPI = this._urlBaseAPI + this._urlGetSuppliersAPI;

    this._httpClient.get<SupplierModel[]>(urlAPI).pipe(
      map((fournisseur: SupplierModel[]) => {
        // sort display
        fournisseur.sort(function (a, b) {
          return a.NOM_FOURNISSEUR.localeCompare(b.NOM_FOURNISSEUR); //using String.prototype.localCompare()
        });
        return fournisseur;
      })
    ).subscribe(
      (response: SupplierModel[]) => {
        console.log('service LIST FOURN response ', response)
        this._listSupplier = response;
        this.EmitterSupplierSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public AddSupplier(pSupplier: SupplierModel) {
    this._listSupplier = [pSupplier, ...this._listSupplier];
    this.EmitterSupplierSubject();
  }

  public GetAllListSupplierWithPrice(pArt: string) {

    let urlAPI = this._urlBaseAPI + this._urlGetAllSupplierWithPrice + pArt;

    this._httpClient.get<VUE_ART_FOUR_TARIF[]>(urlAPI).pipe(
      map((artprice: VUE_ART_FOUR_TARIF[]) => {
        artprice.map((x) => {
          if (
            x.CATALOG_PRIX_EUROS &&
            !isNaN(Number(x.CATALOG_PRIX_EUROS.replace(/\s/g, '')))
          ) {
            x.CATALOG_PRIX_EUROS = Number(
              x.CATALOG_PRIX_EUROS.replace(/\s/g, '')
            ).toFixed(4);
          }
          if (
            x.CATALOG_PRIX_EUROS1 &&
            !isNaN(Number(x.CATALOG_PRIX_EUROS1.replace(/\s/g, '')))
          ) {
            x.CATALOG_PRIX_EUROS1 = Number(
              x.CATALOG_PRIX_EUROS1.replace(/\s/g, '')
            ).toFixed(4);
          }
        });

        artprice.sort((a, b) => {
          if (a.CATALOG_PRIX_EUROS && !b.CATALOG_PRIX_EUROS) return -1
          if (b.CATALOG_PRIX_EUROS && !a.CATALOG_PRIX_EUROS) return 1

          return Number(a.CATALOG_PRIX_EUROS && a.CATALOG_PRIX_EUROS.replace(/[^0-9.-]+/g, "")) - Number(b.CATALOG_PRIX_EUROS && b.CATALOG_PRIX_EUROS.replace(/[^0-9.-]+/g, "")) || a.NOM_FOURNISSEUR.localeCompare(b.NOM_FOURNISSEUR)
        })
        return artprice;
      })
    ).subscribe(
      (response: VUE_ART_FOUR_TARIF[]) => {

        this._listSupplierWithPrice = response;
        this.EmitterListSupplierWithPriceSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetAllListSupplierWithPriceSync(pArt: string) {

    let urlAPI = this._urlBaseAPI + this._urlGetAllSupplierWithPrice + pArt;

    return this._httpClient.get<VUE_ART_FOUR_TARIF[]>(urlAPI).pipe(
      map((artprice: VUE_ART_FOUR_TARIF[]) => {
        artprice.map((x) => {
          if (
            x.CATALOG_PRIX_EUROS &&
            !isNaN(Number(x.CATALOG_PRIX_EUROS.replace(/\s/g, '')))
          ) {
            x.CATALOG_PRIX_EUROS = Number(
              x.CATALOG_PRIX_EUROS.replace(/\s/g, '')
            ).toFixed(4);
          }
          if (
            x.CATALOG_PRIX_EUROS1 &&
            !isNaN(Number(x.CATALOG_PRIX_EUROS1.replace(/\s/g, '')))
          ) {
            x.CATALOG_PRIX_EUROS1 = Number(
              x.CATALOG_PRIX_EUROS1.replace(/\s/g, '')
            ).toFixed(4);
          }
        });
        // sort display
        artprice.sort(function (a, b) {
          return Number(b.CATALOG_PRIX_EUROS) - Number(a.CATALOG_PRIX_EUROS) || a.NOM_FOURNISSEUR.localeCompare(b.NOM_FOURNISSEUR)
        });

        // affecter nouvelle liste
        this._listSupplierWithPrice = artprice;

        return artprice;
      })
    );
  }

  /**
   *  InitNewSupplier
   */
  public InitNewSupplier(): SupplierModel {
    let NewSupplier: SupplierModel = {
      AD1: '',
      AD2: '',
      CODE_FOURNISSEUR: '',
      CODPOST: '',
      DATHRMAJ: '',
      DELAIS_LIVRE: '',
      E_MAIL: '',
      URL_WEB: '',
      LOGO_64: '',
      FAX: '',
      FLAG_INACTIF: '',
      GESTDEL: '',
      HEURE_DEB: '',
      HEURE_FIN: '',
      IU_EXT: '',
      IU_FOURNISSEUR: '-1',
      JOURS_COMMANDE: '',
      JOURS_LIVRAISON: '',
      LIBVIL: '',
      NOM_CONTACT: '',
      NOM_FOURNISSEUR: '',
      NUM_CLIENT: '',
      PRN_CONTACT: '',
      TARIF_COMPARE: '',
      TELEPHONE_C: '',
      TELEPHONE_F: '',
      USERMAJ: '',
      COMMENTAIRE: '',
      LIM_CMD: undefined
    };

    return NewSupplier;
  }

  public InitTarifCatalogFournisseur(): VUE_TARIF_CATALOG_FOUR {
    let InitTarifFou: VUE_TARIF_CATALOG_FOUR = {
      CATALOG_DENREE: '',
      IU_DENREE: '',
      CATALOG_PRIX_DENREE: '',
      CATALOG_PRIX_EUROS: '',
      CATALOG_REF_EXTERNE: '',
      CATALOG_REF_EXTERNE1: '',
      CATALOG_IUFOU: '',
      DATHRMAJ: '',
      USERMAJ: '',
      GESTDEL: '',
      CATALOG_CONDITIO: '',
      CATALOG_CONDITIO1: '',
      DATE_DEB: '',
      DATE_FIN: '',
      CATALOG_PRIX_EUROS1: '',
      DATE_DEB1: '',
      DATE_FIN1: '',
      CATALOG_COMMENT: '',
      CATALOG_COMMENT1: '',
      CATALOG_MARQUE: '',
      CATALOG_MARQUE1: '',
      CATALOG_MARCHE: '',
      CATALOG_MARCHE1: '',
      CATALOG_CODE_MARCHE: '',
      CATALOG_CODE_MARCHE1: '',
      CATALOG_UF: '',
      CATALOG_QUTILE: '',
      FICHE_PRODUIT_NET: '',
      ALLERGENES: '',
      ORIGINE: '',
      NOM_ARTICLE: '',
      CATALOG_UNIPROD_COM: '',
      CATALOG_UK: '',
      NOM_DENREE: '',
      FOU_NOM: '',
      UF_DENREE: '',
      UF_DENREE_CODE: '',
      CATALOG_ACTIF: '1',
      IsEdit: false,
      IsCheck: false,
      IsNewFou: false
    }

    return InitTarifFou;
  }

  public InitDetailSupplier(pNomFou?: string) {
    let DetailSupplier: SupplierDetailModel = {
      fouriu: '-1',
      fournom: pNomFou ? pNomFou : '',
      fourcode: '',
      fourinactif: '',
      fournoclient: '',
      fourconctnom: '',
      fourconcttel: '',
      fouradr1: '',
      fouradr2: '',
      fourcp: '',
      fourcity: '',
      fourtel: '',
      fourfax: '',
      fourmail: '',
      foururl: '',
      fourlogo: '',
      ShipDay: [
        {
          id: 0,
          Name: 'Monday',
          isSelected: false
        },
        {
          id: 1,
          Name: 'Tuesday',
          isSelected: false,
        },
        {
          id: 2,
          Name: 'Wednesday',
          isSelected: false,
        },
        {
          id: 3,
          Name: 'Thursday',
          isSelected: false,
        },
        {
          id: 4,
          Name: 'Friday',
          isSelected: false,
        },
        {
          id: 5,
          Name: 'Saturday',
          isSelected: false,
        },
        {
          id: 6,
          Name: 'Sunday',
          isSelected: false,
        },
      ],
      ShipCmd: [
        {
          id: 0,
          Name: 'Monday',
          isSelected: false
        },
        {
          id: 1,
          Name: 'Tuesday',
          isSelected: false,
        },
        {
          id: 2,
          Name: 'Wednesday',
          isSelected: false,
        },
        {
          id: 3,
          Name: 'Thursday',
          isSelected: false,
        },
        {
          id: 4,
          Name: 'Friday',
          isSelected: false,
        },
        {
          id: 5,
          Name: 'Saturday',
          isSelected: false,
        },
        {
          id: 6,
          Name: 'Sunday',
          isSelected: false,
        },
      ],
      fourheucmddeb: '',
      fourheucmdfin: '',
      delaiShip: '',
      comment: '',
      limcmd: undefined
    };
    this._supplierDetail = DetailSupplier;
    this.EmitterSupplierDetailSubject();
  }

  public CreateNewSuppliser(pNewSuppliser: SupplierDetailModel) {

  }

  public GetDetailSupplier(pIdSupplier: string) {

    let urlAPI = this._urlBaseAPI + this._urlGetDetailSupplierAPI;

    this._httpClient.get<SupplierModel>(urlAPI + pIdSupplier).subscribe(
      (response: SupplierModel) => {
        console.log('four selected service response', response)

        this._supplierDetail = {
          fouriu: response.IU_FOURNISSEUR,
          fournom: response.NOM_FOURNISSEUR,
          fourcode: response.CODE_FOURNISSEUR,
          fourinactif: response.FLAG_INACTIF,
          fournoclient: response.NUM_CLIENT,
          fourconctnom: response.NOM_CONTACT,
          fourconcttel: response.TELEPHONE_C,
          fouradr1: response.AD1,
          fouradr2: response.AD2,
          fourcp: response.CODPOST,
          fourcity: response.LIBVIL,
          fourtel: response.TELEPHONE_F,
          fourfax: response.FAX,
          fourmail: response.E_MAIL,
          foururl: response.URL_WEB,
          fourlogo: response.LOGO_64,
          ShipDay: [
            {
              id: 0,
              Name: 'Monday',
              isSelected: response.JOURS_LIVRAISON ? (response.JOURS_LIVRAISON.split('')[0] == '1' ? true : false) : false
            },
            {
              id: 1,
              Name: 'Tuesday',
              isSelected: response.JOURS_LIVRAISON ? (response.JOURS_LIVRAISON.split('')[1] == '1' ? true : false) : false
            },
            {
              id: 2,
              Name: 'Wednesday',
              isSelected: response.JOURS_LIVRAISON ? (response.JOURS_LIVRAISON.split('')[2] == '1' ? true : false) : false
            },
            {
              id: 3,
              Name: 'Thursday',
              isSelected: response.JOURS_LIVRAISON ? (response.JOURS_LIVRAISON.split('')[3] == '1' ? true : false) : false
            },
            {
              id: 4,
              Name: 'Friday',
              isSelected: response.JOURS_LIVRAISON ? (response.JOURS_LIVRAISON.split('')[4] == '1' ? true : false) : false
            },
            {
              id: 5,
              Name: 'Saturday',
              isSelected: response.JOURS_LIVRAISON ? (response.JOURS_LIVRAISON.split('')[5] == '1' ? true : false) : false
            },
            {
              id: 6,
              Name: 'Sunday',
              isSelected: response.JOURS_LIVRAISON ? (response.JOURS_LIVRAISON.split('')[6] == '1' ? true : false) : false
            },
          ],
          ShipCmd: [
            {
              id: 0,
              Name: 'Monday',
              isSelected: response.JOURS_COMMANDE ? (response.JOURS_COMMANDE.split('')[0] == '1' ? true : false) : false
            },
            {
              id: 1,
              Name: 'Tuesday',
              isSelected: response.JOURS_COMMANDE ? (response.JOURS_COMMANDE.split('')[1] == '1' ? true : false) : false
            },
            {
              id: 2,
              Name: 'Wednesday',
              isSelected: response.JOURS_COMMANDE ? (response.JOURS_COMMANDE.split('')[2] == '1' ? true : false) : false
            },
            {
              id: 3,
              Name: 'Thursday',
              isSelected: response.JOURS_COMMANDE ? (response.JOURS_COMMANDE.split('')[3] == '1' ? true : false) : false
            },
            {
              id: 4,
              Name: 'Friday',
              isSelected: response.JOURS_COMMANDE ? (response.JOURS_COMMANDE.split('')[4] == '1' ? true : false) : false
            },
            {
              id: 5,
              Name: 'Saturday',
              isSelected: response.JOURS_COMMANDE ? (response.JOURS_COMMANDE.split('')[5] == '1' ? true : false) : false
            },
            {
              id: 6,
              Name: 'Sunday',
              isSelected: response.JOURS_COMMANDE ? (response.JOURS_COMMANDE.split('')[6] == '1' ? true : false) : false
            },
          ],
          fourheucmddeb: response.HEURE_DEB ? response.HEURE_DEB.substring(0, 2) + ":" + response.HEURE_DEB.substring(2) : '',
          fourheucmdfin: response.HEURE_FIN ? response.HEURE_FIN.substring(0, 2) + ":" + response.HEURE_FIN.substring(2) : '',
          delaiShip: response.DELAIS_LIVRE ? response.DELAIS_LIVRE : '',
          comment: response.COMMENTAIRE,
          limcmd: response.LIM_CMD
        };

        this.EmitterSupplierDetailSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetDirectorySupplier() {

    let urlAPI = this._urlBaseAPI + this._urlGetSuppliersAPI;

    this._httpClient.get<SupplierModel[]>(urlAPI).subscribe(
      (response: SupplierModel[]) => {
        this._listSupplier = response;
        // console.log('LISTE SUPPLIER ', response)
        let DirSupplier: SupplierDetailModel[] = [];
        this._listSupplier.forEach(x => {
          let DetailSupplier: SupplierDetailModel = {
            fouriu: x.IU_FOURNISSEUR,
            fournom: x.NOM_FOURNISSEUR,
            fourcode: x.CODE_FOURNISSEUR,
            fourinactif: x.FLAG_INACTIF,
            fournoclient: x.NUM_CLIENT,
            fourconctnom: x.NOM_CONTACT,
            fourconcttel: x.TELEPHONE_C,
            fouradr1: x.AD1,
            fouradr2: x.AD2,
            fourcp: x.CODPOST,
            fourcity: x.CODPOST,
            fourtel: x.TELEPHONE_F,
            fourfax: x.FAX,
            fourmail: x.E_MAIL,
            foururl: x.URL_WEB,
            fourlogo: x.LOGO_64,
            ShipDay: [
              {
                id: 0,
                Name: 'Monday',
                isSelected: x.JOURS_LIVRAISON ? (x.JOURS_LIVRAISON.split('')[0] == '1' ? true : false) : false
              },
              {
                id: 1,
                Name: 'Tuesday',
                isSelected: x.JOURS_LIVRAISON ? (x.JOURS_LIVRAISON.split('')[1] == '1' ? true : false) : false,
              },
              {
                id: 2,
                Name: 'Wednesday',
                isSelected: x.JOURS_LIVRAISON ? (x.JOURS_LIVRAISON.split('')[2] == '1' ? true : false) : false,
              },
              {
                id: 3,
                Name: 'Thursday',
                isSelected: x.JOURS_LIVRAISON ? (x.JOURS_LIVRAISON.split('')[3] == '1' ? true : false) : false,
              },
              {
                id: 4,
                Name: 'Friday',
                isSelected: x.JOURS_LIVRAISON ? (x.JOURS_LIVRAISON.split('')[4] == '1' ? true : false) : false,
              },
              {
                id: 5,
                Name: 'Saturday',
                isSelected: x.JOURS_LIVRAISON ? (x.JOURS_LIVRAISON.split('')[5] == '1' ? true : false) : false,
              },
              {
                id: 6,
                Name: 'Sunday',
                isSelected: x.JOURS_LIVRAISON ? (x.JOURS_LIVRAISON.split('')[6] == '1' ? true : false) : false,
              },
            ],
            ShipCmd: [
              {
                id: 0,
                Name: 'Monday',
                isSelected: x.JOURS_COMMANDE ? (x.JOURS_COMMANDE.split('')[0] == '1' ? true : false) : false
              },
              {
                id: 1,
                Name: 'Tuesday',
                isSelected: x.JOURS_COMMANDE ? (x.JOURS_COMMANDE.split('')[1] == '1' ? true : false) : false
              },
              {
                id: 2,
                Name: 'Wednesday',
                isSelected: x.JOURS_COMMANDE ? (x.JOURS_COMMANDE.split('')[2] == '1' ? true : false) : false
              },
              {
                id: 3,
                Name: 'Thursday',
                isSelected: x.JOURS_COMMANDE ? (x.JOURS_COMMANDE.split('')[3] == '1' ? true : false) : false
              },
              {
                id: 4,
                Name: 'Friday',
                isSelected: x.JOURS_COMMANDE ? (x.JOURS_COMMANDE.split('')[4] == '1' ? true : false) : false
              },
              {
                id: 5,
                Name: 'Saturday',
                isSelected: x.JOURS_COMMANDE ? (x.JOURS_COMMANDE.split('')[5] == '1' ? true : false) : false
              },
              {
                id: 6,
                Name: 'Sunday',
                isSelected: x.JOURS_COMMANDE ? (x.JOURS_COMMANDE.split('')[6] == '1' ? true : false) : false
              },
            ],
            fourheucmddeb: x.HEURE_DEB ? x.HEURE_DEB.substring(0, 2) + ":" + x.HEURE_DEB.substring(2) : '',
            fourheucmdfin: x.HEURE_FIN ? x.HEURE_FIN.substring(0, 2) + ":" + x.HEURE_FIN.substring(2) : '',
            delaiShip: x.DELAIS_LIVRE ? x.DELAIS_LIVRE : '',
            comment: x.COMMENTAIRE,
            limcmd: x.LIM_CMD
          };
          DirSupplier = [...DirSupplier, DetailSupplier]
        });
        this._listSupplierDir = DirSupplier;
        this.EmitterSupplierDirSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );


  }

  public SaveEditSupplier(pSuppliderValide: SupplierDetailModel) {

    let SaveFourEditURI = this._urlBaseAPI + this._urlPostSaveEditFournisseur;

    let shipDay = "";

    let cmdDay = "";

    pSuppliderValide.ShipDay.sort((a, b) => { return a.id - b.id }).forEach(x => {
      if (x.isSelected) {
        shipDay += "1";
      }
      else {
        shipDay += "0";
      }
    });

    pSuppliderValide.ShipCmd.sort((a, b) => { return a.id - b.id }).forEach(x => {
      if (x.isSelected) {
        cmdDay += "1";
      }
      else {
        cmdDay += "0";
      }
    });

    let EditFou: SupplierModel = {
      AD1: pSuppliderValide.fouradr1,
      AD2: pSuppliderValide.fouradr2,
      CODE_FOURNISSEUR: pSuppliderValide.fourcode,
      CODPOST: pSuppliderValide.fourcp,
      DATHRMAJ: '',
      DELAIS_LIVRE: pSuppliderValide.delaiShip,
      E_MAIL: pSuppliderValide.fourmail,
      URL_WEB: pSuppliderValide.foururl,
      LOGO_64: pSuppliderValide.fourlogo,
      FAX: pSuppliderValide.fourfax,
      FLAG_INACTIF: pSuppliderValide.fourinactif,
      GESTDEL: '',
      HEURE_DEB: pSuppliderValide.fourheucmddeb.replace(":", ""),
      HEURE_FIN: pSuppliderValide.fourheucmdfin.replace(":", ""),
      IU_EXT: '',
      IU_FOURNISSEUR: pSuppliderValide.fouriu,
      JOURS_COMMANDE: cmdDay,
      JOURS_LIVRAISON: shipDay,
      LIBVIL: pSuppliderValide.fourcity,
      NOM_CONTACT: pSuppliderValide.fourconctnom,
      NOM_FOURNISSEUR: pSuppliderValide.fournom,
      NUM_CLIENT: pSuppliderValide.fournoclient,
      PRN_CONTACT: pSuppliderValide.fourconctnom,
      TARIF_COMPARE: '',
      TELEPHONE_C: pSuppliderValide.fourconcttel,
      TELEPHONE_F: pSuppliderValide.fourtel,
      USERMAJ: '',
      COMMENTAIRE: pSuppliderValide.comment,
      LIM_CMD: pSuppliderValide.limcmd
    };

    try {
      return this._httpClient.post(SaveFourEditURI, EditFou,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
        .pipe(
          map(
            (response) => {

              if (response.status == 200) {
                let FouReturn: SupplierModel = ((response.body) as any);
                return FouReturn;
              }
              return undefined;
            },
            (error) => {
              return undefined;
            }
          ),
          catchError(
            (_error) => {
              return Observable.of(undefined);
            })
        );
    } catch (error) {
      //console.log(error);
      return Observable.of(undefined);
    }
  }
  public SaveLogoSupplier(pSuppliderValide: SupplierDetailModel) {

    let SaveFourEditURI = this._urlBaseAPI + this._urlPostSaveLogoFournisseur;

    let shipDay = "";

    let cmdDay = "";

    pSuppliderValide.ShipDay.sort((a, b) => { return a.id - b.id }).forEach(x => {
      if (x.isSelected) {
        shipDay += "1";
      }
      else {
        shipDay += "0";
      }
    });

    pSuppliderValide.ShipCmd.sort((a, b) => { return a.id - b.id }).forEach(x => {
      if (x.isSelected) {
        cmdDay += "1";
      }
      else {
        cmdDay += "0";
      }
    });

    let EditFou: SupplierModel = {
      AD1: pSuppliderValide.fouradr1,
      AD2: pSuppliderValide.fouradr2,
      CODE_FOURNISSEUR: pSuppliderValide.fourcode,
      CODPOST: pSuppliderValide.fourcp,
      DATHRMAJ: '',
      DELAIS_LIVRE: pSuppliderValide.delaiShip,
      E_MAIL: pSuppliderValide.fourmail,
      URL_WEB: pSuppliderValide.foururl,
      LOGO_64: pSuppliderValide.fourlogo,
      FAX: pSuppliderValide.fourfax,
      FLAG_INACTIF: pSuppliderValide.fourinactif,
      GESTDEL: '',
      HEURE_DEB: pSuppliderValide.fourheucmddeb.replace(":", ""),
      HEURE_FIN: pSuppliderValide.fourheucmdfin.replace(":", ""),
      IU_EXT: '',
      IU_FOURNISSEUR: pSuppliderValide.fouriu,
      JOURS_COMMANDE: cmdDay,
      JOURS_LIVRAISON: shipDay,
      LIBVIL: pSuppliderValide.fourcity,
      NOM_CONTACT: pSuppliderValide.fourconctnom,
      NOM_FOURNISSEUR: pSuppliderValide.fournom,
      NUM_CLIENT: pSuppliderValide.fournoclient,
      PRN_CONTACT: pSuppliderValide.fourconctnom,
      TARIF_COMPARE: '',
      TELEPHONE_C: pSuppliderValide.fourconcttel,
      TELEPHONE_F: pSuppliderValide.fourtel,
      USERMAJ: '',
      COMMENTAIRE: pSuppliderValide.comment,
      LIM_CMD: pSuppliderValide.limcmd
    };

    try {
      return this._httpClient.post(SaveFourEditURI, EditFou,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
        .pipe(
          map(
            (response) => {
              console.log('retour ', response)
              if (response.status == 200) {
                return response.status;
                // TODO check if it's the right return
                // let FouReturn: SupplierModel = ((response.body) as any);
                // return FouReturn;
              }
              return undefined;
            },
            (error) => {
              return undefined;
            }
          ),
          catchError(
            (_error) => {
              console.log('service ', _error);
              return Observable.of(undefined);
            })
        );
    } catch (error) {
      console.log('service ', error);
      return Observable.of(undefined);
    }
  }

  public DeleteSupplier(pIuFou: string) {

    let URI = this._urlBaseAPI + this._urlPostDeleteSupplier;

    try {
      return this._httpClient.post(URI, JSON.stringify(pIuFou),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
        .pipe(
          map(
            (response) => {

              if (response.status == 200) {
                return response.status;
              }
              return response.status;
            },
            (error) => {
              return 404;
            }
          ),
          catchError(
            (_error) => {
              return Observable.of(_error);
            })
        );
    } catch (error) {
      //console.log(error);
      return Observable.of(404);
    }
  }

  public SearchFournisseur(filter: { name: string } = { name: '' }, page = 1): Observable<IFourSearchResponse> {

    let urlAPI = this._urlBaseAPI + this._urlSearchFournisseur + filter.name;

    if (filter.name.length < 3) {
      return Observable.of({
        total: 0,
        results: []
      });
    }

    return this._httpClient.get<IFourSearchResponse>(urlAPI)
      .pipe(
        tap((response: IFourSearchResponse) => {
          response.results = response.results.
            map(fou => new SearchFournisseurModel(fou.IU_FOURNISSEUR, fou.CODE_FOURNISSEUR, fou.NOM_FOURNISSEUR));
          return response;
        })
      );
  }


  public GetListTarifSupplier(pIufou: string) {

    let urlAPI = this._urlBaseAPI + this._urlGetAllCatalogTarifFou + pIufou;

    this._httpClient.get<VUE_TARIF_CATALOG_FOUR[]>(urlAPI).pipe(
      tap((res: VUE_TARIF_CATALOG_FOUR[]) => {
        res.map((x: VUE_TARIF_CATALOG_FOUR) => {
          if (x.DATE_DEB) {
            x.DATE_DEB = moment(x.DATE_DEB)
              .local(false)
              .utc(true)
              .format('DD/MM/YYYY');
          } // TODO check condition date avec espace
          if (x.DATE_FIN) {
            x.DATE_FIN = moment(x.DATE_FIN)
              .local(false)
              .utc(true)
              .format('DD/MM/YYYY');
          }
          if (x.CATALOG_ACTIF == '1') {
            x.IsCheck = true;
          }
          else {
            x.IsCheck = false;
          }
          x.IsEdit = false;
          x.CATALOG_PRIX_EUROS = x.CATALOG_PRIX_EUROS ? Number(x.CATALOG_PRIX_EUROS).toString() : x.CATALOG_PRIX_EUROS;
        });
        return res;
      })
    ).subscribe(
      (response: VUE_TARIF_CATALOG_FOUR[]) => {
        this._listTarifCatalogFou = response;
        this.EmitterListTarifSupplierSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public SaveEditTarifCatalogFournisseur(pTarifCatalog: VUE_TARIF_CATALOG_FOUR[]) {

    let SaveTarifCatalogFourEditURI = this._urlBaseAPI + this._urlPostSaveEditAllCatalogTarifFou;

    try {
      return this._httpClient.post(SaveTarifCatalogFourEditURI, pTarifCatalog,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
        .pipe(
          map(
            (response) => {

              return response.status;
            },
            (error) => {
              return 404;
            }
          ),
          catchError(
            (_error) => {
              return Observable.of(404);
            })
        );
    } catch (error) {
      //console.log(error);
      return Observable.of(404);
    }
  }



}
