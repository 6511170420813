<div class="row">
  <div class="col-md-12 mt-4">
    <div class="container-fluid p-0">
      <!----first row------------------------>
      <div class="row align-items-center mb-2">
        <div class="col-md-6" *ngIf="IsDisplayPannelOption">
          <div class="row">
            <div class="col-md-4">
              <label class="font-weight-700 text-nowrap">{{
                "StorageArea" | translate
                }}</label>
              <mat-form-field class="input-size-select" appearance="fill">
                <mat-select [(ngModel)]="ZoneStockSelected" required (selectionChange)="onZoneStockChange($event)">
                  <mat-option [value]="item" *ngFor="let item of ZoneStock" class="font-size-select">
                    {{ item.LIB_ZONE_STOCK }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="font-weight-700">{{ "Until" | translate }}</label>
              <mat-form-field class="input-size-select" appearance="fill">
                <input class="no-padding-left" [value]="InventoryDate" [(ngModel)]="InventoryDate" matInput
                  [matDatepicker]="pickerDateAu" (dateChange)="ChangeDateSelected('change', $event)"
                  placeholder="dd/mm/yyyy" />
                <mat-datepicker-toggle matSuffix [for]="pickerDateAu"></mat-datepicker-toggle>
                <mat-datepicker #pickerDateAu></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="font-weight-700">{{ "FilterBy" | translate }}</label>
              <mat-form-field class="input-size-select" appearance="fill">
                <mat-select [(ngModel)]="FilterSelected" required (selectionChange)="onFiltrePar($event)">
                  <mat-option [value]="item" *ngFor="let item of ListFilterStock" class="font-size-select">
                    {{ item.ValueSelect }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row align-items-center">
            <div class="col-md-7">
              <form class="search-art" [formGroup]="ArticleSearchForm" (ngSubmit)="onSubmitRechercherArticle()"
                *ngIf="IsDisplayPannelOption">
                <div style="margin-top: 18px"></div>
                <mat-form-field class="input-size-select border-radius-search-bar" appearance="fill">
                  <input class="ms-2" matInput placeholder="Rechercher un article" value=""
                    formControlName="ArticleSearchInput" />
                  <mat-icon matPrefix>
                    <img class="ms-2 mb-2 image-mat-icon" src="../../../../../assets/images/zoom.svg" alt="icon" />
                  </mat-icon>
                </mat-form-field>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnCmd" (optionSelected)="
                ClickOptionSearchArticle($event, $event.option.value)
              ">
                  <mat-option *ngIf="IsLoadingSearchArt" class="is-loading">
                    <mat-spinner class="mat-loader" color="warn" diameter="20" mode="indeterminate"
                      style="margin: auto">
                    </mat-spinner>
                  </mat-option>
                </mat-autocomplete>
              </form>
            </div>
            <div class="col-md-5">
              <div class="d-flex justify-content-end">
                <button class="button-action-color-blue" mat-button [matMenuTriggerFor]="menu">
                  {{ "Actions" | translate }}
                  <i class="fas fa-chevron-down"></i>
                </button>
                <mat-menu #menu="matMenu">
                  <!-- TODO   Minh : connecter les actions suivantes -->
                  <!-- <button mat-menu-item>{{ "Valorization" | translate }}</button> -->
                  <button mat-menu-item (click)="ExportEtatExcelStock($event)" type="button">
                    {{ "ExcelExport" | translate }}
                  </button>
                </mat-menu>
                <span class="btn-space"></span>
                <button class="button-round round-button-icon" matTooltip="Print PDF" matTooltipPosition="above"
                  mat-mini-fab (click)="ExportEtatPdfStock($event)" type="button">
                  <img class="width-30" src="../../../../assets/images/icons-commande/export-pdf-bleu.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!----end first row------------------------>
      <!-- <div class="row align-items-center mb-3">
        <div class="col-md-3">
          <form class="search-art" [formGroup]="ArticleSearchForm" (ngSubmit)="onSubmitRechercherArticle()"
            *ngIf="IsDisplayPannelOption">
            <div style="margin-top: 18px"></div>
            <mat-form-field class="input-size-select border-radius-search-bar" appearance="fill">
              <input class="ms-2" matInput placeholder="Rechercher un article" value=""
                formControlName="ArticleSearchInput" />
              <mat-icon matPrefix>
                <img class="ms-2 mb-2 image-mat-icon" src="../../../../../assets/images/zoom.svg" alt="icon" />
              </mat-icon>
            </mat-form-field>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnCmd" (optionSelected)="
                ClickOptionSearchArticle($event, $event.option.value)
              ">
              <mat-option *ngIf="IsLoadingSearchArt" class="is-loading">
                <mat-spinner class="mat-loader" color="warn" diameter="20" mode="indeterminate" style="margin: auto">
                </mat-spinner>
              </mat-option>
            </mat-autocomplete>
          </form>
        </div>
        <div class="col-md-9">
          <div class="float-end">
            <button class="button-action-color-blue" mat-button [matMenuTriggerFor]="menu">
              {{ "Actions" | translate }}
              <i class="fas fa-chevron-down"></i>
            </button>
            <mat-menu #menu="matMenu">
    
              <button mat-menu-item (click)="ExportEtatExcelStock($event)" type="button">
                {{ "ExcelExport" | translate }}
              </button>
            </mat-menu>
            <span class="btn-space"></span>
            <button class="button-round round-button-icon" matTooltip="Print PDF" matTooltipPosition="above"
              mat-mini-fab (click)="ExportEtatPdfStock($event)" type="button">
              <img class="width-30" src="../../../../assets/images/icons-commande/export-pdf-bleu.svg" />
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <cdk-virtual-scroll-viewport tvsItemSize class="wrapper height-550">
          <table class="table width-100-percent no-border-table" mat-table
            [dataSource]="dataSourceListEtatStockArticles" matSort>
            <ng-container matColumnDef="Mouvement">
              <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                mat-sort-header sortActionDescription="Sort by number">
                {{ "Movement" | translate }}
              </th>
              <td mat-cell *matCellDef="let element" class="text-center border-col-table">
                <span (click)="
                    ConsultMouvementDetail(
                      element.CODE_PR,
                      element.NUM_LOT,
                      element.DATE_LIMITE
                    )
                  " class="art-eye-detail-open">
                  <img class="width-20" src="../../../../../assets/images/icons-commande/eye-blue-87.svg" />
                  {{ element.CODE_PR }}
                </span>
              </td>
            </ng-container>
            <!-- <ng-container matColumnDef="CODE_PR">
              <th
                class="thead-dark font-size-th-list-command width-auto"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                {{ "Code" | translate }}
              </th>
              <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                {{ element.CODE_PR }}
              </td>
            </ng-container> -->

            <ng-container matColumnDef="NOM_PR">
              <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                mat-sort-header>
                {{ "Article" | translate }}
              </th>
              <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                <div class="text-start ms-2">
                  {{ element.NOM_PR }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="STOCK_REEL">
              <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                mat-sort-header>
                {{ "Stock" | translate }}
              </th>
              <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                {{ element.STOCK_REEL }}
              </td>
            </ng-container>

            <ng-container matColumnDef="US">
              <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                mat-sort-header>
                US
              </th>
              <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                {{ element.US }}
              </td>
            </ng-container>

            <ng-container matColumnDef="PRIX_VALO">
              <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                mat-sort-header>
                PU €
              </th>
              <td class="text-end border-col-table" mat-cell *matCellDef="let element">
                <div class="me-2">
                  {{ element.PRIX_VALO }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="PMP_VALO">
              <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                mat-sort-header>
                PMP
              </th>
              <td class="text-end border-col-table" mat-cell *matCellDef="let element">
                <div class="me-2">
                  {{ element.PMP_VALO }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="NUM_LOT">
              <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                mat-sort-header>
                {{ "LotNumber" | translate }}
              </th>
              <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                {{ element.NUM_LOT }}
              </td>
            </ng-container>

            <ng-container matColumnDef="DATE_LIMITE">
              <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                mat-sort-header>
                {{ "UseByDate" | translate }}
              </th>
              <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                <span [ngClass]="
                    element.DlcState == 'G'
                      ? 'dlc-green'
                      : element.DlcState == 'O'
                      ? 'dlc-orange'
                      : element.DlcState == 'R'
                      ? 'dlc-red'
                      : ''
                  ">{{ element.DATE_LIMITE }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="TotalHT">
              <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                mat-sort-header>
                {{ "TotalExclTax" | translate }} €
              </th>
              <td class="text-end border-col-table" mat-cell *matCellDef="let element">
                <div class="me-2">
                  {{ element.TotalHT | currency: "EUR":"symbol":"1.2-2" }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="TotalTTC">
              <th class="thead-dark font-size-th-list-command width-auto no-padding-right" mat-header-cell
                *matHeaderCellDef mat-sort-header>
                {{ "TotalInclTax" | translate }} €
              </th>
              <td class="text-end border-col-table" mat-cell *matCellDef="let element">
                <div class="me-2">
                  {{ element.TotalTTC | currency: "EUR":"symbol":"1.2-2" }}
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{ 'edit-row-tab': row.IsEdit === true }"></tr>
          </table>
          <div *ngIf="!IsLoaded">
            <ng-container *ngFor="let number of [].constructor(8)">
              <div class="row">
                <div class="col">
                  <content-loader [Height]="50"></content-loader>
                  <br />
                </div>
              </div>
            </ng-container>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
    <!-- total row -->
    <div class="row my-3 justify-content-end">
      <div class="col-md-3 ">
        <div class="row-total-global-command display-table-row ">
          <span class="font-weight-700 display-table-cell ">{{ "TotalExclTax" | translate }} :
          </span>
          <span class="float-end font-weight-700 display-table-cell">{{
            CalculTotalMontalHT() | currency: "EUR":"symbol":"1.2-2"
            }}</span>
        </div>
        <div class="odd-row-total-global-command display-table-row ">
          <span class="font-weight-700 display-table-cell ">{{ "TotalInclTax" | translate }} :
          </span>
          <span class="float-end font-weight-700 display-table-cell">{{
            CalculTotalMontalTTC() | currency: "EUR":"symbol":"1.2-2"
            }}</span>
        </div>

      </div>
    </div>
  </div>

  <!-- <div class="container-fluid p-0 mt-2">
        <div class="row">
          <div class="col-md-12">
            <div class="float-end button-footer-form">
              <button (click)="onSave()" mat-stroked-button class="
                  button-size button-footer-form button-generic-validate
                  mt-2
                " type="submit">
                {{ "Save" | translate }}
              </button>
              <span class="btn-space"></span>
              <button mat-stroked-button class="
                  button-size button-footer-form button-generic-cancel
                  mt-2
                " type="button">
                {{ "Cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div> -->
</div>

<div *ngIf="IsDisplayResult">
  <pre [innerHTML]="Name | printjson"></pre>
  <pre [innerHTML]="StockAreaSelected | printjson"></pre>
  <pre [innerHTML]="InventoryDate | printjson"></pre>
  <pre [innerHTML]="TypeSelected | printjson"></pre>
</div>
<Spinner-Loader class="spinner-loader-custom" [color]="colorLoader" [mode]="modeLoader" [value]="valueLoader"
  [msg]="'Veuillez patienter, nous recalculons l\'état de stock'" [backdropEnabled]="true"
  [positionGloballyCenter]="true" [displayProgressSpinner]="displayProgressSpinner">
</Spinner-Loader>