import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { CommandesService } from '../../../core/services/member/commandes/commandes.service';


export class PdfCmdData {

  constructor(public NumCmd: string, public PdfSrcByte: any) {
  }
}

@Component({
  selector: 'PdfViewCommandeComponent',
  templateUrl: './pdf-view-commande.component.html',
  styleUrls: ['./pdf-view-commande.component.css']
})
export class PdfViewCommandeComponent implements OnInit {

  public page = 1;

  public pageLabel!: string;


  private durationNotification: number = 5000;
  private _horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private _verticalPosition: MatSnackBarVerticalPosition = 'top';



  constructor(public dialogRef: MatDialogRef<PdfViewCommandeComponent>,
    @Inject(MAT_DIALOG_DATA) public pdfCmd: PdfCmdData,
    private _commandesService: CommandesService,
    private _snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {

  }


  public DownloadPDF() {
    this._commandesService.GenererPDFCommande(undefined).subscribe(
      (responseCmdValide: any) => {
        if (responseCmdValide) {
          const newBlob = new Blob([responseCmdValide], { type: 'application/pdf' });
          const data = window.URL.createObjectURL(newBlob);

          const link = document.createElement('a');
          link.href = data;
          link.download = 'cmd.pdf';
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );

          setTimeout(function () {
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 0);
        }
        else {
          this.NotificationMsg("Error internal", false);
        }
      }
    );
  }

  public print() {
    const fl = URL.createObjectURL(new Blob([this.pdfCmd.PdfSrcByte], { type: 'application/pdf' }));
    window.open(fl).print();

  }



  public NotificationMsg(msg: string, isOk: boolean) {
    if (isOk) {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-success']
      });
    }
    else {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-error']
      });
    }

  }


}
