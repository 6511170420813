import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from '../../../../config';
import { EnvService } from "../../environment/env.service";
import { catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StatsEgalimModel } from "../../../model/member/statistiques/egalim/stats-egalim.model";
import { StatHeaderRecept } from "../../../model/member/statistiques/reception/stat-header-reception.model";

@Injectable()
export class StatsService {
    // url api
    private _urlBaseAPI: string;

    private _urlStatEgalimArtRecep: string;
    private _urlExportStatsEgalim: string;

    private _urlPostGenerePdfListHeaderReception;


    constructor(private _httpClient: HttpClient, private env: EnvService) {
        this._urlBaseAPI = env.API_URL_BASE;
        this._urlStatEgalimArtRecep = Config.API_ROUTES.STATISTIQUES_API.API_POST_STAT_EGALIM_ART_RECEP;
        this._urlExportStatsEgalim = Config.API_ROUTES.STATISTIQUES_API.API_POST_STAT_EXPORT_EGALIM_ART_RECEP;
        this._urlPostGenerePdfListHeaderReception = Config.API_ROUTES.STATISTIQUES_API.API_POST_PDF_HEADER_RECEP;
    }


    public PostStatistiqueEgalimArtReceptione(pFilter: StatsEgalimModel) {

        let urlAPI = this._urlBaseAPI + this._urlStatEgalimArtRecep;

        try {
            return this._httpClient.post(urlAPI, pFilter,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let statEgalimArtRecepReturn: StatsEgalimModel[] = ((response.body) as any);
                                return statEgalimArtRecepReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public ExportStatEgalim(pFilter: StatsEgalimModel) {

        let URI = this._urlBaseAPI + this._urlExportStatsEgalim;

        try {
            return this._httpClient.post(URI, pFilter,
                {
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public GenerePdfListHeaderReception(pCmdHeader: StatHeaderRecept) {
        let URI = this._urlBaseAPI + this._urlPostGenerePdfListHeaderReception;

        try {
            return this._httpClient.post(URI, pCmdHeader,
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                //let CmdReturn: CmdGesPrecoModel = ((response.body) as any);
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }


}