<mat-form-field appearance="{{ appearance }}">
    <mat-select #selectElem [placeholder]="placeholder" [formControl]="formControl" [multiple]="multiple"
        (selectionChange)="onSelectionChange($event)">
        <div class="box-search">
            <!-- <mat-checkbox *ngIf="multiple" color="primary" class="box-select-all" [(ngModel)]="selectAllChecked"
                (change)="toggleSelectAll($event)"></mat-checkbox> -->
            <input #searchInput type="text" [ngClass]="{ 'pl-1': !multiple }" (input)="filterItem(searchInput.value)"
                [placeholder]="selectPlaceholder" />
            <div class="box-search-icon" (click)="filterItem(''); searchInput.value = ''">
                <button mat-icon-button class="search-button">
                    <mat-icon class="mat-24" aria-label="Search icon">clear</mat-icon>
                </button>
            </div>
        </div>
        <!-- <mat-select-trigger>
            {{ onDisplayString() }}
        </mat-select-trigger> -->
        <mat-option *ngFor="let option of options; trackBy: trackByFn" [disabled]="option.disabled"
            [value]="option[value]" [style.display]="hideOption(option) ? 'none' : 'flex'">{{ option[display] }}
        </mat-option>
    </mat-select>
    <mat-hint style="color:red" *ngIf="showErrorMsg">{{ errorMsg }}</mat-hint>
</mat-form-field>