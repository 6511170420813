import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/of';
import * as _ from 'lodash-es';
import { EnvService } from '../../environment/env.service';
import { Config } from '../../../../config';
import { catchError, map } from 'rxjs/operators';
import { UserClient } from '../../../model/member/param-app/user-client.model';
import { ProfilClient } from '../../../model/member/param-app/profil-app.model';
import { InterfaceAppModel } from '../../../model/member/param-app/interface-app.model';
import { InterfaceAccesModel } from '../../../model/member/param-app/interface-acces.model';

@Injectable()
export class ParamAppService {
  public ListLeftMenuSubject = new Subject<InterfaceAccesModel[]>();
  private _ListLeftMenu: InterfaceAccesModel[] = [];

  // url api
  private _urlBaseAPI: string;

  private _urlGetListUsersActif: string;

  private _urlGetListProfilClient: string;

  private _urlPostGenerateUserIdClient: string;

  private _urlPostSaveUserInfo: string;

  private _urlPostDeleteUser: string;

  private _urlPostInterfaceMenuByProfil: string;

  private _urlGetInterfaceMenuAcces: string;

  private _urlPostSaveProfilApp: string;

  private _urlPostDeleteProfilApp: string;

  private _urlPostSaveAccessUserApp: string;

  private _urlPostSaveMenuApp: string;

  constructor(private _httpClient: HttpClient, private env: EnvService) {
    this._urlBaseAPI = env.API_URL_BASE;
    this._urlGetListUsersActif =
      Config.API_ROUTES.PARAM_APP_API.API_GET_ALL_USERS_ACTIF;
    this._urlGetListProfilClient =
      Config.API_ROUTES.PARAM_APP_API.API_GET_ALL_PROFIL_USER;
    this._urlPostGenerateUserIdClient =
      Config.API_ROUTES.PARAM_APP_API.API_POST_GENERATE_USER_ID;
    this._urlPostSaveUserInfo =
      Config.API_ROUTES.PARAM_APP_API.API_POST_SAVE_USER_INFO;
    this._urlPostDeleteUser =
      Config.API_ROUTES.PARAM_APP_API.API_POST_DELETE_USER;
    this._urlPostInterfaceMenuByProfil =
      Config.API_ROUTES.PARAM_APP_API.API_POST_INTERFACE_MENU_BY_PROFIL_APP;
    this._urlGetInterfaceMenuAcces =
      Config.API_ROUTES.PARAM_APP_API.API_GET_INTERFACE_MENU_ACCES;
    this._urlPostSaveProfilApp =
      Config.API_ROUTES.PARAM_APP_API.API_POST_SAVE_PROFIL_APP;
    this._urlPostDeleteProfilApp =
      Config.API_ROUTES.PARAM_APP_API.API_POST_DELETE_PROFIL_APP;
    this._urlPostSaveAccessUserApp =
      Config.API_ROUTES.PARAM_APP_API.API_POST_SAVE_ACCESS_USER_APP;

    this._urlPostSaveMenuApp =
      Config.API_ROUTES.PARAM_APP_API.API_POST_SAVE_MENU_APP;
  }

  public EmitterListLeftMenu() {
    if (this._ListLeftMenu != null) {
      this.ListLeftMenuSubject.next(this._ListLeftMenu.slice());
    }
  }
  //get modification rights depending on the InterfaceAccesModel(IsUpdated?)
  public GetDroitByInf(monInf: string) {
    return this._ListLeftMenu.find((p) => p.INF_Id == monInf)?.IsUpdated;
  }

  public GetListUserActifSync() {
    let urlAPI = this._urlBaseAPI + this._urlGetListUsersActif;

    return this._httpClient.get(urlAPI).pipe(
      map((res: UserClient[]) => {
        return res;
      })
    );
  }

  public GetListProfilClientSync() {
    let urlAPI = this._urlBaseAPI + this._urlGetListProfilClient;

    return this._httpClient.get(urlAPI).pipe(
      map((res: ProfilClient[]) => {
        return res;
      })
    );
  }

  public GenerateUserIdFromName(pNameUser: string) {
    let URI = this._urlBaseAPI + this._urlPostGenerateUserIdClient;

    try {
      return this._httpClient
        .post(URI, JSON.stringify(pNameUser), {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
          responseType: 'text',
        })
        .pipe(
          map(
            (response) => {
              if (response.status == 200) {
                return response.body;
              }
              return 'KO';
            },
            (error) => {
              return 'KO';
            }
          ),
          catchError((_error) => {
            return Observable.of('KO');
          })
        );
    } catch (error) {
      //console.log(error);
      return Observable.of('KO');
    }
  }

  public SaveEditUserInfo(pUser: UserClient): Observable<UserClient> {
    let SaveUserEditURI = this._urlBaseAPI + this._urlPostSaveUserInfo;

    try {
      return this._httpClient
        .post(SaveUserEditURI, pUser, {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
        })
        .pipe(
          map(
            (response) => {
              if (response.status == 200) {
                let UserReturn: UserClient = response.body as UserClient;
                return UserReturn;
              }
              return undefined;
            },
            (error) => {
              return undefined;
            }
          ),
          catchError((_error) => {
            return Observable.of(undefined);
          })
        );
    } catch (error) {
      //console.log(error);
      return Observable.of(undefined);
    }
  }

  public DeleteUser(UserId: string) {
    let URI = this._urlBaseAPI + this._urlPostDeleteUser;

    try {
      return this._httpClient
        .post(URI, JSON.stringify(UserId), {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
        })
        .pipe(
          map(
            (response) => {
              if (response.status == 200) {
                return 200;
              }
              return response.status;
            },
            (error) => {
              return 404;
            }
          ),
          catchError((_error) => {
            return Observable.of(_error);
          })
        );
    } catch (error) {
      //console.log(error);
      return Observable.of(404);
    }
  }

  public GetListInterfaceMenuSyncByProfil(profil: string) {
    let urlAPI = this._urlBaseAPI + this._urlPostInterfaceMenuByProfil;

    try {
      return this._httpClient
        .post(urlAPI, JSON.stringify(profil), {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
        })
        .pipe(
          map(
            (response) => {
              if (response.status == 200) {
                let MenuApp: InterfaceAppModel[] =
                  response.body as InterfaceAppModel[];
                MenuApp.sort(
                  (a, b) => a.INF_Niv - b.INF_Niv || a.INF_Order - b.INF_Order
                );
                return MenuApp;
              }
              return [];
            },
            (error) => {
              return [];
            }
          ),
          catchError((_error) => {
            return Observable.of([]);
          })
        );
    } catch (error) {
      //console.log(error);
      return Observable.of([]);
    }
  }

  public GetListInterfaceMenuAccesSync() {
    let urlAPI = this._urlBaseAPI + this._urlGetInterfaceMenuAcces;

    return this._httpClient.get(urlAPI).pipe(
      map((res: InterfaceAccesModel[]) => {
        res.map((x) => {
          x.IsVisible = true;
        });
        res.sort((a, b) => a.INF_Niv - b.INF_Niv || a.INF_Order - b.INF_Order);
        console.log(res);
        return res;
      })
    );
  }

  public GetListInterfaceMenuAcces$() {
    let urlAPI = this._urlBaseAPI + this._urlGetInterfaceMenuAcces;

    this._httpClient
      .get<InterfaceAccesModel[]>(urlAPI)
      .pipe(
        map((res: InterfaceAccesModel[]) => {
          res.map((x) => {
            x.IsVisible = true;
          });
          res.sort(
            (a, b) => a.INF_Niv - b.INF_Niv || a.INF_Order - b.INF_Order
          );
          return res;
        })
      )
      .subscribe(
        (response: InterfaceAccesModel[]) => {
          this._ListLeftMenu = response;
          this.EmitterListLeftMenu();
        },
        (error) => {
          console.log('Erreur ! : ' + error);
        }
      );
  }

  public SaveEditProfilApp(editedProfil: ProfilClient) {
    let URI = this._urlBaseAPI + this._urlPostSaveProfilApp;

    try {
      return this._httpClient
        .post(URI, editedProfil, {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
          responseType: 'text',
        })
        .pipe(
          map(
            (response) => {
              if (response.status == 200) {
                return response.body;
              }
              return 'KO';
            },
            (error) => {
              return 'KO';
            }
          ),
          catchError((_error) => {
            return Observable.of('KO');
          })
        );
    } catch (error) {
      //console.log(error);
      return Observable.of('KO');
    }
  }

  public SaveAccessUserApp(items: InterfaceAccesModel[]) {
    let URI = this._urlBaseAPI + this._urlPostSaveAccessUserApp;

    try {
      return this._httpClient
        .post(URI, items, {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
        })
        .pipe(
          map(
            (response) => {
              if (response.status == 200) {
                return 200;
              }
              return response.status;
            },
            (error) => {
              return 404;
            }
          ),
          catchError((_error) => {
            return Observable.of(_error);
          })
        );
    } catch (error) {
      //console.log(error);
      return Observable.of(404);
    }
  }

  public SaveMenuApp(items: InterfaceAccesModel[]) {
    let URI = this._urlBaseAPI + this._urlPostSaveMenuApp;

    try {
      return this._httpClient
        .post(URI, items, {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
        })
        .pipe(
          map(
            (response) => {
              if (response.status == 200) {
                return 200;
              }
              return response.status;
            },
            (error) => {
              return error;
            }
          ),
          catchError((_error) => {
            return Observable.of(_error);
          })
        );
    } catch (error) {
      //console.log(error);
      return Observable.of(error);
    }
  }

  public DeleteProfilApp(idProfil: string) {
    let URI = this._urlBaseAPI + this._urlPostDeleteProfilApp;

    try {
      return this._httpClient
        .post(URI, JSON.stringify(idProfil), {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
          responseType: 'text',
        })
        .pipe(
          map(
            (response) => {
              if (response.status == 200) {
                return response.body;
              }
              return 'KO';
            },
            (error) => {
              return 'KO';
            }
          ),
          catchError((_error) => {
            return Observable.of('KO');
          })
        );
    } catch (error) {
      //console.log(error);
      return Observable.of('KO');
    }
  }
}
