import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-snack-bar',
  templateUrl: './alert-snack-bar.component.html',
  styleUrls: ['./alert-snack-bar.component.css']
})
export class AlertSnackBarComponent implements OnInit {

  constructor(
  ) { }



  ngOnInit(): void {
  }

}
