<div class="container-fluid background-whitesmoke">
  <div class="row">
    <div class="col-md">
      <div mat-dialog-actions class="d-flex close-text-end">
        <button (click)="onClose()" matTooltip="{{ 'Cancel' | translate }}" mat-stroked-button
          class="button-icon-generic-cancel" type="button">
          <i class="fas fa-times"></i>
        </button>
        <!-- <button
          mat-button
          class="button-generic-delete"
          type="button"
          (click)="onClose()"
        >
          Fermer
        </button> -->
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col">
      <div class="container-art-view" style="position: relative; top: 0">
        <div>
          <ArticleDetailComponent [SelectedArticleSearch]="SelectedArticleSearch" [isShowBtnCancel]="false"
            [isShowBtnDelete]="false">
          </ArticleDetailComponent>
        </div>
      </div>
    </div>
  </div>
  <br />
</div>