<div>
  <h1 mat-dialog-title>
    {{ title }}
  </h1>

  <div mat-dialog-content>
    <p>{{ message }}</p>
  </div>

  <div *ngIf="!isNotYesNo" mat-dialog-actions class="d-flex justify-content-center">
    <button mat-button class="button-generic-delete" (click)="onDismiss()">
      No
    </button>
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    <button mat-stroked-button class="button-generic-validate" (click)="onConfirm()">
      Oui
    </button>
  </div>
  <div *ngIf="isNotYesNo" mat-dialog-actions class="d-flex justify-content-center">
    <button mat-button class="button-generic-understood" (click)="onDismissOk()">
      Ok
    </button>
  </div>
</div>