import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/of';
import * as _ from "lodash-es";
import { EnvService } from '../../environment/env.service';
import { Config } from '../../../../config';
import { catchError, map, tap } from 'rxjs/operators';

import { VUE_STOCK_SUIVI_ARTICLE } from '../../../model/member/stock/stock-output-art.model';
import { SelectValueModel } from '../../../model/common/select_value.model';
import { StockOutputArtHeaderModel } from '../../../model/member/stock/stock-output-art-header.model';
import { StockOutputArtValideModel } from '../../../model/member/stock/stock-output-art-valide.model';
import * as moment from 'moment';
import { VUE_STOCK_MOUVEMENT_ART } from '../../../model/member/stock/VUE_STOCK_MOUVEMENT_ART.model';
import { VUE_STOCK_SORTIE_ENTETE } from '../../../model/member/stock/VUE_STOCK_SORTIE_ENTETE.model';
import { VUE_STOCK_SORTIE_ART } from '../../../model/member/stock/VUE_STOCK_SORTIE_ART.model';
import { FilterSortieArtModel } from '../../../model/member/stock/filter-sortie-art.model';
import { StockInventoryModel } from '../../../model/member/stock/inventaire/stock-inventory.model';
import { StockInventoryValide } from '../../../model/member/stock/inventaire/stock-inventory-valide.model';
import { VUE_INVENTORY } from '../../../model/member/stock/inventaire/vue-inventory.model';
import { VUE_INVENTORY_PRODUIT } from '../../../model/member/stock/inventaire/vue-inventory-produit.model';
import { ExportEtatStock } from '../../../model/member/stock/export-etat-stock.model';
import { VUE_STOCK_LIST_REPCEPTION } from '../../../model/member/stock/VUE_STOCK_LIST_REPCEPTION.model';
import { FilterReceptionModel } from '../../../model/member/stock/filter-reception.model';



@Injectable()
export class StockService {

    // url api
    private _urlBaseAPI: string;

    private _urlGetStockSortie: string;

    private _urlGetStockInventaire: string;

    private _urlGetEtatStockArticles: string;

    private _urlGetClientDestOutputArt: string;

    private _urlPostSaveOutputArt: string;

    private _urlPostSaveOutputArtEdit: string;

    private _urlPostSaveInventoryArt: string;

    private _urlPostGenerePdfBs: string;

    private _urlGetSearcheArticleStock: string;

    private _urlGetArticleStockByZoneStock: string;

    private _urlGetMvtArt: string;

    private _urlGetListSortieHeader: string;

    private _urlGetListSortieArtDetail: string;

    private _urlGetListSortieArtDetailEdit: string;

    private _urlPostFilterArtHeader: string;

    private _urlPostExportInventaireTemplate: string;

    private _urlGetInventoryHistory: string;

    private _urlGetInventoryDetailHistory: string;

    private _urlPostGenerePdfInventaireDetail: string;

    private _urlImportInventaireExcel: string;

    private _urlPostSaveImportExcelInventoryArt: string;

    private _urlPostExportExcelEtatStock: string;

    private _urlPostGenerePdfListHeaderOutputArt: string;

    private _urlPostGenerePdfEtatStock: string;

    private _urlPostGenerePdfMouvementStockArt: string;

    private _urlGetAllListReception: string;

    private _urlPostFilterReception: string;

    private _urlSearchReception: string;

    public ListStockSortieArtDetailSubject = new Subject<VUE_STOCK_SORTIE_ART[]>();
    private _listStockSortieArtDetail: VUE_STOCK_SORTIE_ART[] = [];

    public ListStockArtSubject = new Subject<VUE_STOCK_SUIVI_ARTICLE[]>();
    private _listStockArtOutput: VUE_STOCK_SUIVI_ARTICLE[] = [];

    public ListSortieArtHeaderSubject = new Subject<VUE_STOCK_SORTIE_ENTETE[]>();
    private _listSortieArtHeader: VUE_STOCK_SORTIE_ENTETE[] = [];

    public ListStockArtInventaireSubject = new Subject<VUE_STOCK_SUIVI_ARTICLE[]>();
    private _listStockArtInventaire: VUE_STOCK_SUIVI_ARTICLE[] = [];

    public ListEtatStockArtSubject = new Subject<VUE_STOCK_SUIVI_ARTICLE[]>();
    private _listEtatStockArt: VUE_STOCK_SUIVI_ARTICLE[] = [];

    public ListStockArtMvtSubject = new Subject<VUE_STOCK_MOUVEMENT_ART[]>();
    private _listStockArtMvt: VUE_STOCK_MOUVEMENT_ART[] = [];

    public ListStockArtMvtTraceSubject = new Subject<VUE_STOCK_MOUVEMENT_ART[]>();
    private _listStockArtMvtTrace: VUE_STOCK_MOUVEMENT_ART[] = [];

    private ListFilterStock: SelectValueModel[] = [
        { IdSelect: '1', ValueSelect: 'Tous les stocks' },
        { IdSelect: '2', ValueSelect: 'Stocks positifs' },
        { IdSelect: '3', ValueSelect: 'Stocks 0' }
    ];


    constructor(private _httpClient: HttpClient, private env: EnvService) {

        this._urlBaseAPI = env.API_URL_BASE;
        this._urlGetStockSortie = Config.API_ROUTES.STOCK_API.API_GET_ALL_STOCK_SORTIE;
        this._urlGetStockInventaire = Config.API_ROUTES.STOCK_API.API_GET_ALL_STOCK_SORTIE;
        this._urlGetClientDestOutputArt = Config.API_ROUTES.STOCK_API.API_GET_CLIENT_OUTPUT_ART;
        this._urlPostSaveOutputArt = Config.API_ROUTES.STOCK_API.API_POST_SAVE_OUTPUT_ART;
        this._urlPostSaveOutputArtEdit = Config.API_ROUTES.STOCK_API.API_POST_SAVE_OUTPUT_ART_EDIT;
        this._urlPostGenerePdfBs = Config.API_ROUTES.STOCK_API.API_POST_GENERER_PDF_BS;
        this._urlGetSearcheArticleStock = Config.API_ROUTES.STOCK_API.API_GET_SEARCH_ARTICLE_STOCK;
        this._urlGetArticleStockByZoneStock = Config.API_ROUTES.STOCK_API.API_GET_ART_STOCK_BY_ZONE_STOCK;
        this._urlPostSaveInventoryArt = Config.API_ROUTES.STOCK_API.API_POST_SAVE_INVENTORY_ARTICLE;
        this._urlGetEtatStockArticles = Config.API_ROUTES.STOCK_API.API_GET_ETAT_STOCK_ARTICLE;
        this._urlGetMvtArt = Config.API_ROUTES.STOCK_API.API_GET_MVT_STOCK_ARTICLE;
        this._urlGetListSortieHeader = Config.API_ROUTES.STOCK_API.API_GET_LIST_SORTIE_HEADER;
        this._urlGetListSortieArtDetail = Config.API_ROUTES.STOCK_API.API_GET_LIST_SORTIE_ART_DETAIL;
        this._urlPostFilterArtHeader = Config.API_ROUTES.STOCK_API.API_POST_FILTER_ART_HEADER;
        this._urlPostExportInventaireTemplate = Config.API_ROUTES.STOCK_API.API_POST_EXPORT_EXCEL_INVENTAIRE_TEMPLATE;
        this._urlGetInventoryHistory = Config.API_ROUTES.STOCK_API.API_GET_INVENTORY_HISTORY;
        this._urlGetInventoryDetailHistory = Config.API_ROUTES.STOCK_API.API_GET_INVENTORY_HISTORY_DETAIL;
        this._urlPostGenerePdfInventaireDetail = Config.API_ROUTES.STOCK_API.API_POST_GENERER_PDF_INVENTORY_DETAIL;
        this._urlImportInventaireExcel = Config.API_ROUTES.STOCK_API.API_POST_IMPORT_INVENTORY_EXCEL;
        this._urlPostSaveImportExcelInventoryArt = Config.API_ROUTES.STOCK_API.API_POST_SAVE_IMPORT_INVENTORY_EXCEL;
        this._urlGetListSortieArtDetailEdit = Config.API_ROUTES.STOCK_API.API_GET_LIST_SORTIE_ART_DETAIL_EDIT;
        this._urlPostExportExcelEtatStock = Config.API_ROUTES.STOCK_API.API_POST_EXPORT_EXCEL_ETAT_STOCK;
        this._urlPostGenerePdfListHeaderOutputArt = Config.API_ROUTES.STOCK_API.API_POST_EXPORT_PDF_OUTPUT_HEADER_ART;
        this._urlPostGenerePdfEtatStock = Config.API_ROUTES.STOCK_API.API_POST_EXPORT_PDF_ETAT_STOCK_ART;
        this._urlPostGenerePdfMouvementStockArt = Config.API_ROUTES.STOCK_API.API_POST_EXPORT_PDF_MVT_ART;
        this._urlGetAllListReception = Config.API_ROUTES.STOCK_API.API_GET_LIST_RECEPTION;
        this._urlPostFilterReception = Config.API_ROUTES.STOCK_API.API_POST_FILTER_LIST_RECEPTION;
        this._urlSearchReception = Config.API_ROUTES.STOCK_API.API_GET_SEARCH_RECEPTION_BL_FAC;
    }

    public EmitterStockArtSortieDetail() {
        if (this._listStockSortieArtDetail) {
            this.ListStockSortieArtDetailSubject.next(this._listStockSortieArtDetail.slice());
        }
    }

    public EmitterStockArtSortie() {
        if (this._listStockArtOutput) {
            this.ListStockArtSubject.next(this._listStockArtOutput.slice());
        }
    }

    public EmitterStockArtInventaire() {
        if (this._listStockArtInventaire) {
            this.ListStockArtInventaireSubject.next(this._listStockArtInventaire.slice());
        }
    }

    public EmitterListSortieHeader() {
        if (this._listSortieArtHeader) {
            this.ListSortieArtHeaderSubject.next(this._listSortieArtHeader.slice());
        }
    }

    public EmitterEtatStockArticle() {
        if (this._listEtatStockArt) {
            this.ListEtatStockArtSubject.next(this._listEtatStockArt.slice());
        }
    }

    public EmitterMouvementStockArticle() {
        if (this._listStockArtMvt) {
            this.ListStockArtMvtSubject.next(this._listStockArtMvt.slice());
        }
    }

    public EmitterMouvementStockArticleTrace() {
        if (this._listStockArtMvtTrace) {
            this.ListStockArtMvtTraceSubject.next(this._listStockArtMvtTrace.slice());
        }
    }

    public TypeOfMeal: SelectValueModel[] = [
        {
            IdSelect: '0',
            ValueSelect: 'Global',
        },
        { IdSelect: '1', ValueSelect: 'Midi' },
        { IdSelect: '2', ValueSelect: 'Soir' },
        { IdSelect: '3', ValueSelect: 'Perte Alimentaire' },
    ];

    public TypeOfProduct: SelectValueModel[] = [
        {
            IdSelect: '0',
            ValueSelect: '',
        },
        { IdSelect: '1', ValueSelect: 'Alimentaire' },
        { IdSelect: '2', ValueSelect: 'Non-alimentaire' },
    ];

    public GetTypeFilterStock() {
        return this.ListFilterStock.slice();
    }

    public GetHourInventory() {
        return [
            {
                IdSelect: '2',
                ValueSelect: 'Le plus tard le soir',
            },
            {
                IdSelect: '1',
                ValueSelect: 'Le plus tôt le matin',
            },
        ];
    }


    public GetAllStockArtSortie() {

        let urlAPI = this._urlBaseAPI + this._urlGetStockSortie;

        this._httpClient.get<VUE_STOCK_SUIVI_ARTICLE[]>(urlAPI).pipe(
            map((res: VUE_STOCK_SUIVI_ARTICLE[]) => {
                res.map((x) => {
                    if (x.DATE_LIMITE != "") {
                        x.DATE_LIMITE = moment(x.DATE_LIMITE)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_LIMITE = ""
                    }
                    x.TotalHT = 0;
                    x.IsCheckOk = true;
                    x.IsEdit = false;
                });
                // sort display
                res.sort(function (a, b) {
                    return Number(b.STOCK_REEL) - Number(a.STOCK_REEL)
                });
                return res;
            })).subscribe(
                (response: VUE_STOCK_SUIVI_ARTICLE[]) => {
                    this._listStockArtOutput = response;

                    this.EmitterStockArtSortie();
                },
                (error) => {
                    console.log('Erreur ! : ' + error);
                }
            );
    }

    public GetListStockSortieHeader() {

        let urlAPI = this._urlBaseAPI + this._urlGetListSortieHeader;

        this._httpClient.get<VUE_STOCK_SORTIE_ENTETE[]>(urlAPI).subscribe(
            (response: VUE_STOCK_SORTIE_ENTETE[]) => {
                this._listSortieArtHeader = response;
                this._listSortieArtHeader.sort(function (a, b) {
                    // du plus recent au plus ancien
                    return b.DATE_SORTIE.localeCompare(a.DATE_SORTIE)
                });

                this._listSortieArtHeader.map((x) => {
                    if (x.DATE_SORTIE != "") {
                        x.DATE_SORTIE = moment(x.DATE_SORTIE)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_SORTIE = ""
                    }
                });

                this.EmitterListSortieHeader();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetListSortieArticleDetail(nobs: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetListSortieArtDetail + nobs;

        this._httpClient.get<VUE_STOCK_SORTIE_ART[]>(urlAPI).subscribe(
            (response: VUE_STOCK_SORTIE_ART[]) => {
                this._listStockSortieArtDetail = response;

                // console.log('service bl ', this._listStockSortieArtDetail)

                this._listStockSortieArtDetail.map((x) => {
                    if (x.DATE_SORTIE != "") {
                        x.DATE_SORTIE = moment(x.DATE_SORTIE)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_SORTIE = ""
                    }
                    if (x.DATE_LIMITE != "") {
                        x.DATE_LIMITE = moment(x.DATE_LIMITE)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_LIMITE = ""
                    }
                });
                this.EmitterStockArtSortieDetail();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetListSortieArticleDetailSync(nobs: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetListSortieArtDetailEdit + nobs;

        return this._httpClient.get<VUE_STOCK_SORTIE_ART[]>(urlAPI).pipe(
            map((res: VUE_STOCK_SORTIE_ART[]) => {
                res.map(x => {
                    if (x.DATE_SORTIE != "") {
                        x.DATE_SORTIE = moment(x.DATE_SORTIE)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_SORTIE = ""
                    }
                    if (x.DATE_LIMITE != "") {
                        x.DATE_LIMITE = moment(x.DATE_LIMITE)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_LIMITE = ""
                    }
                    x.QteInitRest = Number(x.QUANTITE) + x.QteRest;
                    x.IsCheckOk = true;
                    x.IsEdit = true;
                });
                return res;
            })
        );
    }

    public GetAllStockArtInventaire() {

        let urlAPI = this._urlBaseAPI + this._urlGetStockInventaire;

        this._httpClient.get<VUE_STOCK_SUIVI_ARTICLE[]>(urlAPI).subscribe(
            (response: VUE_STOCK_SUIVI_ARTICLE[]) => {

                this._listStockArtInventaire = response;


                this._listStockArtInventaire.map((x) => {
                    if (x.DATE_LIMITE != "") {
                        x.DATE_LIMITE = moment(x.DATE_LIMITE)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_LIMITE = ""
                    }
                    x.TotalHT = Math.round((x.PRIX_VALO * x.STOCK_REEL) * 100 + Number.EPSILON) / 100
                    x.IsCheckOk = true;
                    x.IsEdit = false;
                    x.Prix_valo_old = x.PRIX_VALO;
                    x.QteInventaire = x.STOCK_REEL.toString();
                });

                this._listStockArtInventaire.sort(function (a, b) {
                    if (a.STOCK_REEL && !b.STOCK_REEL) return -1
                    if (b.STOCK_REEL && !a.STOCK_REEL) return 1

                    return a.NOM_PR.localeCompare(b.NOM_PR) || Number(b.STOCK_REEL) - Number(a.STOCK_REEL);
                });


                this.EmitterStockArtInventaire();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetAllStockArtInventaireSync() {

        let urlAPI = this._urlBaseAPI + this._urlGetStockInventaire;

        return this._httpClient.get<VUE_STOCK_SUIVI_ARTICLE[]>(urlAPI).pipe(
            map((res: VUE_STOCK_SUIVI_ARTICLE[]) => {

                res.map(x => {
                    if (x.DATE_LIMITE != "") {
                        x.DATE_LIMITE = moment(x.DATE_LIMITE)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_LIMITE = ""
                    }
                    x.TotalHT = 0;
                    x.IsCheckOk = true;
                    x.IsEdit = false;
                    x.Prix_valo_old = x.PRIX_VALO;
                });
                return res;
            })
        );

    }

    public GetHisotryInventaireSync(pDateDu: string, pDateAu: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetInventoryHistory + pDateDu + "&a=" + pDateAu;

        return this._httpClient.get<VUE_INVENTORY[]>(urlAPI).pipe(
            map((res: VUE_INVENTORY[]) => {
                res.map(x => {

                });

                return res.sort(function (a, b) {
                    return Number(b.ID_INVENT) - Number(a.ID_INVENT)
                });
            })
        );
    }

    public GetHisotryInventaireDetailSync(pIdInvent: number) {

        let urlAPI = this._urlBaseAPI + this._urlGetInventoryDetailHistory + pIdInvent;

        return this._httpClient.get<VUE_INVENTORY_PRODUIT[]>(urlAPI).pipe(
            map((res: VUE_INVENTORY_PRODUIT[]) => {
                // console.log('Stock service ', res)
                res.map(x => {
                    if (x.DATE_LIMITE != "") {
                        x.DATE_LIMITE = moment(x.DATE_LIMITE)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_LIMITE = ""
                    }
                    x.TotalHT = (Math.round((x.INVT_PRIX_UNI * x.INVT_QTE) * 100 + Number.EPSILON) / 100).toString();
                });
                res.sort(function (a, b) {
                    return Number(b.INVT_QTE) - Number(a.INVT_QTE)
                });
                return res;
            })
        );
    }

    public GetEtatStockArticles(pDate: string, pIuZoneStock: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetEtatStockArticles + 'd=' + pDate + '&z=' + pIuZoneStock;

        this._httpClient.get<VUE_STOCK_SUIVI_ARTICLE[]>(urlAPI)
            .pipe(
                map((res: VUE_STOCK_SUIVI_ARTICLE[]) => {

                    let ArrayqteStockRemoved: VUE_STOCK_SUIVI_ARTICLE[] = [];

                    res.map((x) => {
                        if (x.DATE_LIMITE != "") {
                            x.DATE_LIMITE = moment(x.DATE_LIMITE)
                                .local(false)
                                .utc(true)
                                .format('DD/MM/YYYY');
                        }
                        else {
                            x.DATE_LIMITE = ""
                        }
                        x.TotalHT = Math.round((x.PRIX_VALO * x.STOCK_REEL) * 100 + Number.EPSILON) / 100

                        //TTC column added
                        let tva = Number(x.DENREE_TVA) / 10000
                        x.TotalTTC = x.TotalHT * (1 + tva)


                        x.IsCheckOk = true;
                        x.IsEdit = false;


                        // if (res.filter(p => p.CODE_PR == x.CODE_PR && p.STOCK_REEL > 0).length == 0
                        //     && res.filter(p => p.CODE_PR == x.CODE_PR).length > 1 // il existe des lignes avec numlot ou dlc
                        // ) {
                        //     if (x.NUM_LOT != '' || x.DATE_LIMITE != '') {
                        //         ArrayqteStockRemoved = [...ArrayqteStockRemoved, x];
                        //     }
                        // }
                    });

                    //res = res.filter(p => ArrayqteStockRemoved.findIndex(rm => rm.CODE_PR == p.CODE_PR && rm.NUM_LOT == p.NUM_LOT && rm.DATE_LIMITE.replace('/', '') == p.DATE_LIMITE.replace('/', '')) == -1);

                    // récupérer que les qte 0
                    //res = res.filter(p => p.STOCK_REEL != 0)

                    // return res.sort(function (a, b) {
                    //     return Number(b.STOCK_REEL) - Number(a.STOCK_REEL)
                    // });
                    return res.sort(function (a, b) {
                        if (a.STOCK_REEL && !b.STOCK_REEL) return -1
                        if (b.STOCK_REEL && !a.STOCK_REEL) return 1

                        return a.NOM_PR.localeCompare(b.NOM_PR) || Number(b.STOCK_REEL) - Number(a.STOCK_REEL);
                    });
                }))
            .subscribe(
                (response: VUE_STOCK_SUIVI_ARTICLE[]) => {

                    this._listEtatStockArt = response;

                    // this._listEtatStockArt.map((x) => {
                    //     if (x.DATE_LIMITE != "") {
                    //         x.DATE_LIMITE = moment(x.DATE_LIMITE)
                    //             .local(false)
                    //             .utc(true)
                    //             .format('DD/MM/YYYY');
                    //     }
                    //     else {
                    //         x.DATE_LIMITE = ""
                    //     }
                    //     x.TotalHT = Math.round((x.PRIX_VALO * x.STOCK_REEL) * 100 + Number.EPSILON) / 100
                    //     x.IsCheckOk = true;
                    //     x.IsEdit = false;

                    // });

                    this.EmitterEtatStockArticle();
                },
                (error) => {
                    console.log('Erreur ! : ' + error);
                }
            );
    }

    public GetArticleByZoneStockSync(pIuZone: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetArticleStockByZoneStock + "z=" + pIuZone;

        return this._httpClient.get<VUE_STOCK_SUIVI_ARTICLE[]>(urlAPI).pipe(
            map((res: VUE_STOCK_SUIVI_ARTICLE[]) => {
                res.map(x => {
                    if (x.DATE_LIMITE != "") {
                        x.DATE_LIMITE = moment(x.DATE_LIMITE)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_LIMITE = ""
                    }
                    x.TotalHT = Number((x.PRIX_VALO * x.STOCK_REEL).toFixed(2));
                    x.IsCheckOk = true;
                    x.IsEdit = false;
                    x.Prix_valo_old = x.PRIX_VALO;
                    x.QteInventaire = x.STOCK_REEL.toString();

                });
                return res.sort(function (a, b) {
                    if (a.STOCK_REEL && !b.STOCK_REEL) return -1
                    if (b.STOCK_REEL && !a.STOCK_REEL) return 1

                    return a.NOM_PR.localeCompare(b.NOM_PR) || Number(b.STOCK_REEL) - Number(a.STOCK_REEL);
                });
            })
        );
    }


    public GetAllClientSortieArtSync() {

        let urlAPI = this._urlBaseAPI + this._urlGetClientDestOutputArt;

        return this._httpClient.get<SelectValueModel[]>(urlAPI);
    }

    public InitStockOutputArtHeader(): StockOutputArtHeaderModel {

        let Initheader: StockOutputArtHeaderModel = {
            DATE_SORTIE: '',
            DEST: '',
            IU_MVTSTOCK: '',
            IU_STOCK: '',
            MTHT: '',
            MTTC: '',
            NBRE: '',
            NON_ALIM: '',
            NO_BS: '',
            PREST: '',
            // {
            //     CODE_DENREE: '',
            //     DATE_LIMITE: '',
            //     DATE_MVT: '',
            //     HEURE_MVT: '',
            //     IU_DENREE: '',
            //     IU_STOCK: '',
            //     NUM_LOT: '',
            //     PRIX_UNITAIRE: '',
            //     QUANTITE: '',
            //     TYPE_MVT: ''
            // }

        }

        return Initheader;
    }

    public InitStockOutputArt(): VUE_STOCK_SORTIE_ART {
        let artoutputInit: VUE_STOCK_SORTIE_ART = {
            IU_MVTSTOCK: '',
            NO_BS: '',
            ID_SORTIE_LINE: undefined,
            ID_MVT: undefined,
            DATE_SORTIE: '',
            CODE_DENREE: '',
            NOM_DENREE: '',
            IU_DENREE: '',
            NUM_LOT: '',
            DATE_LIMITE: '',
            IU_ZONESTOCK: '',
            LIBZONESTOCK: '',
            TYPE_MVT: '',
            QUANTITE: '',
            PRIX_UNITAIRE: '',
            US: '',
            PMP_TIME: undefined,
            PMP_INIT: undefined,
            PREST: '',
            PREST_LIB: '',
            NBRE: '',
            DEST: '',
            DEST_LIB: '',
            MTHT: undefined,
            MT_PMP: undefined,
            MTTC: '',
            TOTAL: '',
            NON_ALIM: '',
            QteRest: undefined,
            QteInitRest: undefined,
            IsCheckOk: false,
            IsEdit: false,
            IsNew: false
        };
        return artoutputInit;
    }

    public InitStockInventory(): StockInventoryModel {
        let InitInventory: StockInventoryModel = {
            DATEDEB: undefined,
            ID_INVENT: undefined,
            IU_MVTSTOCK: '',
            IU_ZONE_STOCK: '',
            TYPE_INVENT: '',
            HOUR_INVENT: '2' // par défault c'est le tard le soir
        }

        return InitInventory;
    }
    public SaveOutputArt(pOutputArtValide: StockOutputArtValideModel) {

        let urlAPI = this._urlBaseAPI + this._urlPostSaveOutputArt;

        try {
            return this._httpClient.post(urlAPI, pOutputArtValide,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let outputArtReturn: StockOutputArtValideModel = ((response.body) as any);
                                return outputArtReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }

    }

    public SaveOutputArtEdit(pOutputArtValide: StockOutputArtValideModel) {

        let urlAPI = this._urlBaseAPI + this._urlPostSaveOutputArtEdit;

        try {
            return this._httpClient.post(urlAPI, pOutputArtValide,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let outputArtReturn: StockOutputArtValideModel = ((response.body) as any);
                                return outputArtReturn;
                            }
                            return response.status;
                        },
                        (error) => {
                            return error;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }

    }

    public SaveInventoryArt(pInventoryArtValide: StockInventoryValide) {


        let urlAPI = this._urlBaseAPI + this._urlPostSaveInventoryArt;

        try {
            return this._httpClient.post(urlAPI, pInventoryArtValide,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let inventoryArtReturn: StockInventoryValide = ((response.body) as any);
                                return inventoryArtReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }

    }

    public SaveImportExcelInventoryArt(pInventoryArtValide: StockInventoryValide) {

        let urlAPI = this._urlBaseAPI + this._urlPostSaveImportExcelInventoryArt;

        try {
            return this._httpClient.post(urlAPI, pInventoryArtValide,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let inventoryArtReturn: StockInventoryValide = ((response.body) as any);
                                return inventoryArtReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }

    }

    public GenererPDFBonSortie(pNumBs: string) {
        let URI = this._urlBaseAPI + this._urlPostGenerePdfBs;

        try {
            return this._httpClient.post(URI, JSON.stringify(pNumBs),
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                //let CmdReturn: CmdGesPrecoModel = ((response.body) as any);
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public GenererPDFInventaireDetail(pIdInVent: number) {
        let URI = this._urlBaseAPI + this._urlPostGenerePdfInventaireDetail;

        try {
            return this._httpClient.post(URI, JSON.stringify(pIdInVent),
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                //let CmdReturn: CmdGesPrecoModel = ((response.body) as any);
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public GenerePdfListHeaderOutputArt(formData: any) {

        let URI = this._urlBaseAPI + this._urlPostGenerePdfListHeaderOutputArt;

        try {
            return this._httpClient.post(URI, formData,
                {
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                return response.body;
                            }
                            return response.status;
                        },
                        (error) => {
                            return error;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }

    public ChercherArticleFromListEtatStock(pCodePr: string) {
        if (pCodePr) {
            let ListArt = this._listEtatStockArt.filter(p => p.CODE_PR.toLowerCase().startsWith(pCodePr.toLowerCase()) || p.NOM_PR.toLowerCase().startsWith(pCodePr.toLowerCase()));

            // let listWithTraceAbility = ListArt.filter(e => e.NUM_LOT != "" || e.DATE_LIMITE != "");
            // console.log('service ', listWithTraceAbility)

            return Observable.of(ListArt);
        }
        else {
            return Observable.of(this._listEtatStockArt);
        }
    }

    /**
     * traceability => get only articles with lot num or useByDate(not generic article!)
     */
    public GetArtFromStockListWithTraceability(pCodePr: string) {
        if (pCodePr) {
            let ListArt = this._listEtatStockArt.filter(p => p.CODE_PR.toLowerCase().startsWith(pCodePr.toLowerCase()) || p.NOM_PR.toLowerCase().startsWith(pCodePr.toLowerCase()));

            let listWithTraceAbility = ListArt.filter(e => e.NUM_LOT != "" || e.DATE_LIMITE != "");
            return Observable.of(listWithTraceAbility);
        }
        else {
            console.log('error ');
        }
    }

    /**
    * rechercher article server
    * @param filter 
    * @param page 
    */
    public SearchCommandSearchArticleStockServer(typeSearch: string, filter: { name: string } = { name: '' }, page = 1, toDate?: string): Observable<VUE_STOCK_SUIVI_ARTICLE[]> {

        let urlAPI = this._urlBaseAPI + this._urlGetSearcheArticleStock + filter.name;

        if (filter.name.length < 3) {
            switch (typeSearch) {
                case "output": {
                    return Observable.of(this._listStockArtOutput.slice());
                    // break;
                }
                case "inventory": {
                    return Observable.of(this._listStockArtInventaire.slice());
                    //break;
                }
                case "etat": {
                    return Observable.of(this._listEtatStockArt.slice());
                    //break;
                }
                default: {
                    return Observable.of([]);
                    //break;
                }
            }

        }
        else {

            if (toDate) {
                urlAPI += '&todate=' + toDate;
            }
            return this._httpClient.get<VUE_STOCK_SUIVI_ARTICLE[]>(urlAPI)
                .pipe(
                    map((response: VUE_STOCK_SUIVI_ARTICLE[]) => {
                        // response.map(x => {
                        //     if (x.DATE_LIMITE != "") {
                        //         x.DATE_LIMITE = moment(x.DATE_LIMITE)
                        //             .local(false)
                        //             .utc(true)
                        //             .format('DD/MM/YYYY');
                        //     }
                        //     else {
                        //         x.DATE_LIMITE = ""
                        //     }

                        //     x.TotalHT = Number((x.PRIX_VALO * x.STOCK_REEL).toFixed(4));
                        //     x.IsCheckOk = true;
                        //     x.IsEdit = false;
                        // });


                        let ArrayqteStockRemoved: VUE_STOCK_SUIVI_ARTICLE[] = [];

                        response.map((x) => {
                            if (response.filter(p => p.CODE_PR == x.CODE_PR && p.STOCK_REEL > 0).length == 0 && response.filter(p => p.CODE_PR == x.CODE_PR).length > 1) {
                                if (x.NUM_LOT != '' || x.DATE_LIMITE != '') {
                                    ArrayqteStockRemoved = [...ArrayqteStockRemoved, x];
                                }

                            }

                            if (x.DATE_LIMITE != "") {
                                x.DATE_LIMITE = moment(x.DATE_LIMITE)
                                    .local(false)
                                    .utc(true)
                                    .format('DD/MM/YYYY');
                            }
                            else {
                                x.DATE_LIMITE = ""
                            }
                            x.TotalHT = Math.round((x.PRIX_VALO * x.STOCK_REEL) * 100 + Number.EPSILON) / 100
                            x.IsCheckOk = true;
                            x.IsEdit = false;
                            x.QteInventaire = x.STOCK_REEL.toString();
                        });

                        response = response.filter(p => ArrayqteStockRemoved.findIndex(rm => rm.CODE_PR == p.CODE_PR && rm.NUM_LOT == p.NUM_LOT && rm.DATE_LIMITE.replace('/', '') == p.DATE_LIMITE.replace('/', '')) == -1);

                        return response.sort(function (a, b) {
                            return Number(b.STOCK_REEL) - Number(a.STOCK_REEL)
                        }).slice();

                    })
                );
        }
    }


    public FilterArtHeader(pFiler: FilterSortieArtModel) {

        let urlAPI = this._urlBaseAPI + this._urlPostFilterArtHeader;

        try {
            return this._httpClient.post(urlAPI, pFiler,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let ListCmdReturn: VUE_STOCK_SORTIE_ENTETE[] = ((response.body) as any);
                                if (ListCmdReturn) {
                                    ListCmdReturn.sort(function (a, b) {
                                        // du plus recent au plus ancien
                                        return b.DATE_SORTIE.localeCompare(a.DATE_SORTIE)
                                    });
                                    ListCmdReturn.map((x) => {
                                        if (x.DATE_SORTIE != "") {
                                            x.DATE_SORTIE = moment(x.DATE_SORTIE)
                                                .local(false)
                                                .utc(true)
                                                .format('DD/MM/YYYY');
                                        }
                                        else {
                                            x.DATE_SORTIE = ""
                                        }
                                    });
                                }

                                return ListCmdReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public GetMouvementStockArticles(pDenree: string, pNumlot: string, pDate: string, pOri?: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetMvtArt + 'a=' + pDenree + '&l=' + pNumlot + '&d=' + pDate;

        if (pOri) {
            urlAPI += "&ori=" + pOri;
        }
        this._httpClient.get<VUE_STOCK_MOUVEMENT_ART[]>(urlAPI).subscribe(
            (response: VUE_STOCK_MOUVEMENT_ART[]) => {
                this._listStockArtMvt = response;

                this._listStockArtMvt.sort(function (a, b) {
                    // Le plus récent (en haut) aux plus ancien (en bas)
                    return b.DATE_MVT.localeCompare(a.DATE_MVT) || b.HEURE_MVT.localeCompare(a.HEURE_MVT) || b.ID_MVT - a.ID_MVT;
                });

                this._listStockArtMvt.map((x) => {
                    if (x.DATE_LIMITE != "") {
                        x.DATE_LIMITE = moment(x.DATE_LIMITE)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_LIMITE = "";
                    }

                    if (x.DATE_MVT != "") {
                        x.DATE_MVT = moment(x.DATE_MVT)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_MVT != "";
                    }

                    if (x.HEURE_MVT) {
                        x.HEURE_MVT = x.HEURE_MVT.slice(0, 2) + ":" + x.HEURE_MVT.slice(2, 4) + ":" + x.HEURE_MVT.slice(4, 6);
                    }

                });
                //this._listStockArtMvt.sort((a, b) => { return b.ID_MVT - a.ID_MVT });
                this.EmitterMouvementStockArticle();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }


    /**
     * Get art mouvements for traceability (only entries for now, after exits needed) 
     */
    public GetMouvEntryTraceArticle(pDenree: string, pNumlot: string, pDate: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetMvtArt + 'a=' + pDenree + '&l=' + pNumlot + '&d=' + pDate;

        this._httpClient.get<VUE_STOCK_MOUVEMENT_ART[]>(urlAPI).subscribe(
            (response: VUE_STOCK_MOUVEMENT_ART[]) => {
                this._listStockArtMvtTrace = response;
                let listCancelId = [];///mvnts cancelled ids list(ID_MVT_OLD != null)
                this._listStockArtMvtTrace.forEach(e => {
                    if (e.ID_MVT_OLD != null && !listCancelId.includes(e.ID_MVT_OLD)) {
                        listCancelId = [...listCancelId, e.ID_MVT_OLD];
                    }
                })
                //get only entries & exits not cancelled
                //TODO exclude exits (e.TYPE_MVT != "S")
                this._listStockArtMvtTrace = this._listStockArtMvtTrace.filter(e => e.TYPE_MVT != 'M' && e.TYPE_MVT != 'C' && e.TYPE_MVT != 'I' && !listCancelId.includes(e.ID_MVT));

                this._listStockArtMvtTrace.sort(function (a, b) {
                    // Le plus récent (en haut) aux plus ancien (en bas)
                    return b.DATE_MVT.localeCompare(a.DATE_MVT) || b.HEURE_MVT.localeCompare(a.HEURE_MVT) || b.ID_MVT - a.ID_MVT;
                });

                this._listStockArtMvtTrace.map((x) => {
                    if (x.DATE_LIMITE != "") {
                        x.DATE_LIMITE = moment(x.DATE_LIMITE)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_LIMITE = "";
                    }

                    if (x.DATE_MVT != "") {
                        x.DATE_MVT = moment(x.DATE_MVT)
                            .local(false)
                            .utc(true)
                            .format('DD/MM/YYYY');
                    }
                    else {
                        x.DATE_MVT != "";
                    }

                    if (x.HEURE_MVT) {
                        x.HEURE_MVT = x.HEURE_MVT.slice(0, 2) + ":" + x.HEURE_MVT.slice(2, 4) + ":" + x.HEURE_MVT.slice(4, 6);
                    }

                });

                this.EmitterMouvementStockArticleTrace();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public ExportInventaireTemplate(pIuZoneStock: string) {
        //console.log(pIuZoneStock)

        let URI = this._urlBaseAPI + this._urlPostExportInventaireTemplate;

        try {
            return this._httpClient.post(URI, JSON.stringify(pIuZoneStock),
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                //console.log(response)
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public ImportInventaireExcelAsync(pFormdata: FormData) {

        let URI = this._urlBaseAPI + this._urlImportInventaireExcel;

        try {
            return this._httpClient.post(URI, pFormdata,
                {
                    observe: 'response',
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                let ListArtReturn: VUE_STOCK_SUIVI_ARTICLE[] = ((response.body) as any);
                                ListArtReturn.map(x => {
                                    x.TotalHT = x.PRIX_VALO * x.STOCK_REEL;
                                });
                                return ListArtReturn;
                            }
                            return response.status;
                        },
                        (error) => {
                            return error;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }

    public ExportExcelEtatStock(pFilter: ExportEtatStock) {

        let URI = this._urlBaseAPI + this._urlPostExportExcelEtatStock;

        try {
            return this._httpClient.post(URI, pFilter,
                {
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }

    public ExportPdfEtatStock(pFilter: ExportEtatStock) {

        let URI = this._urlBaseAPI + this._urlPostGenerePdfEtatStock;

        try {
            return this._httpClient.post(URI, pFilter,
                {
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }

    public ExportPdfMouvementStockArt(pList: VUE_STOCK_MOUVEMENT_ART[]) {

        let URI = this._urlBaseAPI + this._urlPostGenerePdfMouvementStockArt;

        try {
            return this._httpClient.post(URI, pList,
                {
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return error;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }

    public GetListReceptionSync() {

        let urlAPI = this._urlBaseAPI + this._urlGetAllListReception;

        return this._httpClient.get<VUE_STOCK_LIST_REPCEPTION[]>(urlAPI).pipe(
            map((res: VUE_STOCK_LIST_REPCEPTION[]) => {
                res.map(x => {

                });
                // console.log('response  service  ', res)
                return res;
            })
        );
    }

    public SearchReceptionBLFactureServer(filter: { name: string } = { name: '' }, page = 1, etat?: string): Observable<VUE_STOCK_LIST_REPCEPTION[]> {

        let urlAPI = this._urlBaseAPI + this._urlSearchReception + filter.name;

        if (filter.name.length < 3) {
            return this.GetListReceptionSync();
        }
        else {
            return this._httpClient.get<VUE_STOCK_LIST_REPCEPTION[]>(urlAPI).pipe(
                map((res: VUE_STOCK_LIST_REPCEPTION[]) => {
                    res.map(x => {

                    });
                    return res;
                })
            );
        }
    }

    public FilterReceptionSync(pRecepFilter: FilterReceptionModel) {

        let urlAPI = this._urlBaseAPI + this._urlPostFilterReception;

        return this._httpClient.post<VUE_STOCK_LIST_REPCEPTION[]>(urlAPI, pRecepFilter);
    }

}