export class SearchArticleModel {
    constructor(public IU_DENREE: string,
        public CODE_DENREE: string,
        public LIB_DENREE: string,
        public NOM_DENREE: string,
        public IU_FOURNISSEUR: string,
        public NOM_FOURNISSEUR: string,
        public IU_FAMDEN: string,
        public FAMI_DENREE: string,
        public LIBFAM_DENREE: string,
        public IU_SFAMDEN: string,
        public SFAMI_DENREE: string,
        public LIBSFAM_DENREE: string,
        public PRESENT: string,
        public UNI_CALC: string,
        public DENREE_TVA: string,
        public CATALOG_PRIX_EUROS: string) { }
}

export interface IShopSearchResponse {
    total: number;
    results: SearchArticleModel[];
}