<div>
  <h1
    style="
      font: 500 20px/32px Roboto, 'Helvetica Neue', sans-serif;
      letter-spacing: normal;
      margin: 0 0 20px;
      display: block;
    "
    mat-dialog-title
  >
    Confirmation
  </h1>

  <div mat-dialog-content>
    <p>Voulez-vous vraiment envoyer la commande ?</p>
  </div>

  <div
    style="
      padding: 8px 0;
      display: flex;
      flex-wrap: wrap;
      min-height: 52px;
      align-items: center;
      box-sizing: content-box;
      margin-bottom: -24px;
    "
    mat-dialog-actions
    class="d-flex justify-content-center"
  >
    <button
      style="
        box-sizing: border-box;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        cursor: pointer;
        outline: none;
        border: none;
        -webkit-tap-highlight-color: transparent;
        display: inline-block;
        white-space: nowrap;
        text-decoration: none;
        vertical-align: baseline;
        text-align: center;
        margin: 0;
        min-width: 64px;
        line-height: 36px;
        padding: 0 16px;
        border-radius: 4px;
        overflow: visible;
      "
      mat-button
      class="button-generic-delete"
      (click)="onAnnulerEnvoyer()"
    >
      No
    </button>
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    <button
      style="
        box-sizing: border-box;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        cursor: pointer;
        outline: none;
        border: none;
        -webkit-tap-highlight-color: transparent;
        display: inline-block;
        white-space: nowrap;
        text-decoration: none;
        vertical-align: baseline;
        text-align: center;
        margin: 0;
        min-width: 64px;
        line-height: 36px;
        padding: 0 16px;
        border-radius: 4px;
        overflow: visible;
      "
      mat-stroked-button
      class="button-generic-validate"
      (click)="onConfirmEnvoyer()"
    >
      Oui
    </button>
  </div>
</div>
