import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { EnvService } from './core/services/environment/env.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private titleService: Title,
    private metaService: Meta,
    private _env: EnvService
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this._env.TITLE);
    this.metaService.addTags([
      { name: 'description', content: this._env.TITLE },
    ]);
  }
}
