<div style="display: flex;align-items: center;" class="align-middle">
    <div>
        <button class="btn-fileupload {{class}}" mat-mini-fab (click)="onClick($event)" *ngIf="mode != 'custom'">
            <i class="fas fa fa-upload"></i>
            {{chooseLabel}}
        </button>
        <button class="btn-fileupload {{class}}" mat-mini-fab matTooltip="{{toolTip | translate}}"
            matTooltipPosition="above" *ngIf="mode == 'custom'" (click)="onClick($event)">
            <img class="width-30" [src]="srcImg" />
        </button>
        <input #fileUpload type="file" class="input_fileupload--hidden" (input)="onInput($event)"
            (change)="onFileSelected($event)" [(ngModel)]="inputFileName" [accept]="accept">
    </div>

    <div *ngFor="let f of files">
        <div class="d-flex justify-content-center align-middle pe-5" style="align-items: center;">
            <div class="px-2">{{f.name}}</div>
            <div class="px-2">
                <button mat-icon-button [attr.title]="deleteButtonLabel?deleteButtonLabel:null"
                    (click)="removeFile(event,f)">
                    <i class="far fas fa-times fa-sm"> {{deleteButtonLabel}} </i>
                </button>
            </div>
        </div>


    </div>
</div>