import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

export class PdfData {

  constructor(public PdfSrcByte: any) {
  }
}

@Component({
  selector: 'app-pdf-preview-dialog',
  templateUrl: './pdf-preview-dialog.component.html',
  styleUrls: ['./pdf-preview-dialog.component.css']
})
export class PdfPreviewDialogComponent implements OnInit {

  public page = 1;

  public pageLabel!: string;


  private durationNotification: number = 5000;
  private _horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private _verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialogRef: MatDialogRef<PdfPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public pdfData: PdfData,
    private _snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
  }


  public NotificationMsg(msg: string, isOk: boolean) {
    if (isOk) {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-success']
      });
    }
    else {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-error']
      });
    }

  }


}
