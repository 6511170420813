import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from '../../../config';
import { EnvService } from "../environment/env.service";

@Injectable()
export class ControleCoherenceService {


    // url api
    private _urlBaseAPI: string;

    private _urlGetControleCoherenceSortieArt: string;

    constructor(private _httpClient: HttpClient, private env: EnvService) {
        this._urlBaseAPI = env.API_URL_BASE;
        this._urlGetControleCoherenceSortieArt = Config.API_ROUTES.COMMON_API.API_GET_CONTROLE_COHERENCE_SORTIE_ART;

    }

    public ControleSortirArticlesSync() {

        let urlAPI = this._urlBaseAPI + this._urlGetControleCoherenceSortieArt;

        return this._httpClient.get(urlAPI);
    }

}