<div class="container-fluid background-whitesmoke">
  <div class="row">
    <!-- <div class="col-md-6 mt-4 h4">
      <span class="font-weight-700">
        {{ "Detail" | translate }} {{ "Mouvement" | translate }}
      </span>
    </div> -->
    <div class="col-md-6">
      <div mat-dialog-actions class="d-flex justify-content-end">
        <button (click)="onClose()" matTooltip="{{ 'Cancel' | translate }}" mat-stroked-button
          class="button-icon-generic-cancel" type="button">
          <i class="fas fa-times"></i>
        </button>
        <!-- <button
          mat-button
          class="button-generic-delete"
          type="button"
          (click)="onClose()"
        >
          {{ "Close" | translate }}
        </button> -->
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col">
      <div class="container-art-ouput-view" style="position: relative; top: 0">
        <div>
          <EtatStockComponent [CodePr]="artInfo.pCodePr"> </EtatStockComponent>
        </div>
      </div>
    </div>
  </div>
  <br />
</div>