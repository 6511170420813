<div class="container-fluid background-whitesmoke">
  <div class="row">
    <div class="col-md">
      <div mat-dialog-actions class="d-flex justify-content-end">
        <button
          (click)="onClose()"
          mat-stroked-button
          class="button-icon-generic-cancel"
          type="button"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col">
      <div class="container-art-ouput-view" style="position: relative; top: 0">
        <cdk-virtual-scroll-viewport tvsItemSize class="wrapper height-550">
          <div>
            <DetailSupplierComponent
              [Detailfourni]="fourInfo.fournIU"
              [isUpdatedInfRight]="isUpdatedInf"
            >
            </DetailSupplierComponent>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>
  <br />
</div>
