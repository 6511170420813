import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/of';
import * as _ from "lodash-es";
import { EnvService } from '../../environment/env.service';
import { Config } from '../../../../config';
import { GRAAL_CENTRE } from '../../../model/common/graal_centre.model';
import { catchError, map } from 'rxjs/operators';
import { GRAAL_SERVICE } from '../../../model/member/parametre/service-etab/graal_service.model';
import { TreeEtabServModel } from '../../../model/member/parametre/service-etab/tree-etab.model';
import { DynamicFlatNode } from '../../../../member/parametre/service-etablissement/service-etab/service-etab.component';
import { DenreeModel } from '../../../model/member/purchases/denree/denree.model';
import { STOCK_ZONE_STOCK } from '../../../model/common/zone-stock.model';
import { GES_PRES } from '../../../model/common/ges_pres.model';
import { ParamAttachArtZoneStockageModel } from '../../../model/member/parametre/stock/attach-art-zone-stock.model';




@Injectable()
export class ParametreService {

    // url api
    private _urlBaseAPI: string;

    private _urlGetAllEtabliAPI: string;
    private _urlGetAllServiceEtab: string;

    private _urlGetDetailServiceInfo: string;
    private _urlLibServCondition: string;

    // save
    private _urlPostEditEtablissemnt: string;
    private _urlPostEditServiceEtab: string;

    private _urlGetAllArticlesByZoneStock: string;

    private _urlPostEditZoneStock: string;

    private _urlPostDeleteZoneStock: string;

    private _urlPostDeleteServiceEtab: string;

    private _urlPostDeleteEtablissement: string;

    private _urlPostEditTarifDenree: string;

    private _urlPostDeleteTarifDenree: string;

    private _urlPostEditCondArtUniPoid: string;

    private _urlPostDeleteCondArtUniPoid: string;

    private _urlGetParamHeaderCmd: string;

    private _urlPostEditParamHeaderCmd: string;

    private _urlGetParamEtab: string;

    private _urlGetParamEtabInfoSup: string;

    private _urlGetParamPreMailCmd: string;

    private _urlPostSaveEditParamEtab: string;

    private _urlPostDeleteAdrlivraison: string;

    private _urlPostSaveLogoEtab: string;

    private _urlPostAttachArtZoneStock: string;

    // subscription and model

    public ListEtabSubject = new Subject<GRAAL_CENTRE[]>();
    private _listEtablissement: GRAAL_CENTRE[] = [];

    public DetailServInfoSubject = new Subject<GRAAL_SERVICE>();
    private _detailServInfo: GRAAL_SERVICE;

    public ListLibServConditionSubject = new Subject<GRAAL_CENTRE[]>();
    private _listLibServCondition: GRAAL_CENTRE[] = [];

    public ListArticleZoneStock = new Subject<DenreeModel[]>();
    private _listArticleZoneStock: DenreeModel[] = [];

    public ListParamHeaderCmdSubject = new Subject<GRAAL_CENTRE[][]>();
    private _listParamHeaderCmd: GRAAL_CENTRE[][] = [];

    public ExpandNodeStateSubject = new Subject<DynamicFlatNode>();
    private _ExpandNode: DynamicFlatNode;

    // subscription lors un noeud de service est ajouté
    public AddNodeSubject = new Subject<DynamicFlatNode>();
    private _addedNode: DynamicFlatNode;

    // subscription lors un noeud d'article est supprimé
    public DeletedNodeSubject = new Subject<DynamicFlatNode>();
    private _deletedNode: DynamicFlatNode;

    constructor(private _httpClient: HttpClient, private env: EnvService) {
        this._urlBaseAPI = env.API_URL_BASE;
        this._urlGetAllEtabliAPI = Config.API_ROUTES.COMMON_API.API_GET_ALL_ETABLISSEMENTS;
        this._urlGetAllServiceEtab = Config.API_ROUTES.COMMON_API.API_GET_ALL_SERVICE_ETAB;
        this._urlGetDetailServiceInfo = Config.API_ROUTES.COMMON_API.API_GET_DETAIL_SERVICE_INFO;
        this._urlLibServCondition = Config.API_ROUTES.COMMON_API.API_GET_LIB_SERV_CONDITION;
        this._urlPostEditEtablissemnt = Config.API_ROUTES.PARAM_API.API_POST_EDIT_ETABLISSEMENT;
        this._urlPostEditServiceEtab = Config.API_ROUTES.PARAM_API.API_POST_EDIT_SERVICE_ETAB;
        this._urlGetAllArticlesByZoneStock = Config.API_ROUTES.PARAM_API.API_GET_ARTICLES_ZONE_STOCK;
        this._urlPostEditZoneStock = Config.API_ROUTES.PARAM_API.API_POST_EDIT_ZONE_STOCK;
        this._urlPostDeleteZoneStock = Config.API_ROUTES.PARAM_API.API_POST_DELETE_ZONE_STOCK;
        this._urlPostDeleteServiceEtab = Config.API_ROUTES.PARAM_API.API_POST_DELETE_SERVICE_ETAB;
        this._urlPostDeleteEtablissement = Config.API_ROUTES.PARAM_API.API_POST_DELETE_ETABLISSEMENT;
        this._urlPostEditTarifDenree = Config.API_ROUTES.PARAM_API.API_POST_EDIT_TARIF_DENREE;
        this._urlPostDeleteTarifDenree = Config.API_ROUTES.PARAM_API.API_POST_DELETE_TARIF_DENREE;
        this._urlPostEditCondArtUniPoid = Config.API_ROUTES.PARAM_API.API_POST_EDIT_COND_ART_UNI_POID;
        this._urlPostDeleteCondArtUniPoid = Config.API_ROUTES.PARAM_API.API_POST_DELETE_COND_ART_UNI_POID;
        this._urlGetParamHeaderCmd = Config.API_ROUTES.PARAM_API.API_GET_PARAM_HEADER_CMD;
        this._urlPostEditParamHeaderCmd = Config.API_ROUTES.PARAM_API.API_POST_EDIT_PARAM_HEADER;
        this._urlGetParamEtab = Config.API_ROUTES.PARAM_API.API_GET_PARAM_ETAB;
        this._urlGetParamEtabInfoSup = Config.API_ROUTES.PARAM_API.API_GET_PARAM_ETAB_INFO_SUP;
        this._urlPostSaveEditParamEtab = Config.API_ROUTES.PARAM_API.API_POST_EDIT_PARAM_ETAB;
        this._urlPostDeleteAdrlivraison = Config.API_ROUTES.PARAM_API.API_POST_DELETE_ADR_LIVRAISON;
        this._urlPostSaveLogoEtab = Config.API_ROUTES.PARAM_API.API_POST_SAVE_LOGO_ETAB;
        this._urlGetParamPreMailCmd = Config.API_ROUTES.PARAM_API.API_GET_PARAM_PREMAIL_CMD;
        this._urlPostAttachArtZoneStock = Config.API_ROUTES.PARAM_API.API_POST_ATTACH_ART_ZONE_STOCK;

    }

    public EmitterExpandNode(ExpandNode: DynamicFlatNode) {
        this.ExpandNodeStateSubject.next(_.cloneDeep(ExpandNode));
    }

    public EmitterAddedNode(AddedNode: DynamicFlatNode) {
        this.AddNodeSubject.next(_.cloneDeep(AddedNode));
    }

    // send event lors un noeud de service est supprimé
    public EmitterDeletedNode(DeletedNode: DynamicFlatNode) {
        this.DeletedNodeSubject.next(_.cloneDeep(DeletedNode));
    }

    public EmitterListLibConditionService() {
        if (this._listLibServCondition != undefined) {
            this.ListLibServConditionSubject.next(this._listLibServCondition.slice());
        }
    }

    public EmitterListParamHeaderCmd() {
        if (this._listParamHeaderCmd != undefined) {
            this.ListParamHeaderCmdSubject.next(this._listParamHeaderCmd.slice());
        }
    }

    public EmitterListArticleZoneStock() {
        if (this._listArticleZoneStock != undefined) {
            this.ListArticleZoneStock.next(this._listArticleZoneStock.slice());
        }
    }

    public EmitterDetailServInfoSubject() {
        if (this._detailServInfo != null) {
            this.DetailServInfoSubject.next(_.cloneDeep(this._detailServInfo));
        }
    }

    public EmitterListEtabSubject() {
        if (this._listEtablissement != undefined) {
            this.ListEtabSubject.next(this._listEtablissement.slice());
        }
    }

    public GetListEtablissement() {

        let urlAPI = this._urlBaseAPI + this._urlGetAllEtabliAPI;

        this._httpClient.get<GRAAL_CENTRE[]>(urlAPI).subscribe(
            (response: GRAAL_CENTRE[]) => {
                this._listEtablissement = response;
                //console.log(this._listEtablissement)
                this.EmitterListEtabSubject();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetServiceEtab(node: any) {

        let urlAPISFam = this._urlBaseAPI + this._urlGetAllServiceEtab + node.codeEtab;
        return this._httpClient.get(urlAPISFam)
            .pipe(
                map((res: GRAAL_SERVICE[]) => {

                    //console.log(data);

                    if (res) {
                        let data: TreeEtabServModel[] = [];
                        res.map(x => {
                            data = [...data, {
                                CODE_ETAB: x.SERV_ETAB,
                                CODE_SERV: x.SERV_SERV,
                                LIB_SERV: x.SERV_NOM,
                                LIB_ETAB: '',
                                isExpandable: false
                            }];
                        });
                        return data;

                    } else {

                        let data: TreeEtabServModel[] = [];
                        return data;
                    }
                }
                )
            );
    }

    public GetEtablissementInfoSync() {

        let urlAPI = this._urlBaseAPI + this._urlGetParamEtab;

        return this._httpClient.get(urlAPI)
            .pipe(
                map((res: GRAAL_CENTRE[]) => {
                    return res;
                })
            );
    }

    public GetEtablissementInfoSupSync() {

        let urlAPI = this._urlBaseAPI + this._urlGetParamEtabInfoSup;

        return this._httpClient.get(urlAPI)
            .pipe(
                map((res: GRAAL_CENTRE[]) => {
                    return res;
                })
            );
    }

    public GetPreMailCmdSync() {

        let urlAPI = this._urlBaseAPI + this._urlGetParamPreMailCmd;

        return this._httpClient.get(urlAPI)
            .pipe(
                map((res: GRAAL_CENTRE[]) => {
                    return res;
                })
            );
    }

    public GetParamHeaderCmd() {

        let urlAPI = this._urlBaseAPI + this._urlGetParamHeaderCmd;

        this._httpClient.get<GRAAL_CENTRE[][]>(urlAPI).subscribe(
            (response: GRAAL_CENTRE[][]) => {
                this._listParamHeaderCmd = response;
                this.EmitterListParamHeaderCmd();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetDetailServiceInfo(pEtab: string, pServ: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetDetailServiceInfo + "etab=" + pEtab + "&serv=" + pServ;

        this._httpClient.get<GRAAL_SERVICE>(urlAPI).subscribe(
            (response: GRAAL_SERVICE) => {
                this._detailServInfo = response;
                this.EmitterDetailServInfoSubject();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetDetailServiceLibCondition() {

        let urlAPI = this._urlBaseAPI + this._urlLibServCondition;

        this._httpClient.get<GRAAL_CENTRE[]>(urlAPI).subscribe(
            (response: GRAAL_CENTRE[]) => {
                this._listLibServCondition = response;
                this.EmitterListLibConditionService();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public SaveEditEtablissemnt(pEtab: GRAAL_CENTRE) {

        let URI = this._urlBaseAPI + this._urlPostEditEtablissemnt;

        try {
            return this._httpClient.post(URI, pEtab,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return 200;
                            }
                            return response.status;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }
    }

    public SaveEditServiceEtab(pEditService: GRAAL_SERVICE): Observable<string> {

        let URI = this._urlBaseAPI + this._urlPostEditServiceEtab;

        try {
            return this._httpClient.post(URI, pEditService,
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    'responseType': 'text'
                })
                .pipe(
                    map(
                        (response) => {


                            if (response.status == 200) {
                                //console.log(response.body);
                                return response.body;
                            }
                            else {
                                return "KO";
                            }
                        },
                        (error) => {
                            return "KO";
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of("KO");
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of("KO");
        }
    }

    public InitService(pCodeEtab: string, pNomEtab: string): GRAAL_SERVICE {
        let NewService: GRAAL_SERVICE = {
            _NomEtab: pNomEtab,
            SERV_ETAB: pCodeEtab,
            SERV_SERV: '-1',
            SERV_NOM: '',
            SERV_COND: '0',
            SERV_TYPE: '1',
            SERV_REGIME: '',
            SERV_IND_COL: '0',
            DATHRMAJ: '',
            USERMAJ: '',
            GESTDEL: '',
            SERV_GAMME: '',
            SERV_SCOL: '0',
            SERV_REGIMES: '',
        }

        return NewService;
    }

    public CreateNewService(pNewService: GRAAL_SERVICE) {
        this._detailServInfo = pNewService;
        this.EmitterDetailServInfoSubject();
    }

    public GetAllArticleByZoneStock(pIuZoneStock: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetAllArticlesByZoneStock + pIuZoneStock;

        this._httpClient.get<DenreeModel[]>(urlAPI).subscribe(
            (response: DenreeModel[]) => {
                this._listArticleZoneStock = response;
                this.EmitterListArticleZoneStock();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public SaveEditZoneStock(pIuStock: string, pNomZoneStock: string): Observable<string> {


        let ZoneStock: STOCK_ZONE_STOCK = {
            IU_STOCK: '',
            IU_ZONE_STOCK: pIuStock,
            LIB_ZONE_STOCK: pNomZoneStock,
            COMM_ZONE_STOCK: '',
            DATE_INVENTAIRE: '',
            GESTDEL: ''
        };

        let URI = this._urlBaseAPI + this._urlPostEditZoneStock;

        try {
            return this._httpClient.post(URI, ZoneStock,
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    'responseType': 'text'
                })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return response.body;
                            }
                            return "KO";
                        },
                        (error) => {
                            return "KO";
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of("KO");
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of("KO");
        }
    }

    public DeleteZoneStock(pIuStock: string) {


        let ZoneStockDelete: STOCK_ZONE_STOCK = {
            IU_STOCK: '',
            IU_ZONE_STOCK: pIuStock,
            LIB_ZONE_STOCK: '',
            COMM_ZONE_STOCK: '',
            DATE_INVENTAIRE: '',
            GESTDEL: ''
        };
        let URI = this._urlBaseAPI + this._urlPostDeleteZoneStock;

        try {
            return this._httpClient.post(URI, ZoneStockDelete,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return 200;
                            }
                            return response.status;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }

    }



    public DeleteServiceEtab(pServiceSrv: string, pServiceEtab: string) {

        let ServDelete: GRAAL_SERVICE = {
            SERV_ETAB: pServiceEtab,
            SERV_SERV: pServiceSrv,
            SERV_NOM: '',
            SERV_TYPE: '',
            SERV_REGIME: '',
            SERV_GAMME: '',
            SERV_IND_COL: '',
            SERV_COND: '',
            SERV_SCOL: '',
            DATHRMAJ: '',
            GESTDEL: '',
            SERV_REGIMES: '',
            USERMAJ: '',
            _NomEtab: ''
        };

        let URI = this._urlBaseAPI + this._urlPostDeleteServiceEtab;

        try {
            return this._httpClient.post(URI, ServDelete,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return 200;
                            }
                            return response.status;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }
    }

    public DeleteEtablissement(pCodeEtab: string) {

        let URI = this._urlBaseAPI + this._urlPostDeleteEtablissement;

        try {
            return this._httpClient.post(URI, JSON.stringify(pCodeEtab),
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return 200;
                            }
                            return response.status;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }

    }

    public SaveEditTarifDenree(pTarifClepar: string, pTarifLib: string) {
        let EditTarif: GRAAL_CENTRE = {
            CLEPAR: pTarifClepar,
            PARALIB: pTarifLib,
            PARAM: '',
            PARAVAL: '',
            LIB_Id: '',
            HTMLTEXT: '',
        };

        let URI = this._urlBaseAPI + this._urlPostEditTarifDenree;

        try {
            return this._httpClient.post(URI, EditTarif,
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    'responseType': 'text'
                })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return response.body;
                            }
                            return "KO";
                        },
                        (error) => {
                            return "KO";
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of("KO");
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of("KO");
        }
    }

    public DeleteTarifDenree(pIdTarifClepar: string) {


        let URI = this._urlBaseAPI + this._urlPostDeleteTarifDenree;

        try {
            return this._httpClient.post(URI, JSON.stringify(pIdTarifClepar),
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return 200;
                            }
                            return response.status;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }

    }

    public SaveEditUniPoid(pIuFou: string, pfouLib: string) {
        let EditUniPoid: GES_PRES = {
            PRES_FOU_IU: pIuFou,
            DATHRMAJ: '',
            GESTDEL: '',
            PRES_FOU_LIB: pfouLib,
            USERMAJ: ''
        };

        let URI = this._urlBaseAPI + this._urlPostEditCondArtUniPoid;

        try {
            return this._httpClient.post(URI, EditUniPoid,
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    'responseType': 'text'
                })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return response.body;
                            }
                            return "KO";
                        },
                        (error) => {
                            return "KO";
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of("KO");
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of("KO");
        }
    }

    public DeleteUniPoid(piuUniPoid: string) {


        let URI = this._urlBaseAPI + this._urlPostDeleteCondArtUniPoid;

        try {
            return this._httpClient.post(URI, JSON.stringify(piuUniPoid),
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return 200;
                            }
                            return response.status;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }

    }

    public SaveEditParamHeaderCmd(pParamheaderCmd: GRAAL_CENTRE[]) {

        let URI = this._urlBaseAPI + this._urlPostEditParamHeaderCmd;

        try {
            return this._httpClient.post(URI, pParamheaderCmd,
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    'responseType': 'text'
                })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return response.body;
                            }
                            return "KO";
                        },
                        (error) => {
                            return "KO";
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of("KO");
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of("KO");
        }
    }

    public SaveEditParamEtab(pParamEtab: GRAAL_CENTRE[]) {

        let URI = this._urlBaseAPI + this._urlPostSaveEditParamEtab;

        try {
            return this._httpClient.post(URI, pParamEtab,
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    'responseType': 'text'
                })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return response.body;
                            }
                            return "KO";
                        },
                        (error) => {
                            return "KO";
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of("KO");
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of("KO");
        }
    }

    public DeleteAdresseLivaison(pListAdr: GRAAL_CENTRE[]) {


        let URI = this._urlBaseAPI + this._urlPostDeleteAdrlivraison;

        try {
            return this._httpClient.post(URI, pListAdr,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return 200;
                            }
                            return response.status;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }

    }

    public SaveLogoEtab(pLogo: any) {

        let URI = this._urlBaseAPI + this._urlPostSaveLogoEtab;

        try {
            return this._httpClient.post(URI, JSON.stringify(pLogo),
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return 200;
                            }
                            return response.status;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }
    }

    public AttachArtZoneStockage(ListArt: DenreeModel[], ZoneDest: string) {

        let URI = this._urlBaseAPI + this._urlPostAttachArtZoneStock;

        let ArtAttachZone: ParamAttachArtZoneStockageModel = {
            ListArt: ListArt,
            ZoneDest: ZoneDest
        }

        try {
            return this._httpClient.post(URI, ArtAttachZone,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                return 200;
                            }
                            return response.status;
                        },
                        (error) => {
                            return error;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }
}
