<div class="container error-page">
  <div class="row">
    <div class="col-md-12">
      <img class="error-page-logo" src="../../../assets/images/logo-wenzi.svg" alt="W Wenzi circle" />
      <h1 class="error-page-text"> OOOPS <br />
        PAGE NOT FOUND</h1>
      <button type="button" class="btn btn-lg error-page-button" (click)="RedirectHomePage()">
        HOME PAGE
      </button>
    </div>
  </div>

</div>