import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf, LOCALE_ID } from '@angular/core';
import { throwIfAlreadyLoaded } from './module-import';
import { ModuleWithProviders } from '@angular/core';
import { SharedModule } from '../shared/share.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AuthGuard } from './guards/auth-guard';
import { SupplierService } from './services/member/purchages/supplier/supplier.service';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { DenreeService } from './services/member/purchages/supplier/denree.service';
import { ErrorCustomService } from './services/error/error-custom.service';
import { CommonService } from './services/common/common.service';
import { ParametreService } from './services/member/parametre/parametre.service';
import { ParamAppService } from './services/member/param-app/param-app.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommandesService } from './services/member/commandes/commandes.service';
import { SpinnerLoaderService } from './services/loader/spinner/spinner-loader.service';
import { StockService } from './services/member/stock/stock.service';
import { ControleCoherenceService } from './services/controle-coherance/controle-coherence.service';
import { MercurialeService } from './services/member/mercuriales/mercuriale.service';
import { LayoutService } from './services/member/layout/layout.service';
import { StatsService } from './services/member/statistiques/stats.service';
import { DashboardService } from './services/member/dashboard/dashboard.service';
import { NotificationService } from './services/util/notification.service';
import { RecetteService } from './services/member/recettes/recette.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';



registerLocaleData(localeFr);

@NgModule({
    imports: [
        SharedModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [],
    providers: [
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ]
})


export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                {
                    provide: LOCALE_ID,
                    useValue: 'fr-FR' // 'fr-FR' for France ...
                },
                AuthGuard,
                LayoutService,
                ErrorCustomService,
                NotificationService,
                SupplierService,
                DenreeService,
                CommonService,
                ParametreService,
                CommandesService,
                SpinnerLoaderService,
                StockService,
                ControleCoherenceService,
                MercurialeService,
                StatsService,
                DashboardService,
                ParamAppService,
                RecetteService
            ]
        };
    }
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}