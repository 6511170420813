<div class="row">
  <div class="col-md-12 mt-4">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-md-10 col-xl-6">
          <mat-card class="bottom-5 p-0">
            <div class="d-flex justify-content-between">
              <div>
                <img style="width: 40px" src="../../../../assets/images/icons-menu/articles-black.svg" />
              </div>
              <div class="d-flex align-items-center font-size-14 pe-2">
                {{ this.CodePr }}
              </div>
              <div class="d-flex align-items-center font-size-14">
                {{
                this.ListMvtArt.length != 0
                ? this.ListMvtArt[0].NOM_DENREE
                : ""
                }}
              </div>
              <div class="d-flex align-items-center font-size-14">
                N° de lot{{
                this.ListMvtArt.length != 0
                ? " : " + this.ListMvtArt[0].NUM_LOT
                : ""
                }}
              </div>
              <div class="d-flex align-items-center font-size-14">DLC{{
                this.ListMvtArt.length != 0
                ? " : " + this.ListMvtArt[0].DATE_LIMITE
                : ""
                }}
              </div>
              <div class="d-flex align-items-center">
                <!-- TODO => hide btn or add click function to display pop-up-->
                <button mat-button matTooltip="Voir" type="button">
                  <img class="width-20" src="../../../../assets/images/icons-commande/eye-blue-87.svg" />
                </button>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="col-md-2 col-xl-6">
          <div class="float-end">
            <button class="button-round round-button-icon" matTooltip="{{ 'PDF' | translate }}" type="button"
              (click)="GenererPdfMvtArt()" matTooltipPosition="above" mat-mini-fab>
              <img class="width-30" src="../../../../../assets/images/icons-commande/export-pdf-bleu.svg" />
            </button>

            <!-- <span class="btn-space"></span>
            <button class="button-round round-button-icon" matTooltip="{{ 'Print' | translate }}"
              matTooltipPosition="above" mat-mini-fab>
              <img class="width-30" src="../../../../../assets/images/icons-commande/print-bleu.svg" />
            </button> -->
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-xl-12">
          <div class="container-fluid p-0">
            <cdk-virtual-scroll-viewport tvsItemSize class="wrapper height-550">
              <table class="table width-100-percent no-border-table" mat-table [dataSource]="dataSourceListStockArtMvt"
                matSort>
                <ng-container matColumnDef="DATE_MVT">
                  <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                    mat-sort-header>
                    {{ "Date" | translate }}
                  </th>
                  <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                    {{ element.DATE_MVT }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="HEURE_MVT">
                  <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                    mat-sort-header>
                    {{ "Time" | translate }}
                  </th>
                  <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                    {{ element.HEURE_MVT }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="TYPE_MVT">
                  <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                    mat-sort-header>
                    {{ "Type" | translate }}
                  </th>
                  <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                    <div [ngClass]="[
                        element.TYPE_MVT === 'E' || element.TYPE_MVT === 'R'
                          ? 'green'
                          : '',
                        element.TYPE_MVT === 'S' ? 'orange' : '',
                        element.TYPE_MVT === 'C' ? 'red' : '',
                        element.TYPE_MVT === 'A' ? 'red' : ''
                      ]">
                      <span *ngIf="element.TYPE_MVT == 'E'">
                        {{ "Entrance" | translate }}
                      </span>
                      <span *ngIf="element.TYPE_MVT == 'S'">
                        {{ "Output" | translate }}
                      </span>
                      <span *ngIf="element.TYPE_MVT == 'I'">
                        {{ "Inventory" | translate }}
                      </span>
                      <span *ngIf="element.TYPE_MVT == 'X'">
                        {{ "Reconstruction" | translate }}
                      </span>
                      <span *ngIf="element.TYPE_MVT == 'A'"> Avoir </span>

                      <span *ngIf="element.TYPE_MVT == 'C'"> Annulé </span>

                      <span *ngIf="element.TYPE_MVT == 'R'">
                        {{ "Return" | translate }}
                      </span>
                      <span *ngIf="element.TYPE_MVT == 'M'">
                        {{ "Edit" | translate }}
                      </span>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="QUANTITE">
                  <th class="thead-dark font-size-th-list-command width-200" mat-header-cell *matHeaderCellDef
                    mat-sort-header>
                    {{ "Qty" | translate }}
                  </th>
                  <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                    <div [ngClass]="
                        element.TYPE_MVT == 'E' || element.TYPE_MVT == 'R'
                          ? 'float-left'
                          : element.TYPE_MVT == 'S'
                          ? 'float-right'
                          : 'center'
                      " class="d-flex">
                      <div class="px-2 d-flex">
                        {{ element.QUANTITE }}
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="US">
                  <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                    mat-sort-header>
                    US
                  </th>
                  <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                    {{ element.US }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="DATE_LIMITE">
                  <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                    mat-sort-header>
                    {{ "UseByDate" | translate }}
                  </th>
                  <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                    {{ element.DATE_LIMITE }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="NUM_LOT">
                  <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                    mat-sort-header>
                    {{ "LotNumber" | translate }}
                  </th>
                  <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                    {{ element.NUM_LOT }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="TEMPERATURE">
                  <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                    mat-sort-header>
                    T C
                  </th>
                  <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                    {{ element.TEMPERATURE }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="PRIX_UNITAIRE">
                  <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                    mat-sort-header>
                    PU HT €
                  </th>
                  <td class="text-end border-col-table" mat-cell *matCellDef="let element">
                    <div class="pe-2">
                      {{ element.PRIX_UNITAIRE }}
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="PMP_TIME">
                  <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                    mat-sort-header>
                    PMP
                  </th>
                  <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                    {{ element.PMP_TIME }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="QTE_TIME">
                  <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                    mat-sort-header>
                    {{ "Stock" | translate }}
                  </th>
                  <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                    {{ element.QTE_TIME }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="NOM_FOURNISSEUR">
                  <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                    mat-sort-header>
                    {{ "Supplier" | translate }}
                  </th>
                  <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                    {{ element.NOM_FOURNISSEUR }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="CreatedDate">
                  <th class="thead-dark font-size-th-list-command width-auto" mat-header-cell *matHeaderCellDef
                    mat-sort-header>
                    Date de saisie
                  </th>
                  <td class="text-center border-col-table" mat-cell *matCellDef="let element">
                    {{ element.CreatedDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
              <!-- Seen with Vanessa 17/10/22 no need to display the total -->
              <!-- <div class="col-md size-police-content-global-command mt-2">
                <div
                  class="odd-row-total-global-command display-table-row d-flex justify-content-end"
                >
                  <span class="font-weight-700 display-table-cell col-md-1"
                    >{{ "TotalExclTax" | translate }} :
                  </span>
                  <span class="float-end font-weight-700 display-table-cell">{{
                    CalculTotalMontalHT() | currency: "EUR":"symbol":"1.2-2"
                  }}</span>
                </div>
              </div> -->
              <div *ngIf="!IsLoaded">
                <ng-container *ngFor="let number of [].constructor(8)">
                  <div class="row">
                    <div class="col">
                      <content-loader [Height]="50"></content-loader>
                      <br />
                    </div>
                  </div>
                </ng-container>
              </div>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-xl-12">
          <div class="float-end">
            <button mat-stroked-button class="button-footer-form button-generic-cancel mt-2" type="button">
              {{ "Cancel" | translate }}
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
<Spinner-Loader class="spinner-loader-custom" [color]="colorLoader" [mode]="modeLoader" [value]="valueLoader"
  [msg]="'Veuillez patienter, nous recalculons l\'état de stock'" [backdropEnabled]="true"
  [positionGloballyCenter]="true" [displayProgressSpinner]="displayProgressSpinner">
</Spinner-Loader>