import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import 'rxjs/add/observable/of';
import * as _ from 'lodash-es';
import { EnvService } from '../environment/env.service';
import { Config } from '../../../config';
import { catchError, map } from 'rxjs/operators';
import { GES_PRES } from '../../model/common/ges_pres.model';
import { GRAAL_CENTRE } from '../../model/common/graal_centre.model';
import { STOCK_STOCK } from '../../model/common/stock.model';
import { STOCK_ZONE_STOCK } from '../../model/common/zone-stock.model';
import { GRAAL_REGIME } from '../../model/common/graal_regime.model';
import { GES_GAMME } from '../../model/common/ges_gamme.model';
import { GRAAL_LIBELLE } from '../../model/common/graal_libelle.model';
import { ART_DENREE_EGALIM } from '../../model/member/purchases/denree/denree-egalim.model';
import { ART_DENREE_ALLERGENES } from '../../model/member/purchases/denree/denree-allergenes.model';
import { SelectValueModel } from '../../model/common/select_value.model';
import { NUTRI_GROUPE } from '../../model/member/purchases/denree/NUTRI_GROUPE.model';
import { GesRights } from '../../model/common/ges-rights.model';
import jwt_decode from 'jwt-decode';
import { SendMailCommonModel } from '../../model/common/send-mail-common.model';

@Injectable()
export class CommonService {
  public enableDebug: boolean;
  // url api
  private _urlBaseAPI: string;

  private _urlGetUnitPoid: string;

  private _urlGetUniCal: string;

  private _urlGetGraalCentre: string;

  private _urlGetAllStock: string;

  private _urlGetAllZoneStock: string;

  private _urlGetAllTva: string;

  private _urlGetAllTypeDenreeInfo: string;

  private _urlGetAllRegime: string;

  private _urlGetAllGesGamme: string;

  private _urlGetAllEgalim: string;

  private _urlGetArtDenreeEgalim: string;

  private _urlGetAllAllergenes: string;

  private _urlGetArtDenreeAllergenes: string;

  private _urlGetAllLettersCmd: string;

  private _urlGetAllNutriGroup: string;

  private _urlGetRights: string;

  private _urlGetAllArdLivraison: string;

  private _urlPostSendMailCommon: string;

  private _urlGetAllRecetteSalaison: string;

  //private _urlGetAllUser: string;////////////////dani

  // subscription and model

  public NutriGroupSubject = new Subject<NUTRI_GROUPE[]>();
  private _listNutriGroup: NUTRI_GROUPE[] = [];

  public UnitPoidsSubject = new Subject<GES_PRES[]>();
  private _listUnitPoids: GES_PRES[] = [];

  public UnitCalculsSubject = new Subject<GRAAL_LIBELLE[]>();
  private _listUnitCalcul: GRAAL_LIBELLE[] = [];

  public GraalCentreSubject = new Subject<GRAAL_CENTRE[]>();
  private _listGraalCentre: GRAAL_CENTRE[] = [];

  public ListStockSubject = new Subject<STOCK_STOCK[]>();
  private _listStock: STOCK_STOCK[] = [];

  public ListZoneStockSubject = new Subject<STOCK_ZONE_STOCK[]>();
  private _listZoneStock: STOCK_ZONE_STOCK[] = [];

  public ListTvaSubject = new Subject<GRAAL_CENTRE[]>();
  private _listTva: GRAAL_CENTRE[] = [];

  public ListTypeInfoDenreeSubject = new Subject<GRAAL_CENTRE[]>();
  private _listTypeInfoDenree: GRAAL_CENTRE[] = [];

  public ListRegimeSubject = new Subject<GRAAL_REGIME[]>();
  private _listRegime: GRAAL_REGIME[] = [];

  public ListGesGammeSubject = new Subject<GES_GAMME[]>();
  private _listGesGamme: GES_GAMME[] = [];

  public ListEgalimSubject = new Subject<GRAAL_LIBELLE[]>();
  private _listEgalim: GRAAL_LIBELLE[] = [];

  public ListAllergenesSubject = new Subject<GRAAL_LIBELLE[]>();
  private _listAllergenes: GRAAL_LIBELLE[] = [];

  public ListDenreeEgalimSubject = new Subject<ART_DENREE_EGALIM[]>();
  private _listDenreeEgalim: ART_DENREE_EGALIM[] = [];

  public ListDenreeAllergenesSubject = new Subject<ART_DENREE_ALLERGENES[]>();
  private _listDenreeAllergenes: ART_DENREE_ALLERGENES[] = [];

  public ListLetterCmdSubject = new Subject<SelectValueModel[]>();
  private _listLetterCmd: SelectValueModel[] = [];

  public ListRightsSubject = new Subject<GesRights[]>();
  private _listRights: GesRights[] = [];


  constructor(private _httpClient: HttpClient, private env: EnvService) {
    this.enableDebug = env.enableDebug;

    this._urlBaseAPI = env.API_URL_BASE;
    this._urlGetUnitPoid = Config.API_ROUTES.COMMON_API.API_GET_UNIT_POIDS;
    this._urlGetGraalCentre = Config.API_ROUTES.COMMON_API.API_GET_GRAAL_CENTRE_PREPA;
    this._urlGetAllStock = Config.API_ROUTES.COMMON_API.API_GET_ALL_STOCK;
    this._urlGetAllZoneStock = Config.API_ROUTES.COMMON_API.API_GET_ALL_ZONE_STOCK;
    // this._urlGetAllZoneStock = Config.API_ROUTES.COMMON_API.API_GET_ALL_ZONE_STOCK;
    this._urlGetAllTva = Config.API_ROUTES.COMMON_API.API_GET_ALL_TVA;
    this._urlGetAllTypeDenreeInfo = Config.API_ROUTES.COMMON_API.API_GET_ALL_TYPE_DENREE_INFO;
    this._urlGetAllRegime = Config.API_ROUTES.COMMON_API.API_GET_ALL_REGIME;
    this._urlGetAllGesGamme = Config.API_ROUTES.COMMON_API.API_GET_ALL_GES_GAMME;
    this._urlGetAllEgalim = Config.API_ROUTES.COMMON_API.API_GET_ALL_EGALIM;
    this._urlGetArtDenreeEgalim = Config.API_ROUTES.COMMON_API.API_GET_DENREE_EGALIM;
    this._urlGetAllAllergenes = Config.API_ROUTES.COMMON_API.API_GET_ALL_ALLERGENES;
    this._urlGetArtDenreeAllergenes =
      Config.API_ROUTES.COMMON_API.API_GET_DENREE_ALLERGENES;
    this._urlGetUniCal = Config.API_ROUTES.COMMON_API.API_GET_UNIT_CALCUL;
    this._urlGetAllLettersCmd =
      Config.API_ROUTES.COMMON_API.API_GET_ALL_LETTERS_CMD;
    this._urlGetAllNutriGroup =
      Config.API_ROUTES.COMMON_API.API_GET_ALL_NUTRI_GROUP;
    this._urlGetRights = Config.API_ROUTES.COMMON_API.API_POST_RIGHTS;
    this._urlGetAllArdLivraison =
      Config.API_ROUTES.COMMON_API.API_GET_ALL_ADR_LIVRAISON;
    this._urlPostSendMailCommon =
      Config.API_ROUTES.COMMON_API.API_POST_SEND_MAIL_COMMON;
    this._urlGetAllRecetteSalaison = Config.API_ROUTES.COMMON_API.API_GET_RECIPE_SALAISON;
  }

  public GetRights() {
    return this._listRights.slice();
  }

  public EmitterListRightsSubject() {
    if (this._listRights) {
      this.ListRightsSubject.next(this._listRights.slice());
    }
  }

  public EmitterListDenreeAllergenesSubject() {
    if (this._listDenreeAllergenes) {
      this.ListDenreeAllergenesSubject.next(this._listDenreeAllergenes.slice());
    }
  }

  public EmitterListLettesCmdSubject() {
    if (this._listLetterCmd) {
      this.ListLetterCmdSubject.next(this._listLetterCmd.slice());
    }
  }

  public EmitterListDenreeEgalimSubject() {
    if (this._listDenreeEgalim) {
      this.ListDenreeEgalimSubject.next(this._listDenreeEgalim.slice());
    }
  }

  public EmitterListEgalimSubject() {
    if (this._listEgalim) {
      this.ListEgalimSubject.next(this._listEgalim.slice());
    }
  }

  public EmitterListAllergenesSubject() {
    if (this._listAllergenes) {
      this.ListAllergenesSubject.next(this._listAllergenes.slice());
    }
  }

  public EmitterListGesGammeSubject() {
    if (this._listGesGamme) {
      this.ListGesGammeSubject.next(this._listGesGamme.slice());
    }
  }

  public EmitterListRegimeSubject() {
    if (this._listRegime) {
      this.ListRegimeSubject.next(this._listRegime.slice());
    }
  }

  public EmitterListTypeInfoDenreeSubject() {
    if (this._listTypeInfoDenree) {
      this.ListTypeInfoDenreeSubject.next(this._listTypeInfoDenree.slice());
    }
  }

  public EmitterListTvaSubject() {
    if (this._listTva) {
      this.ListTvaSubject.next(this._listTva.slice());
    }
  }

  public EmitterListNutriGroupSubject() {
    if (this._listNutriGroup) {
      this.NutriGroupSubject.next(this._listNutriGroup.slice());
    }
  }

  public EmitterListUnitPoidsSubject() {
    if (this._listUnitPoids) {
      this.UnitPoidsSubject.next(this._listUnitPoids.slice());
    }
  }

  public EmitterListUnitCalculSubject() {
    if (this._listUnitCalcul) {
      this.UnitCalculsSubject.next(this._listUnitCalcul.slice());
    }
  }

  public EmitterListGraalCentreSubject() {
    if (this._listGraalCentre) {
      this.GraalCentreSubject.next(this._listGraalCentre.slice());
    }
  }

  public EmitterListStockSubject() {
    if (this._listStock) {
      this.ListStockSubject.next(this._listStock.slice());
    }
  }

  public EmitterListZoneStockSubject() {
    if (this._listZoneStock) {
      this.ListZoneStockSubject.next(this._listZoneStock.slice());
    }
  }

  public GetAudience() {
    let user = jwt_decode(localStorage.getItem('currentUserToken'))['aud'];

    if (user) {
      user = user.substring(0, 3);
    }

    return user;
  }

  public GetUserInfoJwt(type: string): string {
    let result: string = undefined;

    switch (type) {
      case 'UserProfilId': {
        result = jwt_decode(localStorage.getItem('currentUserToken'))[
          'x:jwt:ProfilId'
        ];
        break;
      }
      case 'UserRoleId': {
        result = jwt_decode(localStorage.getItem('currentUserToken'))[
          'x:jwt:RoleId'
        ];
        break;
      }
      case 'FullName': {
        result = jwt_decode(localStorage.getItem('currentUserToken'))[
          'x:jwt:FullName'
        ];
        break;
      }
      case 'UserId':
        result = jwt_decode(localStorage.getItem('currentUserToken'))[
          'x:jwt:IdRessource'
        ];
        break;
      default: {
        //statements;
        break;
      }
    }
    return result;
  }

  public GetRights$(right: GesRights[]) {
    let urlAPI = this._urlBaseAPI + this._urlGetRights;
    this._httpClient.post<GesRights[]>(urlAPI, right).subscribe({
      next: (data) => {
        this._listRights = data;
        this.EmitterListRightsSubject();
      },
      error: (error) => {
        console.error('There was an error!', error);
      },
    });
  }

  public GetALlLettersCmd() {
    let urlAPI = this._urlBaseAPI + this._urlGetAllLettersCmd;
    this._httpClient.get<SelectValueModel[]>(urlAPI).subscribe(
      (response: SelectValueModel[]) => {
        this._listLetterCmd = response;
        this.EmitterListLettesCmdSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetListUnitPoids() {
    let urlAPI = this._urlBaseAPI + this._urlGetUnitPoid;
    this._httpClient.get<GES_PRES[]>(urlAPI).subscribe(
      (response: GES_PRES[]) => {
        this._listUnitPoids = response;
        this.EmitterListUnitPoidsSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetListUnitCalcul() {
    let urlAPI = this._urlBaseAPI + this._urlGetUniCal;
    this._httpClient.get<GRAAL_LIBELLE[]>(urlAPI).subscribe(
      (response: GRAAL_LIBELLE[]) => {
        this._listUnitCalcul = response;
        this.EmitterListUnitCalculSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetListGraalCentrePreparation() {
    let urlAPI = this._urlBaseAPI + this._urlGetGraalCentre;
    this._httpClient.get<GRAAL_CENTRE[]>(urlAPI).subscribe(
      (response: GRAAL_CENTRE[]) => {
        this._listGraalCentre = response;
        this.EmitterListGraalCentreSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetListStock() {
    let urlAPI = this._urlBaseAPI + this._urlGetAllStock;
    this._httpClient.get<STOCK_STOCK[]>(urlAPI).subscribe(
      (response: STOCK_STOCK[]) => {
        this._listStock = response;
        this.EmitterListStockSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetListZoneStock() {
    let urlAPI = this._urlBaseAPI + this._urlGetAllZoneStock;
    this._httpClient.get<STOCK_ZONE_STOCK[]>(urlAPI).subscribe(
      (response: STOCK_ZONE_STOCK[]) => {
        this._listZoneStock = response.filter(p => p.IU_ZONE_STOCK != "0199");
        this._listZoneStock.sort((a, b) => {
          return a.LIB_ZONE_STOCK.localeCompare(b.LIB_ZONE_STOCK)
        })
        this.EmitterListZoneStockSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetListZoneStockSync() {
    let urlAPI = this._urlBaseAPI + this._urlGetAllZoneStock;
    return this._httpClient.get<STOCK_ZONE_STOCK[]>(urlAPI);
  }

  public GetAllTva() {
    let urlAPI = this._urlBaseAPI + this._urlGetAllTva;
    this._httpClient.get<GRAAL_CENTRE[]>(urlAPI).subscribe(
      (response: GRAAL_CENTRE[]) => {
        this._listTva = response;
        this.EmitterListTvaSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetAllNutrigroup() {
    let urlAPI = this._urlBaseAPI + this._urlGetAllNutriGroup;
    this._httpClient.get<NUTRI_GROUPE[]>(urlAPI).subscribe(
      (response: NUTRI_GROUPE[]) => {
        this._listNutriGroup = response;
        this.EmitterListNutriGroupSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetAllNutrigroupSync() {
    let urlAPI = this._urlBaseAPI + this._urlGetAllNutriGroup;
    return this._httpClient.get<NUTRI_GROUPE[]>(urlAPI);
  }

  public GetAllAdrLivraisonSync() {
    let urlAPI = this._urlBaseAPI + this._urlGetAllArdLivraison;
    return this._httpClient.get<GRAAL_CENTRE[]>(urlAPI);
  }

  /***
   * Type de tarification dans parametre
   */
  public GetAllTypeInfoDenree() {
    let urlAPI = this._urlBaseAPI + this._urlGetAllTypeDenreeInfo;
    this._httpClient.get<GRAAL_CENTRE[]>(urlAPI).subscribe(
      (response: GRAAL_CENTRE[]) => {
        this._listTypeInfoDenree = response;
        this.EmitterListTypeInfoDenreeSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetAllRegime() {
    let urlAPI = this._urlBaseAPI + this._urlGetAllRegime;
    this._httpClient.get<GRAAL_REGIME[]>(urlAPI).subscribe(
      (response: GRAAL_REGIME[]) => {
        this._listRegime = response;
        this.EmitterListRegimeSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetAllGesGamme$() {

    return this.GetAllGesGamme().subscribe((response: GES_GAMME[]) => {
      this._listGesGamme = response;
      this.EmitterListGesGammeSubject();
    },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetAllGesGamme() {

    let urlAPI = this._urlBaseAPI + this._urlGetAllGesGamme;

    return this._httpClient.get<GES_GAMME[]>(urlAPI)
      .pipe(
        map((res: GES_GAMME[]) => {
          res.sort(function (a, b) {
            return a.IU_GAMME.localeCompare(b.IU_GAMME)
          });
          return res;
        }
        )
      );
  }

  public GetRecetteAllSalaison() {

    let urlAPI = this._urlBaseAPI + this._urlGetAllRecetteSalaison;

    return this._httpClient.get<GRAAL_LIBELLE[]>(urlAPI)
      .pipe(
        map((res: GRAAL_LIBELLE[]) => {
          res.sort(function (a, b) {
            return a.LIB_Libelle1.localeCompare(b.LIB_Libelle1)
          });
          return res;
        }
        )
      );
  }

  public GetAllEgalim() {
    let urlAPI = this._urlBaseAPI + this._urlGetAllEgalim;
    this._httpClient.get<GRAAL_LIBELLE[]>(urlAPI).subscribe(
      (response: GRAAL_LIBELLE[]) => {
        this._listEgalim = response;
        this.EmitterListEgalimSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetAllEgalimSync() {
    let urlAPI = this._urlBaseAPI + this._urlGetAllEgalim;
    return this._httpClient.get<GRAAL_LIBELLE[]>(urlAPI).pipe(
      map((response) => {
        response = response
          .sort((a, b) => (Number(a.LIB_Ordre) > Number(b.LIB_Ordre) ? 1 : -1))
          .slice();
        return response;
      })
    );
  }

  public GetAllAllergenes() {
    let urlAPI = this._urlBaseAPI + this._urlGetAllAllergenes;
    this._httpClient.get<GRAAL_LIBELLE[]>(urlAPI).subscribe(
      (response: GRAAL_LIBELLE[]) => {
        this._listAllergenes = response;
        this.EmitterListAllergenesSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetDenreeEgalim$(
    pFou: string,
    pIdDenree: string,
    pCodeDenree: string,
    tarif: number
  ) {
    let urlAPI =
      this._urlBaseAPI +
      this._urlGetArtDenreeEgalim +
      'fou=' +
      pFou +
      '&den=' +
      pIdDenree +
      '&cod=' +
      pCodeDenree +
      '&t=' +
      tarif;
    this._httpClient.get<ART_DENREE_EGALIM[]>(urlAPI).subscribe(
      (response: ART_DENREE_EGALIM[]) => {
        this._listDenreeEgalim = response;
        this.EmitterListDenreeEgalimSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public GetDenreeEgalimSync(
    pFou: string,
    pIdDenree: string,
    pCodeDenree: string,
    tarif: number
  ) {
    let urlAPI =
      this._urlBaseAPI +
      this._urlGetArtDenreeEgalim +
      'fou=' +
      pFou +
      '&cod=' +
      pCodeDenree +
      '&t=' +
      tarif;

    if (pIdDenree) {
      urlAPI += '&den=' + pIdDenree;
    }

    return this._httpClient.get<ART_DENREE_EGALIM[]>(urlAPI);
  }

  public GetDenreeAllergenes(
    pFou: string,
    pIdDenree: string,
    pCodeDenree: string,
    tarif: number
  ) {
    let urlAPI =
      this._urlBaseAPI +
      this._urlGetArtDenreeAllergenes +
      'fou=' +
      pFou +
      '&den=' +
      pIdDenree +
      '&cod=' +
      pCodeDenree +
      '&t=' +
      tarif;
    this._httpClient.get<ART_DENREE_ALLERGENES[]>(urlAPI).subscribe(
      (response: ART_DENREE_ALLERGENES[]) => {
        this._listDenreeAllergenes = response;
        this.EmitterListDenreeAllergenesSubject();
      },
      (error) => {
        console.log('Erreur ! : ' + error);
      }
    );
  }

  public SetZoneStock(
    pIuZoneStock: string,
    pLibZoneStock: string,
    isDelete?: boolean
  ) {
    if (!isDelete) {
      let idx = this._listZoneStock.findIndex(
        (p) => p.IU_ZONE_STOCK == pIuZoneStock
      );

      if (idx == -1) {
        // insert new zone stock
        this._listZoneStock.splice(0, 0, {
          IU_ZONE_STOCK: pIuZoneStock,
          LIB_ZONE_STOCK: pLibZoneStock,
          COMM_ZONE_STOCK: '',
          DATE_INVENTAIRE: '',
          GESTDEL: '',
          IU_STOCK: '',
        });
      }
    } else {
      let idx = this._listZoneStock.findIndex(
        (p) => p.IU_ZONE_STOCK == pIuZoneStock
      );
      if (idx != -1) {
        this._listZoneStock.splice(idx, 1);
      }
    }
  }

  public SetListTarifDenree(
    pIdTarif: string,
    pLibZoneStock: string,
    isDelete?: boolean
  ) {
    if (!isDelete) {
      let idx = this._listTypeInfoDenree.findIndex((p) => p.CLEPAR == pIdTarif);

      if (idx == -1) {
        // insert new zone stock
        this._listTypeInfoDenree.splice(0, 0, {
          CLEPAR: pIdTarif,
          PARALIB: pLibZoneStock,
          PARAM: '',
          PARAVAL: '',
          LIB_Id: '',
          HTMLTEXT: '',
        });
      }
    } else {
      let idx = this._listTypeInfoDenree.findIndex((p) => p.CLEPAR == pIdTarif);
      if (idx != -1) {
        this._listTypeInfoDenree.splice(idx, 1);
      }
    }
  }

  public SetCondArtUniPoid(
    pIuFou: string,
    pLibFou: string,
    isDelete?: boolean
  ) {
    if (!isDelete) {
      let idx = this._listUnitPoids.findIndex((p) => p.PRES_FOU_IU == pIuFou);

      if (idx == -1) {
        // insert new cond art
        this._listUnitPoids.splice(0, 0, {
          PRES_FOU_IU: pIuFou,
          PRES_FOU_LIB: pLibFou,
          DATHRMAJ: '',
          GESTDEL: '',
          USERMAJ: '',
        });
      }
    } else {
      let idx = this._listUnitPoids.findIndex((p) => p.PRES_FOU_IU == pIuFou);
      if (idx != -1) {
        this._listUnitPoids.splice(idx, 1);
      }
    }
  }

  public SendMailCommon(pMailContent: SendMailCommonModel) {
    let URI = this._urlBaseAPI + this._urlPostSendMailCommon;

    try {
      return this._httpClient
        .post(URI, pMailContent, {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
        })
        .pipe(
          map(
            (response) => {
              return response.status;
            },
            (error) => {
              return 404;
            }
          ),
          catchError((_error) => {
            return Observable.of(404);
          })
        );
    } catch (error) {
      //console.log(error);
      return Observable.of(404);
    }
  }
}
