import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



export class ViewFournDetailData {

  constructor(public fournIU: string) {
  }
}

@Component({
  selector: 'app-dialog-view-fourn-detail',
  templateUrl: './dialog-view-fourn-detail.component.html',
  styleUrls: ['./dialog-view-fourn-detail.component.css']
})
export class DialogViewFournDetailComponent implements OnInit {
  public isUpdatedInf: boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogViewFournDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public fourInfo: ViewFournDetailData,
  ) { }

  ngOnInit(): void {
  }

  public onClose() {
    this.dialogRef.close(false);
  }

}
