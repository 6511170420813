import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  @Input() mode;
  @Input() names;
  @Input() url;
  @Input() srcImg;
  @Input() toolTip;
  @Input() class;
  @Input() method;
  @Input() multiple;
  @Input() disabled;
  @Input() accept;
  @Input() maxFileSize;
  @Input() auto = true;
  @Input() withCredentials;
  @Input() invalidFileSizeMessageSummary;
  @Input() invalidFileSizeMessageDetail;
  @Input() invalidFileTypeMessageSummary;
  @Input() invalidFileTypeMessageDetail;
  @Input() previewWidth;
  @Input() chooseLabel = 'Sélectionner';
  @Input() uploadLabel = 'Envoyer';
  @Input() cancelLabel = 'Annuler';
  @Input() customUpload;
  @Input() showUploadButton;
  @Input() showCancelButton;
  @Input() dataUriPrefix;
  @Input() deleteButtonLabel = "Supprimer";
  @Input() deleteButtonIcon = 'Fermer';
  @Input() showUploadInfo;

  @ViewChild('fileUpload') fileUpload: ElementRef;

  public inputFileName: string;

  @Input() files: File[] = [];

  @Output() SelectedFileChange = new EventEmitter<File[]>();

  @Output() LoadingChange = new EventEmitter<boolean>();

  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  public onClick(event: any) {
    if (this.fileUpload)
      this.fileUpload.nativeElement.click()
  }

  public onInput(event) {

  }

  public onFileSelected(event: any) {

    this.LoadingChange.emit(true);

    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    //console.log('event file upload', event)
    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      //if(!this.isFileSelected(file)){
      if (this.validate(file)) {
        //      if(this.isImage(file)) {
        file.objectURL = this._sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
        //      }
        if (!this.isMultiple()) {
          this.files = []
        }
        this.files.push(files[i]);
        //  }
      }
      //}
    }
    if (this.files.length != 0) {
      this.SelectedFileChange.emit(this.files);
    }

    this.LoadingChange.emit(false);
  }

  public removeFile(event: any, file: any) {
    let ix: number
    if (this.files && -1 !== (ix = this.files.indexOf(file))) {
      this.files.splice(ix, 1)
      this.clearInputElement();
    }
    this.SelectedFileChange.emit(this.files);
  }

  public validate(file: File) {
    for (const f of this.files) {
      if (f.name === file.name
        && f.lastModified === file.lastModified
        && f.size === f.size
        && f.type === f.type
      ) {
        return false
      }
    }
    return true
  }

  public clearInputElement() {
    this.fileUpload.nativeElement.value = ''
  }


  public isMultiple(): boolean {
    return this.multiple
  }

}
