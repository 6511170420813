import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import 'rxjs/add/observable/of';
import * as _ from "lodash-es";
import { EnvService } from '../../../environment/env.service';
import { Config } from '../../../../../config';
import { FamdenModel } from '../../../../model/member/purchases/denree/famden.model';
import { SFamdenModel } from '../../../../model/member/purchases/denree/sfamden.model';
import { catchError, map, tap } from 'rxjs/operators';
import { DenreeModel } from '../../../../model/member/purchases/denree/denree.model';
import { TreeArticleModel } from '../../../../model/member/purchases/denree/tree-article.model';
import { IShopSearchResponse, SearchArticleModel } from '../../../../model/member/purchases/denree/search-article-result.model';
import { GES_CATALOG_FOUR } from '../../../../model/member/purchases/denree/ges_catalog_four.model';
import { DynamicFlatNode } from '../../../../../member/purchases/denree/list-articles/list-articles.component';
import { DenreeValideModel } from '../../../../model/member/purchases/denree/denree-edit-valid.model';
import { VUE_GES_CATALOG_FOUR } from '../../../../model/member/purchases/denree/VUE_GES_CATALOG_FOUR.model';
import { VUE_NUTRI_ALIM_GROUPE } from '../../../../model/member/purchases/denree/VUE_NUTRI_ALIM_GROUPE.model';
import { NutriValAlimModel } from '../../../../model/member/purchases/denree/nutri-val-alim.model';
import { NUTRI_GROUPE } from '../../../../model/member/purchases/denree/NUTRI_GROUPE.model';
import { NUTRI_ALIMENT } from '../../../../model/member/purchases/denree/NUTRI_ALIMENT';
import { ActionExecModel } from '../../../../model/common/action-exec.model';

@Injectable()
export class DenreeService {

    // url api
    private _urlBaseAPI: string;

    private _urlGetFamdenAPI: string;

    private _urlGetSFamdenAPI: string;

    private _urlGetDenreeAPI: string;

    private _urlPostRegisteFam: string;

    private _urlGetDetailDenreeAPI: string;

    private _urlSearchArticle: string;

    private _urlSearchArticleWithFouPrice: string;

    private _urlSaveEditDenree: string;

    private _urlGetCatalogFourn: string;

    private _urlPostEditFamille: string;

    private _urlPostEditSFamille: string;

    private _urlDeleteDenreeArticle: string;

    private _urlGetListArtCatalogFourn: string;

    private _urlGetFamDetail: string;

    private _urlGetListCatalogCmdFour: string;

    private _urlGetListAlimentByGroup: string;

    private _urlGetValNutriByAlim: string;

    private _urlPostCreateAliment: string;

    private _urlPostCreateGroupAliment: string;

    private _urlPostDeleteSousFam: string;

    private _urlPostDeleteFam: string;

    private _urlGetListDenreeByFam: string;

    private _urlPostCopyDenree: string;

    private _urlPostExportAllArtExcel: string;

    private _urlPostImportAllArtExcel: string;

    // subscription and model
    public FamdenSubject = new Subject<FamdenModel[]>();
    private _listFamden: FamdenModel[] = [];

    public DetailDenreeSubject = new Subject<DenreeModel>();
    private _detailDenree: DenreeModel;

    public DetailDenreeMAJSubject = new Subject<DenreeModel>();

    public CatalogDenreeFournSubject = new Subject<GES_CATALOG_FOUR>();
    private _catalogDenree: GES_CATALOG_FOUR;

    // subscription lors un noeud d'article est MAJ
    public ExpandNodeStateSubject = new Subject<DynamicFlatNode>();
    private _ExpandNode: DynamicFlatNode;

    // subscription lors un noeud d'article est supprimé
    public DeletedNodeSubject = new Subject<DynamicFlatNode>();
    private _deletedNode: DynamicFlatNode;

    // subscription lors un noeud d'article est ajouté
    public AddedNodeSubject = new Subject<DynamicFlatNode>();
    private _addedNode: DynamicFlatNode;




    constructor(private _httpClient: HttpClient, private env: EnvService) {
        this._urlBaseAPI = env.API_URL_BASE;
        this._urlGetFamdenAPI = Config.API_ROUTES.PURCHASE_API.API_GET_FAM_DEN;
        this._urlGetSFamdenAPI = Config.API_ROUTES.PURCHASE_API.API_GET_SFAM_DEN;
        this._urlGetDenreeAPI = Config.API_ROUTES.PURCHASE_API.API_GET_DENREE;
        this._urlGetDetailDenreeAPI = Config.API_ROUTES.PURCHASE_API.API_GET_DETAIL_DENREE;
        this._urlSearchArticle = Config.API_ROUTES.PURCHASE_API.API_GET_SEARCH_ARTICLE_DENREE;
        this._urlSearchArticleWithFouPrice = Config.API_ROUTES.PURCHASE_API.API_GET_SEARCH_ARTICLE_WITH_FOU_PRICE;
        this._urlSaveEditDenree = Config.API_ROUTES.PURCHASE_API.API_POST_SAVE_EDIT_DENREE;
        this._urlGetCatalogFourn = Config.API_ROUTES.PURCHASE_API.API_GET_CATALOG_FOURNISSEUR_PRIX;
        this._urlPostEditFamille = Config.API_ROUTES.PURCHASE_API.API_POST_SAVE_FAMILLE;
        this._urlPostEditSFamille = Config.API_ROUTES.PURCHASE_API.API_POST_SAVE_SFAMILLE;
        this._urlDeleteDenreeArticle = Config.API_ROUTES.PURCHASE_API.API_POST_DELETE_DENREE_ARTICLE;
        this._urlGetListArtCatalogFourn = Config.API_ROUTES.PURCHASE_API.API_GET_ART_CATALOG_FOURNISSEUR;
        this._urlGetFamDetail = Config.API_ROUTES.PURCHASE_API.API_GET_FAM_DETAIL;
        this._urlGetListCatalogCmdFour = Config.API_ROUTES.PURCHASE_API.API_GET_CATALOG_FOUR_CMD;
        this._urlGetListAlimentByGroup = Config.API_ROUTES.PURCHASE_API.API_GET_ALIMENTS_BY_GROUP;
        this._urlGetValNutriByAlim = Config.API_ROUTES.PURCHASE_API.API_GET_VAL_NUTRI_BY_ALIM;
        this._urlPostCreateAliment = Config.API_ROUTES.PURCHASE_API.API_POST_CREATE_ALIMENT;
        this._urlPostCreateGroupAliment = Config.API_ROUTES.PURCHASE_API.API_POST_CREATE_GROUPE_ALIMENT;
        this._urlPostDeleteSousFam = Config.API_ROUTES.PURCHASE_API.API_POST_DELETE_SOUS_FAM;
        this._urlPostDeleteFam = Config.API_ROUTES.PURCHASE_API.API_POST_DELETE_FAM;
        this._urlGetListDenreeByFam = Config.API_ROUTES.PURCHASE_API.API_GET_LIST_DENREE_BY_FAM;
        this._urlPostCopyDenree = Config.API_ROUTES.PURCHASE_API.API_POST_COPY_DENREE;
        this._urlPostExportAllArtExcel = Config.API_ROUTES.PURCHASE_API.API_POST_EXPORT_ALL_ART_EXCEL;
        this._urlPostImportAllArtExcel = Config.API_ROUTES.PURCHASE_API.API_POST_IMPORT_ALL_ART_EXCEL;
    }

    /************************* region subscription RX  *////////////////////////



    // send event lors un noeud d'article est supprimé
    public EmitterDeletedNode(DeletedNode: DynamicFlatNode) {
        this.DeletedNodeSubject.next(_.cloneDeep(DeletedNode));
    }

    // send event lors un noeud d'article est ajouté
    public EmitterAddedNode(AddedNode: DynamicFlatNode) {
        this.AddedNodeSubject.next(_.cloneDeep(AddedNode));
    }

    public EmitterExpandNode(ExpandNode: DynamicFlatNode) {
        this.ExpandNodeStateSubject.next(_.cloneDeep(ExpandNode));
    }

    public EmitterCatalogDenreeSubject() {
        if (this._catalogDenree) {
            this.CatalogDenreeFournSubject.next(_.clone(this._catalogDenree));
        }
    }

    public EmitterDetailDenreeMAJSubject(pDenree: DenreeModel) {
        if (pDenree) {
            this.DetailDenreeMAJSubject.next(_.clone(pDenree));
        }
    }

    public EmitterDetailDenreeSubject() {
        if (this._detailDenree) {
            this.DetailDenreeSubject.next(_.clone(this._detailDenree));
        }
    }

    public EmitterListFamDenSubject() {
        if (this._listFamden != undefined) {
            this.FamdenSubject.next(this._listFamden.slice());
        }
    }
    /************************* end region subscription RX  *////////////////////////

    public GetListDenreeArtiles() {

        this._listFamden = [];

        let urlAPI = this._urlBaseAPI + this._urlGetFamdenAPI;
        this._httpClient.get<FamdenModel[]>(urlAPI).subscribe(
            (response: FamdenModel[]) => {
                this._listFamden = response;
                this.EmitterListFamDenSubject();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetListDenreeByFam(fam: string) {

        let urlAPIDenree = this._urlBaseAPI + this._urlGetListDenreeByFam + fam;

        return this._httpClient.get(urlAPIDenree)
            .pipe(
                map((res: DenreeModel[]) => {
                    res.sort(function (a, b) {
                        return a.NOM_DENREE.localeCompare(b.NOM_DENREE)
                    });
                    return res;
                }
                )
            );
    }

    public GetSFamDenree(node): Observable<TreeArticleModel[]> {

        switch (node.level) {

            case 0:
                let urlAPISFam = this._urlBaseAPI + this._urlGetSFamdenAPI + node.codeFam;
                return this._httpClient.get(urlAPISFam)
                    .pipe(
                        map((res: SFamdenModel[]) => {
                            //console.log(data);
                            if (res) {
                                let data: TreeArticleModel[] = [];
                                res.map(x => {
                                    data = [...data, { CODE: x.IU_SFAMDEN, FAMI_DENREE: x.SFAM_DENREE, LIBFAM_DENREE: x.LIBSFAM_DENREE, isExpandable: true }];
                                });
                                res.sort(function (a, b) {
                                    return a.LIBSFAM_DENREE.localeCompare(b.LIBSFAM_DENREE)
                                });
                                return data;

                            } else {

                                let data: TreeArticleModel[] = [];
                                return data;
                            }
                        }
                        )
                    );
                break;
            case 1:
                let urlAPIDenree = this._urlBaseAPI + this._urlGetDenreeAPI + node.codeFam;
                return this._httpClient.get(urlAPIDenree)
                    .pipe(
                        map((res: DenreeModel[]) => {

                            //console.log(data);

                            if (res) {

                                let data: TreeArticleModel[] = [];
                                res.map(x => {
                                    data = [...data, { CODE: x.IU_DENREE, FAMI_DENREE: x.CODE_DENREE, LIBFAM_DENREE: x.NOM_DENREE, isExpandable: false }];
                                });
                                return data;

                            } else {

                                let data: TreeArticleModel[] = [];
                                return data;
                            }
                        }
                        )
                    );
                break;
        }


    }

    public GetDetailDenree(code: string, iu: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetDetailDenreeAPI + code + '&iu=' + iu;

        this._httpClient.get<DenreeModel>(urlAPI).subscribe(
            (response: DenreeModel) => {
                this._detailDenree = response;
                this.EmitterDetailDenreeSubject();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetDetailDenreeSync(code: string, iu: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetDetailDenreeAPI + code

        if (iu) {
            urlAPI += '&iu=' + iu;
        }

        return this._httpClient.get<DenreeModel>(urlAPI);

    }

    public DeleteDenreeArticle(pDenreArtDelete: DenreeModel) {

        let URI = this._urlBaseAPI + this._urlDeleteDenreeArticle;

        try {
            return this._httpClient.post(URI, pDenreArtDelete,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                return 200;
                            }
                            return response.status;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }
    }

    public DeleteSousFam(pSfam: string) {

        let URI = this._urlBaseAPI + this._urlPostDeleteSousFam;

        try {
            return this._httpClient.post(URI, JSON.stringify(pSfam),
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let status: number = ((response.body) as any);
                                return status;
                            }
                            return response.status;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(404);
        }
    }

    public DeleteFam(pfam: string) {

        let URI = this._urlBaseAPI + this._urlPostDeleteFam;

        try {
            return this._httpClient.post(URI, JSON.stringify(pfam),
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {
                            return response.status;
                        },
                        (error) => {
                            return error;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(_error);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(error);
        }
    }


    /**
     * RegisterFamDenreeDB
     */
    public RegisterFamDenreeDB(pType: string, pLib: string, pFam?: string, pSFam?: string): Observable<number> {

        // let ObjectSend: any;

        // switch (pType) {
        //     case 'fam': {

        //         let FamRegis: FamdenModel = {
        //             IU_FAMDEN: '-1', FAMI_DENREE: null, LIBFAM_DENREE: pLib,
        //             DATHRMAJ: '', USERMAJ: 'minh', FAMDEN_PRODSTOCK: '', GESTDEL: ''
        //         };
        //         ObjectSend = _.clone(FamRegis);

        //         break;
        //     }
        //     case 'sfam': {
        //         if (pFam) {
        //             let SFamRegis: SFamdenModel = {
        //                 IU_SFAMDEN: '-1', FamDenree: pFam, SFAM_DENREE: null,
        //                 DATHRMAJ: '', GESTDEL: '', IUFOU: '', JOURS_DISPO: '', LIBSFAM_DENREE: pLib, MOINS_DIS: '', USERMAJ: 'minh'
        //             };
        //             ObjectSend = _.clone(SFamRegis);
        //         }
        //         break;
        //     }
        //     case 'art': {
        //         // TODO creer nouvel article
        //         break;
        //     }
        //     default: {
        //         //statements; 
        //         break;
        //     }
        // }
        // if (ObjectSend) {
        //     try {

        //         let loginURI = this._urlBaseAPI + this._urlPostRegisteFam;

        //         return this._httpClient.post(loginURI, ObjectSend,
        //             { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
        //             .pipe(
        //                 map(
        //                     (response) => {
        //                        
        //                         if (response.status == 200) {
        //                             return 200;
        //                         }
        //                         return response.status;
        //                     },
        //                     (error) => {
        //                         return 404;
        //                     }
        //                 ),
        //                 catchError(
        //                     (_error) => {
        //                         return Observable.of(_error);
        //                     })
        //             );
        //     } catch (error) {
        //         //console.log(error);
        //         return Observable.of(404);
        //     }
        // }
        // else {
        //     return Observable.of(-1);
        // }
        return Observable.of(404);
    }

    /**
 * rechercher article
 * @param filter 
 * @param page 
 */
    public SearchArticle(filter: { name: string } = { name: '' }, page = 1, pIuFou?: string): Observable<IShopSearchResponse> {

        let urlAPI = this._urlBaseAPI;

        if (pIuFou) {
            urlAPI += this._urlSearchArticleWithFouPrice + filter.name + "&iufou=" + pIuFou;
        }
        else {
            urlAPI = this._urlBaseAPI + this._urlSearchArticle + filter.name;
        }

        if (filter.name.length < 3) {
            return Observable.of({
                total: 0,
                results: []
            });
        }

        return this._httpClient.get<IShopSearchResponse>(urlAPI)
            .pipe(
                tap((response: IShopSearchResponse) => {
                    response.results = response.results.
                        map(art => new SearchArticleModel(art.IU_DENREE,
                            art.CODE_DENREE,
                            art.LIB_DENREE,
                            art.NOM_DENREE,
                            art.IU_FOURNISSEUR,
                            art.NOM_FOURNISSEUR,
                            art.IU_FAMDEN,
                            art.FAMI_DENREE,
                            art.LIBFAM_DENREE,
                            art.IU_SFAMDEN,
                            art.SFAMI_DENREE,
                            art.LIBSFAM_DENREE,
                            art.PRESENT,
                            art.UNI_CALC,
                            art.DENREE_TVA,
                            art.CATALOG_PRIX_EUROS));
                    if (pIuFou) {
                        response.results.sort((a, b) => {
                            if (a.CATALOG_PRIX_EUROS && !b.CATALOG_PRIX_EUROS) return -1
                            if (b.CATALOG_PRIX_EUROS && !a.CATALOG_PRIX_EUROS) return 1

                            return Number(a.CATALOG_PRIX_EUROS.replace(/[^0-9.-]+/g, "")) - Number(b.CATALOG_PRIX_EUROS.replace(/[^0-9.-]+/g, "")) || a.NOM_DENREE.localeCompare(b.NOM_DENREE)
                        });

                    }
                    return response;
                })
            );
    }


    public ControleArtBusiness(value: any): string {

        let msgError: string = 'Erreur inconnu';

        if (value.Fourni != "") {
            return "OK";
        }
        else {
            msgError = 'Sélectionnez un fournisseur !';

        }
        return msgError;
    }

    public SaveEditDenree(pDenreeValide: DenreeValideModel): Observable<DenreeValideModel> {

        let SaveDenreeEditURI = this._urlBaseAPI + this._urlSaveEditDenree;

        try {
            return this._httpClient.post(SaveDenreeEditURI, pDenreeValide,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let DenreeReturn: DenreeValideModel = ((response.body) as any);
                                return DenreeReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return undefined;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public InitDenree(): DenreeModel {
        let DenreeEditModel: DenreeModel = {
            IU_DENREE: '',
            CODE_DENREE: '',
            OLD_DENREE: '',
            UNI_FACT: '',
            PRESENT: '',
            QTE_UTILE: '',
            DELAI_LIVRE: '',
            UNI_CALC: '',
            PRIX_DENREE: '',
            OLD_PRIX_EUROS: '',
            REF_EXTERNE: '',
            TYPE_STOCK: '',
            ZONE_STOCK: '',
            DENREE_IUFOU: '',
            GERE_NUM_LOT: '',
            GERE_PEREMPTION: '',
            OLD_COND_PIECE: '',
            VAL_COND_PIECE: '',
            IU_NUTRI_ALIM: '',
            POIDS_NET: '',
            POIDS_BRUT: '',
            REF_EXT_ECO: '',
            TYPE: '',
            STK_MINI: '',
            DATHRMAJ: '',
            USERMAJ: '',
            GESTDEL: '',
            DENREE_TVA: '1',
            DENREE_IUSTOCK: '',
            PMP_DENREE: undefined,
            GERE_TEMPERAT: '',
            TEMPERATURE1: '',
            TEMPERATURE2: '',
            RISQUE: '',
            HACCP: '',
            LIB_DENREE: '',
            COMMENTAIRE1: '',
            CONDITIO: '',
            COND_PIECE: '',
            ABREG_DENREE: '',
            PRIX_EUROS: '',
            INFO_CONSO: '',
            HORS_STOCK: '',
            PRIX_EUROS1: '',
            TAR_DATE_DEB: '',
            TAR_DATE_FIN: '',
            DENREE_IUFOU1: '',
            REF_EXTERNE1: '',
            TAR_DATE_DEB1: '',
            TAR_DATE_FIN1: '',
            CONDT_FOU: '',
            CONDT_FOU1: '',
            FLAG_UCOM: '0',
            COMMENTAIRE: '',
            DENREE_MARQUE: '',
            DENREE_MARQUE1: '',
            COMMENT_FOU: '',
            COMMENT_FOU1: '',
            HORS_BONECO: '',
            REGROUPE_ETAT_PREP1: '',
            MARCHE: '',
            MARCHE1: '',
            CODE_MARCHE: '',
            CODE_MARCHE1: '',
            DENREE_PREP: '',
            NOM_DENREE: '',
            UF_FOU: '',
            UF_FOU1: '',
            QUTILE_FOU: '',
            QUTILE_FOU1: '',
            JOURS_DISPO: '',
            FLAG_UNSTK: '0',
            DENREE_PVTE: '',
            ALLERGENES: '',
            INGREDIENT_ETIQ: '',
            PMP_DENREE_OLD: undefined,
            NO_ALLERG: '',
            ARRONDI_BESOIN: '',
            JOURS_CONSERVE: '',
        };

        return DenreeEditModel;
    }


    public SetDetailDenree(pDenree: DenreeModel) {
        this._detailDenree = pDenree;
    }

    public CreateNewArticle(pDenree: DenreeModel) {

        this._detailDenree = pDenree;
        this.EmitterDetailDenreeSubject();
    }


    /**
     * get catalog tarif
     * @param codeDenree 
     * @param iu 
     * @param pGroup group MAJ : Article, tarif 2
     */
    public GetCatalogFournisseur(codeDenree: string, iu: string, pGroup?: string, IsUpdateDenree?: boolean) {

        let urlAPI = this._urlBaseAPI + this._urlGetCatalogFourn + 'd=' + codeDenree + '&iu=' + iu;

        this._httpClient.get<GES_CATALOG_FOUR>(urlAPI).subscribe(
            (response: GES_CATALOG_FOUR) => {
                this._catalogDenree = response;
                if (pGroup && IsUpdateDenree) {
                    // MAJ detail article en fonction de founissuer
                    // MAJ detail article
                    this.SetMAJArticleParCatalog(response, pGroup);
                    this.EmitterDetailDenreeMAJSubject(this._detailDenree);
                }
                this.EmitterCatalogDenreeSubject();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetCataLogFournisseurSync(codeDenree: string, iu: string): Observable<VUE_GES_CATALOG_FOUR> {

        let urlAPI = this._urlBaseAPI + this._urlGetCatalogFourn + 'd=' + codeDenree + '&iu=' + iu;

        return this._httpClient.get<VUE_GES_CATALOG_FOUR>(urlAPI);
    }

    public GetArtCatalogFourSync(codeDenree: string) {
        let urlAPI = this._urlBaseAPI + this._urlGetListArtCatalogFourn + 'd=' + codeDenree + "&actif=1";

        return this._httpClient.get<VUE_GES_CATALOG_FOUR[]>(urlAPI).pipe(
            map((res: VUE_GES_CATALOG_FOUR[]) => {
                res.sort((a, b) => {
                    if (a.CATALOG_PRIX_EUROS && !b.CATALOG_PRIX_EUROS) return -1
                    if (b.CATALOG_PRIX_EUROS && !a.CATALOG_PRIX_EUROS) return 1

                    return Number(a.CATALOG_PRIX_EUROS && a.CATALOG_PRIX_EUROS.replace(/[^0-9.-]+/g, "")) - Number(b.CATALOG_PRIX_EUROS && b.CATALOG_PRIX_EUROS.replace(/[^0-9.-]+/g, "")) || a.FOU_NOM.localeCompare(b.FOU_NOM)
                })
                return res;
            })
        );
    }

    public GetAlimentByGroup(pIuGroup: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetListAlimentByGroup + 'gl=' + pIuGroup;

        return this._httpClient.get<VUE_NUTRI_ALIM_GROUPE[]>(urlAPI);
    }

    public GetValueNutriByAliment(pIuAlim: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetValNutriByAlim + 'alim=' + pIuAlim;

        return this._httpClient.get<NutriValAlimModel>(urlAPI);
    }

    /***
     * Set article selon group tab Article, tarif 2, tracabilité, stock, info
     */
    public SetMAJArticleParCatalog(pArtCatalog: GES_CATALOG_FOUR, pGroup: string) {

        switch (pGroup) {
            case 'art': {
                this._detailDenree.PRIX_EUROS = pArtCatalog.CATALOG_PRIX_EUROS;
                this._detailDenree.TAR_DATE_DEB = pArtCatalog.DATE_DEB;
                this._detailDenree.TAR_DATE_FIN = pArtCatalog.DATE_FIN;
                this._detailDenree.CONDT_FOU = pArtCatalog.CATALOG_CONDITIO;
                this._detailDenree.DENREE_MARQUE = pArtCatalog.CATALOG_MARQUE;
                this._detailDenree.COMMENT_FOU = pArtCatalog.CATALOG_COMMENT;
                this._detailDenree.REF_EXTERNE = pArtCatalog.CATALOG_REF_EXTERNE;
                this._detailDenree.MARCHE = pArtCatalog.CATALOG_MARCHE;
                this._detailDenree.CODE_MARCHE = pArtCatalog.CATALOG_CODE_MARCHE;
                break;
            }
            case 'tarif1': {
                this._detailDenree.PRIX_EUROS1 = pArtCatalog.CATALOG_PRIX_EUROS1;
                this._detailDenree.TAR_DATE_DEB1 = pArtCatalog.DATE_DEB1;
                this._detailDenree.TAR_DATE_FIN1 = pArtCatalog.DATE_FIN1;
                this._detailDenree.CONDT_FOU1 = pArtCatalog.CATALOG_CONDITIO1;
                this._detailDenree.DENREE_MARQUE1 = pArtCatalog.CATALOG_MARQUE1;
                this._detailDenree.COMMENT_FOU1 = pArtCatalog.CATALOG_COMMENT;
                this._detailDenree.REF_EXTERNE = pArtCatalog.CATALOG_REF_EXTERNE1;
                this._detailDenree.MARCHE = pArtCatalog.CATALOG_MARCHE1;
                this._detailDenree.CODE_MARCHE = pArtCatalog.CATALOG_CODE_MARCHE1;
                //statements; 
                break;
            }
            case 'info': {
                //statements; 
                break;
            }
            default: {
                //statements; 
                break;
            }
        }


    }

    /**
     * maj nom de famille
     */
    public SaveEditFamille(pIuFam: string, pCodeFam: string, pNomFam: string): Observable<FamdenModel> {


        let FamEditModel: FamdenModel = {
            IU_FAMDEN: pIuFam,
            FAMI_DENREE: pCodeFam,
            LIBFAM_DENREE: pNomFam,
            DATHRMAJ: '',
            FAMDEN_PRODSTOCK: '',
            GESTDEL: '',
            USERMAJ: '',
            NbSFam: undefined
        };

        let URI = this._urlBaseAPI + this._urlPostEditFamille;

        try {
            return this._httpClient.post(URI, FamEditModel,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let FamReturn: FamdenModel = ((response.body) as any);
                                return FamReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public SaveEditSFamille(pIuSFam: string, pCodeSFam: string, pNomSFam: string): Observable<SFamdenModel> {


        let FamEditModel: SFamdenModel = {
            IU_SFAMDEN: pIuSFam,
            SFAM_DENREE: pCodeSFam,
            LIBSFAM_DENREE: pNomSFam,
            DATHRMAJ: '',
            FamDenree: '',
            GESTDEL: '',
            IUFOU: '',
            JOURS_DISPO: '',
            MOINS_DIS: '',
            USERMAJ: ''
        };

        let URI = this._urlBaseAPI + this._urlPostEditSFamille;

        try {
            return this._httpClient.post(URI, FamEditModel,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let SFamReturn: SFamdenModel = ((response.body) as any);
                                return SFamReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public GetFamilleDetailSync(pCodeSFam: string): Observable<FamdenModel> {

        let urlAPI = this._urlBaseAPI + this._urlGetFamDetail + pCodeSFam;

        return this._httpClient.get<FamdenModel>(urlAPI);
    }

    public GetImportCatalogFournisseurCmdSyc(iuFou: string) {

        let urlAPI = this._urlBaseAPI + this._urlGetListCatalogCmdFour + iuFou;

        return this._httpClient.get<VUE_GES_CATALOG_FOUR[]>(urlAPI).pipe(
            map((res: VUE_GES_CATALOG_FOUR[]) => {

                res.sort(function (a, b) {
                    return a.NOM_DENREE.localeCompare(b.NOM_DENREE)
                });

                return res;
            })
        );

    }


    public PostSaveCreateGroupAliment(pGroup: NUTRI_GROUPE): Observable<NUTRI_GROUPE> {


        let SaveCreateEditURI = this._urlBaseAPI + this._urlPostCreateGroupAliment;


        try {
            return this._httpClient.post(SaveCreateEditURI, pGroup,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let GReturn: NUTRI_GROUPE = ((response.body) as any);
                                return GReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return undefined;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public PostSaveCreateAliment(pAlim: NUTRI_ALIMENT): Observable<VUE_NUTRI_ALIM_GROUPE> {


        let SaveCreateEditURI = this._urlBaseAPI + this._urlPostCreateAliment;


        try {
            return this._httpClient.post(SaveCreateEditURI, pAlim,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let AReturn: VUE_NUTRI_ALIM_GROUPE = ((response.body) as any);
                                return AReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return undefined;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public CopyDenree(pDenree: ActionExecModel): Observable<DenreeValideModel> {

        let SaveDenreeEditURI = this._urlBaseAPI + this._urlPostCopyDenree;

        try {
            return this._httpClient.post(SaveDenreeEditURI, pDenree,
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {

                            if (response.status == 200) {
                                let DenreeReturn: DenreeValideModel = ((response.body) as any);
                                return DenreeReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return undefined;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public ExportAllListArticlesExcel(filename: string) {

        let URI = this._urlBaseAPI + this._urlPostExportAllArtExcel;

        try {
            return this._httpClient.post(URI, JSON.stringify(filename),
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public ImportAllListArticlesExcel(pFormdata: FormData) {

        let URI = this._urlBaseAPI + this._urlPostImportAllArtExcel;

        try {
            return this._httpClient.post(URI, pFormdata,
                {
                    observe: 'response',
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                let MsgError: any = (response.body) as any;
                                return MsgError.msg;
                            }
                            return 'Error interne client';
                        },
                        (error) => {
                            return 'Error interne client';
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of('Error interne client');
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of('Error interne client');
        }
    }
}