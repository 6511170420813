import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { VUE_TARIF_CATALOG_FOUR } from "../../../model/member/purchases/denree/VUE_TARIF_CATALOG_FOUR.model";
import { Config } from '../../../../config';
import { EnvService } from "../../environment/env.service";
import { catchError, map } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { GRAAL_CENTRE } from "../../../model/common/graal_centre.model";
import * as _ from "lodash";
import * as moment from "moment";

@Injectable()
export class MercurialeService {
    // url api
    private _urlBaseAPI: string;

    private _urlExportStdMercuriale: string;

    private _urlImportStdMercuriale: string;

    private _urlScaChargerMercuriale: string;

    private _urlAgaChargerMercuriale: string;

    private _urlPostScaSaveMercuriale: string;

    private _urlPostAgaSaveMercuriale: string;

    private _urlSaveFouInactifActif: string;

    private _urlGetNewFournisseurMercuriales: string;

    private _urlGetNotifyMercuriale: string;

    private _urlPostCloseNotifyMercuriale: string;

    public ListNewFouMercuSubject = new Subject<VUE_TARIF_CATALOG_FOUR[]>();
    private _listNewFouMercuDir: VUE_TARIF_CATALOG_FOUR[] = [];

    public NotifyMercuSubject = new Subject<GRAAL_CENTRE>();
    private _notifyMercu: GRAAL_CENTRE;

    constructor(private _httpClient: HttpClient, private env: EnvService) {
        this._urlBaseAPI = env.API_URL_BASE;
        this._urlExportStdMercuriale = Config.API_ROUTES.MERCURIALES.API_POST_EXPORT_STD_MERCURIALE;
        this._urlImportStdMercuriale = Config.API_ROUTES.MERCURIALES.API_POST_IMPORT_STD_MERCURIALE;
        this._urlScaChargerMercuriale = Config.API_ROUTES.MERCURIALES.API_POST_SCA_CHARGER_MERCURIALE;
        this._urlAgaChargerMercuriale = Config.API_ROUTES.MERCURIALES.API_POST_AGA_CHARGER_MERCURIALE;
        this._urlPostScaSaveMercuriale = Config.API_ROUTES.MERCURIALES.API_POST_SCA_SAVE_MERCURIALE;
        this._urlPostAgaSaveMercuriale = Config.API_ROUTES.MERCURIALES.API_POST_AGA_SAVE_MERCURIALE;
        this._urlSaveFouInactifActif = Config.API_ROUTES.MERCURIALES.API_POST_SAVE_FOU_INACTIF;
        this._urlGetNewFournisseurMercuriales = Config.API_ROUTES.MERCURIALES.API_GET_NEW_Fournisseur_MERCURIALE;
        this._urlGetNotifyMercuriale = Config.API_ROUTES.MERCURIALES.API_GET_NOTIFY_MERCURIALE;
        this._urlPostCloseNotifyMercuriale = Config.API_ROUTES.MERCURIALES.API_POST_CLOSE_NOTIFY_MERCURIALE;
    }

    public EmitterListNewFouMercuSubject() {
        if (this._listNewFouMercuDir != undefined) {
            this.ListNewFouMercuSubject.next(this._listNewFouMercuDir.slice());
        }
    }

    public EmitterNotifyMercuSubject() {
        if (this._notifyMercu != undefined) {
            this.NotifyMercuSubject.next(_.clone(this._notifyMercu));
        }
    }

    /**
    * Export std mercuriale excel
    */
    public ExportStdMercuriale(pListArt: VUE_TARIF_CATALOG_FOUR[]) {

        let URI = this._urlBaseAPI + this._urlExportStdMercuriale;

        try {
            return this._httpClient.post(URI, pListArt,
                {
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                return response.body;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    /**
    * Export std mercuriale excel
    */
    public ImportStdMercuriale(pFormdata: FormData) {

        // console.log('before reponse import ', pFormdata.get('DateDeb'), pFormdata.get('DateFin'))

        let URI = this._urlBaseAPI + this._urlImportStdMercuriale;

        try {
            return this._httpClient.post(URI, pFormdata,
                {
                    observe: 'response',
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                let ListCmdReturn: VUE_TARIF_CATALOG_FOUR[] = ((response.body) as any);
                                // console.log('reponse import ', ListCmdReturn)
                                return ListCmdReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public ChargerMercuriale(pDate: string, pUser: string) {

        let URI = this._urlBaseAPI;

        switch (pUser) {
            case 'sca': {
                URI += this._urlScaChargerMercuriale
                break;
            }
            case 'aga': {
                URI += this._urlAgaChargerMercuriale
                break;
            }
            default: {
                break;
            }
        }

        try {
            return this._httpClient.post(URI, JSON.stringify(pDate),
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                let ListCmdReturn: VUE_TARIF_CATALOG_FOUR[] = ((response.body) as any);
                                return ListCmdReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public EnregistrerMercuriale(pDate: string, pUser: string) {


        let URI = this._urlBaseAPI;

        switch (pUser) {
            case 'sca': {
                URI += this._urlPostScaSaveMercuriale
                break;
            }
            case 'aga': {
                URI += this._urlPostAgaSaveMercuriale
                break;
            }
            default: {
                break;
            }
        }

        try {
            return this._httpClient.post(URI, JSON.stringify(pDate),
                { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                let ListCmdReturn: VUE_TARIF_CATALOG_FOUR[] = ((response.body) as any);
                                return ListCmdReturn;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }


    }

    public SaveFouInactif(pListFou: VUE_TARIF_CATALOG_FOUR[]) {

        let URI = this._urlBaseAPI + this._urlSaveFouInactifActif;

        try {
            return this._httpClient.post(URI, pListFou,
                {
                    observe: 'response',
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                return 200;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }

    public GetNewFournisseurMercuriale$() {

        let urlAPI = this._urlBaseAPI + this._urlGetNewFournisseurMercuriales;

        this._httpClient.get<VUE_TARIF_CATALOG_FOUR[]>(urlAPI).pipe(
            map((fournisseur: VUE_TARIF_CATALOG_FOUR[]) => {
                // sort display
                fournisseur.sort(function (a, b) {
                    return a.FOU_NOM.localeCompare(b.FOU_NOM); //using String.prototype.localCompare()
                });
                return fournisseur;
            })
        ).subscribe(
            (response: VUE_TARIF_CATALOG_FOUR[]) => {
                this._listNewFouMercuDir = response;
                this.EmitterListNewFouMercuSubject();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public GetNotifyMercuriale$() {

        let urlAPI = this._urlBaseAPI + this._urlGetNotifyMercuriale;

        this._httpClient.get<GRAAL_CENTRE>(urlAPI).subscribe(
            (response: GRAAL_CENTRE) => {
                this._notifyMercu = response;
                this.EmitterNotifyMercuSubject();
            },
            (error) => {
                console.log('Erreur ! : ' + error);
            }
        );
    }

    public CloseNotifyMercuriale(pListFou: VUE_TARIF_CATALOG_FOUR[]) {

        let URI = this._urlBaseAPI + this._urlPostCloseNotifyMercuriale;

        try {
            return this._httpClient.post(URI, pListFou,
                {
                    observe: 'response',
                })
                .pipe(
                    map(
                        (response) => {
                            if (response.status == 200) {
                                return 200;
                            }
                            return undefined;
                        },
                        (error) => {
                            return 404;
                        }
                    ),
                    catchError(
                        (_error) => {
                            return Observable.of(undefined);
                        })
                );
        } catch (error) {
            //console.log(error);
            return Observable.of(undefined);
        }
    }
}