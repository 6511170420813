<div *ngIf="IsFirstLoad; else firstload" class="container-fluid-0">
  <div class="row mt-2">
    <div class="col-md-12 p-0">
      <mat-tab-group class="color-active-mat-tab" dynamicHeight *ngIf="DetailDenree" class="mat-tab-custom ms-2 "
        #tabGroupArtDtail (selectedTabChange)="tabChanged($event)" [selectedIndex]="currentTabIndex">
        <!-- ARTICLE -->
        <mat-tab>
          <!-- #docregion label-directive -->
          <ng-template mat-tab-label>
            <img class="icon-tab" src="../../../../../assets/images/Icons-tab/articles-tab.svg" alt="bottle fruits" />
            {{ "Article" | translate }}
          </ng-template>
          <!-- #enddocregion label-directive -->
          <div class="container-fluid ps-3 mt-3">
            <div *ngIf="IsLoadedArticle; else loadingArticle1">
              <form class="general-form" [formGroup]="RegisterFormDetailArt" (ngSubmit)="onSubmitRegisterFormArticle()">
                <!-- first fieldset -->
                <div class="row ">
                  <div class="col-md-4">
                    <div>
                      <mat-label class="font-weight-700">{{
                        "Name" | translate
                      }}</mat-label>
                      <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                        <input type="text" matInput formControlName="ArtNom" maxlength="100" />
                        <!-- <mat-error *ngIf="">
                                    Please enter a valid email address
                                </mat-error>
                                <mat-error *ngIf="">
                                    Email is <strong>required</strong>
                                </mat-error> -->
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-1 "></div>
                  <div class="col-md-3">
                    <!-- temporary layout for VAT, ticket 468 -->
                    <div>
                      <mat-label class="font-weight-700">{{
                        "VAT" | translate
                      }}</mat-label>
                      <mat-form-field class="form-control-select-option input-size-select" appearance="fill">
                        <mat-select formControlName="Tva">
                          <mat-option class="font-size-select" *ngFor="let tva of ListTva" [value]="tva.CLEPAR">
                            {{ tva.PARALIB / 100 }} %
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <!-- <label
                      for="label-stock-area"
                      class="form-label form-label-denree"
                    ></label> -->
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-3">
                    <mat-label class="font-weight-700">{{
                      "Code" | translate
                    }}</mat-label>
                    <p>
                      {{ DetailDenree.CODE_DENREE }}
                    </p>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-md-2">
                    <div>
                      <div>
                        <mat-label class="font-weight-700 white-space-nowrap">{{
                          "BillingUnit" | translate
                        }}</mat-label>
                      </div>
                      <mat-form-field class="form-control-select-option input-size-select" appearance="fill">
                        <mat-select formControlName="UnitFact">
                          <mat-option class="font-size-select" *ngFor="let unit of ListUnitPoids"
                            [value]="unit.PRES_FOU_IU">
                            {{ unit.PRES_FOU_LIB }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div>
                      <div>
                        <mat-label class="font-weight-700 white-space-nowrap">{{
                          "UsefulQuantity" | translate
                        }}</mat-label>
                      </div>
                      <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                        <input type="text" matInput formControlName="QteUtile" maxlength="8" />
                      </mat-form-field>
                      <div>
                        <mat-error *ngIf="f.QteUtile.errors?.pattern">{{ "NotValid" | translate }}
                          <br />
                        </mat-error>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-1 "></div>
                  <div class="col-md-3">
                    <div>
                      <div>
                        <mat-label class="font-weight-700">{{
                          "CalculationUnit" | translate
                        }}</mat-label>
                      </div>
                      <mat-form-field class="form-control-select-option input-size-select " appearance="fill">
                        <mat-select formControlName="UnitCalc" (selectionChange)="onUniteCalculChange($event)">
                          <mat-option class="font-size-select" *ngFor="let unitCal of ListUnitCalcul"
                            [value]="unitCal.LIB_Id">
                            {{ unitCal.LIB_Libelle1 }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-3">
                    <div style="width: 70%; display: none">
                      <label for="label-history" class="form-label"></label>
                      <button mat-stroked-button class="button-size-form button-generic-form mb-3 white-space-nowrap">
                        <div>
                          {{ "HistoryOfNeeds" | translate }}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-md-2">
                    <div>
                      <div>
                        <mat-label class="font-weight-700">{{
                          "Packing" | translate
                        }}</mat-label>
                      </div>
                      <mat-form-field class="form-control-select-option input-size-select" appearance="fill">
                        <mat-select formControlName="Colisage">
                          <mat-option class="font-size-select" *ngFor="let coli of ListUnitPoids"
                            [value]="coli.PRES_FOU_IU">
                            {{ coli.PRES_FOU_LIB }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div>
                      <div>
                        <mat-label class="font-weight-700">{{
                          "Conditioning" | translate
                        }}</mat-label>
                      </div>

                      <mat-form-field class="form-control-input-denree form-condi-article input-size-height"
                        appearance="fill">
                        <input type="text" matInput formControlName="Cdt" class="text-end me-2" maxlength="8" />
                        <div class="font-size-1-5">
                          {{ LibUniCalculSelected }}
                        </div>
                      </mat-form-field>
                      <div>
                        <mat-error *ngIf="f.Cdt.errors?.pattern">{{ "NotValid" | translate }}
                          <br />
                        </mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-3">
                    <label for="label-command-unit"
                      class="form-label font-weight-700">{{ "ControlUnit" | translate }}</label>
                    <div>
                      <mat-radio-group aria-labelledby="radio-group-label" class="radio-group"
                        formControlName="UnitCom">
                        <mat-radio-button class="radio-button radio-toggle-denree" *ngFor="let o of OptUniCmd"
                          [value]="o" color="accent">
                          {{ o.label }}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-3">
                    <label for="label-stockage-unit"
                      class="form-label font-weight-700">{{ "StorageUnit" | translate }}</label>
                    <div>
                      <mat-radio-group aria-labelledby="radio-group-label" class="radio-group"
                        formControlName="UnitStock">
                        <mat-radio-button class="radio-button radio-toggle-denree" *ngFor="let o of OptUniStock"
                          [value]="o" color="accent">
                          {{ o.label }}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
                <!-- end first fieldset -->

                <fieldset class="form-legend-border">
                  <legend class="form-legend-border">
                    <i class="fas fa-euro-sign"></i>
                    {{ "Rate" | translate }}
                  </legend>
                  <div>
                    <div class="row">
                      <div class="col-md-4">
                        <mat-label class="font-weight-700">{{
                          "Supplier" | translate
                        }}</mat-label>
                        <mat-form-field class="form-control-select-option input-size-select pe-2" appearance="fill">
                          <mat-select formControlName="Fourni" [errorStateMatcher]="matcher"
                            (selectionChange)="onFournisseurChange($event)">
                            <mat-select-trigger>
                              {{ selectedFou?.NOM_FOURNISSEUR }}
                            </mat-select-trigger>
                            <mat-option class="font-size-select" *ngFor="let four of ListSupplier"
                              [value]="four.IU_FOURNISSEUR">
                              <span>{{ four.NOM_FOURNISSEUR }} </span>&nbsp;&nbsp;
                              <span style="float: right">{{
                                four.CATALOG_PRIX_EUROS
                                  | currency : "EUR" : "symbol" : "1.3-3"
                              }}</span>
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="f.Fourni.errors?.required">{{ "PleaseSelectASupplier" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-1"></div>
                      <div class="col-md-3">
                        <div>
                          <mat-label class="font-weight-700">{{
                            "Price" | translate
                          }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input class="text-end me-2" type="text" matInput formControlName="Prix1" maxlength="9"
                              [errorStateMatcher]="matcher" />
                          </mat-form-field>
                        </div>

                        <div>
                          <mat-error *ngIf="f.Prix1.errors?.required"><span>{{ "Price" | translate }}
                              {{ "Required" | translate }}</span>
                            <br />
                          </mat-error>
                          <mat-error *ngIf="f.Prix1.errors?.pattern"><span>{{ "NotValid" | translate }}</span>
                            <br />
                          </mat-error>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                      <div class="col-md-3">
                        <div>
                          <mat-label class="font-weight-700">{{
                            "Reference" | translate
                          }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput formControlName="RefTarif1" maxlength="10" />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 ">
                        <div>
                          <mat-label class="font-weight-700">{{
                            "From" | translate
                          }}</mat-label>
                        </div>
                        <div>
                          <mat-form-field class="input-size-select pe-2" appearance="fill">
                            <input matInput [matDatepicker]="pickerArt1" formControlName="DateTarif1Du"
                              placeholder="dd/mm/yyyy" />
                            <mat-datepicker-toggle matSuffix [for]="pickerArt1"></mat-datepicker-toggle>
                            <mat-datepicker #pickerArt1></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="col-md-1"></div>

                      <div class="col-md-3">
                        <div>
                          <mat-label class="font-weight-700">{{
                            "Conditioning" | translate
                          }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput formControlName="CdtTarif1" maxlength="8" />
                          </mat-form-field>
                          <div>
                            <mat-error *ngIf="f.CdtTarif1.errors?.pattern">{{ "NotValid" | translate }}
                              <br />
                            </mat-error>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                      <div class="col-md-3">
                        <div>
                          <mat-label class="font-weight-700">{{
                            "Market" | translate
                          }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput formControlName="MarcheTarif1" maxlength="15" />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 ">
                        <div>
                          <mat-label class="font-weight-700">{{
                            "To" | translate
                          }}</mat-label>
                        </div>
                        <div>
                          <mat-form-field class="input-size-select pe-2" appearance="fill">
                            <input matInput [matDatepicker]="pickerArt2" formControlName="DateTarif1Au"
                              placeholder="dd/mm/yyyy" />
                            <mat-datepicker-toggle matSuffix [for]="pickerArt2"></mat-datepicker-toggle>
                            <mat-datepicker #pickerArt2></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                      <div class="col-md-3">
                        <mat-label class="font-weight-700">{{
                          "Brand" | translate
                        }}</mat-label>
                        <mat-form-field class="form-control-input-denree input-size-height " appearance="fill">
                          <input type="text" matInput formControlName="MarqueTarif1" maxlength="20" />
                        </mat-form-field>
                      </div>
                      <div class="col-md-1"></div>
                      <div class="col-md-3">
                        <mat-label class="font-weight-700">{{
                          "Batch" | translate
                        }}</mat-label>
                        <mat-form-field class="form-control-input-denree input-size-height " appearance="fill">
                          <input type="text" matInput formControlName="LotTarif1" maxlength="15" />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <!-- Loi Egalim -->
                  <div *ngIf="IsLoadedEgalim; else loadingEgalim">
                    <fieldset class="form-legend-border">
                      <legend class="form-legend-border">
                        <i class="fas fa-balance-scale"></i>
                        Egalim
                      </legend>
                      <div class="row" *ngIf="ListEgalim.length != 0">
                        <ng-container *ngFor="let egalim of ListEgalim">
                          <div class="form-check col-md-3">
                            <mat-slide-toggle formControlName="{{ egalim.LIB_Id }}" [matTooltip]="
                                !selectedFou ? 'Fournisseur obligatoire' : ''
                              ">
                              {{ egalim.LIB_Libelle1 }}
                            </mat-slide-toggle>
                          </div>
                        </ng-container>
                      </div>
                    </fieldset>
                  </div>
                  <ng-template #loadingEgalim>
                    <ng-container *ngFor="let number of [].constructor(2)">
                      <div class="row">
                        <div class="col-6">
                          <br />
                          <content-loader [Height]="50"></content-loader>
                        </div>
                        <div class="col-6">
                          <br />
                          <content-loader [Height]="50"></content-loader>
                        </div>
                      </div>
                    </ng-container>
                  </ng-template>
                </fieldset>
                <!-- Bloc Notes -->
                <div class="row">
                  <div class="col-md-6">
                    <mat-label class="font-weight-700">{{
                      "Notebook" | translate
                    }}</mat-label>
                    <mat-form-field appearance="fill" class="form-control-input-denree">
                      <textarea class="commentary-text-area" matInput formControlName="CommentTarif1" maxlength="50">
                      </textarea>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <ng-template #loadingArticle1>
            <ng-container *ngFor="let number of [].constructor(8)">
              <div class="row">
                <div class="col-6">
                  <br />
                  <content-loader [Height]="50"></content-loader>
                </div>
                <div class="col-6">
                  <br />
                  <content-loader [Height]="50"></content-loader>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </mat-tab>
        <!-- TARIF 2 -->
        <mat-tab>
          <ng-template mat-tab-label>
            <img class="icon-tab" src="../../../../../assets/images/Icons-tab/tarif2-tab.svg" alt="euro" />
            {{ "Rate" | translate }} 2
          </ng-template>
          <div *ngIf="IsLoadedArticle; else loadingArticle2">
            <form class="general-form ms-2" [formGroup]="RegisterFormDetailTarif2">
              <div>
                <div class="row">
                  <div class="col-xl-4">
                    <mat-label class="font-weight-700">{{
                      "Supplier" | translate
                    }}</mat-label>
                    <mat-form-field class="form-control-select-option input-size-select pe-2" appearance="fill">
                      <mat-select formControlName="Fourni2" [errorStateMatcher]="matcher"
                        (selectionChange)="onFournisseur2Change($event)">
                        <mat-select-trigger>
                          {{ selectedFou1?.NOM_FOURNISSEUR }}
                        </mat-select-trigger>
                        <mat-option class="font-size-select" *ngFor="let four of ListSupplier"
                          [value]="four.IU_FOURNISSEUR">
                          <span>{{ four.NOM_FOURNISSEUR }} </span>&nbsp;&nbsp;
                          <span style="float: right">{{
                            four.CATALOG_PRIX_EUROS
                              | currency : "EUR" : "symbol" : "1.3-3"
                          }}</span>
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="f2.Fourni2.errors?.required">{{ "PleaseSelectASupplier" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2"></div>
                  <div class="col-md-3">
                    <div style="width: 40%">
                      <mat-label class="font-weight-700">{{
                        "Price" | translate
                      }}</mat-label>
                      <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                        <input class="text-end me-2" type="text" matInput formControlName="Prix2" maxlength="9"
                          [errorStateMatcher]="matcher" />
                      </mat-form-field>
                    </div>

                    <div>
                      <mat-error *ngIf="f2.Prix2.errors?.required"><span>{{ "Price" | translate }}
                          {{ "Required" | translate }}</span>
                        <br />
                      </mat-error>
                      <mat-error *ngIf="f2.Prix2.errors?.pattern"><span>{{ "NotValid" | translate }}</span>
                        <br />
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div style="width: 40%">
                      <mat-label class="font-weight-700">{{
                        "Reference" | translate
                      }}</mat-label>
                      <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                        <input type="text" matInput formControlName="Ref2" maxlength="10" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 col-lg-2">
                    <div>
                      <mat-label class="font-weight-700">{{
                        "From" | translate
                      }}</mat-label>
                    </div>
                    <div>
                      <mat-form-field class="input-size-select pe-2" appearance="fill">
                        <input matInput [matDatepicker]="pickerTarifDu2" formControlName="DateTarif2Du"
                          placeholder="dd/mm/yyyy" />
                        <mat-datepicker-toggle matSuffix [for]="pickerTarifDu2"></mat-datepicker-toggle>
                        <mat-datepicker #pickerTarifDu2></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-2 col-lg-2">
                    <div>
                      <mat-label class="font-weight-700">{{
                        "To" | translate
                      }}</mat-label>
                    </div>
                    <div>
                      <mat-form-field class="input-size-select pe-2" appearance="fill">
                        <input matInput [matDatepicker]="pickerTarifAu2" formControlName="DateTarif2Au"
                          placeholder="dd/mm/yyyy" />
                        <mat-datepicker-toggle matSuffix [for]="pickerTarifAu2"></mat-datepicker-toggle>
                        <mat-datepicker #pickerTarifAu2></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-2"></div>

                  <div class="col-md-3">
                    <div style="width: 40%">
                      <mat-label class="font-weight-700">{{
                        "Conditioning" | translate
                      }}</mat-label>
                      <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                        <input type="text" matInput formControlName="Cdt2" maxlength="8" />
                      </mat-form-field>
                      <div>
                        <mat-error *ngIf="f2.Cdt2.errors?.pattern">{{ "NotValid" | translate }}
                          <br />
                        </mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div style="width: 40%">
                      <mat-label class="font-weight-700">{{
                        "Market" | translate
                      }}</mat-label>
                      <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                        <input type="text" matInput formControlName="Marche2" maxlength="15" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4">
                    <mat-label class="font-weight-700">{{
                      "Brand" | translate
                    }}</mat-label>
                    <mat-form-field class="form-control-input-denree input-size-height pe-2" appearance="fill">
                      <input type="text" matInput formControlName="Marque2" maxlength="20" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4">
                    <mat-label class="font-weight-700">{{
                      "Batch" | translate
                    }}</mat-label>
                    <mat-form-field class="form-control-input-denree input-size-height pe-2" appearance="fill">
                      <input type="text" matInput formControlName="Lot2" maxlength="15" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!--****************-->
          <!-- <div class="container-fluid">
        <div *ngIf="IsLoadedArticle && IsLoadedSupplier; else loadingArticle2">
          <form class="general-form" [formGroup]="RegisterFormDetailTarif2">
            <div class="row">
              <div class="col-md-6">
                <mat-label class="font-weight-700">{{
                  "Supplier" | translate
                }}</mat-label>
                <mat-form-field
                  class="form-control-select-option input-size-select"
                  appearance="fill"
                >
                  <mat-select
                    formControlName="Fourni2"
                    [errorStateMatcher]="matcher"
                    (selectionChange)="onFournisseur2Change($event)"
                  >
                    <mat-option></mat-option>
                    <mat-select-trigger>
                      {{ selectedFou1?.NOM_FOURNISSEUR }}
                    </mat-select-trigger>
                    <mat-option class="font-size-select" *ngFor="let four of ListSupplier"
                      [value]="four.IU_FOURNISSEUR">
                      <span>{{ four.NOM_FOURNISSEUR }} </span>&nbsp;&nbsp;
                      <span style="float: right">{{
                        four.CATALOG_PRIX_EUROS1 | currency :'EUR':'symbol':'1.4-4'
                        }}</span>
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="f2.Fourni2.errors?.required">{{ "PleaseSelectASupplier" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <mat-label class="font-weight-700">{{
                  "Price" | translate
                  }}</mat-label>
                <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                  <input type="text" matInput formControlName="Prix2" class="text-end me-2" />
                </mat-form-field>
                <div>
                  <mat-error *ngIf="f2.Prix2.errors?.required">{{ "Price" | translate }} {{ "Required" | translate }}
                    <br />
                  </mat-error>
                  <mat-error *ngIf="f2.Prix2.errors?.pattern">{{ "NotValid" | translate }}
                    <br />
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <mat-label class="font-weight-700">{{
                  "From" | translate
                  }}</mat-label>
                <mat-form-field class="input-size-select" appearance="fill">
                  <input matInput [matDatepicker]="pickerTarifDu2" formControlName="DateTarif2Du"
                    placeholder="dd/mm/yyyy" />
                  <mat-datepicker-toggle matSuffix [for]="pickerTarifDu2"></mat-datepicker-toggle>
                  <mat-datepicker #pickerTarifDu2></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-2">
                <mat-label class="font-weight-700">{{
                  "To" | translate
                  }}</mat-label>
                <mat-form-field class="input-size-select" appearance="fill">
                  <input matInput [matDatepicker]="pickerTarifAu2" formControlName="DateTarif2Au"
                    placeholder="dd/mm/yyyy" />
                  <mat-datepicker-toggle matSuffix [for]="pickerTarifAu2"></mat-datepicker-toggle>
                  <mat-datepicker #pickerTarifAu2></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <mat-label class="font-weight-700">{{
                  "Conditioning" | translate
                  }}</mat-label>
                <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                  <input type="text" matInput formControlName="Cdt2" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <mat-label class="font-weight-700">{{
                  "Brand" | translate
                  }}</mat-label>
                <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                  <input type="text" matInput formControlName="Marque2" />
                </mat-form-field>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-3"></div>
              <div class="col-md">
                <mat-label class="font-weight-700">{{
                  "Reference" | translate
                  }}</mat-label>
                <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                  <input type="text" matInput formControlName="Ref2" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <mat-label class="font-weight-700">{{
                  "Batch" | translate
                  }}</mat-label>
                <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                  <input type="text" matInput formControlName="Lot2" />
                </mat-form-field>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <mat-label class="font-weight-700">{{
                  "Market" | translate
                  }}</mat-label>
                <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                  <input type="text" matInput formControlName="Marche2" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-label class="font-weight-700">{{
                  "Notebook" | translate
                  }}</mat-label>
                <mat-form-field class="form-control-input-denree">
                  <textarea class="commentary-text-area" matInput formControlName="CommentTarif2">
                  </textarea>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div> -->

          <ng-template #loadingArticle2>
            <ng-container *ngFor="let number of [].constructor(5)">
              <div class="row">
                <div class="col-6">
                  <br />
                  <content-loader [Height]="50"></content-loader>
                </div>
                <div class="col-6">
                  <br />
                  <content-loader [Height]="50"></content-loader>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </mat-tab>
        <!-- TRACABILITÉ -->
        <mat-tab>
          <ng-template mat-tab-label>
            <img class="icon-tab" src="../../../../../assets/images/Icons-tab/traçabilité-tab.svg" alt="looking shoe" />
            {{ "Tracking" | translate }}
          </ng-template>
          <div class="container-fluid mt-3">
            <div *ngIf="IsLoadedArticle; else loadingTraca">
              <!--traca test -->
              <TraceabilityComponent [article]="DetailDenree"></TraceabilityComponent>
              <!--end traca test -->
              <!--form moved to 'Infos' & fields updated with d-none 21/03/23 seen with vanessa for camera feature-->
              <!-- <form class="general-form" [formGroup]="RegisterFormTraca">
                <div class="row">
                  <div class="col-xl-6">
                    <mat-label class="font-weight-700">{{ "ProtocolNumber" | translate }} HACCP</mat-label>
                    <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                      <input type="text" matInput formControlName="NoHACCP" />
                    </mat-form-field>
                    <div class="container-fluid p-0 mt-4 mb-4">
                      <label class="font-weight-700">{{
                        "Temperature" | translate
                      }}</label>
                      <div class="row">
                        <div class="col-md-3">
                          <div class="d-flex me-4 mt-4">
                            <mat-label class="font-weight-700 mt-2 me-2">{{
                              "From" | translate
                            }}</mat-label>
                            <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                              <input step="0.1" type="number" matInput formControlName="TempeDe" />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-2"></div>
                        <div class="col-md-3">
                          <div class="d-flex me-4 mt-4">
                            <mat-label class="font-weight-700 mt-2 me-2">{{
                              "To" | translate
                            }}</mat-label>
                            <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                              <input type="number" step="0.1" matInput formControlName="TempeAu" />
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 invisible">
                    <div class="mt-4">
                      <mat-slide-toggle formControlName="PtnCritique">
                        {{
                          "RoundingNeededOfTheDay" | translate
                        }}</mat-slide-toggle>
                    </div>
                    <div class="mt-4">
                      <mat-slide-toggle formControlName="GestDLC">
                        {{ "ManagementOfThe" | translate }}
                        {{ "UseByDate" | translate }}</mat-slide-toggle>
                    </div>
                  </div>
                  <div class="col-md-3 invisible">
                    <div class="mt-4">
                      <mat-slide-toggle formControlName="GestnoLot">
                        {{
                          "BatchNumberManagement" | translate
                        }}</mat-slide-toggle>
                    </div>
                    <div class="mt-4">
                      <mat-slide-toggle formControlName="GestTempe">
                        {{ "Temperature" | translate }}</mat-slide-toggle>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <mat-label class="font-weight-700">{{
                      "Preparation" | translate
                    }}</mat-label>
                    <mat-form-field class="form-control-select-option input-size-select" appearance="fill">
                      <mat-select formControlName="Preparation">
                        <mat-option class="font-size-select" *ngFor="let centre of ListGraalCentre"
                          [value]="centre.CLEPAR">
                          {{ centre.PARALIB }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <mat-label class="font-weight-700">{{
                      "Notebook" | translate
                    }}</mat-label>
                    <mat-form-field appearance="fill" class="form-control-input-denree">
                      <textarea class="commentary-text-area" matInput formControlName="CommentTraca">
                      </textarea>
                    </mat-form-field>
                  </div>
                </div>
              </form> -->
            </div>
          </div>

          <ng-template #loadingTraca>
            <ng-container *ngFor="let number of [].constructor(5)">
              <div class="row">
                <div class="col-6">
                  <br />
                  <content-loader [Height]="50"></content-loader>
                </div>
                <div class="col-6">
                  <br />
                  <content-loader [Height]="50"></content-loader>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </mat-tab>
        <!-- STOCKS -->
        <mat-tab>
          <ng-template mat-tab-label>
            <img class="icon-tab" src="../../../../../assets/images/Icons-tab/Stocks-tab.svg" alt="cubes" />
            {{ "Stock" | translate }}
          </ng-template>
          <div class="container-fluid mt-3">
            <div *ngIf="IsLoadedArticle; else loadingStock">
              <form class="general-form" [formGroup]="RegisterFormStock">
                <!-- <div class="row">
              <div class="col">
                <mat-form-field class="form-control-input-denree" appearance="fill">
                  <mat-label class="font-weight-700">Zone de stockage</mat-label>
                  <mat-select formControlName="StockId">
                    <mat-option *ngFor="let stock of ListStock" [value]="stock.IU_STOCK">
                      {{ stock.LIB_STOCK }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->
                <div class="row">
                  <div class="col-md-4">
                    <mat-label class="font-weight-700">{{
                      "StorageArea" | translate
                    }}</mat-label>

                    <!-- <mat-label class="font-weight-700">Zone de stockage</mat-label> -->
                    <mat-form-field class="form-control-input-denree input-size-select" appearance="fill">
                      <mat-select formControlName="ZoneStockId">
                        <mat-option class="font-size-select" *ngFor="let zoneStock of ListZoneStock"
                          [value]="zoneStock.IU_ZONE_STOCK">
                          {{ zoneStock.LIB_ZONE_STOCK }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-1">
                    <mat-label class="font-weight-700">PMP</mat-label>
                    <mat-form-field class="example-full-width input-size-height" appearance="fill">
                      <input type="text" matInput formControlName="Pmp" class="text-end me-2" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-3"></div>
                  <div class="col-md-2">
                    <button mat-stroked-button class="button-size-form button-generic-form nowrap mt-2" type="button"
                      (click)="DetailEtatStock()">
                      {{ "StockCondition" | translate }}
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <mat-label class="font-weight-700">{{
                      "MinimumStock" | translate
                    }}</mat-label>
                    <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                      <input type="text" matInput formControlName="StockMini" maxlength="5" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-1">
                    <mat-label class="font-weight-700">{{
                      "Available" | translate
                    }}</mat-label>
                    <mat-form-field class="example-full-width input-size-height" appearance="fill">
                      <input type="text" matInput formControlName="Dispo" maxlength="1" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-3"></div>
                  <div class="col-md-2">
                    <button (click)="stockMovement()" mat-stroked-button
                      class="button-size-form button-generic-form nowrap mt-2">
                      {{ "StockMovement" | translate }}
                    </button>
                  </div>
                  <!-- <div class="col-md-3"></div>
              <div class="col-md-3"></div> -->
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <mat-label class="font-weight-700">{{
                      "DaysOfConservationAfterOpening" | translate
                    }}</mat-label>
                    <mat-form-field class="example-full-width input-size-height" appearance="fill">
                      <input type="text" matInput formControlName="JourConserve" maxlength="1" />
                    </mat-form-field>
                    <div>
                      <mat-error *ngIf="f4.JourConserve.errors?.pattern"><span>{{ "NotValid" | translate }}</span>
                        <br />
                      </mat-error>
                    </div>
                  </div>
                </div>
                <div class="row order-button-article">
                  <div class="col-md-6">
                    <div class="form-check">
                      <mat-slide-toggle formControlName="HorsStock">
                        {{ "OutOfStock" | translate }}</mat-slide-toggle>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-check">
                      <!--removed ticket 301 {{ "RoundingNeedOfTheDay" | translate }} -->
                      <mat-slide-toggle formControlName="ArrondiUniSup">
                        {{ "HigherUnit" | translate }}</mat-slide-toggle>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <ng-template #loadingStock>
            <ng-container *ngFor="let number of [].constructor(5)">
              <div class="row">
                <div class="col-6">
                  <br />
                  <content-loader [Height]="50"></content-loader>
                </div>
                <div class="col-6">
                  <br />
                  <content-loader [Height]="50"></content-loader>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </mat-tab>
        <!-- ************* VALEURS NUTRITIONNELLES **************-->
        <mat-tab>
          <ng-template mat-tab-label><img class="icon-tab"
              src="../../../../../assets/images/Icons-tab/valeurs-nutritionnels.svg"
              alt="dot i" />{{ "NutritionalValues" | translate }}</ng-template>
          <div class="container-fluid">
            <div *ngIf="IsLoadedArticle && IsLoadedGroupAliment; else loadingNutri">
              <form class="general-form" [formGroup]="RegisterFormNutri">
                <div class="row">
                  <div class="col-md-4" style="
                      border-right: 4px solid #a5c09c;
                      border-left: 4px solid #a5c09c;
                    ">
                    <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="font-size-2 font-weight-700">
                            {{ "Create" | translate }}
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div class="d-flex">
                            <button mat-stroked-button (click)="CreateGroup('G')"
                              class="button-generic button-command-list-size-margin mt-2 width-button-100"
                              type="button">
                              {{ "Group" | translate }}
                            </button>
                            <span class="btn-space"></span>
                            <button mat-stroked-button (click)="CreateAliments('A')"
                              class="button-generic button-command-list-size-margin mt-2 width-button-100"
                              type="button">
                              {{ "Food" | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="showInputCreateAliment || showInputCreateGroup" class="mt-3"
                        formGroupName="FormCreateGroupOrAliment">
                        <div class="row">
                          <div class="col-md-12">
                            <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                              <input placeholder="{{
                                  (CreateWhat == 'G'
                                    ? 'EnterANewGroupFood'
                                    : 'EnterANewFood'
                                  ) | translate
                                }}" type="text" matInput formControlName="NutriNewGroupOrAlim" />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <button mat-stroked-button
                              class="button-size button-footer-form button-generic-validate mt-2" type="submit"
                              (click)="submitCreateGroupOrAliments()">
                              {{ "Validate" | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container-fluid p-0">
                      <!--- list groupe aliments -->
                      <div class="row mt-3">
                        <div class="col-md-12" *ngIf="IsLoadedAliments; else loadingGroupAliments">
                          <mat-label class="font-weight-700">Groupe d'aliments</mat-label>

                          <div *ngIf="!GroupAlimEditId" class="d-flex">
                            <mat-form-field class="input-size-select mt-2" appearance="fill">
                              <mat-select (selectionChange)="onNutriGroupChange($event)" formControlName="NutriGroup">
                                <mat-option class="font-size-select" *ngFor="let group of ListNutriGroup"
                                  [value]="group.NUTRI_GROUPE_IU">
                                  <span>{{ group.NUTRI_GROUPE_LIB }} </span>
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <button (click)="GroupEditAlim()" matTooltip="{{ 'Edit' | translate }}" type="button"
                              mat-button class="button-icon2-generic-duplicate">
                              <i class="fas fa-edit fa-sm"></i>
                            </button>
                          </div>
                          <div *ngIf="GroupAlimEditId" class="d-flex flex-wrap width-100-percent">
                            <mat-form-field class="input-size-height mt-2" appearance="fill">
                              <input formControlName="InputGroupNameEdit" matInput placeholder="" #input />
                            </mat-form-field>
                            <div class="d-flex">
                              <button matTooltip="{{ 'Validate' | translate }}" (click)="ValideEditGroup()"
                                type="button" mat-button class="button-icon-generic-validate">
                                <i class="fas fa-check fa-sm"></i>
                              </button>
                              <button (click)="CancelEditGroup()" matTooltip="{{ 'Cancel' | translate }}" type="button"
                                mat-button class="button-icon-generic-cancel">
                                <i class="fas fa-times fa-sm"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <ng-template #loadingGroupAliments>
                          <ng-container *ngFor="let number of [].constructor(1)">
                            <div class="col-md-12">
                              <content-loader [Height]="20"></content-loader>
                              <br />
                            </div>
                          </ng-container>
                        </ng-template>
                      </div>
                      <!--- list aliments -->
                      <div class="row">
                        <div class="col-md-12">
                          <!-- <cdk-virtual-scroll-viewport itemSize="50" style="height: 400px"> -->
                          <fieldset class="form-legend-border">
                            <legend class="form-legend-border mb-3">
                              Aliments
                            </legend>
                            <div [style.overflow]="'auto'" [style.height.px]="'400'" *ngIf="
                                IsLoadedAliments && currentTabIndex == 4;
                                else loadingAliments
                              ">
                              <div class="list-hover survol" [ScrollIntoView]="
                                  alim.ALIM_IU === selectedAlimScroll
                                " [ngClass]="{
                                  selectedActive:
                                    ListValueNutriAliment.AlimId == alim.ALIM_IU
                                }" *ngFor="
                                  let alim of ListAliments;
                                  let index = index
                                " (click)="onAlimentsChange(alim.ALIM_IU)">
                                <div
                                  class="d-flex flex-wrap width-100-percent align-items-center justify-content-between"
                                  *ngIf="AlimEditId !== alim.ALIM_IU">
                                  <div>
                                    {{ alim.ALIM_NOM }}
                                  </div>

                                  <button matTooltip="{{ 'Edit' | translate }}" (click)="EditAliment($event, alim)"
                                    type="button" mat-button class="button-icon2-generic-duplicate">
                                    <i class="fas fa-edit fa-sm"></i>
                                  </button>
                                </div>
                                <div class="d-flex flex-wrap" *ngIf="AlimEditId === alim.ALIM_IU">
                                  <mat-form-field class="input-size-height mt-2" appearance="fill">
                                    <input type="text" formControlName="InputAlimNameEdit" matInput
                                      (click)="$event.stopPropagation()" />
                                  </mat-form-field>

                                  <button matTooltip="{{ 'Validate' | translate }}"
                                    (click)="ValideEditAlim($event, alim)" type="button" mat-button
                                    class="button-icon-generic-validate">
                                    <i class="fas fa-check fa-sm"></i>
                                  </button>
                                  <button matTooltip="{{ 'Cancel' | translate }}"
                                    (click)="CancelEditAliment($event, alim)" type="button" mat-button
                                    class="button-icon-generic-cancel">
                                    <i class="fas fa-times fa-sm"></i>
                                  </button>
                                </div>
                              </div>

                              <!-- <div
                            formControlName="NutriAlim"
                            [style.overflow]="'auto'"
                            [style.height.px]="'400'"
                            (selectionChange)="onAlimentsChange($event)"
                          >
                            <div
                              class="list-hover"
                              *ngFor="let alim of ListAliments"
                              [ScrollIntoView]="alim.ALIM_IU === selectedAlim"
                              [value]="alim.ALIM_IU"
                            >
                              <div style="display: flex; align-items: center">
                                <div *ngIf="AlimEditId !== alim.ALIM_IU">
                                  {{ alim.ALIM_NOM }}
                                </div>
                                <div
                                  *ngIf="AlimEditId === alim.ALIM_IU"
                                  style="z-index: 10 !important"
                                >
                                  <mat-form-field
                                    class="form-control-input-denree input-size-height"
                                    appearance="fill"
                                  >
                                    <input type="text" matInput />
                                  </mat-form-field>
                                </div>
                                <span class="flex" style="flex: 1"></span>
                                <div>
                                  <div *ngIf="AlimEditId !== alim.ALIM_IU">
                                    <button
                                      matTooltip="{{ 'Edit' | translate }}"
                                      (click)="EditAliment($event, alim)"
                                      type="button"
                                      mat-button
                                      class="button-icon2-generic-duplicate"
                                    >
                                      <i class="fas fa-edit fa-sm"></i>
                                    </button>
                                  </div>
                                  <div *ngIf="AlimEditId === alim.ALIM_IU">
                                    <button
                                      matTooltip="{{ 'Validate' | translate }}"
                                      (click)="EditAliment($event, alim)"
                                      type="button"
                                      mat-button
                                      class="button-icon2-generic-duplicate"
                                    >
                                      <i class="fas fa-check fa-sm"></i>
                                    </button>
                                    <button
                                      matTooltip="{{ 'Cancel' | translate }}"
                                      (click)="EditAliment($event, alim)"
                                      type="button"
                                      mat-button
                                      class="button-icon2-generic-duplicate"
                                    >
                                      <i class="fas fa-times fa-sm"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> -->
                            </div>
                            <ng-template #loadingAliments>
                              <ng-container *ngFor="let number of [].constructor(3)">
                                <div class="row">
                                  <div class="col">
                                    <content-loader [Height]="20"></content-loader>
                                    <br />
                                  </div>
                                </div>
                              </ng-container>
                            </ng-template>
                          </fieldset>
                          <!-- </cdk-virtual-scroll-viewport> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="line-param mt-3 ms-2">
                      <span class="text-line-param">
                        {{ "NutritionalValuePer" | translate }}
                      </span>
                    </div>
                    <!--- list value nutri -->
                    <div class="container-fluid p-0 mt-4 ms-2" *ngIf="
                        ListValueNutriAliment && IsLoadedValueNutri;
                        else loadingNutriValue
                      ">
                      <!-- <cdk-virtual-scroll-viewport itemSize="50" style="height: 70vh"> -->
                      <div class="row" *ngFor="
                          let number of [].constructor(rowNumberNutrital);
                          let idxRow = index;
                          let isLast = last
                        " [ngClass]="{ odd: idxRow % 2 === 0 }">
                        <div class="col-md-4 d-flex justify-content-center" *ngFor="
                            let number1 of [].constructor(3);
                            let idxCol = index
                          ">
                          <div class="d-flex" *ngIf="
                              ListValueNutriAliment.NutriValue[
                                (idxRow + 1) * 3 - (3 - idxCol)
                              ]?.Id
                            " style="width: 80%; display: inline-block">
                            <div style="width: 100%" class="d-flex">
                              <div class="mt-3" style="width: 60%">
                                {{
                                  ListValueNutriAliment.NutriValue[
                                    (idxRow + 1) * 3 - (3 - idxCol)
                                  ]?.Lib
                                }}
                              </div>

                              <div style="text-align: right; width: 40%">
                                <mat-form-field class="form-control-input-denree input-size-height mt-2"
                                  appearance="fill">
                                  <input type="text" matInput class="input-price" formControlName="{{
                                      ListValueNutriAliment.NutriValue[
                                        (idxRow + 1) * 3 - (3 - idxCol)
                                      ]?.Id
                                    }}"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    maxlength="9" />
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 
                <div>
                  <button (click)="addNutrital()" mat-stroked-button class="button-generic-add float-end mt-2"
                    style="margin-right: 19%" type="button">
                    <i class="fas fa-plus"></i> {{ "Add" | translate }}
                  </button>
                </div> -->
                      <!-- </cdk-virtual-scroll-viewport> -->
                    </div>

                    <ng-template #loadingNutriValue>
                      <ng-container *ngFor="let number of [].constructor(3)">
                        <div class="row">
                          <div class="col-6">
                            <br />
                            <content-loader [Height]="50"></content-loader>
                          </div>
                          <div class="col-6">
                            <br />
                            <content-loader [Height]="50"></content-loader>
                          </div>
                        </div>
                      </ng-container>
                    </ng-template>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <ng-template #loadingNutri>
            <ng-container *ngFor="let number of [].constructor(5)">
              <div class="row">
                <div class="col-6">
                  <br />
                  <content-loader [Height]="50"></content-loader>
                </div>
                <div class="col-6">
                  <br />
                  <content-loader [Height]="50"></content-loader>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </mat-tab>
        <!-- **************************-->
        <!-- INFOS -->
        <mat-tab>
          <ng-template mat-tab-label>
            <img class="icon-tab" src="../../../../../assets/images/Icons-tab/infos-tab.svg" alt="dot i" />
            {{ "Infos" | translate }}
          </ng-template>
          <div class="container-fluid p-0 mt-3">
            <div *ngIf="IsLoadedArticle; else loadingInfos">
              <div class="row">
                <!--form moved from 'tracabilité' & fields updated with d-none 21/03/23 seen with vanessa for camera feature-->
                <form class="general-form ms-2" [formGroup]="RegisterFormTraca">
                  <div class="row">
                    <div class="col-md-6">
                      <mat-label class="font-weight-700 d-none">{{ "ProtocolNumber" | translate }} HACCP</mat-label>
                      <mat-form-field class="d-none form-control-input-denree input-size-height" appearance="fill">
                        <input type="text" matInput formControlName="NoHACCP" />
                      </mat-form-field>
                      <div class="container-fluid p-0 mt-4 mb-4">
                        <fieldset class="form-legend-border">
                          <legend class="form-legend-border">{{
                          "Temperature" | translate
                        }}</legend>
                          <div class="row">
                            <div class="col-md-3">
                              <div class="d-flex me-4 mt-4">
                                <mat-label class="font-weight-700 mt-2 me-2">{{
                                "From" | translate
                              }}</mat-label>
                                <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                                  <input step="0.1" type="number" matInput formControlName="TempeDe" />
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-3">
                              <div class="d-flex me-4 mt-4">
                                <mat-label class="font-weight-700 mt-2 me-2">{{
                                "To" | translate
                              }}</mat-label>
                                <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                                  <input type="number" step="0.1" matInput formControlName="TempeAu" />
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div class="col-md-3 invisible">
                      <div class="mt-4">
                        <mat-slide-toggle formControlName="PtnCritique">
                          {{
                            "RoundingNeededOfTheDay" | translate
                          }}</mat-slide-toggle>
                      </div>
                      <div class="mt-4">
                        <mat-slide-toggle formControlName="GestDLC">
                          {{ "ManagementOfThe" | translate }}
                          {{ "UseByDate" | translate }}</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="col-md-3 invisible">
                      <div class="mt-4">
                        <mat-slide-toggle formControlName="GestnoLot">
                          {{
                            "BatchNumberManagement" | translate
                          }}</mat-slide-toggle>
                      </div>
                      <div class="mt-4">
                        <mat-slide-toggle formControlName="GestTempe">
                          {{ "Temperature" | translate }}</mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                  <div class="row d-none">
                    <div class="col-xl-6">
                      <mat-label class="font-weight-700">{{
                        "Preparation" | translate
                      }}</mat-label>
                      <mat-form-field class="form-control-select-option input-size-select" appearance="fill">
                        <mat-select formControlName="Preparation">
                          <mat-option class="font-size-select" *ngFor="let centre of ListGraalCentre"
                            [value]="centre.CLEPAR">
                            {{ centre.PARALIB }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row d-none">
                    <div class="col-xl-6">
                      <mat-label class="font-weight-700">{{
                        "Notebook" | translate
                      }}</mat-label>
                      <mat-form-field appearance="fill" class="form-control-input-denree">
                        <textarea class="commentary-text-area" matInput formControlName="CommentTraca">
                        </textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </form>
              </div>
              <form class="general-form ms-2" [formGroup]="RegisterFormInfos">
                <div class="row">
                  <div class="col-md-2">
                    <mat-label class="font-weight-700">{{
                      "Delay" | translate
                    }}</mat-label>
                    <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                      <input type="text" matInput formControlName="Delai" maxlength="2" />
                    </mat-form-field>
                    <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col-xl-12">
                          <mat-label class="font-weight-700">{{
                            "SellingPrice" | translate
                          }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput formControlName="Pv" class="text-end me-2" maxlength="8" />
                          </mat-form-field>

                          <mat-label class="font-weight-700">{{
                            "Reference" | translate
                          }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput formControlName="RefInfo" maxlength="10" />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2"></div>
                  <div class="col-md-2">
                    <fieldset class="form-legend-border">
                      <legend class="form-legend-border mb-3 text-nowrap">
                        {{ "Weight" | translate }} UN/UN
                      </legend>
                      <div class="row p-2">
                        <div class="col">
                          <mat-label class="font-weight-700">{{
                            "Net" | translate
                          }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput formControlName="PoidsNet" maxlength="4" />
                          </mat-form-field>
                          <mat-label class="font-weight-700">{{
                            "Gross" | translate
                          }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput formControlName="PoidsBrut" maxlength="4" />
                          </mat-form-field>
                        </div>
                      </div>
                    </fieldset>
                    <!-- <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col">
                          <mat-label class="font-weight-700">{{
                            "VAT" | translate
                            }}</mat-label>
                          <mat-form-field class="form-control-select-option input-size-select" appearance="fill">
                            <mat-select formControlName="Tva">
                              <mat-option class="font-size-select" *ngFor="let tva of ListTva" [value]="tva.CLEPAR">
                                {{ tva.PARALIB / 100 }} %
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <label for="label-stock-area" class="form-label form-label-denree"></label>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="col-md-2"></div>
                  <div class="col-md-3">
                    <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col-md-8">
                          <mat-label class="font-weight-700">{{
                            "Type" | translate
                          }}</mat-label>
                          <mat-form-field class="form-control-select-option input-size-select" appearance="fill">
                            <mat-select formControlName="TypeInfo">
                              <mat-option class="font-size-select" *ngFor="let centre of ListTypeInfoDenree"
                                [value]="centre.CLEPAR">
                                {{ centre.PARALIB }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <mat-label class="font-weight-700 text-nowrap">{{
                            "IngredientsLabels" | translate
                          }}</mat-label>
                          <mat-form-field class="form-control-input-denree input-size-height" appearance="fill">
                            <input type="text" matInput formControlName="Ingredient" maxlength="80" />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col-xl">
                          <div class="form-check-tbr">
                            <mat-slide-toggle formControlName="InfoConso">{{
                              "ConsumerInformations" | translate
                            }}</mat-slide-toggle>
                          </div>
                          <div class="form-check-tbr">
                            <mat-slide-toggle formControlName="PrepaGroupe">Et.Prép1 groupé</mat-slide-toggle>
                          </div>
                          <div class="form-check-tbr">
                            <mat-slide-toggle formControlName="ExcBonEco">{{
                              "ExcludeDailyVoucher" | translate
                            }}</mat-slide-toggle>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div *ngIf="IsLoadedAlergene; else loadingAllergene">
                    <fieldset class="form-legend-border">
                      <legend class="form-legend-border">
                        {{ "Allergens" | translate }}
                      </legend>
                      <div class="row" *ngIf="ListAllergenes.length != 0">
                        <ng-container *ngFor="let allergene of ListAllergenes">
                          <div class="col-md-3 col-sm-12">
                            <div class="form-check">
                              <mat-slide-toggle formControlName="{{ allergene.LIB_Id }}">{{ allergene.LIB_Libelle1 }}
                              </mat-slide-toggle>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </fieldset>
                  </div>
                  <ng-template #loadingAllergene>
                    <ng-container *ngFor="let number of [].constructor(2)">
                      <div class="row">
                        <div class="col-6">
                          <br />
                          <content-loader [Height]="50"></content-loader>
                        </div>
                        <div class="col-6">
                          <br />
                          <content-loader [Height]="50"></content-loader>
                        </div>
                      </div>
                    </ng-container>
                  </ng-template>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <mat-label class="font-weight-700">{{
                      "Notebook" | translate
                    }}</mat-label>
                    <mat-form-field appearance="fill" class="form-control-input-denree">
                      <textarea class="commentary-text-area" matInput formControlName="InfoComment">
                      </textarea>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <ng-template #loadingInfos>
            <ng-container *ngFor="let number of [].constructor(9)">
              <div class="row">
                <div class="col-6">
                  <br />
                  <content-loader [Height]="50"></content-loader>
                </div>
                <div class="col-6">
                  <br />
                  <content-loader [Height]="50"></content-loader>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <div class="container-fluid" *ngIf="IsLoadedArticle && DetailDenree">
        <div class="flex-direction-button wrap-button-footer-form" *ngIf="
            !showInputCreateGroup &&
            !showInputCreateAliment &&
            !GroupAlimEditId &&
            !AlimEditId
          ">
          <div>
            <!-- <button mat-stroked-button class="button-size button-footer-form button-generic-cancel mt-2" type="button"
              (click)="Cancel()" *ngIf="isShowBtnCancel">
              {{ "Cancel" | translate }}
            </button> -->
          </div>
          <div *ngIf="currentTabIndex != 2" class="float-end">
            <button mat-stroked-button class="button-size button-footer-form button-generic-validate mt-2" type="submit"
              (click)="Submit()">
              {{ "Save" | translate }}
            </button>

            <span class="btn-space"></span>

            <button mat-stroked-button class="button-size button-footer-form button-generic-delete mt-2 ms-3"
              *ngIf="isShowBtnDelete" (click)="DeleteArticle()" type="button">
              {{ "Delete" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #firstload>
  <ng-container *ngFor="let number of [].constructor(8)">
    <div class="row">
      <div class="col-6">
        <br />
        <content-loader [Height]="50"></content-loader>
      </div>
      <div class="col-6">
        <br />
        <content-loader [Height]="50"></content-loader>
      </div>
    </div>
  </ng-container>
</ng-template>
<!--- Zone bouton valider -->

<!-- Loading new article-->
<ng-template #NewArticleLoad>
  <ng-container *ngFor="let number of [].constructor(1)">
    <div class="row">
      <div class="col">
        <mat-spinner class="mat-loader" color="warn" diameter="80" mode="indeterminate" style="margin: auto">
        </mat-spinner>
      </div>
    </div>
  </ng-container>
</ng-template>