<ng-template #progressSpinnerRef>
    <div class="spinner_custom">
        <mat-progress-spinner [color]="color" [diameter]="diameter" [mode]="mode" [strokeWidth]="strokeWidth"
            [value]="value">
        </mat-progress-spinner>
        <div style="position: absolute;
                    max-width: 100%;
                    min-width: 100%;
                    margin: auto;
                    left: 0;
                    right: 0;
                    text-align: center;">
            <b>{{msg}}</b>
        </div>
    </div>


</ng-template>