import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/of';
import * as _ from "lodash-es";
import { EnvService } from '../../environment/env.service';
import { Config } from '../../../../config';
import { ResultDockModal } from '../../../../core/model/member/layout/ResultDockModal.model';



@Injectable()
export class LayoutService {

    // url api
    private _urlBaseAPI: string;

    public DockModalSubject = new Subject<ResultDockModal>();

    //public DockModalCheckSubject = new Subject<boolean>();

    constructor(private _httpClient: HttpClient, private env: EnvService) {
        this._urlBaseAPI = env.API_URL_BASE;
    }

    public EmitterCreateDockModal(CreateDockModel: ResultDockModal) {
        this.DockModalSubject.next(CreateDockModel);
    }

    // public EmitterCheckDockModal(IsOpen: boolean) {
    //     this.DockModalCheckSubject.next(IsOpen);
    // }

}