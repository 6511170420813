export class SearchCommandeModel {
    constructor(public PRECO_IUCOM: string,
        public PRECO_IUFOU: string,
        public NOM_FOURNISSEUR: string,
        public PRECO_DATE_COM: string,
        public PRECO_DATE_LIVRE: string,
        public PRECO_DATE_LIVRE_EDIT: string,
        public PRECO_LETTRE: string,
        public PRECO_ETAT: string,
        public DATHRMAJ: string,
        public USERMAJ: string,
        public GESTDEL: string,
        public PRECO_IUCLILIV: string,
        public PRECO_NUM: number,
        public NO_BL: string,
        public NO_FACTURE: string,
        public PRECO_HRE_LIVRE: string,
        public PRECO_COMMENT: string,
        public PRECO_FLAG_PRINT: string,
        public PRECO_ADR_LIV: string,
        public PRECO_ADR_LIV_ID: string,
        public PRECO_MARCHE: string,
        public PRECO_IUSTOCK: string,
        public PRECO_TRAITE: string,
        public COMMENT_FIQ: string,
        public ENVOI_PDF: string,
        public TOTAL: number,
        public PRECO_DATE_LAST_LIVRE: Date
    ) { }
}

export interface ICommandeSearchResponse {
    total: number,
    results: SearchCommandeModel[],
}