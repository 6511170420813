import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'content-loader',
  templateUrl: './content-loader.component.html',
  styleUrls: ['./content-loader.component.css'],
})
export class ContentLoaderComponent implements OnInit {
  @Input() Width: number;
  @Input() Height: number;

  public WidthPx: string;
  public HeightPx: string;

  constructor() {}

  ngOnInit(): void {
    if (this.Width) {
      this.WidthPx = this.Width.toString() + 'px';
    }
    if (this.Height) {
      this.HeightPx = this.Height.toString() + 'px';
    }
  }
}
