import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StockService } from '../../../../core/services/member/stock/stock.service';

export class ViewArtPictureDetailData {
  //TODO get pictures as well
  constructor(public NUM_LOT: string, public DATE_LIMITE: string, public DATE_MVT: string, public NOM_FOURNISSEUR: string) { }
}

@Component({
  selector: 'app-dialog-view-art-picture-detail',
  templateUrl: './dialog-view-art-picture-detail.component.html',
  styleUrls: ['./dialog-view-art-picture-detail.component.css'],
})
export class DialogViewArtPictureDetailComponent implements OnInit {
  public picsList: string[] = [
    '../../../../../assets/images/Icons-tab/traçabilité-tab.svg',
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=',
    '../../../../../assets/images/Icons-tab/tarif2-tab.svg',

  ]
  public picsListWithUpdate: string[] = this.picsList;

  constructor(
    public dialogRef: MatDialogRef<DialogViewArtPictureDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public bs: ViewArtPictureDetailData,
    private _stockService: StockService
  ) { }

  ngOnInit(): void { }

  public onClose() {
    this.dialogRef.close(true);
  }

  public zoomOnImg(i: string) {

    let img = document.getElementById(i);
    let el = this.picsListWithUpdate[i];
    if (img.classList.contains('expand-img')) {
      img.classList.remove('expand-img');
      this.picsListWithUpdate = this.picsList;
    } else {
      img.classList.add('expand-img');
      this.picsListWithUpdate = this.picsListWithUpdate.filter(x => x == el);
    }


  }


}
