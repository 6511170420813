<div class="row pb-3">
  <div class="col-md-6">
    <span class="font-weight-700 me-5">{{article.CODE_DENREE}}</span>
    <span class="font-weight-700">{{article.NOM_DENREE}}</span>
  </div>
</div>
<div class="container-fluid p-0">
  <div class="row">
    <div class="col-md">
      <cdk-virtual-scroll-viewport tvsItemSize class="wrapper height-550">
        <table class="table no-border-table" mat-table [dataSource]="DataSourceListArtTraceability" matSort>
          <!-- 'IMAGES' -->
          <ng-container matColumnDef='IMAGES'>
            <th class="font-size-th-list-command" mat-header-cell *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by number">
              {{ "Images" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-center border-col-table">
              <!-- TODO => connect click function when backend ready (click)="OpenDetailPicArticle(element.NUM_LOT, element.DATE_LIMITE,element.DATE_MVT,element.NOM_FOURNISSEUR)" -->
              <span *ngIf="element.TYPE_MVT != 'S'" class="art-eye-detail-open">
                <img class="width-20" src="../../../../../assets/images/icons-commande/eye-blue-87.svg" />
              </span>
            </td>
          </ng-container>

          <!-- 'MOUVEMENT' -->
          <ng-container matColumnDef='MOUVEMENT'>
            <th class="font-size-th-list-command" mat-header-cell *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by number">
              {{ "Mouvement" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-center border-col-table">
              <span [ngClass]="[
              element.TYPE_MVT === 'E' ? 'green': '',
              element.TYPE_MVT === 'S' ? 'orange' : '',
            ]" class="art-eye-detail-open">
                {{element.LIB_MVT}}
              </span>
            </td>
          </ng-container>

          <!-- 'DATE' -->
          <ng-container matColumnDef='DATE'>
            <th class="font-size-th-list-command" mat-header-cell *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by number">
              {{ "Date" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-center border-col-table padding-r-03-em">
              <span class="art-eye-detail-open">
                {{element.DATE_MVT}}
              </span>
            </td>
          </ng-container>

          <!-- 'FOURNISSEUR' -->
          <ng-container matColumnDef='FOURNISSEUR'>
            <th class="font-size-th-list-command" mat-header-cell *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by number">
              {{ "Supplier" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-center border-col-table">
              <span class="art-eye-detail-open">
                {{element.NOM_FOURNISSEUR}}
              </span>
            </td>
          </ng-container>

          <!-- 'NUM_LOT' -->
          <ng-container matColumnDef='NUM_LOT'>
            <th class="font-size-th-list-command" mat-header-cell *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by number">
              {{ "LotNumber" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-center border-col-table">
              {{ element.NUM_LOT }}
            </td>
          </ng-container>

          <!-- 'DATE_LIMITE' -->
          <ng-container matColumnDef='DATE_LIMITE'>
            <th class="font-size-th-list-command" mat-header-cell *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by number">
              {{ "UseByDate" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-center border-col-table padding-r-03-em">
              {{ element.DATE_LIMITE }}
            </td>
          </ng-container>

          <!-- 'TEMPERATURE' -->
          <ng-container matColumnDef='TEMPERATURE'>
            <th class="font-size-th-list-command" mat-header-cell *matHeaderCellDef mat-sort-header
              sortActionDescription="Sort by number">
              {{ "Temperature" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-center border-col-table">
              <span class="art-eye-detail-open">10</span>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <div *ngIf="!IsLoaded">
          <ng-container *ngFor="let number of [].constructor(8)">
            <div class="row">
              <div class="col">
                <content-loader [Height]="50"></content-loader>
                <br />
              </div>
            </div>
          </ng-container>
        </div>

      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>