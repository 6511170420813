import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currency:"EUR":"symbol":"1.2-2"'
})
export class CurrencyPipeRound implements PipeTransform {

    transform(value: number): string {
        var num1 = Math.floor(value * 100) / 100;
        return num1 + " € ";
    }


}