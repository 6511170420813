import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  FormGroupName,
  NgForm,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { combineLatest, Subscription } from 'rxjs';
import { CommonService } from '../../../../core/services/common/common.service';
import { DenreeModel } from '../../../../core/model/member/purchases/denree/denree.model';
import { DenreeService } from '../../../../core/services/member/purchages/supplier/denree.service';
import { DynamicFlatNode } from '../list-articles/list-articles.component';
import { GES_PRES } from '../../../../core/model/common/ges_pres.model';
import { SupplierService } from '../../../../core/services/member/purchages/supplier/supplier.service';
import { SearchArticleModel } from '../../../../core/model/member/purchases/denree/search-article-result.model';
import { GRAAL_CENTRE } from '../../../../core/model/common/graal_centre.model';
import { STOCK_STOCK } from '../../../../core/model/common/stock.model';
import { STOCK_ZONE_STOCK } from '../../../../core/model/common/zone-stock.model';
import * as moment from 'moment';
import { GRAAL_LIBELLE } from '../../../../core/model/common/graal_libelle.model';
import { ART_DENREE_EGALIM } from '../../../../core/model/member/purchases/denree/denree-egalim.model';
import { ExtensionErrorStateMatcher } from '../../../../core/extension/ExtensionErrorStateMatcher.matcher';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ART_DENREE_ALLERGENES } from '../../../../core/model/member/purchases/denree/denree-allergenes.model';
import { DenreeValideModel } from '../../../../core/model/member/purchases/denree/denree-edit-valid.model';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../../../../shared/util/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { VUE_ART_FOUR_TARIF } from '../../../../core/model/member/purchases/supplier/VUE_ART_FOUR_TARIF.model';
import {
  DialogViewArtMouvementComponent,
  ViewArtMouvDetailData,
} from '../../../../member/stock/inventaire/dialog-view-art-mouvement/dialog-view-art-mouvement.component';
import {
  DialogViewArtEtatStockComponent,
  ViewArtEtatStockDetailData,
} from '../../../../member/stock/etat-stock/dialog-view-art-etat-stock/dialog-view-art-etat-stock.component';
import { NUTRI_GROUPE } from '../../../../core/model/member/purchases/denree/NUTRI_GROUPE.model';
import { VUE_NUTRI_ALIM_GROUPE } from '../../../../core/model/member/purchases/denree/VUE_NUTRI_ALIM_GROUPE.model';
import { NutriValAlimModel } from '../../../../core/model/member/purchases/denree/nutri-val-alim.model';
import { NUTRI_ALIMENT } from '../../../../core/model/member/purchases/denree/NUTRI_ALIMENT';
import { debounceTime } from 'rxjs/operators';
import { GesRights } from '../../../../core/model/common/ges-rights.model';
import { ActionExecModel } from '../../../../core/model/common/action-exec.model';

@Component({
  selector: 'ArticleDetailComponent',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.css'],
})
export class ArticleDetailComponent implements OnInit {
  // init current tab
  public currentTabIndex: number = 0;

  matcher = new ExtensionErrorStateMatcher();

  @Input() article: any;

  private durationNotification: number = 5000;
  private _horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private _verticalPosition: MatSnackBarVerticalPosition = 'top';

  public SelectedNodeArtile: DynamicFlatNode;

  @Input() SelectedArticleSearch: SearchArticleModel;

  @Input() isShowBtnCancel: boolean;

  @Input() isShowBtnDelete: boolean;

  public isEditArticle: boolean; // déterminier si on peut saisir un article

  public rightUser: GesRights[];

  // gestion droit des object de l'interface 
  private right: GesRights[] = [{
    ProfilId: 'user',
    RoleId: 'user',
    InfId: 'GESART',
    ObjectId: 'Btfam',
    Action: undefined,
    Value: undefined
  },
  {
    ProfilId: 'user',
    RoleId: 'user',
    InfId: 'GESART',
    ObjectId: 'Btsfam',
    Action: undefined,
    Value: undefined
  }];

  public rowNumberNutrital: number;

  public RegisterFormDetailArt: FormGroup;
  public submittedFormDetailArt: boolean = false;

  public RegisterFormDetailTarif2: FormGroup;
  public submittedDetailTarif2: boolean = false;

  public RegisterFormTraca: FormGroup;
  public submittedDetailTraca: boolean = false;

  public RegisterFormInfos: FormGroup;
  public submittedFormInfos: boolean = false;

  public RegisterFormStock: FormGroup;
  public submittedStock: boolean = false;

  public RegisterFormNutri: FormGroup;
  public submittedNutri: boolean = false;

  private _subscriptions: Subscription[] = [];

  public DetailDenree: DenreeModel;

  public ListUnitPoids: GES_PRES[] = [];

  public ListUnitCalcul: GRAAL_LIBELLE[] = [];

  public ListGraalCentre: GRAAL_CENTRE[] = [];

  public ListTva: GRAAL_CENTRE[] = [];

  public ListNutriGroup: NUTRI_GROUPE[] = [];

  public ListTypeInfoDenree: GRAAL_CENTRE[] = [];

  public ListSupplier: VUE_ART_FOUR_TARIF[] = [];

  public ListStock: STOCK_STOCK[] = [];

  public ListZoneStock: STOCK_ZONE_STOCK[] = [];

  public ListEgalim: GRAAL_LIBELLE[] = [];

  public ListAllergenes: GRAAL_LIBELLE[] = [];

  public ListDenreeEgalim: ART_DENREE_EGALIM[] = [];

  public ListDenreeAllergenes: ART_DENREE_ALLERGENES[] = [];

  public IsLoadedArticle: Boolean;
  public IsFirstLoad: Boolean;

  public IsLoadedEgalim: boolean = false;

  public IsLoadedAlergene: boolean = false;

  public IsLoadedSupplier: boolean = false;

  public IsLoadedGroupAliment: boolean = false;

  public IsLoadedValueNutri: boolean = false;

  public IsLoadedAliments: boolean = false;

  public selectedAlimScroll: string; // to scroll aliment

  // public IsComponentChanged: boolean; // déterminer si le composant activé onChanged

  public OptUniCmd = [
    { id: '0', label: 'UF' },
    { id: '1', label: 'UC' },
  ];

  public OptUniStock = [
    { id: '0', label: 'UF' },
    { id: '1', label: 'UC' },
  ];

  public selectedFou1: any; // display text dropdown fournisseur tarif 1
  public selectedFou: any; // display text dropdown fournisseur tarif

  public LibUniCalculSelected: string = '';

  public ListGroupeAliments: NUTRI_GROUPE[] = [];

  public ListAliments: VUE_NUTRI_ALIM_GROUPE[] = [];

  public ListValueNutriAliment: NutriValAlimModel;

  public showInputCreateGroup: boolean = false;

  public showInputCreateAliment: boolean = false;

  public CreateWhat: string; // déterminer si c'est une création d'un group ou nouvel aliment

  public IsEditValuNutri: boolean; // modifier la valeur nutritionel

  public IsEditAlimentName: boolean; // modifier le nom d'un aliment

  public AlimEditId: string; // aliment edited

  public GroupAlimEditId: string;

  public User: string;

  constructor(
    private _router: Router,
    private _denreeService: DenreeService,
    private _snackBar: MatSnackBar,
    private _formBuilderArtDetail: FormBuilder,
    private _formBuilderArtTarif2: FormBuilder,
    private _formBuilderTraca: FormBuilder,
    private _formBuilderStock: FormBuilder,
    private _formBuilderInfos: FormBuilder,
    private _fromNutri: FormBuilder,
    private _supplierService: SupplierService,
    private _commonService: CommonService,
    private _dialog: MatDialog
  ) {

    this.User = this._commonService.GetAudience();

    /**********  start contructor form  ******/
    this.constructorFormDenree();
  }

  /***
   * listener detail denrée MAJ par catalog fournisseur
   */
  public MAJDetailDenreeParCatalogFournisseur() {
    this._subscriptions.push(
      this._denreeService.DetailDenreeMAJSubject.subscribe(
        (ResultDetailDenree: DenreeModel) => {

          if (ResultDetailDenree) {
            // si on est sur tab article

            if (this.currentTabIndex == 0) {
              this.MAJArticle(ResultDetailDenree, true);
            }
            // si on est sur tab tarif 1
            if (this.currentTabIndex == 1) {
              this.MAJArticleTarif2(ResultDetailDenree, true);
            }
            this.IsLoadedArticle = true;
            this.IsFirstLoad = true;
          }
        }
      )
    );
  }

  // route mouvement stock
  public stockMovement() {
    // this._router.navigate(['stock/mouvement/article']);
    const ArtDialogData = new ViewArtMouvDetailData(
      this.DetailDenree.CODE_DENREE,
      '',
      '',
      'all'
    );

    const dialogRef = this._dialog.open(DialogViewArtMouvementComponent, {
      width: '100%',
      data: ArtDialogData,
      disableClose: false,
    });

    return dialogRef;
  }

  public DetailEtatStock() {
    // this._router.navigate(['stock/mouvement/article']);
    const ArtDialogData = new ViewArtEtatStockDetailData(
      this.DetailDenree.CODE_DENREE
    );

    const dialogRef = this._dialog.open(DialogViewArtEtatStockComponent, {
      width: '100%',
      data: ArtDialogData,
      disableClose: false,
    });

    return dialogRef;
  }

  /**
   *
   * @param pDenren MAJ article tarif 1
   */
  public MAJArticle(pDenren: DenreeModel, isMAJParCataglo: boolean) {
    // console.log(pDenren);
    if (isMAJParCataglo) {
      this.RegisterFormDetailArt.controls['Prix1'].setValue(pDenren.PRIX_EUROS ? Number(pDenren.PRIX_EUROS).toString() : '');
      this.RegisterFormDetailArt.controls['DateTarif1Du'].setValue(
        pDenren.TAR_DATE_DEB &&
        pDenren.TAR_DATE_DEB.replace(/\s/g, '') != '' &&
        moment(pDenren.TAR_DATE_DEB.replace(/\s/g, ''))
          .local(false)
          .utc(true)
          .toDate()
      );
      this.RegisterFormDetailArt.controls['DateTarif1Au'].setValue(
        pDenren.TAR_DATE_FIN &&
        pDenren.TAR_DATE_FIN.replace(/\s/g, '') != '' &&
        moment(pDenren.TAR_DATE_FIN.replace(/\s/g, ''))
          .local(false)
          .utc(true)
          .toDate()
      );
      this.RegisterFormDetailArt.controls['CdtTarif1'].setValue(
        pDenren.CONDT_FOU
      );
      this.RegisterFormDetailArt.controls['MarqueTarif1'].setValue(
        pDenren.DENREE_MARQUE
      );
      this.RegisterFormDetailArt.controls['RefTarif1'].setValue(
        pDenren.REF_EXTERNE
      );

      this.RegisterFormDetailArt.controls['LotTarif1'].setValue(pDenren.MARCHE);
      this.RegisterFormDetailArt.controls['MarcheTarif1'].setValue(
        pDenren.CODE_MARCHE
      );
      this.RegisterFormDetailArt.controls['CommentTarif1'].setValue(
        pDenren.COMMENT_FOU
      );
    }
    else {
      this.RegisterFormDetailArt.controls['ArtNom'].setValue(
        pDenren.NOM_DENREE
      );
      // desactiver ArtNom si c'est user sca
      if (this.User == 'sca' && !this.isEditArticle) {
        this.RegisterFormDetailArt.controls['ArtNom'].disable();
      }
      else {
        this.RegisterFormDetailArt.controls['ArtNom'].enable();
      }
      this.RegisterFormDetailArt.controls['UnitFact'].setValue(pDenren.PRESENT);
      this.RegisterFormDetailArt.controls['QteUtile'].setValue(
        pDenren.QTE_UTILE
      );
      this.RegisterFormDetailArt.controls['UnitCalc'].setValue(
        pDenren.UNI_CALC
      );
      // set libelle Unite calcul
      this.LibUniCalculSelected = pDenren.UNI_CALC.substring(
        7,
        pDenren.UNI_CALC.length
      );
      this.RegisterFormDetailArt.controls['Colisage'].setValue(
        pDenren.COND_PIECE
      );
      this.RegisterFormDetailArt.controls['Cdt'].setValue(pDenren.CONDITIO);

      this.RegisterFormDetailArt.controls['UnitCom'].setValue(
        this.OptUniCmd.find((p) => p.id == pDenren.FLAG_UCOM)
      );

      this.RegisterFormDetailArt.controls['UnitStock'].setValue(
        this.OptUniStock.find((p) => p.id == pDenren.FLAG_UNSTK)
      );
      this.RegisterFormDetailArt.controls['Fourni'].setValue(pDenren.DENREE_IUFOU);

      this.RegisterFormDetailArt.controls['Prix1'].setValue(pDenren.PRIX_EUROS ? Number(pDenren.PRIX_EUROS).toString() : '');
      this.RegisterFormDetailArt.controls['DateTarif1Du'].setValue(
        pDenren.TAR_DATE_DEB &&
        pDenren.TAR_DATE_DEB.replace(/\s/g, '') != '' &&
        moment(pDenren.TAR_DATE_DEB.replace(/\s/g, ''))
          .local(false)
          .utc(true)
          .toDate()
      );
      this.RegisterFormDetailArt.controls['DateTarif1Au'].setValue(
        pDenren.TAR_DATE_FIN &&
        pDenren.TAR_DATE_FIN.replace(/\s/g, '') != '' &&
        moment(pDenren.TAR_DATE_FIN.replace(/\s/g, ''))
          .local(false)
          .utc(true)
          .toDate()
      );
      this.RegisterFormDetailArt.controls['CdtTarif1'].setValue(
        pDenren.CONDT_FOU
      );
      this.RegisterFormDetailArt.controls['MarqueTarif1'].setValue(
        pDenren.DENREE_MARQUE
      );
      this.RegisterFormDetailArt.controls['RefTarif1'].setValue(
        pDenren.REF_EXTERNE
      );

      this.RegisterFormDetailArt.controls['LotTarif1'].setValue(pDenren.MARCHE);
      this.RegisterFormDetailArt.controls['MarcheTarif1'].setValue(
        pDenren.CODE_MARCHE
      );
      this.RegisterFormDetailArt.controls['CommentTarif1'].setValue(
        pDenren.COMMENT_FOU
      );


      switch (pDenren.DENREE_TVA) {
        case '1': {
          this.RegisterFormDetailArt.controls['Tva'].setValue('PAR82');
          break;
        }
        case '2': {
          this.RegisterFormDetailArt.controls['Tva'].setValue('PAR83');

          break;
        }
        case '3': {
          this.RegisterFormDetailArt.controls['Tva'].setValue('PAR84');
          break;
        }
        case '4': {
          this.RegisterFormDetailArt.controls['Tva'].setValue('PAR85');
          break;
        }
        default: {
          //statements;
          break;
        }
      }

    }
    // desactiver ref si c'est user sca
    if (this.User == 'sca' && !this.isEditArticle) {
      this.RegisterFormDetailArt.controls['RefTarif1'].disable();
    }
    else {
      this.RegisterFormDetailArt.controls['RefTarif1'].enable();
    }
  }

  /**
   * MAJ tarif 2
   * @param pDenren
   */
  public MAJArticleTarif2(pDenren: DenreeModel, isMAJParCataglo: boolean) {
    //******************************** tarif 2************************************************/
    if (isMAJParCataglo) {
      this.RegisterFormDetailTarif2.controls['Prix2'].setValue(
        pDenren.PRIX_EUROS1
      );
      this.RegisterFormDetailTarif2.controls['DateTarif2Du'].setValue(
        pDenren.TAR_DATE_DEB1 &&
        pDenren.TAR_DATE_DEB1.replace(/\s/g, '') != '' &&
        moment(pDenren.TAR_DATE_DEB1.replace(/\s/g, ''))
          .local(false)
          .utc(true)
          .toDate()
      );
      this.RegisterFormDetailTarif2.controls['DateTarif2Au'].setValue(
        pDenren.TAR_DATE_FIN1 &&
        pDenren.TAR_DATE_FIN1.replace(/\s/g, '') != '' &&
        moment(pDenren.TAR_DATE_FIN1.replace(/\s/g, ''))
          .local(false)
          .utc(true)
          .toDate()
      );
      this.RegisterFormDetailTarif2.controls['Cdt2'].setValue(
        pDenren.CONDT_FOU1
      );
      this.RegisterFormDetailTarif2.controls['Marque2'].setValue(
        pDenren.DENREE_MARQUE1
      );
      this.RegisterFormDetailTarif2.controls['CommentTarif2'].setValue(
        pDenren.COMMENT_FOU1
      );
    } else {
      this.RegisterFormDetailTarif2.controls['Fourni2'].setValue(
        pDenren.DENREE_IUFOU1 ? pDenren.DENREE_IUFOU1 : ''
      );
      this.RegisterFormDetailTarif2.controls['Prix2'].setValue(
        pDenren.PRIX_EUROS1
      );
      this.RegisterFormDetailTarif2.controls['DateTarif2Du'].setValue(
        pDenren.TAR_DATE_DEB1 &&
        pDenren.TAR_DATE_DEB1.replace(/\s/g, '') != '' &&
        moment(pDenren.TAR_DATE_DEB1.replace(/\s/g, ''))
          .local(false)
          .utc(true)
          .toDate()
      );
      this.RegisterFormDetailTarif2.controls['DateTarif2Au'].setValue(
        pDenren.TAR_DATE_FIN1 &&
        pDenren.TAR_DATE_FIN1.replace(/\s/g, '') != '' &&
        moment(pDenren.TAR_DATE_FIN1.replace(/\s/g, ''))
          .local(false)
          .utc(true)
          .toDate()
      );
      this.RegisterFormDetailTarif2.controls['Cdt2'].setValue(
        pDenren.CONDT_FOU1
      );
      this.RegisterFormDetailTarif2.controls['Marque2'].setValue(
        pDenren.DENREE_MARQUE1
      );
      this.RegisterFormDetailTarif2.controls['Ref2'].setValue(
        pDenren.REF_EXTERNE1
      );
      // desactiver Ref2 si c'est user sca
      if (this.User == 'sca' && !this.isEditArticle) {
        this.RegisterFormDetailTarif2.controls['Ref2'].disable();
      }
      else {
        this.RegisterFormDetailTarif2.controls['Ref2'].enable();
      }
      this.RegisterFormDetailTarif2.controls['Lot2'].setValue(pDenren.MARCHE1);
      this.RegisterFormDetailTarif2.controls['Marche2'].setValue(
        pDenren.CODE_MARCHE1
      );
      this.RegisterFormDetailTarif2.controls['CommentTarif2'].setValue(
        pDenren.COMMENT_FOU1
      );
    }
  }

  /**
   *
   * @param pDenren MAJ tracabilité
   */
  public MAJArticleTracabilite(pDenren: DenreeModel) {
    //******************************** Tracabilité ************************************************/
    this.RegisterFormTraca.controls['NoHACCP'].setValue(pDenren.HACCP);
    this.RegisterFormTraca.controls['PtnCritique'].setValue(
      pDenren.RISQUE == '1' ? true : false
    );
    this.RegisterFormTraca.controls['GestDLC'].setValue(
      pDenren.GERE_PEREMPTION == '1' ? true : false
    );
    this.RegisterFormTraca.controls['GestnoLot'].setValue(
      pDenren.GERE_NUM_LOT == '1' ? true : false
    );
    this.RegisterFormTraca.controls['GestTempe'].setValue(
      pDenren.GERE_TEMPERAT == '1' ? true : false
    );
    this.RegisterFormTraca.controls['TempeDe'].setValue(pDenren.TEMPERATURE1);
    this.RegisterFormTraca.controls['TempeAu'].setValue(pDenren.TEMPERATURE2);
    this.RegisterFormTraca.controls['Preparation'].setValue(
      pDenren.DENREE_PREP
    );
    //TODO champs DB commentaire de traçabilité
    this.RegisterFormTraca.controls['CommentTraca'].setValue('');
  }

  /***
   * MAJ Stock
   */
  public MAJArticleStock(pDenren: DenreeModel) {
    //******************************** Stocke ************************************************/

    //this.RegisterFormStock.controls['StockId'].setValue('');
    //TODO champs DB TYPE_STOCK de GESDENREE pointe vers quel champs de STOCK_STOCK

    if (pDenren.ZONE_STOCK) {
      this.RegisterFormStock.controls['ZoneStockId'].setValue(pDenren.ZONE_STOCK);
    }
    else {
      // par défaut on assigne sans zone de stockage
      this.RegisterFormStock.controls['ZoneStockId'].setValue('0100');
    }

    this.RegisterFormStock.controls['StockMini'].setValue(pDenren.STK_MINI);
    this.RegisterFormStock.controls['Pmp'].setValue(pDenren.PMP_DENREE);
    this.RegisterFormStock.controls['HorsStock'].setValue(
      pDenren.HORS_STOCK == '1' ? true : false
    );
    this.RegisterFormStock.controls['Dispo'].setValue(pDenren.JOURS_DISPO);
    this.RegisterFormStock.controls['ArrondiUniSup'].setValue(
      pDenren.ARRONDI_BESOIN == '1' ? true : false
    );
    this.RegisterFormStock.controls['JourConserve'].setValue(
      pDenren.JOURS_CONSERVE
    );
  }

  /***
   * MAJ info
   */
  public MAJArticleInfo(pDenren: DenreeModel) {
    //******************************** Infos ************************************************/
    this.RegisterFormInfos.controls['Delai'].setValue(pDenren.DELAI_LIVRE);
    //TODO confirmation si le champs Type info est TYPE dasn GES_DENREE-->
    this.RegisterFormInfos.controls['TypeInfo'].setValue(pDenren.TYPE);
    this.RegisterFormInfos.controls['PoidsNet'].setValue(pDenren.POIDS_NET);
    this.RegisterFormInfos.controls['PoidsBrut'].setValue(pDenren.POIDS_BRUT);
    this.RegisterFormInfos.controls['InfoConso'].setValue(
      pDenren.INFO_CONSO == '1' ? true : false
    );
    this.RegisterFormInfos.controls['PrepaGroupe'].setValue(
      pDenren.REGROUPE_ETAT_PREP1 == '1' ? true : false
    );
    this.RegisterFormInfos.controls['ExcBonEco'].setValue(
      pDenren.HORS_BONECO == '1' ? true : false
    );
    this.RegisterFormInfos.controls['Pv'].setValue(pDenren.DENREE_PVTE);
    this.RegisterFormInfos.controls['RefInfo'].setValue(pDenren.REF_EXT_ECO);
    if (this.User == 'sca' && !this.isEditArticle) {
      this.RegisterFormInfos.controls['RefInfo'].disable();
    }
    else {
      this.RegisterFormInfos.controls['RefInfo'].enable();
    }
    this.RegisterFormInfos.controls['Ingredient'].setValue(
      pDenren.INGREDIENT_ETIQ
    );
    this.RegisterFormInfos.controls['InfoComment'].setValue(
      pDenren.COMMENTAIRE
    );
  }

  /***
   * MAJ valeur nutritionelle
   */
  public MAJNutrinon(pDenren: DenreeModel) {

    if (pDenren.IU_NUTRI_ALIM) {
      this._subscriptions.push(
        this._denreeService
          .GetValueNutriByAliment(pDenren.IU_NUTRI_ALIM)
          .subscribe((result: NutriValAlimModel) => {
            if (result.NutriValue) {
              result.NutriValue.forEach((x) => {
                this.RegisterFormNutri.removeControl(x.Id);
                // calcul energie en kcal
                let valEnergieKcal = x.Value;

                if (x.Id == "01") { // calculer Kcal
                  let valLipide = result.NutriValue.find(p => p.Id == "03")?.Value;
                  let valProtide = result.NutriValue.find(p => p.Id == "04")?.Value;
                  let valGlucide = result.NutriValue.find(p => p.Id == "05")?.Value;
                  let valAlcool = result.NutriValue.find(p => p.Id == "06")?.Value;
                  valEnergieKcal = this.CalculerValEnergieKCAL(valLipide, valProtide, valGlucide, valAlcool);

                }

                if (x.Id == "02") { // calculer KJ
                  // 1 kcal = 4,18 kJ
                  if (this.RegisterFormNutri.controls['01'].value) {
                    valEnergieKcal = (Number(this.RegisterFormNutri.controls['01'].value) * 4.18).toFixed(2);
                  }
                }

                this.RegisterFormNutri.addControl(
                  x.Id,
                  new FormControl(valEnergieKcal, [
                    Validators.pattern('^[0-9]+(?:[.,][0-9]{1,4})?$'),
                  ])
                );
              });
              this.ListValueNutriAliment = result;
              // get all aliment de ce groupe aliment de ce denrée
              this._subscriptions.push(
                this._denreeService
                  .GetAlimentByGroup(this.ListValueNutriAliment.AlimGroupId)
                  .subscribe((result: VUE_NUTRI_ALIM_GROUPE[]) => {
                    this.ListAliments = result;
                    if (this.ListAliments.length != 0) {
                      this.RegisterFormNutri.controls['NutriAlim'].setValue([
                        this.ListValueNutriAliment.AlimId,
                      ]);
                      this.IsLoadedValueNutri = true;
                      this.IsLoadedAliments = true;
                      this.selectedAlimScroll = this.ListValueNutriAliment.AlimId;
                    }
                  })
              );
            }
          })
      );
    } else {
      // reset aliment si le denrée n'a pas d'aliment ALIM_IU
      if (this.ListValueNutriAliment) {
        this.ListValueNutriAliment.AlimGroupId = '';
        this.ListValueNutriAliment.AlimId = '';
        this.ListValueNutriAliment.NutriValue = [];
      } else {
        this.ListValueNutriAliment = new NutriValAlimModel();
        this.ListValueNutriAliment.AlimGroupId = '';
        this.ListValueNutriAliment.AlimId = '';
        this.ListValueNutriAliment.NutriValue = [];
      }
      // les valeurs d'aliments et ses nutrion sont vide
      this.ListAliments = [];
      this.IsLoadedAliments = true;
      this.IsLoadedValueNutri = true;
    }
  }


  /****
   * contructor form denrée init
   */
  public constructorFormDenree() {
    this.RegisterFormDetailArt = this._formBuilderArtDetail.group({
      ArtNom: ['', [Validators.required]],
      UnitFact: [''],
      QteUtile: ['', [Validators.pattern('^[0-9]+(?:[.,][0-9]{1,4})?$')]],
      UnitCalc: [''],
      Colisage: [''],
      Cdt: ['', [Validators.pattern('^[0-9]+(?:[.,][0-9]{1,4})?$')]],
      UnitCom: [this.OptUniCmd[0]],
      UnitStock: [this.OptUniCmd[0]],
      Fourni: [''],
      Prix1: ['', [Validators.pattern('^[0-9]+(?:[.,][0-9]{1,4})?$')]], // on peut créer un article sans prix
      DateTarif1Du: [''],
      DateTarif1Au: [''],
      CdtTarif1: ['', [Validators.pattern('^[0-9]+(?:[.,][0-9]{1,4})?$')]],
      MarqueTarif1: [''],
      RefTarif1: [''],
      LotTarif1: [''],
      MarcheTarif1: [''],
      CommentTarif1: [''],
      Tva: ['1'],
    });

    // tarif 2
    this.RegisterFormDetailTarif2 = this._formBuilderArtTarif2.group({
      Fourni2: [''],
      Prix2: ['', [Validators.pattern('^[0-9]+(?:[.,][0-9]{1,4})?$')]],
      DateTarif2Du: [''],
      DateTarif2Au: [''],
      Cdt2: ['', [Validators.pattern('^[0-9]+(?:[.,][0-9]{1,4})?$')]],
      Marque2: [''],
      Ref2: [''],
      Lot2: [''],
      Marche2: [''],
      CommentTarif2: [''],
    });

    // Tracabilité
    this.RegisterFormTraca = this._formBuilderTraca.group({
      NoHACCP: [''],
      PtnCritique: [false],
      GestDLC: [false],
      GestnoLot: [false],
      GestTempe: [false],
      TempeDe: [''],
      TempeAu: [''],
      Preparation: [''],
      CommentTraca: [''],
    });

    // Stock
    this.RegisterFormStock = this._formBuilderStock.group({
      // StockId: [''],
      ZoneStockId: [''],
      StockMini: [''],
      Pmp: [''],
      HorsStock: [false],
      ArrondiUniSup: [false],
      Dispo: [''],
      JourConserve: ['', [Validators.pattern('^[0-9]*$')]],
    });

    // Infos
    this.RegisterFormInfos = this._formBuilderInfos.group({
      Delai: [''],
      TypeInfo: [''],
      PoidsNet: [''],
      PoidsBrut: [''],
      InfoConso: [false],
      PrepaGroupe: [false],
      ExcBonEco: [false],
      Pv: [''],
      RefInfo: [''],
      Ingredient: [''],
      InfoComment: ['']
    });
    // Nutrition
    this.RegisterFormNutri = this._fromNutri.group({
      NutriAlim: [''],
      NutriGroup: [''],
      InputGroupNameEdit: [''],
      InputAlimNameEdit: ['']
    });
  }

  /**
   *
   * @param event code fournisseur
   * Récupérer les prix d'un article par fournisseur lors de sélectionner un fournisseur
   */
  public onFournisseurChange(event: any) {
    console.log(this.DetailDenree);
    // select text select dropdown
    this.selectedFou = this.ListSupplier.find(
      (p) => p.IU_FOURNISSEUR == event.value
    );

    // get catalog maj que si article existe (length's code article > 2)
    if (this.DetailDenree.CODE_DENREE.length > 2) {
      this._denreeService.GetCatalogFournisseur(
        this.DetailDenree.CODE_DENREE, event.value, 'art', true);
    }

    // get detail denrée egalim et allergenes après que denrée défini pour tarif 1
    let tarif: number = 1;
    this.IsLoadedEgalim = false;
    setTimeout(() => {
      this._commonService.GetDenreeEgalim$(event.value, this.DetailDenree.IU_DENREE, this.DetailDenree.CODE_DENREE, tarif);
    }, 500);




    // not used car allergene n'attache pas à un article
    //this._commonService.GetDenreeAllergenes(event.value, this.DetailDenree.IU_DENREE, this.DetailDenree.CODE_DENREE, tarif);
  }

  public onUniteCalculChange(event: any) {
    if (event && event.value) {
      this.LibUniCalculSelected = event.value.substring(7, event.value.length);
    }
  }

  /**
   *
   * @param event code fournisseur tarif 2
   * Récupérer les prix d'un article par fournisseur lors de sélectionner un fournisseur
   */
  public onFournisseur2Change(event: any) {
    // select text select dropdown
    this.selectedFou1 = this.ListSupplier.find(
      (p) => p.IU_FOURNISSEUR == event.value
    );
    // get catalog maj
    this._denreeService.GetCatalogFournisseur(
      this.DetailDenree.CODE_DENREE,
      event.value,
      'tarif1',
      true
    );
  }

  /**
   * get form tarif 1
   */
  public get f() {
    return this.RegisterFormDetailArt.controls;
  }
  /**
   * get form tarif 2
   */
  public get f2() {
    return this.RegisterFormDetailTarif2.controls;
  }
  /**
   * get form tracabilite
   */
  public get f3() {
    return this.RegisterFormTraca.controls;
  }
  /**
   * get form stock
   */
  public get f4() {
    return this.RegisterFormStock.controls;
  }
  /**
   * get form info
   */
  public get f5() {
    return this.RegisterFormInfos.controls;
  }

  //not used => fait dans Submit()
  public onSubmitRegisterFormArticle() {
    this.submittedFormDetailArt = true;
  }

  /***
   *
   * Suppression un article
   *
   */
  public DeleteArticle() {
    if (this.DetailDenree.IU_DENREE != '-1') {
      const message = 'Voulez-vous vraiment supprimer cet article ? ';

      const dialogData = new ConfirmDialogModel(
        'Confirmation de suppression',
        message
      );

      const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        maxWidth: '500px',
        data: dialogData,
        height: '220px',
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this._subscriptions.push(
            this._denreeService
              .DeleteDenreeArticle(this.DetailDenree)
              .subscribe((response: number) => {
                switch (response) {
                  case 200: {
                    // remettre à jour la liste , supprimer l'article du noeud
                    this._denreeService.EmitterDeletedNode(
                      this.SelectedNodeArtile
                    );
                    this.DetailDenree = undefined;
                    this.SelectedNodeArtile = undefined;
                    // notification
                    this.NotificationMsg('Suppression succès !', true);
                    break;
                  }
                  default: {
                    this.NotificationMsg('Error interne', false);

                    break;
                  }
                }
              })
          );
        }
      });
    }
  }

  /***
   * Validation de modification article
   */
  public Submit() {
    // stop here if form is invalid
    this.getFormValidationErrors(this.RegisterFormDetailArt);
    //this.RegisterFormDetailArt.get('Fourni').markAsTouched();
    // if (this.RegisterFormDetailArt.invalid || this.RegisterFormDetailTarif2.invalid
    //   || this.RegisterFormStock.invalid || this.RegisterFormTraca) {
    //   this.getFormValidationErrors(this.RegisterFormDetailArt);
    //   // this.getFormValidationErrors(this.RegisterFormDetailTarif2);
    //   // this.getFormValidationErrors(this.RegisterFormStock);
    //   // this.getFormValidationErrors(this.RegisterFormTraca);
    //   this._snackBar.open('Champs obligatoires', 'Fermer', {
    //     duration: this.durationNotification,
    //     horizontalPosition: this._horizontalPosition,
    //     verticalPosition: this._verticalPosition,
    //     panelClass: ['mat-notification-error']
    //   });
    //   return;
    // }
    // else {

    //this.getFormValidationErrors(this.RegisterFormDetailArt);
    //let Control = this._denreeService.ControleArtBusiness(this.RegisterFormDetailArt.value);
    // controle valide form 2
    if (!this.RegisterFormDetailTarif2.valid) {
      this.currentTabIndex = 1;
      return;
    }
    if (!this.RegisterFormDetailArt.valid) {
      this.currentTabIndex = 0;
      return;
    }
    if (!this.RegisterFormNutri.valid) {
      this.currentTabIndex = 4;
      this.NotificationMsg('Erreur format', false);
      return;
    }
    if (
      this.RegisterFormDetailArt.valid &&
      this.RegisterFormDetailTarif2.valid
    ) {
      // get egalim check value
      let EgalimDenreeValide: ART_DENREE_EGALIM[] = [];
      if (this.ListEgalim.length != 0) {
        this.ListEgalim.forEach((x) => {
          if (this.RegisterFormDetailArt.controls[x.LIB_Id].value == true) {
            if (
              EgalimDenreeValide.find(
                (p) =>
                  p.CODE_DENREE == this.DetailDenree.CODE_DENREE &&
                  p.IU_FOURNISSEUR == this.f.Fourni.value &&
                  p.IU_DENREE == this.DetailDenree.IU_DENREE &&
                  p.EGA_Id == x.LIB_Id &&
                  p.Tarif == 1
              ) == undefined
            ) {
              EgalimDenreeValide = [
                ...EgalimDenreeValide,
                {
                  IU_FOURNISSEUR: this.f.Fourni.value,
                  IU_DENREE: this.DetailDenree.IU_DENREE,
                  CODE_DENREE: this.DetailDenree.CODE_DENREE,
                  EGA_Id: x.LIB_Id,
                  LIB_Libelle1: '',
                  Tarif: 1,
                },
              ];
            }
          }
        });
      }
      // get allergene check value
      let AllergenesDenreeValide: ART_DENREE_ALLERGENES[] = [];
      if (this.ListAllergenes.length != 0) {
        this.ListAllergenes.forEach((x) => {
          if (this.RegisterFormInfos.controls[x.LIB_Id].value == true) {
            if (
              AllergenesDenreeValide.find(
                (p) =>
                  p.CODE_DENREE == this.DetailDenree.CODE_DENREE &&
                  p.IU_FOURNISSEUR == this.f.Fourni.value &&
                  p.IU_DENREE == this.DetailDenree.IU_DENREE &&
                  p.ALLERGENE_Id == x.LIB_Id &&
                  p.Tarif == 1
              ) == undefined
            ) {
              AllergenesDenreeValide = [
                ...AllergenesDenreeValide,
                {
                  IU_FOURNISSEUR: this.f.Fourni.value,
                  IU_DENREE: this.DetailDenree.IU_DENREE,
                  CODE_DENREE: this.DetailDenree.CODE_DENREE,
                  ALLERGENE_Id: x.LIB_Id,
                  LIB_Libelle1: '',
                  Tarif: 1,
                },
              ];
            }
          }
        });
      }

      let DenreeValNutri: NutriValAlimModel = {
        AlimGroupId: this.ListValueNutriAliment.AlimGroupId,
        AlimId: this.ListValueNutriAliment.AlimId,
        NutriValue: this.ListValueNutriAliment.NutriValue,
        IsEditNutriValue: this.IsEditValuNutri,
      };

      let DenreeSendValide: DenreeValideModel = {
        Denree: {
          IU_DENREE: this.DetailDenree.IU_DENREE,
          CODE_DENREE: this.DetailDenree.CODE_DENREE,
          OLD_DENREE: '',
          UNI_FACT: '',
          PRESENT: this.f.UnitFact.value,
          QTE_UTILE: this.f.QteUtile.value,
          DELAI_LIVRE: this.f5.Delai.value,
          UNI_CALC: this.f.UnitCalc.value,
          PRIX_DENREE: '',
          OLD_PRIX_EUROS: '',
          REF_EXTERNE: this.f.RefTarif1.value,
          TYPE_STOCK: '',
          ZONE_STOCK: this.f4.ZoneStockId.value,
          DENREE_IUFOU: this.f.Fourni.value,
          GERE_NUM_LOT: this.f3.GestnoLot.value === true ? '1' : '0',
          GERE_PEREMPTION: this.f3.GestDLC.value === true ? '1' : '0',
          OLD_COND_PIECE: '',
          VAL_COND_PIECE: '',
          IU_NUTRI_ALIM: DenreeValNutri?.AlimId ? DenreeValNutri.AlimId : '',
          POIDS_NET: this.f5.PoidsNet.value,
          POIDS_BRUT: this.f5.PoidsBrut.value,
          REF_EXT_ECO: this.f5.RefInfo.value,
          TYPE: this.f5.TypeInfo.value,
          STK_MINI: this.f4.StockMini.value,
          DATHRMAJ: '',
          USERMAJ: '',
          GESTDEL: '',
          DENREE_TVA: this.f.Tva.value,
          DENREE_IUSTOCK: '',
          PMP_DENREE: this.f4.Pmp.value,
          GERE_TEMPERAT: this.f3.GestTempe.value === true ? '1' : '0',
          TEMPERATURE1: this.f3.TempeDe.value.toString(),
          TEMPERATURE2: this.f3.TempeAu.value.toString(),
          RISQUE: this.f3.PtnCritique.value === true ? '1' : '0',
          HACCP: this.f3.NoHACCP.value,
          NOM_DENREE: this.f.ArtNom.value,
          LIB_DENREE: '',
          COMMENTAIRE1: '',
          CONDITIO: this.f.Cdt.value,
          COND_PIECE: this.f.Colisage.value,
          ABREG_DENREE: '',
          PRIX_EUROS: this.f.Prix1.value,
          INFO_CONSO: this.f5.InfoConso.value === true ? '1' : '0',
          HORS_STOCK: this.f4.HorsStock.value === true ? '1' : '0',
          PRIX_EUROS1: this.f2.Prix2.value,
          TAR_DATE_DEB:
            this.f.DateTarif1Du.value &&
            moment(this.f.DateTarif1Du.value)
              .local(false)
              .utc(true)
              .format('DD/MM/YYYY'),
          TAR_DATE_FIN:
            this.f.DateTarif1Au.value &&
            moment(this.f.DateTarif1Au.value)
              .local(false)
              .utc(true)
              .format('DD/MM/YYYY'),
          DENREE_IUFOU1: this.f2.Fourni2.value,
          REF_EXTERNE1: this.f2.Ref2.value,
          TAR_DATE_DEB1:
            this.f2.DateTarif2Du.value &&
            moment(this.f2.DateTarif2Du.value)
              .local(false)
              .utc(true)
              .format('DD/MM/YYYY'),
          TAR_DATE_FIN1:
            this.f2.DateTarif2Au.value &&
            moment(this.f2.DateTarif2Au.value)
              .local(false)
              .utc(true)
              .format('DD/MM/YYYY'),
          CONDT_FOU: this.f.CdtTarif1.value,
          CONDT_FOU1: this.f2.Cdt2.value,
          FLAG_UCOM: this.f.UnitCom.value && this.f.UnitCom.value.id,
          COMMENTAIRE: this.f5.InfoComment.value,
          DENREE_MARQUE: this.f.MarqueTarif1.value,
          DENREE_MARQUE1: this.f2.Marque2.value,
          COMMENT_FOU: this.f.CommentTarif1.value,
          COMMENT_FOU1: this.f2.CommentTarif2.value,
          HORS_BONECO: this.f5.ExcBonEco.value === true ? '1' : '0',
          REGROUPE_ETAT_PREP1: this.f5.PrepaGroupe.value === true ? '1' : '0',
          MARCHE: this.f.LotTarif1.value,
          MARCHE1: this.f2.Lot2.value,
          CODE_MARCHE: this.f.MarcheTarif1.value,
          CODE_MARCHE1: this.f2.Marche2.value,
          DENREE_PREP: this.f3.Preparation.value,
          UF_FOU: '',
          UF_FOU1: '',
          QUTILE_FOU: '',
          QUTILE_FOU1: '',
          JOURS_DISPO: this.f4.Dispo.value,
          FLAG_UNSTK: this.f.UnitStock.value && this.f.UnitStock.value.id,
          DENREE_PVTE: this.f5.Pv.value,
          ALLERGENES: '',
          INGREDIENT_ETIQ: this.f5.Ingredient.value,
          PMP_DENREE_OLD: undefined,
          NO_ALLERG: '',
          ARRONDI_BESOIN: this.f4.ArrondiUniSup.value === true ? '1' : '0',
          JOURS_CONSERVE: this.f4.JourConserve.value,
        },
        DenreeEgalim: EgalimDenreeValide,
        DenreeAllergene: AllergenesDenreeValide,
        DenreeNutriVal: DenreeValNutri,
      };

      this._denreeService
        .SaveEditDenree(DenreeSendValide)
        .subscribe((responseDenreeValide: DenreeValideModel) => {
          if (responseDenreeValide) {
            if (responseDenreeValide.Denree.IU_DENREE == "-404") {
              // dépasser 100 articles
              this.NotificationMsg(' Erreur, Famille complète !', false);
            }
            else {
              if (this.SelectedNodeArtile) {
                this.SelectedNodeArtile.item = this.f.ArtNom.value;
              } else {
                const newNodeArticle: DynamicFlatNode = {
                  item: this.f.ArtNom.value,
                  codeIU: responseDenreeValide.Denree.IU_DENREE,
                  codeFam: responseDenreeValide.Denree.CODE_DENREE,
                  level: 2,
                  expandable: false,
                  isLoading: false,
                };
                // TODO refresh node quand user continuer à cliquer après la création
                this._denreeService.EmitterAddedNode(newNodeArticle);
              }

              this.DetailDenree.CODE_DENREE = responseDenreeValide.Denree.CODE_DENREE;
              this.DetailDenree.IU_DENREE = responseDenreeValide.Denree.IU_DENREE;
              // reinit edit valeur nutritionel si save OK
              this.IsEditValuNutri = false;
              // MAJ list fournisseur avec le tarif comparé
              this._supplierService.GetAllListSupplierWithPriceSync(responseDenreeValide.Denree.CODE_DENREE).subscribe((res: VUE_ART_FOUR_TARIF[]) => {
                this.ListSupplier = res;
              });
              this.NotificationMsg('Modification enregistrée !', true);
            }
          }
          else {
            this.NotificationMsg('Error interne', false);
          }
        });
    } else {
      this.NotificationMsg('Champs obligatoire', false);
    }
  }

  public getFormValidationErrors(formcheck: FormGroup) {
    Object.keys(formcheck.controls).forEach((key) => {
      const controlErrors: ValidationErrors = formcheck.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
            controlErrors[keyError]
          );
        });
      }
    });
  }

  ngOnInit(): void {
    // this.IsLoadedArticle = false;
    this.isShowBtnCancel = true;
    // this.IsLoadedSupplier = false;
    // this.IsLoadedGroupAliment = false;
    // this.IsLoadedAliments = false;
    // this.IsLoadedValueNutri = false;
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    // console.log('tabChangeEvent => ', tabChangeEvent);
    // console.log('index => ', tabChangeEvent.index);
    this.currentTabIndex = tabChangeEvent.index;
    // console.log('tab ', this.currentTabIndex);
    if (this.currentTabIndex == 4) {
      this.GesSaisieValNutri();
    }
  }

  /***
   * load détail article
   *
   */
  ngOnChanges(changes: SimpleChanges) {

    // reinit all loader
    this.IsLoadedArticle = false;
    // this.IsLoadedSupplier = false;
    this.IsLoadedGroupAliment = false;
    this.IsLoadedAliments = false;
    this.IsLoadedValueNutri = false;
    this.showInputCreateGroup = false;
    this.showInputCreateAliment = false;
    this.IsEditValuNutri = false;
    this.GroupAlimEditId = undefined;
    this.AlimEditId = undefined;
    this.IsLoadedEgalim = false;
    this.IsLoadedAlergene = false;
    // this.IsComponentChanged = false;
    // ne pas loader à partir de 2eme fois
    //this.IsFirstLoad = false;
    // select article by search node article's tree
    let codeDenree = "";
    let IuDenree = "";
    if (changes.article) {
      if (changes.article.currentValue) {
        this.SelectedNodeArtile = changes.article.currentValue;
      }

      if (this.SelectedNodeArtile) {
        codeDenree = this.SelectedNodeArtile.codeFam;
        IuDenree = this.SelectedNodeArtile.codeIU;
      }
    }
    // select article by search article
    if (changes.SelectedArticleSearch) {
      if (changes.SelectedArticleSearch.currentValue) {
        codeDenree = this.SelectedArticleSearch.CODE_DENREE;
        IuDenree = this.SelectedArticleSearch.IU_DENREE;
      }
    }

    if (codeDenree != "" && IuDenree != "") {
      // check edit arcile
      // this.isEditArticle = this.CheckIsEditArticle(this.SelectedNodeArtile.codeFam);
      // get détail d'article
      this.DenreeSubscriptionRepository();
      this.BuildDetailDenree(true, codeDenree, IuDenree);
      this.MAJDetailDenreeParCatalogFournisseur();
    }
    else {
      this.DenreeSubscriptionRepository();
      this.BuildDetailDenree(false, '-1', '-1');
      this.MAJDetailDenreeParCatalogFournisseur();
    }

    if (this.currentTabIndex == 4) {
      // activer observale de saisie value nutri
      this.GesSaisieValNutri();
      // reinit control de création de group or aliment if exist
      this.RegisterFormNutri.removeControl('FormCreateGroupOrAliment');
    }

  }

  public CheckIsEditArticle(pCode: string, object: string, action: string) {
    // article est edité en fonction de fam
    // get FAM
    let valFam = pCode;

    if (pCode != '*') {
      if (pCode.length >= 2) {
        valFam = pCode.substring(0, 1);
      }
    }

    // console.log(valFam);

    let IsEdit = false;

    var rightSFam = this.right.find(p => p.ObjectId == object);

    if (rightSFam != undefined) {

      let EditValue = this.rightUser.find(p => p.ProfilId == rightSFam.ProfilId
        && p.InfId == rightSFam.InfId
        && p.ObjectId == rightSFam.ObjectId
        && p.RoleId == rightSFam.RoleId
        && p.Value == valFam
      )

      if (EditValue != undefined) {
        if (EditValue.Action == action) {
          IsEdit = true;
        }
      }
    }

    return IsEdit;
  }

  public DenreeSubscriptionRepository() {

    // prevent memory leak when component hot reload 
    this._subscriptions.forEach((s) => s.unsubscribe());

    this._subscriptions.push(combineLatest([
      this._denreeService.DetailDenreeSubject,
      this._supplierService.ListSupplierWithPriceSubject,
      this._commonService.UnitPoidsSubject,
      this._commonService.UnitCalculsSubject,
      this._commonService.GraalCentreSubject,
      this._commonService.ListStockSubject,
      this._commonService.ListZoneStockSubject,
      this._commonService.ListTvaSubject,
      this._commonService.ListTypeInfoDenreeSubject,
      this._commonService.ListEgalimSubject,
      this._commonService.ListAllergenesSubject,
      this._commonService.NutriGroupSubject
    ])
      .subscribe(([ResultDetailDenree, ListSupplierResult, ResultUnitPoids, ResultUnitCalcul, ResultGraalCentre,
        ResultStock, ResultZoneStock, ResultTva, ResultTypeInfoDenree, ResultTypeEgalim, ResultAllergenes, ResultNutriGroup]
        : [DenreeModel, VUE_ART_FOUR_TARIF[], GES_PRES[], GRAAL_LIBELLE[], GRAAL_CENTRE[], STOCK_STOCK[], STOCK_ZONE_STOCK[], GRAAL_CENTRE[],
          GRAAL_CENTRE[], GRAAL_LIBELLE[], GRAAL_LIBELLE[], NUTRI_GROUPE[]]) => {
        //console.log('fireeeeeeeeeeeee');
        // listener detail denrée
        if (ResultDetailDenree && ListSupplierResult) {
          // console.log(ResultDetailDenree);
          // get all droit saisie
          this.rightUser = this._commonService.GetRights();
          // check droit saisie
          let codeArticleCheckEdit = "";
          this.isEditArticle = true;
          if (this.SelectedNodeArtile) {
            codeArticleCheckEdit = this.SelectedNodeArtile.codeFam;
          }
          if (this.SelectedArticleSearch) {
            codeArticleCheckEdit = this.SelectedArticleSearch.CODE_DENREE;
          }

          if (codeArticleCheckEdit != "") {
            this.isEditArticle = this.CheckIsEditArticle(codeArticleCheckEdit, 'Btsfam', '*')
          }

          // list unit poids
          this.ListUnitPoids = ResultUnitPoids;
          // list unit calcul
          this.ListUnitCalcul = ResultUnitCalcul;
          // list graal centre
          this.ListGraalCentre = ResultGraalCentre;
          // list stock
          this.ListStock = ResultStock;
          // list zone stock
          this.ListZoneStock = ResultZoneStock;
          // list TVA
          this.ListTva = ResultTva;
          // list Type Info Denree
          this.ListTypeInfoDenree = ResultTypeInfoDenree;
          // lister all nutri group
          this.ListNutriGroup = [];
          this.ListNutriGroup = ResultNutriGroup;
          // list egalim
          if (ResultTypeEgalim.length != 0) {
            this.ListEgalim = ResultTypeEgalim;
            // construction de form controle egalim dynamique
            this.ListEgalim = this.ListEgalim.sort((a, b) =>
              Number(a.LIB_Ordre) > Number(b.LIB_Ordre) ? 1 : -1
            );
            this.ListEgalim.forEach((x) => {
              this.RegisterFormDetailArt.addControl(
                x.LIB_Id,
                new FormControl(false)
              );
            });
          }
          // list allergens
          if (ResultAllergenes.length != 0) {
            this.ListAllergenes = ResultAllergenes;
            // construction de form controle egalim dynamique
            ResultAllergenes.forEach((x) => {
              this.RegisterFormInfos.addControl(
                x.LIB_Id,
                new FormControl(false)
              );
            });
          }
          // affectation détail denrée
          this.DetailDenree = ResultDetailDenree;

          // if (ResultDetailDenree.IU_DENREE == '-1') {
          //   // si iu art == -1, cas de création d'une nouvelle article, on recharge la liste des fournisseurs
          //   this._supplierService.GetAllListSupplierWithPrice(
          //     ResultDetailDenree.IU_DENREE
          //   );
          // }

          this.MAJArticle(ResultDetailDenree, false);
          this.MAJArticleTarif2(ResultDetailDenree, false);
          this.MAJArticleTracabilite(ResultDetailDenree);
          this.MAJArticleStock(ResultDetailDenree);
          this.MAJArticleInfo(ResultDetailDenree);
          this.MAJNutrinon(ResultDetailDenree);

          // List fournisseur tarif par denrée
          // set dropdown fournisseur 2 selected
          this.selectedFou1 = ListSupplierResult.find(p => p.IU_FOURNISSEUR == ResultDetailDenree.DENREE_IUFOU1);
          // set dropdown fournisseur selected
          this.selectedFou = ListSupplierResult.find(p => p.IU_FOURNISSEUR == ResultDetailDenree.DENREE_IUFOU);
          this.ListSupplier = ListSupplierResult;
          // get detail denrée egalim après que denrée défini
          // par défaut, le tarif est 1
          let tarif: number = 1;
          // get detail denrée allergenes après que denrée défini
          // par défaut, le tarif est 1
          this.MAJEgalimEtAllergene(ResultDetailDenree.DENREE_IUFOU,
            ResultDetailDenree.IU_DENREE,
            ResultDetailDenree.CODE_DENREE,
            tarif);

        }

        // finish load
        this.IsLoadedArticle = true;
        this.IsFirstLoad = true;
        this.IsLoadedGroupAliment = true;
      }));
  }

  public BuildDetailDenree(isChange: boolean, pCodeDenree: string, pIuDenree: string) {

    if (isChange) {
      // get détail denree info
      this._denreeService.GetDetailDenree(pCodeDenree, pIuDenree);
    }

    // get list fournisseur avec le tarif comparé
    this._supplierService.GetAllListSupplierWithPrice(pCodeDenree);

    this._commonService.GetListUnitPoids();
    this._commonService.GetListUnitCalcul();
    this._commonService.GetListGraalCentrePreparation();
    this._commonService.GetListStock();
    this._commonService.GetListZoneStock();
    this._commonService.GetAllTva();
    this._commonService.GetAllTypeInfoDenree();
    this._commonService.GetAllEgalim();
    this._commonService.GetAllAllergenes();
    this._commonService.GetAllNutrigroup();


  }

  /****
   * get detail denrée egalim après que denrée défini
   * get detail denrée allergenes après que denrée défini
   * par défaut, le tarif est 1
   */
  public MAJEgalimEtAllergene(pIuFou: string, IuDenree: string, pCodeDenree: string, NumTarif: number) {
    // get detail denrée egalim après que denrée défini en fonction du fournisseur
    // par défaut, le tarif est 1
    this._commonService.GetDenreeEgalim$(pIuFou, IuDenree, pCodeDenree, NumTarif);
    // get detail denrée allergenes après que denrée défini
    // par défaut, le tarif est 1
    this._commonService.GetDenreeAllergenes(pIuFou, IuDenree, pCodeDenree, NumTarif);

    this._subscriptions.push(combineLatest([
      this._commonService.ListDenreeEgalimSubject,
      this._commonService.ListDenreeAllergenesSubject,

    ]).subscribe(([ResultDenreeEgalim, ResultDenreeAllergenes]
      : [ART_DENREE_EGALIM[], ART_DENREE_ALLERGENES[]]) => {
      // MAJ denree egalim

      if (ResultDenreeEgalim.length != 0) {
        this.ListDenreeEgalim = ResultDenreeEgalim;
        if (this.ListEgalim.length != 0) {
          this.ListEgalim.forEach((x) => {
            let egalimIdDenree = ResultDenreeEgalim.find(p => p.EGA_Id == x.LIB_Id);
            if (egalimIdDenree != undefined) {
              if (x.LIB_Id == egalimIdDenree.EGA_Id) {
                this.RegisterFormDetailArt.controls[x.LIB_Id].setValue(true);
              }
              else {
                this.RegisterFormDetailArt.controls[x.LIB_Id].setValue(false);
              }
            }
            else {
              this.RegisterFormDetailArt.controls[x.LIB_Id].setValue(false);
            }
            // désactiver egalime si le fournisseur est vide
            if (!pIuFou) {
              if (this.selectedFou && this.selectedFou.IU_FOURNISSEUR) {
                this.RegisterFormDetailArt.controls[x.LIB_Id].enable();
              }
              else {
                this.RegisterFormDetailArt.controls[x.LIB_Id].disable();
              }
            }
            else {
              // activer egalime si le fournisseur n'est pas vide
              this.RegisterFormDetailArt.controls[x.LIB_Id].enable();
            }
          });
        }
      } else {
        // init all control egalim à false en fonction du fournisseur
        this.ListEgalim.forEach((x) => {
          this.RegisterFormDetailArt.controls[x.LIB_Id].setValue(false);
          // désactiver egalime si le fournisseur est vide
          if (!pIuFou) {
            if (this.selectedFou && this.selectedFou.IU_FOURNISSEUR) {
              this.RegisterFormDetailArt.controls[x.LIB_Id].enable();
            }
            else {
              this.RegisterFormDetailArt.controls[x.LIB_Id].disable();
            }
          }
          else {
            // activer egalime si le fournisseur n'est pas vide
            this.RegisterFormDetailArt.controls[x.LIB_Id].enable();
          }
        });
      }

      // MAJ denree allergenes
      if (ResultDenreeAllergenes.length != 0) {
        this.ListDenreeAllergenes = ResultDenreeAllergenes;
        if (this.ListAllergenes.length != 0) {
          this.ListAllergenes.forEach((x) => {
            let allergeneIdDenree = ResultDenreeAllergenes.find(p => p.ALLERGENE_Id == x.LIB_Id);
            if (allergeneIdDenree != undefined) {
              if (x.LIB_Id == allergeneIdDenree.ALLERGENE_Id) {
                this.RegisterFormInfos.controls[x.LIB_Id].setValue(true);
              }
              else {
                this.RegisterFormInfos.controls[x.LIB_Id].setValue(false);
              }
            }
            else {
              this.RegisterFormInfos.controls[x.LIB_Id].setValue(false);
            }
          });
        }
      } else {
        // init all control allergenes à false
        this.ListAllergenes.forEach((x) => {
          this.RegisterFormInfos.controls[x.LIB_Id].setValue(false);
        });
      }

      this.IsLoadedAlergene = true;
      this.IsLoadedEgalim = true;

    }));

  }


  public ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  public Cancel() {
    this._commonService.GetListUnitPoids();
    this._commonService.GetListUnitCalcul();
  }

  // ngAfterContentChecked() {
  //   // set dropdown fournisseur selected
  //   if (this.RegisterFormDetailArt.controls['Fourni'].value) {
  //     this.selectedFou = this.ListSupplier.find(
  //       (p) =>
  //         p.IU_FOURNISSEUR ==
  //         this.RegisterFormDetailArt.controls['Fourni'].value
  //     );
  //   }

  //   // set dropdown fournisseur selected
  //   if (this.RegisterFormDetailTarif2.controls['Fourni2'].value) {
  //     this.selectedFou1 = this.ListSupplier.find(
  //       (p) =>
  //         p.IU_FOURNISSEUR ==
  //         this.RegisterFormDetailTarif2.controls['Fourni2'].value
  //     );
  //   }

  //   this.IsLoadedSupplier = true;

  //   if (this.ListValueNutriAliment) {
  //     this.rowNumberNutrital = this.GetRowNumberNutrital();
  //     this.RegisterFormNutri.controls['NutriGroup'].setValue(
  //       this.ListValueNutriAliment.AlimGroupId
  //     );
  //     this.IsLoadedGroupAliment = true;
  //   }
  // }

  /*** VALEURS NUTRITIONNELLES ***/

  public onNutriGroupChange(event: any) {
    this.IsLoadedAliments = false;

    if (event && event.value) {

      this.ListValueNutriAliment.AlimGroupId = event.value;
      this._subscriptions.push(
        this._denreeService
          .GetAlimentByGroup(event.value)
          .subscribe((result: VUE_NUTRI_ALIM_GROUPE[]) => {
            this.ListAliments = result;
            this.IsLoadedAliments = true;
          })
      );
    }
  }

  public CreateAliments(type: string) {
    this.showInputCreateGroup = false;

    if (!this.ListValueNutriAliment.AlimGroupId) {
      this.NotificationMsg("Sélectionnez un group d'aliment", false);
      return;
    }

    this.showInputCreateAliment = !this.showInputCreateAliment;

    this.CreateWhat = type;

    if (this.showInputCreateAliment) {

      // remove controle if exist
      this.RegisterFormNutri.removeControl('FormCreateGroupOrAliment');

      this.RegisterFormNutri.addControl(
        'FormCreateGroupOrAliment',
        new FormGroup({
          NutriNewGroupOrAlim: new FormControl('', [Validators.required]),
        })
      );
    } else {
      this.RegisterFormNutri.removeControl('FormCreateGroupOrAliment');
    }
  }

  public CreateGroup(type: string) {
    this.showInputCreateGroup = !this.showInputCreateGroup;
    this.showInputCreateAliment = false;
    this.CreateWhat = type;
    // let formnew = this._formBuilderNutri.group({
    //   NutriNewGroupOrAlim: ['', Validators.required],
    // });
    // this.RegisterFormNutri.addControl(
    //   'FormCreateGroupOrAliment', formnew
    // );

    if (this.showInputCreateGroup) {

      // remove controle if exist
      this.RegisterFormNutri.removeControl('FormCreateGroupOrAliment');

      this.RegisterFormNutri.addControl(
        'FormCreateGroupOrAliment',
        new FormGroup({
          NutriNewGroupOrAlim: new FormControl('', [Validators.required]),
        })
      );
    } else {
      this.RegisterFormNutri.removeControl('FormCreateGroupOrAliment');
    }
  }
  /****
   *
   * Submit création group or aliment
   */
  public submitCreateGroupOrAliments() {
    if (!this.RegisterFormNutri.valid) {
      this.NotificationMsg('Champs obligatoire', false);
      return;
    }
    let NewGroupAliment: NUTRI_GROUPE;
    let NewAliment: NUTRI_ALIMENT;

    if (this.CreateWhat == 'G') {
      NewGroupAliment = {
        NUTRI_GROUPE_IU: '-1',
        NUTRI_GROUPE_LIB:
          this.RegisterFormNutri.controls['FormCreateGroupOrAliment'].value
            .NutriNewGroupOrAlim,
      };
    }
    if (this.CreateWhat == 'A') {
      NewAliment = {
        ALIM_IU: '-1',
        ALIM_NOM:
          this.RegisterFormNutri.controls['FormCreateGroupOrAliment'].value
            .NutriNewGroupOrAlim,
        NUTRI_GROUP: this.ListValueNutriAliment.AlimGroupId,
      };
    }

    if (NewGroupAliment || NewAliment) {

      if (this.CreateWhat == 'A') {
        this._subscriptions.push(
          this._denreeService
            .PostSaveCreateAliment(NewAliment)
            .subscribe((result: VUE_NUTRI_ALIM_GROUPE) => {
              if (result) {
                if (result.ALIM_IU == "-409") {
                  this.NotificationMsg('Erreur, Nom d\'aliment existe déjà !', false);
                }
                else {
                  this.NotificationMsg('Modification enregistrée !', true);
                  this.ListAliments = [...this.ListAliments, result];
                  this.showInputCreateAliment = false; // cacher input create aliment
                  // vider liste valeur nutri 
                  if (this.ListValueNutriAliment) {
                    this.ListValueNutriAliment.NutriValue = [];
                  }
                }
              }
            })
        );
      }
      if (this.CreateWhat == 'G') {
        this._subscriptions.push(
          this._denreeService
            .PostSaveCreateGroupAliment(NewGroupAliment)
            .subscribe((result: NUTRI_GROUPE) => {
              if (result) {
                if (result.NUTRI_GROUPE_IU == "-409") {
                  this.NotificationMsg('Erreur, Nom de groupe existe déjà !', false);
                }
                else {
                  this.NotificationMsg('Modification enregistrée !', true);
                  this.ListNutriGroup = [...this.ListNutriGroup, result];
                  this.showInputCreateGroup = false; // cacher input create groupe
                  this.ListValueNutriAliment.AlimGroupId = result.NUTRI_GROUPE_IU // set new id group selectioné to déclancher event dans ngAftercontentchecked
                  this.ListAliments = []; // vider liste d'aliment pour le nouveau groupe
                  // vider liste valeur nutri 
                  if (this.ListValueNutriAliment) {
                    this.ListValueNutriAliment.NutriValue = [];
                  }
                }

              }
            })
        );
      }
    }
  }

  public onAlimentsChange(pAlimIu: string) {
    if (this.IsEditValuNutri) {
      const message =
        ' Vous allez perdre la valeur nutritionelle saisie, voulez-vous vraiment continuer ?';

      const dialogData = new ConfirmDialogModel('Confirmation', message);

      const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        maxWidth: '500px',
        data: dialogData,
        height: '220px',
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.AffectOnAlimentChange(pAlimIu);
        } else {
          this.RegisterFormNutri.controls['NutriAlim'].setValue([
            this.ListValueNutriAliment.AlimId,
          ]);
        }
      });
    } else {
      this.AffectOnAlimentChange(pAlimIu);
    }
  }

  public AffectOnAlimentChange(pAlimIu: string) {
    this.IsLoadedValueNutri = false;
    this.IsEditValuNutri = false;

    if (this.ListValueNutriAliment) {
      this.ListValueNutriAliment.NutriValue = [];
    }

    if (pAlimIu) {
      this._subscriptions.push(
        this._denreeService
          .GetValueNutriByAliment(pAlimIu)
          .subscribe((result: NutriValAlimModel) => {
            result.NutriValue.forEach((x) => {
              this.RegisterFormNutri.removeControl(x.Id);
              // calcul energie en kcal
              let valEnergieKcal = x.Value;

              if (x.Id == "01") {
                let valLipide = result.NutriValue.find(p => p.Id == "03")?.Value;
                let valProtide = result.NutriValue.find(p => p.Id == "04")?.Value;
                let valGlucide = result.NutriValue.find(p => p.Id == "05")?.Value;
                let valAlcool = result.NutriValue.find(p => p.Id == "06")?.Value;
                valEnergieKcal = this.CalculerValEnergieKCAL(valLipide, valProtide, valGlucide, valAlcool);
              }

              if (x.Id == "02") {
                // 1 kcal = 4,18 kJ
                if (this.RegisterFormNutri.controls['01'].value) {
                  valEnergieKcal = (Number(this.RegisterFormNutri.controls['01'].value) * 4.18).toFixed(2);
                }
              }

              this.RegisterFormNutri.addControl(
                x.Id,
                new FormControl(valEnergieKcal, [
                  Validators.pattern('^[0-9]+(?:[.,][0-9]{1,4})?$'),
                ])
              );
            });
            this.ListValueNutriAliment = result;
            this.rowNumberNutrital = this.GetRowNumberNutrital();
            this.IsLoadedValueNutri = true;
            // add control edit
            this.GesSaisieValNutri();
          })
      );
    }
  }

  public CalculerValEnergieKCAL(lipide: any, protide: any, glucide: any, alcool: any) {

    let valKCAL: number = 0;

    let valLipide = Number(lipide);
    let valProtide = Number(protide);
    let valGlucide = Number(glucide);
    let valAlcool = Number(alcool);

    valKCAL = (valLipide * 9) + (valProtide * 4) + (valGlucide * 4) + (valAlcool * 7);

    return valKCAL.toFixed(2);

  }

  public GesSaisieValNutri() {
    this.ListValueNutriAliment.NutriValue.forEach((x) => {
      this._subscriptions.push(
        this.RegisterFormNutri.get(x.Id)
          .valueChanges.pipe(debounceTime(500))
          .subscribe((val) => {
            let regexp = /^[0-9]+(?:[.,][0-9]{1,4})?$/; // check quantité saisie regex
            let checkOk: boolean = false;
            if (regexp.test(val)) {
              if (!isNaN(Number(val.replace(',', '.')))) {

                let idxValEditNutri =
                  this.ListValueNutriAliment.NutriValue.findIndex(
                    (p) => p.Id == x.Id
                  );
                if (idxValEditNutri != -1) {
                  this.ListValueNutriAliment.NutriValue[idxValEditNutri].Value =
                    val;
                  this.IsEditValuNutri = true;
                }
              }
            }
          })
      );
    });
  }

  public GroupEditAlim() {
    this.GroupAlimEditId = this.ListValueNutriAliment.AlimGroupId;

    this.RegisterFormNutri.controls['InputGroupNameEdit'].setValue(
      this.ListNutriGroup.find((p) => p.NUTRI_GROUPE_IU == this.GroupAlimEditId)
        ?.NUTRI_GROUPE_LIB
    );
  }

  public ValideEditGroup() {

    if (!this.RegisterFormNutri.controls['InputGroupNameEdit'].value) {
      this.NotificationMsg('Nom de groupe obligatoire', false);
      return;
    }

    let groupEdit: NUTRI_GROUPE = {
      NUTRI_GROUPE_IU: this.GroupAlimEditId,
      NUTRI_GROUPE_LIB: this.RegisterFormNutri.controls['InputGroupNameEdit'].value
    };

    this._subscriptions.push(
      this._denreeService
        .PostSaveCreateGroupAliment(groupEdit)
        .subscribe((result: NUTRI_GROUPE) => {
          if (result) {
            if (result.NUTRI_GROUPE_IU == "-409") {
              this.NotificationMsg('Erreur, Nom de groupe existe déjà !', false);
            }
            else {
              this.NotificationMsg('Modification enregistrée !', true);
              let idxEditedGroup = this.ListNutriGroup.findIndex(p => p.NUTRI_GROUPE_IU == result.NUTRI_GROUPE_IU);
              // set new nom group
              if (idxEditedGroup != -1) {
                this.ListNutriGroup[idxEditedGroup].NUTRI_GROUPE_LIB = this.RegisterFormNutri.controls['InputGroupNameEdit'].value;
              }

              this.GroupAlimEditId = undefined;
            }

          }
        })
    );

  }

  public EditAliment(event: any, pAlimEdit: VUE_NUTRI_ALIM_GROUPE) {
    event.stopPropagation();
    event.preventDefault();

    let IdxAlimEdit = this.ListAliments.findIndex(
      (p) => p.ALIM_IU == pAlimEdit.ALIM_IU
    );

    if (IdxAlimEdit != -1) {
      this.ListAliments[IdxAlimEdit].IsEdit = true;
      this.AlimEditId = pAlimEdit.ALIM_IU;
      this.RegisterFormNutri.controls['InputAlimNameEdit'].setValue(this.ListAliments[IdxAlimEdit].ALIM_NOM);
    }
  }

  public ValideEditAlim(event: any, pAlimEdit: VUE_NUTRI_ALIM_GROUPE) {

    event.stopPropagation();
    event.preventDefault();

    if (!this.RegisterFormNutri.controls['InputAlimNameEdit'].value) {
      this.NotificationMsg('Nom d\'aliment obligatoire', false);
      return;
    }

    let EditedAlim: NUTRI_ALIMENT = {
      ALIM_IU: pAlimEdit.ALIM_IU,
      ALIM_NOM: this.RegisterFormNutri.controls['InputAlimNameEdit'].value,
      NUTRI_GROUP: pAlimEdit.NUTRI_GROUPE_IU
    };

    this._subscriptions.push(
      this._denreeService
        .PostSaveCreateAliment(EditedAlim)
        .subscribe((result: VUE_NUTRI_ALIM_GROUPE) => {
          if (result) {
            if (result.ALIM_IU == "-409") {
              this.NotificationMsg('Erreur, Nom d\'aliment existe déjà !', false);
            }
            else {
              this.NotificationMsg('Modification enregistrée !', true);

              // set new nom d'aliment
              let idxEditedAliment = this.ListAliments.findIndex(p => p.ALIM_IU == result.ALIM_IU);

              if (idxEditedAliment != -1) {
                this.ListAliments[idxEditedAliment].ALIM_NOM = this.RegisterFormNutri.controls['InputAlimNameEdit'].value;
              }

              this.AlimEditId = undefined;
            }
          }
        })
    );

  }

  public CancelEditAliment(event: any, pAlimEdit: VUE_NUTRI_ALIM_GROUPE) {
    event.stopPropagation();
    event.preventDefault();
    this.AlimEditId = undefined;
  }

  public addNutrital() {
    this.ListValueNutriAliment.NutriValue.push({
      Id: '-1',
      Lib: 'test',
      Value: '-1',
    });
    this.rowNumberNutrital = this.GetRowNumberNutrital();
  }

  public GetRowNumberNutrital() {
    let nbRow = 0;

    if (this.ListValueNutriAliment) {
      if (this.ListValueNutriAliment.NutriValue.length != 0) {
        if (this.ListValueNutriAliment.NutriValue.length % 3 == 0) {
          nbRow = this.ListValueNutriAliment.NutriValue.length / 3;
        } else {
          nbRow =
            Math.floor(this.ListValueNutriAliment.NutriValue.length / 3) + 1;
        }
      }
    }

    return nbRow;
  }

  public NotificationMsg(msg: string, isOk: boolean) {
    if (isOk) {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-success'],
      });
    } else {
      this._snackBar.open(msg, 'Fermer', {
        duration: this.durationNotification,
        horizontalPosition: this._horizontalPosition,
        verticalPosition: this._verticalPosition,
        panelClass: ['mat-notification-error'],
      });
    }
  }

  public CancelEditGroup() {
    this.GroupAlimEditId = undefined;
  }
}
